import React from "react";
import ReactMarkdown from "react-markdown";
import { format } from "date-fns";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";

const CodeBlock = ({ language, value }) => {
  return (
    <div style={{ position: "relative", marginBottom: "16px" }}>
      <div
        style={{
          position: "absolute",
          top: "5px",
          right: "10px",
          fontSize: "12px",
          color: "#999",
        }}
      >
        {language}
      </div>
      <SyntaxHighlighter
        language={language}
        style={dark}
        customStyle={{
          margin: 0,
          borderRadius: "8px",
          padding: "30px 15px 15px",
        }}
      >
        {value}
      </SyntaxHighlighter>
    </div>
  );
};

const TripPlanner = ({
  chatRef,
  isSubscribed,
  remainingUses,
  AIIcon,
  chatContainerRef,
  messages,
  currentUser,
  renderParagraph,
  inputMessage,
  setInputMessage,
  handleSendMessage,
}) => {
  return (
    <div
      ref={chatRef}
      style={{
        position: "fixed",
        top: "80px",
        right: "20px",
        width: "300px",
        height: "500px",
        background: "#1E1E1E",
        borderRadius: "15px",
        boxShadow: "0 4px 20px rgba(0,0,0,0.2)",
        display: "none",
        flexDirection: "column",
        opacity: 0,
        transform: "scale(0.1) translate(150px, -100px)",
        transformOrigin: "top right",
        transition: "opacity 0.3s ease, transform 0.3s ease",
        zIndex: 1000,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          background: "linear-gradient(135deg, #2C2C2E 0%, #1E1E1E 100%)",
          color: "white",
          padding: "10px",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
          borderRadius: "15px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={AIIcon}
            alt="AI"
            style={{
              width: "28px",
              height: "28px",
              marginRight: "5px",
              filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.1))",
            }}
          />
          <span
            style={{
              fontWeight: "700",
              fontSize: "18px",
              letterSpacing: "0.5px",
              background: "linear-gradient(45deg, #64B5F6, #0A84FF)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              marginRight: "-60px",
            }}
          >
            VibeAI Trip Planner
          </span>
        </div>
      </div>
      <div
        ref={chatContainerRef}
        style={{
          flex: 1,
          overflowY: "auto",
          padding: "16px",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          background: "#1C1C1E",
        }}
      >
        {messages.map((message, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection:
                message.senderId === currentUser.uid ? "row-reverse" : "row",
              alignItems: "flex-start",
              marginBottom: "16px",
              animation: "fadeInUp 0.3s ease-in-out",
            }}
          >
            <img
              src={
                message.senderId === currentUser.uid
                  ? currentUser.profileImage ||
                    "/static/media/CircleLogo.ced1583cf407a90a6a6aacfe764022ee.svg"
                  : AIIcon
              }
              alt={message.senderId === currentUser.uid ? "You" : "AI"}
              style={{
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                margin: "0 8px",
              }}
            />
            <div
              style={{
                maxWidth: "75%",
                backgroundColor:
                  message.senderId === currentUser.uid ? "#4A4A4A" : "#2C2C2E",
                padding: "5px 14px",
                borderRadius: "18px",
                color: "white",
                fontSize: "14px",
                lineHeight: "1.4",
                boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
              }}
            >
              <ReactMarkdown
                components={{
                  a: ({ node, ...props }) => (
                    <a
                      {...props}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#64B5F6" }}
                    />
                  ),
                  code: ({ node, inline, className, children, ...props }) => {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                      <CodeBlock
                        language={match[1]}
                        value={String(children).replace(/\n$/, "")}
                      />
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  },
                  p: renderParagraph,
                }}
              >
                {message.text}
              </ReactMarkdown>
              <div
                style={{
                  fontSize: "10px",
                  color: "#A0A0A0",
                  marginTop: "4px",
                  textAlign:
                    message.senderId === currentUser.uid ? "right" : "left",
                }}
              >
                {format(new Date(message.timestamp), "p")}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          padding: "12px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#2C2C2E",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#1C1C1E",
            borderRadius: "20px",
            padding: "4px",
            flex: 1,
          }}
        >
          <input
            type="text"
            placeholder="Ask anything..."
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
            style={{
              flex: 1,
              padding: "8px 12px",
              border: "none",
              backgroundColor: "transparent",
              color: "white",
              fontSize: "14px",
              outline: "none",
            }}
          />
          <button
            onClick={handleSendMessage}
            style={{
              backgroundColor: "#0A84FF",
              color: "white",
              border: "none",
              borderRadius: "50%",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              transition: "background-color 0.2s ease",
              marginLeft: "8px",
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TripPlanner;
