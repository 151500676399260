import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase"; // Adjust the import path as needed
import "../assets/styles/postPage.css";
import { useNavigate } from "react-router-dom";
import { ref, getDownloadURL } from "firebase/storage"; // Import storage functions
import { storage } from "../firebase"; // Make sure you import your Firebase storage instance
import like from "../assets/images/Like.svg";
import unlike from "../assets/images/Unlike.svg";
import AuthContext from "../context/AuthContext"; // Import AuthContext to access currentUser
import { useContext } from "react";
import { runTransaction, serverTimestamp } from "firebase/firestore";
import defaultImage from "../assets/images/CircleLogo.svg";
import sendIcon from "../assets/images/send.svg";
import { collection, addDoc } from "firebase/firestore"; // Import the addDoc function
import { getDocs, query, orderBy } from "firebase/firestore"; // Import the getDocs and query functions
import likeIcon from "../assets/images/Like.svg"; // Import the like icon
import unlikeIcon from "../assets/images/Unlike.svg"; // Import the unlike icon
import { increment } from "firebase/firestore";
import { Share } from "@capacitor/share";
import { updateDoc } from "firebase/firestore";
import Sidebar from "../components/Sidebar"; // Import the Sidebar component
import TournamentSidebar from "../components/TournamentSidebar";
import { db } from "../firebase";
import { where } from "firebase/firestore";
import { auth } from "../firebase";
import axios from "axios";
import { useRef } from "react";
import { Capacitor } from "@capacitor/core";
import { Plugins } from "@capacitor/core";
import { Link } from "react-router-dom";
import { deleteDoc } from "firebase/firestore";
import threeDotIcon from "../assets/images/3dot.svg";

const { LocalNotifications } = Plugins;

const cardStyle = {
  background: "#1d2021",
  borderRadius: "12.84px",
  margin: "10px auto",
  padding: "16px",
  width: "90%",
  maxWidth: "350px",
  height: "auto",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  cursor: "pointer",
  opacity: 0,
  transform: "translateY(20px)",
  animation: "fadeInUp 0.5s forwards",
  transition: "box-shadow 0.3s ease",
};

const fadeInUpKeyframes = `
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const PostPage = () => {
  const navigate = useNavigate();
  const { postId } = useParams();
  const { currentUser } = useContext(AuthContext); // Use AuthContext to get the current user
  const [post, setPost] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(defaultImage);
  const [username, setUsername] = useState("");
  const [isLiked, setIsLiked] = useState(false); // State to track like status
  const [currentUserProfileImageUrl, setCurrentUserProfileImageUrl] =
    useState(defaultImage);
  const [isImageOpen, setIsImageOpen] = useState(false);

  const [postImageUrl, setPostImageUrl] = useState(null);

  const [comment, setComment] = useState(""); // State for the comment input

  const [comments, setComments] = useState([]);

  const [replyingTo, setReplyingTo] = useState(null);
  const [reply, setReply] = useState("");

  const [repliesVisible, setRepliesVisible] = useState({});

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1439);
  const [isWideDesktop, setIsWideDesktop] = useState(window.innerWidth >= 1580);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userRegion, setUserRegion] = useState(null);
  const [activeTab, setActiveTab] = useState("myRegion");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [postAs, setPostAs] = useState("user");
  const [showPostAsDropdown, setShowPostAsDropdown] = useState(false);
  const [userTeam, setUserTeam] = useState(null);
  const dropdownRef = useRef(null);
  const apiKey =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiOTlmNmYyMzYwNTY0ZTY1NmJiODlkNTUwNmYzZjRiMmMxNDA0M2E2YjZlMWVkNWRmODVkNThjYzkzOWFiYzk0YjIyYTYwYjA4MzcyNTZmNjYiLCJpYXQiOjE3MTY2NzM2MTguMTM0NTE0MSwibmJmIjoxNzE2NjczNjE4LjEzNDUxNywiZXhwIjoyNjYzMzYyMDE4LjEyODY2NSwic3ViIjoiMTI3Nzg2Iiwic2NvcGVzIjpbXX0.jhOKVtQQI1acAwIRrAR78xw7l__XHewZzJDYBf3OMzHZ4zsIh_6jWG1ulswGCN6128v_dwPji6s8JmVQj_tmcn-KyDLQACkY8ZJHQXZLIuTSrNGXFKE-DLqFKO9an04kmZoMKtZay86bCuSMiHoJGkd9WctvUdAkjzNdsWBz8lwD48hLY5h2F5U55YhX2areEoPyioOsN1QHe9ExoXOk8fW88vpgvw7_aZnmhTp5BV6WICnIlP1PCLWxUI_EwI8X32QJ1PJhOUFJ6eEb7iM2KPT3jlJmJBy675wDCix84H11jSUCJs8F-LIXRERVERniWElTWp9tIJYRk1HRQN4bhCEnuzL8U01oO49Ghc-k9vYQnY3WlHwAJEGuPQtQt8NttI2DcBDcBM3acQXHHHfvKvk6pc1__bwM32W41Ealqku1dL5Jw8MNUCFqAthDd--mjtkyevuGJsLwu1NobdI94wNDHna8iuRg27XwRGXJKGy32scGsmeBnplhEdOBbVu44Ns1MGJuxmLLffICQWVdEl4Gwth63r4AzBiX__iMYwJw39MfWpiyYrDZRX7Z6n12m2WeeZqrdmRAuSyzVSeZIqFs6TgqbgAuFOMo68aN_6InrbMD62PvXhdH8CL6wR05_v_Gz10OJtAWdwboXB780tdd7X5mdP2KcBZHbqvSbl4";
  const seasonId = "190"; // Update this with the correct season ID

  const replyInputRef = useRef(null);

  const [menuOpen, setMenuOpen] = useState({ commentId: null, replyId: null });

  const [commentCount, setCommentCount] = useState(0);

  // Add this function to calculate the total number of comments and replies
  const calculateCommentCount = (comments) => {
    return comments.reduce((total, comment) => {
      return total + 1 + (comment.replies ? comment.replies.length : 0);
    }, 0);
  };

  // Modify the existing useEffect that fetches comments
  useEffect(() => {
    const fetchCommentsAndCount = async () => {
      if (!postId) return;

      const commentsRef = collection(firestore, `posts/${postId}/comments`);
      const commentsQuery = query(commentsRef, orderBy("createdAt", "desc"));
      const commentsSnapshot = await getDocs(commentsQuery);

      let totalCount = 0;
      const commentsWithReplies = await Promise.all(
        commentsSnapshot.docs.map(async (commentDoc) => {
          const comment = { id: commentDoc.id, ...commentDoc.data() };
          totalCount++; // Count the comment

          // Fetch user data for the comment
          const userRef = doc(firestore, "users", comment.userId);
          const userSnap = await getDoc(userRef);
          comment.user = userSnap.exists()
            ? userSnap.data()
            : { username: "Unknown User", profileImage: defaultImage };

          // Fetch replies for this comment
          const repliesRef = collection(
            firestore,
            `posts/${postId}/comments/${comment.id}/replies`
          );
          const repliesSnapshot = await getDocs(repliesRef);

          comment.replies = await Promise.all(
            repliesSnapshot.docs.map(async (replyDoc) => {
              const reply = { id: replyDoc.id, ...replyDoc.data() };
              // Fetch user data for the reply
              const replyUserRef = doc(firestore, "users", reply.userId);
              const replyUserSnap = await getDoc(replyUserRef);
              reply.user = replyUserSnap.exists()
                ? replyUserSnap.data()
                : { username: "Unknown User", profileImage: defaultImage };
              return reply;
            })
          );
          totalCount += comment.replies.length; // Count the replies

          return comment;
        })
      );

      setComments(commentsWithReplies);
      setCommentCount(totalCount);
    };

    fetchCommentsAndCount();
  }, [postId]);

  const handleDeleteComment = async (commentId) => {
    if (!currentUser) return;

    try {
      await deleteDoc(doc(firestore, `posts/${postId}/comments/${commentId}`));
      setComments(comments.filter((comment) => comment.id !== commentId));
      setMenuOpen({ commentId: null, replyId: null });
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleDeleteReply = async (commentId, replyId) => {
    if (!currentUser) return;

    try {
      await deleteDoc(
        doc(
          firestore,
          `posts/${postId}/comments/${commentId}/replies/${replyId}`
        )
      );
      setComments(
        comments.map((comment) =>
          comment.id === commentId
            ? {
                ...comment,
                replies: comment.replies.filter(
                  (reply) => reply.id !== replyId
                ),
              }
            : comment
        )
      );
      setMenuOpen({ commentId: null, replyId: null });
    } catch (error) {
      console.error("Error deleting reply:", error);
    }
  };

  const handleMenuToggle = (commentId, replyId = null) => {
    setMenuOpen((prev) =>
      prev.commentId === commentId && prev.replyId === replyId
        ? { commentId: null, replyId: null }
        : { commentId, replyId }
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMenuOpen({ commentId: null, replyId: null });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderThreeDotMenu = (commentId, replyId = null) => {
    const isOpen =
      menuOpen.commentId === commentId && menuOpen.replyId === replyId;
    return (
      <div style={{ position: "relative" }}>
        <img
          src={threeDotIcon}
          alt="Menu"
          onClick={() => handleMenuToggle(commentId, replyId)}
          style={{
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />
        {isOpen && (
          <div
            ref={dropdownRef}
            style={{
              position: "absolute",
              right: 0,
              top: "100%",
              backgroundColor: "#1e1e1e",
              border: "1px solid #2E313C",
              borderRadius: "4px",
              zIndex: 10,
            }}
          >
            <button
              onClick={() => {
                replyId
                  ? handleDeleteReply(commentId, replyId)
                  : handleDeleteComment(commentId);
                setMenuOpen({ commentId: null, replyId: null });
              }}
              style={{
                background: "none",
                border: "none",
                color: "#FFF",
                padding: "8px 16px",
                cursor: "pointer",
                width: "100%",
                textAlign: "left",
              }}
            >
              Delete
            </button>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    const fetchUserTeam = async () => {
      if (currentUser) {
        const userRef = doc(firestore, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists() && userSnap.data().teamId) {
          const teamRef = doc(firestore, "teams", userSnap.data().teamId);
          const teamSnap = await getDoc(teamRef);
          if (teamSnap.exists()) {
            setUserTeam(teamSnap.data().team_name);
          }
        }
      }
    };
    fetchUserTeam();
  }, [currentUser]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowPostAsDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const togglePostAsDropdown = () => {
    setShowPostAsDropdown(!showPostAsDropdown);
  };

  const selectPostAs = (option) => {
    setPostAs(option);
    setShowPostAsDropdown(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 769);
      setIsWideDesktop(window.innerWidth >= 1580);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        replyInputRef.current &&
        !replyInputRef.current.contains(event.target)
      ) {
        setReplyingTo(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const loadUserRegionTournaments = async () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userRegion = userDoc.data().region;
          setUserRegion(userRegion);
          await fetchUserRegionEvents(userRegion);
        } else {
          console.error("User document does not exist");
        }
      } else {
        console.error("No user is logged in");
      }
    } catch (error) {
      console.error("Error fetching user region:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserRegionEvents = async (region, page) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://www.robotevents.com/api/v2/events`,
        {
          params: {
            season: seasonId,
            region: region,
            eventType: "tournament",
            page: page,
          },
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      const sortedEvents = response.data.data.sort(
        (a, b) => new Date(a.start) - new Date(b.start)
      );
      if (sortedEvents.length === 0) {
        setHasMore(false);
      } else {
        setEvents((prevEvents) => [...prevEvents, ...sortedEvents]);
      }
    } catch (error) {
      console.error("Failed to fetch events:", error);
      setEvents([]);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllWorldwideEvents = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://www.robotevents.com/api/v2/events`,
        {
          params: {
            season: seasonId,
            eventTypes: ["tournament"],
            page: page,
          },
          headers: {
            Authorization: `Bearer ${apiKey}`,
            accept: "application/json",
          },
        }
      );
      const sortedAllEvents = response.data.data.sort(
        (a, b) => new Date(a.start) - new Date(b.start)
      );
      if (sortedAllEvents.length === 0) {
        setHasMore(false);
      } else {
        setEvents((prevEvents) => [...prevEvents, ...sortedAllEvents]);
      }
    } catch (error) {
      console.error("Failed to fetch worldwide events:", error);
      setEvents([]);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = async (tab) => {
    setActiveTab(tab);
    setPage(1);
    setHasMore(true);
    setEvents([]);

    if (tab === "myRegion") {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userRegion = userDoc.data().region;
            fetchUserRegionEvents(userRegion, 1);
          } else {
            console.error("User document does not exist");
          }
        } else {
          console.error("No user is logged in");
        }
      } catch (error) {
        console.error("Error fetching user region:", error);
      }
    } else if (tab === "all") {
      fetchAllWorldwideEvents(1);
    }
  };

  const handleCardClick = (eventId) => {
    navigate(`/events/${eventId}`);
  };

  useEffect(() => {
    if (isDesktop) {
      loadUserRegionTournaments();
    }
  }, [isDesktop]);

  useEffect(() => {
    if (activeTab === "all" && hasMore) {
      fetchAllWorldwideEvents(page);
    }
  }, [activeTab, page]);

  const [newComment, setNewComment] = useState("");

  const handleToggleReplies = (commentId) => {
    setRepliesVisible((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  useEffect(() => {
    const initialRepliesVisible = comments.reduce((acc, comment) => {
      acc[comment.id] = true;
      return acc;
    }, {});
    setRepliesVisible(initialRepliesVisible);
  }, [comments]);

  const handleLikeReply = async (commentId, replyId) => {
    if (!currentUser) {
      console.error("User must be authenticated to like replies");
      return;
    }

    const replyRef = doc(
      firestore,
      `posts/${postId}/comments/${commentId}/replies/${replyId}`
    );
    const likeRef = doc(
      firestore,
      `posts/${postId}/comments/${commentId}/replies/${replyId}/likes/${currentUser.uid}`
    );

    try {
      await runTransaction(firestore, async (transaction) => {
        const replyDoc = await transaction.get(replyRef);
        const likeDoc = await transaction.get(likeRef);

        if (!replyDoc.exists()) {
          throw "Reply does not exist!";
        }

        let newLikeCount = replyDoc.data().likeCount || 0;

        if (likeDoc.exists()) {
          // If the like already exists, delete it and decrement the like count
          transaction.delete(likeRef);
          newLikeCount -= 1;
        } else {
          // If the like does not exist, create it and increment the like count
          transaction.set(likeRef, {
            userId: currentUser.uid,
            createdAt: serverTimestamp(),
          });
          newLikeCount += 1;
        }

        transaction.update(replyRef, { likeCount: newLikeCount });

        // Optimistically update the UI
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === commentId
              ? {
                  ...comment,
                  replies: comment.replies.map((reply) =>
                    reply.id === replyId
                      ? {
                          ...reply,
                          likeCount: newLikeCount,
                          isLikedByCurrentUser: !likeDoc.exists(),
                        }
                      : reply
                  ),
                }
              : comment
          )
        );
      });
    } catch (error) {
      console.error("Transaction failed: ", error);
    }
  };

  const handleSendReply = async (commentId) => {
    if (!reply.trim()) return;
    await addDoc(
      collection(firestore, `posts/${postId}/comments/${commentId}/replies`),
      {
        content: reply,
        userId: currentUser.uid, // Store only the userId
        createdAt: new Date(),
      }
    );
    setReply("");
    setReplyingTo(null);
    fetchComments(); // Refresh comments after adding a reply
  };

  useEffect(() => {
    const fetchPostAndUserData = async () => {
      const postDocRef = doc(firestore, "posts", postId);
      const postDocSnap = await getDoc(postDocRef);
      if (postDocSnap.exists()) {
        setPost(postDocSnap.data());
        const userId = postDocSnap.data().userId;
        const teamId = postDocSnap.data().teamId;

        if (teamId) {
          // Fetch team data
          const teamDocRef = doc(firestore, "teams", teamId);
          const teamDocSnap = await getDoc(teamDocRef);
          if (teamDocSnap.exists()) {
            const teamData = teamDocSnap.data();
            setUsername(teamData.team_name);
            setProfileImageUrl(teamData.profileImage || defaultImage);
          } else {
            console.log("No such team!");
          }
        } else if (userId) {
          // Fetch user data
          const userDocRef = doc(firestore, "users", userId);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUsername(userData.username);
            setProfileImageUrl(userData.profileImage || defaultImage);
          } else {
            console.log("No such user!");
          }
        } else {
          console.log("No user or team associated with this post!");
        }
      } else {
        console.log("No such post!");
      }
    };

    fetchPostAndUserData();
  }, [postId]);

  const handleSharePost = async (postId, content) => {
    const postUrl = `https://vexvibe.com/posts/${postId}`;

    if (window.Capacitor && window.Capacitor.isNative) {
      // Running on a mobile app with Capacitor
      try {
        await Share.share({
          title: "Check out this post on VexVibe!",
          text: content,
          url: postUrl,
          dialogTitle: "Share with",
        });
        console.log("Post shared successfully");
      } catch (error) {
        console.error("Error sharing the post:", error);
      }
    } else if (navigator.share) {
      // Running on a website with Web Share API support
      try {
        await navigator.share({
          title: "Check out this post on VexVibe!",
          text: content,
          url: postUrl,
        });
        console.log("Post shared successfully");
      } catch (error) {
        console.error("Error sharing the post:", error);
      }
    } else {
      // Fallback for unsupported browsers or platforms
      console.log("Sharing is not supported on this platform.");
    }
  };

  useEffect(() => {
    const fetchLikeStatus = async () => {
      if (currentUser && postId) {
        // Reference to the specific like document for the current user and post
        const likeRef = doc(
          firestore,
          `posts/${postId}/likes/${currentUser.uid}`
        );
        try {
          const likeSnap = await getDoc(likeRef);
          // Set the isLiked state based on whether the document exists
          setIsLiked(likeSnap.exists());
        } catch (error) {
          console.error("Error fetching like status:", error);
          setIsLiked(false); // Assume not liked if there's an error fetching the status
        }
      } else {
        setIsLiked(false); // If there's no currentUser or postId, set isLiked to false
      }
    };

    fetchLikeStatus();
  }, [postId, currentUser]); // Removed firestore from dependency array

  useEffect(() => {
    const fetchComments = async () => {
      const commentsRef = collection(firestore, `posts/${postId}/comments`);
      const commentsSnap = await getDocs(
        query(commentsRef, orderBy("createdAt", "desc"))
      );

      const commentsWithUserAndLikes = await Promise.all(
        commentsSnap.docs.map(async (docSnapshot) => {
          const commentData = docSnapshot.data();
          const userRef = doc(firestore, "users", commentData.userId);
          const userSnap = await getDoc(userRef);
          if (!userSnap.exists()) {
            console.error("User not found");
            // Continue with the default user data if not found
            return {
              ...commentData,
              user: { username: "Unknown", profileImage: defaultImage },
            };
          }
          const userData = userSnap.data();

          // Fetch like count and like status for each comment
          const likesRef = collection(
            firestore,
            `posts/${postId}/comments/${docSnapshot.id}/likes`
          );
          const likesSnap = await getDocs(likesRef);
          const likeCount = likesSnap.docs.length;
          const isLikedByCurrentUser = likesSnap.docs.some(
            (doc) => doc.id === currentUser?.uid
          );

          return {
            ...commentData,
            id: docSnapshot.id,
            user: {
              ...userData,
              profileImage: userData.profileImage || defaultImage,
            },
            likeCount, // Include the like count
            isLikedByCurrentUser, // Include like state for the current user
          };
        })
      );

      // Filter out any null values if user documents were missing
      setComments(
        commentsWithUserAndLikes.filter((comment) => comment !== null)
      );
    };

    fetchComments();
  }, [postId, currentUser]); // Depend on currentUser to re-fetch when user changes

  // Define fetchComments function outside of useEffect
  const fetchComments = useCallback(async () => {
    const commentsRef = collection(firestore, `posts/${postId}/comments`);
    const commentsSnap = await getDocs(
      query(commentsRef, orderBy("createdAt", "desc"))
    );

    const commentsWithUserAndLikes = await Promise.all(
      commentsSnap.docs.map(async (docSnapshot) => {
        const commentData = docSnapshot.data();

        // Check if the comment is made by a user or a team
        const userDocRef = commentData.teamId
          ? doc(firestore, "teams", commentData.teamId)
          : doc(firestore, "users", commentData.userId);
        const userSnap = await getDoc(userDocRef);

        // If user doesn't exist, return null
        if (!userSnap.exists()) {
          console.log(`User or team not found for comment: ${docSnapshot.id}`);
          return null;
        }

        const userData = userSnap.data();

        // Check if the current user has liked this comment
        const likeRef = doc(
          firestore,
          `posts/${postId}/comments/${docSnapshot.id}/likes/${currentUser?.uid}`
        );
        const likeSnap = await getDoc(likeRef);
        const isLikedByCurrentUser = likeSnap.exists();

        // Fetch replies for the comment
        const repliesRef = collection(
          firestore,
          `posts/${postId}/comments/${docSnapshot.id}/replies`
        );
        const repliesSnap = await getDocs(repliesRef);
        const replies = await Promise.all(
          repliesSnap.docs.map(async (replyDocSnapshot) => {
            const replyData = replyDocSnapshot.data();
            const replyUserDocRef = replyData.teamId
              ? doc(firestore, "teams", replyData.teamId)
              : doc(firestore, "users", replyData.userId);
            const replyUserSnap = await getDoc(replyUserDocRef);
            const replyUserData = replyUserSnap.exists()
              ? replyUserSnap.data()
              : { username: "Unknown", profileImage: defaultImage };

            // Check if the current user has liked this reply
            const replyLikeRef = doc(
              firestore,
              `posts/${postId}/comments/${docSnapshot.id}/replies/${replyDocSnapshot.id}/likes/${currentUser?.uid}`
            );
            const replyLikeSnap = await getDoc(replyLikeRef);
            const isReplyLikedByCurrentUser = replyLikeSnap.exists();

            return {
              ...replyData,
              id: replyDocSnapshot.id,
              user: {
                ...replyUserData,
                profileImage: replyUserData.profileImage || defaultImage,
              },
              likeCount: replyData.likeCount || 0,
              isLikedByCurrentUser: isReplyLikedByCurrentUser,
            };
          })
        );

        return {
          ...commentData,
          id: docSnapshot.id,
          user: {
            ...userData,
            profileImage: userData.profileImage || defaultImage,
          },
          likeCount: commentData.likeCount || 0,
          isLikedByCurrentUser,
          replies,
        };
      })
    );

    // Filter out null values (comments from non-existent users)
    setComments(commentsWithUserAndLikes.filter((comment) => comment !== null));
  }, [postId, currentUser]);

  useEffect(() => {
    fetchComments();
  }, [postId, fetchComments]);

  const handleSendComment = async () => {
    if (!comment.trim() || !currentUser) return;

    try {
      const commentData = {
        content: comment,
        createdAt: serverTimestamp(),
        likeCount: 0,
      };

      if (postAs === "user") {
        commentData.userId = currentUser.uid;
      } else {
        commentData.teamId = userTeam;
      }

      const commentRef = await addDoc(
        collection(firestore, `posts/${postId}/comments`),
        commentData
      );

      // Fetch the post data to get the post owner's userId or teamId
      const postRef = doc(firestore, "posts", postId);
      const postSnap = await getDoc(postRef);
      const postData = postSnap.data();

      // Create a notification if the commenter is not the post owner and it's not a team post
      if (postData && !postData.teamId && postData.userId !== currentUser.uid) {
        const notificationsRef = collection(firestore, "notifications");
        await addDoc(notificationsRef, {
          type: "comment",
          senderId: currentUser.uid,
          recipientId: postData.userId,
          postId: postId,
          commentId: commentRef.id,
          commentContent: comment,
          createdAt: serverTimestamp(),
        });

        // Send notification
        sendNotification(
          "New Comment",
          `${currentUser.displayName || "Someone"} commented on your post`
        );
      }

      setComment("");
      fetchComments();
    } catch (error) {
      console.error("Error sending comment: ", error);
    }
  };

  const sendNotification = async (title, body) => {
    if (Capacitor.isNativePlatform()) {
      await LocalNotifications.schedule({
        notifications: [
          {
            title: title,
            body: body,
            id: Date.now(),
          },
        ],
      });
    } else {
      // Web notification
      if (Notification.permission === "granted") {
        new Notification(title, { body: body });
      }
    }
  };

  const handleLikeClick = async () => {
    if (!currentUser) {
      console.error("User must be authenticated to like posts");
      return;
    }

    const postRef = doc(firestore, "posts", postId);
    const likeRef = doc(firestore, `posts/${postId}/likes/${currentUser.uid}`);

    // Optimistically update the UI
    const wasLiked = isLiked;
    const currentLikeCount = post?.likeCount || 0;
    setIsLiked(!wasLiked);
    setPost((prevPost) => ({
      ...prevPost,
      likeCount: wasLiked ? currentLikeCount - 1 : currentLikeCount + 1,
    }));

    try {
      await runTransaction(firestore, async (transaction) => {
        const postDoc = await transaction.get(postRef);
        if (!postDoc.exists()) {
          throw new Error("Post does not exist!");
        }

        const likeDoc = await transaction.get(likeRef);
        const postData = postDoc.data();
        let newLikeCount = postData.likeCount || 0;

        if (wasLiked && likeDoc.exists()) {
          // Unlike the post
          transaction.delete(likeRef);
          newLikeCount = Math.max(0, newLikeCount - 1);
        } else if (!wasLiked && !likeDoc.exists()) {
          // Like the post
          transaction.set(likeRef, {
            userId: currentUser.uid,
            createdAt: serverTimestamp(),
          });
          newLikeCount += 1;

          // Create notification only when liking a user post (not a team post)
          if (!postData.teamId && currentUser.uid !== postData.userId) {
            const notificationRef = doc(collection(firestore, "notifications"));
            transaction.set(notificationRef, {
              type: "like",
              content: `${
                currentUser.displayName || "Someone"
              } liked your post`,
              recipientId: postData.userId,
              senderId: currentUser.uid,
              postId: postId,
              createdAt: serverTimestamp(),
            });

            // Send notification
            sendNotification(
              "New Like",
              `${currentUser.displayName || "Someone"} liked your post`
            );
          }
        }

        transaction.update(postRef, { likeCount: newLikeCount });

        // Update local state within the transaction
        setPost((prevPost) => ({
          ...prevPost,
          likeCount: newLikeCount,
        }));
        setIsLiked(!wasLiked);
      });
    } catch (error) {
      console.error("Transaction failed: ", error);
      // Revert the optimistic UI update in case of error
      setIsLiked(wasLiked);
      setPost((prevPost) => ({
        ...prevPost,
        likeCount: currentLikeCount,
      }));
    }
  };

  useEffect(() => {
    const fetchLikeStatus = async () => {
      if (currentUser && postId) {
        // Reference to the specific like document for the current user and post
        const likeRef = doc(
          firestore,
          `posts/${postId}/likes/${currentUser.uid}`
        );
        try {
          const likeSnap = await getDoc(likeRef);
          // Set the isLiked state based on whether the document exists
          setIsLiked(likeSnap.exists());
        } catch (error) {
          console.error("Error fetching like status:", error);
          setIsLiked(false); // Assume not liked if there's an error fetching the status
        }
      } else {
        setIsLiked(false); // If there's no currentUser or postId, set isLiked to false
      }
    };

    fetchLikeStatus();
  }, [postId, currentUser]); // Removed firestore from dependency array

  useEffect(() => {
    if (currentUser) {
      // Assuming the currentUser object has a uid property
      const fetchUserProfile = async () => {
        const userRef = doc(firestore, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          setProfileImageUrl(userData.profileImage || defaultImage);
        } else {
          console.log("No such user!");
          setProfileImageUrl(defaultImage);
        }
      };

      fetchUserProfile();
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchPost = async () => {
      const postRef = doc(firestore, "posts", postId);
      const postSnap = await getDoc(postRef);
      if (postSnap.exists()) {
        const postData = postSnap.data();
        setPost(postData);
        // Check if there's an imageUrl in the post data
        if (postData.imageUrl) {
          setPostImageUrl(postData.imageUrl);
        }
      } else {
        console.log("No such post!");
      }
    };

    fetchPost();
  }, [postId]);

  useEffect(() => {
    const fetchCurrentUserProfileImage = async () => {
      if (currentUser && currentUser.uid) {
        const profileImageRef = ref(
          storage,
          `profileImages/${currentUser.uid}`
        );
        try {
          const url = await getDownloadURL(profileImageRef);
          setCurrentUserProfileImageUrl(url);
        } catch (error) {
          console.error(
            "Failed to fetch current user's profile image URL:",
            error
          );
          setCurrentUserProfileImageUrl(defaultImage);
        }
      }
    };

    fetchCurrentUserProfileImage();
  }, [currentUser]);

  useEffect(() => {
    return () => {
      const scrollPosition = sessionStorage.getItem("scrollPosition");
      if (scrollPosition !== null) {
        window.scrollTo(0, parseInt(scrollPosition, 10));
      }
    };
  }, []);

  const handleLikeComment = async (commentId) => {
    if (!currentUser) {
      console.error("User must be authenticated to like comments");
      return;
    }

    const commentRef = doc(firestore, `posts/${postId}/comments/${commentId}`);
    const likeRef = doc(
      firestore,
      `posts/${postId}/comments/${commentId}/likes/${currentUser.uid}`
    );

    // Optimistically update the UI
    const currentComments = [...comments];
    const commentIndex = currentComments.findIndex(
      (comment) => comment.id === commentId
    );
    if (commentIndex === -1) return;

    const commentData = currentComments[commentIndex];
    const wasLiked = commentData.isLikedByCurrentUser;
    const newLikeCount = wasLiked
      ? (commentData.likeCount || 0) - 1
      : (commentData.likeCount || 0) + 1;

    // Update local state to reflect the new like count and like status
    currentComments[commentIndex] = {
      ...commentData,
      likeCount: newLikeCount,
      isLikedByCurrentUser: !wasLiked,
    };
    setComments(currentComments);

    // Perform Firestore transaction
    await runTransaction(firestore, async (transaction) => {
      const likeSnap = await transaction.get(likeRef);
      if (likeSnap.exists()) {
        // If the like already exists, delete it and decrement the like count
        transaction.delete(likeRef);
        transaction.update(commentRef, { likeCount: increment(-1) });
      } else {
        // If the like does not exist, create it and increment the like count
        transaction.set(likeRef, {
          userId: currentUser.uid,
          createdAt: serverTimestamp(),
        });
        transaction.update(commentRef, { likeCount: increment(1) });
      }
    }).catch((error) => {
      console.error("Transaction failed: ", error);
      // Revert the optimistic UI update in case of error
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === commentId
            ? {
                ...comment,
                likeCount: wasLiked
                  ? (comment.likeCount || 0) - 1
                  : (comment.likeCount || 0) + 1,
                isLikedByCurrentUser: wasLiked,
              }
            : comment
        )
      );
    });
  };

  const handleAddComment = async () => {
    if (!currentUser || !newComment.trim()) return;

    try {
      const commentRef = await addDoc(
        collection(firestore, `posts/${postId}/comments`),
        {
          content: newComment,
          userId: currentUser.uid,
          username: currentUser.displayName,
          createdAt: serverTimestamp(),
        }
      );

      // Update the comments state
      setComments([
        ...comments,
        {
          id: commentRef.id,
          content: newComment,
          user: {
            uid: currentUser.uid,
            username: currentUser.displayName,
            profileImage: currentUser.photoURL,
          },
          createdAt: { seconds: Date.now() / 1000 },
        },
      ]);

      // Clear the comment input
      setNewComment("");

      // Update the comment count on the post
      const postRef = doc(firestore, "posts", postId);
      await updateDoc(postRef, {
        commentCount: increment(1),
      });
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  // Add this useEffect to fetch comments when the component mounts
  useEffect(() => {
    const fetchComments = async () => {
      if (!postId) return;

      const commentsRef = collection(firestore, `posts/${postId}/comments`);
      const q = query(commentsRef, orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);

      const fetchedComments = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
      }));

      setComments(fetchedComments);
    };

    fetchComments();
  }, [postId]);

  const renderPostContent = (content) => {
    const parts = content.split(/@(\w+)/);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        // This is a username
        return (
          <Link
            key={index}
            to={`/users/${part}`}
            style={{
              color: "#1DA1F2", // Twitter blue color
              textDecoration: "none",
              fontWeight: "bold",
            }}
            onClick={(e) => e.stopPropagation()} // Prevent event from bubbling up to post click
          >
            @{part}
          </Link>
        );
      }
      return part;
    });
  };

  if (isDesktop) {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "#121516",
          minHeight: "100vh",
        }}
      >
        {/* Left Sidebar */}
        <div
          style={{
            width: "350px",
            background: "#121516",
            position: "fixed",
            left: 0,
            top: 0,
            height: "100vh",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "12px",
              left: "12px",
              animation: "fadeInUp 1s ease-in-out",
            }}
          >
            <Sidebar
              activeTab={activeTab}
              handleTabClick={handleTabClick}
              events={events}
              loading={loading}
              handleCardClick={handleCardClick}
            />
          </div>
        </div>
        <div
          style={{
            width: "1px",
            height: "100vh",
            position: "fixed",
            left: "350px",
            top: 0,
            background: "var(--black, #2E313C)",
          }}
        ></div>

        {/* Main Content */}
        <div
          style={{
            flex: 1,
            padding: "20px",
            marginLeft: isWideDesktop ? "auto" : "350px",
            marginRight: isWideDesktop ? "auto" : "0",
            maxWidth: isWideDesktop ? "800px" : "calc(100% - 350px)",
            width: isWideDesktop ? "800px" : "auto",
            overflowY: "auto",
            height: "100vh",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {/* Post content */}
          <div
            style={{
              backgroundColor: "#1e1e1e",
              borderRadius: "10px",
              padding: "20px",
              marginBottom: "20px",
            }}
          >
            {/* Post Header */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <img
                src={profileImageUrl}
                alt="Profile"
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  marginRight: "15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (post?.teamId) {
                    navigate(`/teams/${username}`);
                  } else {
                    navigate(`/users/${username}`);
                  }
                }}
              />
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "#fff",
                    fontSize: "18px",
                  }}
                >
                  {username}
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    color: "grey",
                    marginTop: "5px",
                  }}
                >
                  {post?.createdAt &&
                    new Date(post.createdAt.seconds * 1000).toLocaleString(
                      "en-US",
                      {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      }
                    )}
                </div>
              </div>
            </div>

            {/* Post Content */}
            <div
              style={{
                color: "#fff",
                fontSize: "16px",
                marginBottom: "15px",
                wordWrap: "break-word",
                marginLeft: "70px",
              }}
            >
              {post?.content && renderPostContent(post.content)}
            </div>

            {/* Post Image */}
            {postImageUrl && (
              <div style={{ marginBottom: "15px", textAlign: "center" }}>
                <img
                  src={postImageUrl}
                  alt="Post"
                  style={{
                    maxWidth: "80%",
                    maxHeight: "500px",
                    borderRadius: "10px",
                    cursor: "pointer",
                    marginLeft: "-20px",
                  }}
                  onClick={() => setIsImageOpen(true)}
                />
              </div>
            )}

            {/* Like and Share Section */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "70px",
              }}
            >
              <img
                src={isLiked ? likeIcon : unlikeIcon}
                alt={isLiked ? "Unlike" : "Like"}
                onClick={handleLikeClick}
                style={{
                  cursor: "pointer",
                  width: "24px",
                  height: "24px",
                  marginRight: "10px",
                }}
              />
              <span style={{ color: "#fff", marginRight: "20px" }}>
                {post?.likeCount}
              </span>
              <img
                src={sendIcon}
                alt="Share"
                onClick={() => handleSharePost(postId, post?.content)}
                style={{ cursor: "pointer", width: "24px", height: "24px" }}
              />
            </div>
          </div>

          {/* Comments Section */}
          <div
            style={{
              backgroundColor: "#1e1e1e",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <h3
              style={{ color: "#fff", marginBottom: "25px", marginTop: "0px" }}
            >
              Comments ({commentCount})
            </h3>
            {comments.map((comment, index) => (
              <div
                key={index}
                style={{
                  marginBottom: "20px",
                  borderBottom: "1px solid #2E313C",
                  paddingBottom: "15px",
                }}
              >
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  <img
                    src={comment.user?.profileImage || defaultImage}
                    alt="Profile"
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      comment.user?.username &&
                      navigate(`/users/${comment.user.username}`)
                    }
                  />
                  <div style={{ flex: 1 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          color: "#fff",
                          marginBottom: "5px",
                        }}
                      >
                        {comment.user?.username || "Unknown User"}
                      </div>
                      {comment.userId === currentUser?.uid &&
                        renderThreeDotMenu(comment.id)}
                    </div>
                    <div style={{ color: "#fff", marginBottom: "10px" }}>
                      {comment.content}
                    </div>
                    <div style={{ color: "#8899A6", fontSize: "12px" }}>
                      {comment.createdAt &&
                        new Date(
                          comment.createdAt.seconds * 1000
                        ).toLocaleString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <img
                        src={
                          comment.isLikedByCurrentUser ? likeIcon : unlikeIcon
                        }
                        alt="Like"
                        onClick={() => handleLikeComment(comment.id)}
                        style={{
                          cursor: "pointer",
                          width: "24px",
                          height: "24px",
                          marginRight: "8px",
                        }}
                      />
                      <span style={{ color: "#fff", marginRight: "15px" }}>
                        {comment.likeCount || 0}
                      </span>
                      <button
                        onClick={() => setReplyingTo(comment.id)}
                        style={{
                          background: "none",
                          border: "none",
                          color: "#FFF",
                          cursor: "pointer",
                          marginRight: "15px",
                        }}
                      >
                        Reply
                      </button>
                    </div>
                    {replyingTo === comment.id && (
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Write a reply..."
                          value={reply}
                          onChange={(e) => setReply(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              handleSendReply(comment.id);
                            }
                          }}
                          style={{
                            flex: 1,
                            background: "transparent",
                            border: "1px solid #2E313C",
                            borderRadius: "20px",
                            color: "#fff",
                            padding: "8px 15px",
                            marginRight: "10px",
                          }}
                        />
                        <button
                          onClick={() => handleSendReply(comment.id)}
                          style={{
                            background: "#1DA1F2",
                            border: "none",
                            borderRadius: "20px",
                            color: "#fff",
                            cursor: "pointer",
                            padding: "8px 15px",
                          }}
                        >
                          Send
                        </button>
                      </div>
                    )}
                    {/* Replies Section */}
                    {comment.replies && comment.replies.length > 0 && (
                      <div style={{ marginTop: "15px", marginLeft: "20px" }}>
                        {comment.replies.map((reply, replyIndex) => (
                          <div
                            key={replyIndex}
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              marginBottom: "10px",
                            }}
                          >
                            <img
                              src={reply.user.profileImage || defaultImage}
                              alt="Profile"
                              onClick={() =>
                                navigate(`/users/${reply.user.username}`)
                              }
                              style={{
                                width: "32px",
                                height: "32px",
                                borderRadius: "50%",
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                            />
                            <div style={{ flex: 1 }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "flex-start",
                                }}
                              >
                                <div
                                  style={{ fontWeight: "bold", color: "#fff" }}
                                >
                                  {reply.user.username}
                                </div>
                                {reply.userId === currentUser?.uid &&
                                  renderThreeDotMenu(comment.id, reply.id)}
                              </div>
                              <div style={{ color: "#fff" }}>
                                {reply.content}
                              </div>
                              <div
                                style={{ color: "#8899A6", fontSize: "12px" }}
                              >
                                {reply.createdAt &&
                                  new Date(
                                    reply.createdAt.seconds * 1000
                                  ).toLocaleString("en-US", {
                                    month: "long",
                                    day: "numeric",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  })}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "5px",
                                }}
                              >
                                <img
                                  src={
                                    reply.isLikedByCurrentUser
                                      ? likeIcon
                                      : unlikeIcon
                                  }
                                  alt="Like"
                                  onClick={() =>
                                    handleLikeReply(comment.id, reply.id)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "5px",
                                  }}
                                />
                                <span
                                  style={{ color: "#fff", fontSize: "12px" }}
                                >
                                  {reply.likeCount || 0}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}

            {/* Comment Input */}
            <div style={{ display: "flex", marginTop: "20px" }}>
              <input
                type="text"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Write a comment..."
                style={{
                  flex: 1,
                  padding: "10px",
                  borderRadius: "20px",
                  border: "none",
                  backgroundColor: "#2E313C",
                  color: "#fff",
                  marginRight: "10px",
                }}
              />
              <button
                onClick={handleAddComment}
                style={{
                  padding: "10px 20px",
                  borderRadius: "20px",
                  border: "none",
                  backgroundColor: "#1DA1F2",
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                Post
              </button>
            </div>
          </div>
        </div>

        {isWideDesktop && <TournamentSidebar />}

        {/* Full-screen Image Modal */}
        {isImageOpen && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.9)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
            onClick={() => setIsImageOpen(false)}
          >
            <img
              src={postImageUrl}
              alt="Full-screen"
              style={{
                maxWidth: "90%",
                maxHeight: "90%",
                objectFit: "contain",
              }}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="postContent">
      {/* Back button */}
      <svg
        onClick={() => navigate("/home", { state: { from: "post" } })}
        viewBox="0 0 24 24"
        aria-hidden="true"
        className="backButton"
        style={{
          color: "rgb(239, 243, 244)",
          cursor: "pointer",
          position: "absolute",
          top: "20px",
          left: "20px",
        }}
      >
        <g>
          <path d="M7.414 13l5.043 5.04-1.414 1.42L3.586 12l7.457-7.46 1.414 1.42L7.414 11H21v2H7.414z"></path>
        </g>
      </svg>

      <div className="postTitle">Post</div>
      <img
        src={profileImageUrl}
        alt="Profile"
        className="postProfileImage"
        style={{
          position: "absolute",
          top: "85px",
          left: "35px",
          cursor: "pointer",
        }}
        onClick={() => {
          if (post?.teamId) {
            navigate(`/teams/${username}`);
          } else {
            navigate(`/users/${username}`);
          }
        }}
      />
      <span
        style={{
          display: "flex",
          width: "180.259px",
          height: "40px", // Reduced height
          flexDirection: "column",
          justifyContent: "center",
          color: "var(--white, #fff)",
          fontFamily: "Roboto",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "25px",
          letterSpacing: "0.14px",
          marginLeft: "40px",
          marginTop: "60px", // Added top margin
        }}
      >
        {username}
      </span>
      {/* Timestamp under username */}
      <div
        style={{
          marginTop: "5px", // Adjusted top margin
          marginLeft: "40px",
          color: "grey",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.12px",
        }}
      >
        {post?.createdAt &&
          new Date(post.createdAt.seconds * 1000).toLocaleString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
      </div>
      <div
        style={{
          marginTop: "10px", // Adjusted top margin
          marginLeft: "40px",
          color: "var(--white, #FFF)",
          fontFamily: "Roboto",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "20px",
          letterSpacing: "0.14px",
          wordWrap: "break-word",
          overflowWrap: "break-word",
        }}
      >
        {post?.content && renderPostContent(post.content)}
      </div>
      {/* PostContent */}
      <div
        style={{
          marginTop: "20px",
          textAlign: "center",
          cursor: "pointer",
        }}
        onClick={() => setIsImageOpen(!isImageOpen)}
      >
        {postImageUrl && (
          <img
            src={postImageUrl}
            alt="Post"
            style={{
              maxWidth: "calc(100% - 100px)", // Ensures the image does not go past the right margin
              maxHeight: "500px", // Increased maxHeight for better display of tall images
              width: "auto", // Ensures the image maintains its aspect ratio
              height: "auto", // Ensures the image maintains its aspect ratio
              display: "block",
              marginLeft: "40px",
              marginRight: "20px",
              borderRadius: "10px",
              marginBottom: "10px",
            }}
          />
        )}
      </div>
      {isImageOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
          onClick={() => setIsImageOpen(false)}
        >
          <img
            src={postImageUrl}
            alt="Post"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              objectFit: "contain",
              borderRadius: "10px",
            }}
          />
        </div>
      )}
      <div style={{ position: "relative" }}>
        <img
          src={isLiked ? likeIcon : unlikeIcon}
          alt={isLiked ? "Unlike" : "Like"}
          onClick={handleLikeClick}
          style={{
            cursor: "pointer",
            width: "27px",
            height: "26px",
            marginTop: "10px",
            marginLeft: "40px",
          }}
        />
        <span
          style={{
            position: "absolute",
            left: "80px",
            top: "0px",
            marginTop: "15px",
          }}
        >
          {post?.likeCount}
        </span>
        {/* Add the share icon */}
        <img
          src={sendIcon}
          alt="Share"
          onClick={() => handleSharePost(postId, post?.content)}
          style={{
            cursor: "pointer",
            width: "26px",
            height: "26px",
            marginLeft: "35px",
            marginTop: "10px",
          }}
        />
      </div>
      <div
        style={{
          marginTop: "16px",
          height: "2px",
          backgroundColor: "#4A4A4A", // Darker shade for better contrast
          width: "calc(100% + 90px)", // Adjusted width to extend beyond the viewport
          marginLeft: "-45px",
          borderRadius: "5px", // Added rounded corners
        }}
      ></div>
      <div
        style={{
          color: "var(--white, #FFF)",
          fontFamily: "Roboto",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "45px",
          letterSpacing: "0.2px",
          marginTop: "8px",
          marginLeft: "-8px",
          paddingBottom: "75px", // Add this line to add padding at the bottom
        }}
      >
        <div style={{ marginTop: "16px" }}>
          <div style={{ marginTop: "0px", fontWeight: 700 }}>
            Comments ({commentCount})
          </div>
          {comments.map((comment, index) => (
            <div
              key={index}
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <img
                src={comment.user?.profileImage || defaultImage}
                alt="Profile"
                onClick={() =>
                  comment.user?.username &&
                  navigate(`/users/${comment.user.username}`)
                }
                style={{
                  width: "48px",
                  height: "48px",
                  borderRadius: "50%",
                  marginRight: "8px",
                  cursor: "pointer",
                }}
              />
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "var(--white, #FFF)",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "44px",
                      letterSpacing: "0.16px",
                      marginTop: "-10px",
                    }}
                  >
                    {comment.user?.username || "Unknown User"}
                  </div>
                  {comment.userId === currentUser?.uid &&
                    renderThreeDotMenu(comment.id)}
                </div>
                <div
                  style={{
                    color: "grey",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "16px",
                    letterSpacing: "0.12px",
                    marginTop: "-10px",
                    marginBottom: "5px",
                  }}
                >
                  {comment.createdAt &&
                    new Date(comment.createdAt.seconds * 1000).toLocaleString(
                      "en-US",
                      {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      }
                    )}
                </div>
                <div
                  style={{
                    color: "#FFF",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "20px",
                    letterSpacing: "0.14px",
                  }}
                >
                  {comment.content}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <img
                    src={comment.isLikedByCurrentUser ? like : unlike}
                    alt="Like"
                    onClick={() => handleLikeComment(comment.id)}
                    style={{
                      cursor: "pointer",
                      width: "24px",
                      height: "24px",
                      marginRight: "8px",
                    }}
                  />
                  <span>{comment.likeCount || 0}</span>
                  <button
                    onClick={() => setReplyingTo(comment.id)}
                    style={{
                      marginLeft: "10px",
                      background: "none",
                      border: "none",
                      color: "#FFF",
                      cursor: "pointer",
                    }}
                  >
                    Reply
                  </button>
                  <button
                    onClick={() => handleToggleReplies(comment.id)}
                    style={{
                      marginLeft: "10px",
                      background: "none",
                      border: "none",
                      color: "#FFF",
                      cursor: "pointer",
                    }}
                  >
                    {repliesVisible[comment.id] ? "▼" : "►"}
                  </button>
                </div>
                {replyingTo === comment.id && (
                  <div ref={replyInputRef} style={{ marginTop: "10px" }}>
                    <input
                      type="text"
                      placeholder="Write a reply..."
                      value={reply}
                      onChange={(e) => setReply(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          handleSendReply(comment.id);
                        }
                      }}
                      style={{
                        flex: 1,
                        background: "transparent",
                        border: "none",
                        color: "#8D8D8D",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 700,
                        outline: "none",
                        padding: "0 10px",
                      }}
                    />
                    <img
                      src={sendIcon}
                      alt="Send"
                      onClick={() => handleSendReply(comment.id)}
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                )}
                {repliesVisible[comment.id] &&
                  comment.replies &&
                  comment.replies.map((reply, replyIndex) => (
                    <div
                      key={replyIndex}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        marginTop: "10px",
                        marginLeft: "40px",
                        borderLeft: "2px solid #FFF",
                        paddingLeft: "10px",
                      }}
                    >
                      <img
                        src={reply.user.profileImage || defaultImage}
                        alt="Profile"
                        onClick={() =>
                          navigate(`/users/${reply.user.username}`)
                        }
                        style={{
                          width: "32px",
                          height: "32px",
                          borderRadius: "50%",
                          marginRight: "8px",
                          cursor: "pointer",
                          marginTop: "5px",
                        }}
                      />
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              color: "var(--white, #FFF)",
                              fontFamily: "Roboto",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "32px",
                              letterSpacing: "0.14px",
                            }}
                          >
                            {reply.user.username}
                          </div>
                          {reply.userId === currentUser?.uid &&
                            renderThreeDotMenu(comment.id, reply.id)}
                        </div>
                        {/* Add reply timestamp here */}
                        <div
                          style={{
                            color: "grey",
                            fontFamily: "Roboto",
                            fontSize: "10px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "14px",
                            letterSpacing: "0.1px",
                            marginTop: "-5px",
                            marginBottom: "5px",
                          }}
                        >
                          {reply.createdAt &&
                            new Date(
                              reply.createdAt.seconds * 1000
                            ).toLocaleString("en-US", {
                              month: "long",
                              day: "numeric",
                              year: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            })}
                        </div>
                        <div
                          style={{
                            color: "#FFF",
                            fontFamily: "Roboto",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "20px",
                            letterSpacing: "0.12px",
                          }}
                        >
                          {reply.content}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "0px",
                          }}
                        >
                          <img
                            src={reply.isLikedByCurrentUser ? like : unlike}
                            alt="Like"
                            onClick={() =>
                              handleLikeReply(comment.id, reply.id)
                            }
                            style={{
                              cursor: "pointer",
                              width: "24px",
                              height: "24px",
                              marginRight: "8px",
                            }}
                          />
                          <span>{reply.likeCount || 0}</span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            position: "fixed",
            left: 0,
            bottom: -17,
            width: "100vw",
            overflow: "hidden",
          }}
        >
          {/* Display the current user's profile picture */}
          {currentUser && (
            <img
              src={currentUserProfileImageUrl}
              alt="Profile"
              style={{
                width: "56px",
                height: "56px",
                borderRadius: "50%",
                position: "fixed", // Changed to fixed to position at the bottom left of the page
                left: "20px", // Adjusted for spacing from the left edge of the viewport
                bottom: "25px", // Adjusted to position above the commenting UI
                zIndex: 2, // Ensure it's above other elements
              }}
            />
          )}
          {/* Adjusted commenting UI to be at the bottom of the page without the SVG */}
          <div
            style={{
              display: "flex",
              width: "280px", // Adjust width to ensure it fits within the viewport width
              padding: "14px 12px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "10px",
              background: "#1C1C1C",
              position: "fixed", // Use fixed to stick to the bottom
              left: "100px", // Match left position with the profile image
              bottom: "26px", // Position at the bottom with some space
              zIndex: 2, // Ensure it's above other elements
            }}
          >
            <input
              type="text"
              placeholder="Add a comment..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSendComment();
                }
              }}
              style={{
                flex: 1, // Allow input to fill available space
                background: "transparent",
                border: "none",
                color: "#8D8D8D",
                fontFamily: "Roboto",
                fontSize: "16px",
                fontWeight: 700,
                outline: "none",
                padding: "0 10px", // Add some padding inside the input
              }}
            />
            <img
              src={sendIcon}
              alt="Send"
              onClick={handleSendComment}
              style={{
                width: "24px",
                height: "24px",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostPage;
