import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import "../assets/styles/welcomePage.css";
import logo from "../assets/images/VexVibe.svg";
import secondLogo from "../assets/images/CircleLogo.svg";
import "../assets/styles/fonts.css";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../firebase";

const WelcomePage = () => {
  const navigate = useNavigate();
  const [showLoginOverlay, setShowLoginOverlay] = useState(false);
  const [showSignUpOverlay, setShowSignUpOverlay] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate("/home"); // If user is logged in, redirect to the home page.
      }
    });

    return unsubscribe; // Cleanup subscription on component unmount.
  }, [navigate]);

  const handleLogin = () => {
    if (window.innerWidth >= 1024) {
      setShowLoginOverlay(true); // Show login overlay on desktop
    } else {
      navigate("/login"); // Navigate to the login page on mobile
    }
  };

  const handleSignUp = () => {
    if (window.innerWidth >= 1024) {
      setShowSignUpOverlay(true); // Show signup overlay on desktop
    } else {
      navigate("/signup"); // Navigate to the signup page on mobile
    }
  };

  const handleOverlayClose = () => {
    setShowLoginOverlay(false);
    setShowSignUpOverlay(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLoginSubmit = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/home"); // Navigate to home after login
    } catch (error) {
      console.error("Error logging in:", error);
      alert(error.message); // Provide user feedback
    }
  };

  const handleSignUpSubmit = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      await setDoc(doc(firestore, "users", user.uid), {
        fullName,
        email,
      });

      navigate("/onboarding");
    } catch (error) {
      console.error("Error signing up:", error);
      alert(error.message);
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(to bottom right, #121516, #242A2B)",
        color: "white",
        WebkitOverflowScrolling: "touch", // Ensures smooth scrolling on iOS
      }}
    >
      <div
        style={{ maxWidth: "400px", textAlign: "center", marginBottom: "2rem" }}
      >
        <img
          src={logo}
          alt="VexVibe Logo"
          className="logo"
          style={{ width: "100%", marginBottom: "1rem", marginTop: "-150px" }}
        />
        <img
          src={secondLogo}
          alt="VexVibe Circle"
          className="secondLogo"
          style={{ width: "250px", marginBottom: "2rem", marginTop: "25px" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%",
          maxWidth: "300px",
        }}
      >
        <button
          style={{
            padding: "1rem",
            borderRadius: "0.5rem",
            backgroundColor: "white",
            color: "#121516",
            fontWeight: "bold",
            cursor: "pointer",
            border: "none",
            textAlign: "center",
            fontSize: "1.1rem", // Increased font size
            fontFamily: "Roboto, sans-serif", // Added Roboto font
            WebkitTapHighlightColor: "transparent",
            marginTop: "75px",
          }}
          onClick={handleLogin}
        >
          Log In
        </button>
        <button
          style={{
            padding: "1rem",
            borderRadius: "0.5rem",
            border: "1px solid white",
            backgroundColor: "transparent",
            color: "white",
            fontWeight: "bold",
            cursor: "pointer",
            textAlign: "center",
            fontSize: "1.1rem", // Increased font size
            fontFamily: "Roboto, sans-serif", // Added Roboto font
            WebkitTapHighlightColor: "transparent", // Removes tap highlight on iOS
          }}
          onClick={handleSignUp}
        >
          Sign Up
        </button>
      </div>

      {/* Login Overlay */}
      {showLoginOverlay && (
        <div
          className="overlay"
          onClick={handleOverlayClose}
          style={{ overflowX: "hidden" }}
        >
          {" "}
          {/* Prevent horizontal scrolling */}
          <div
            className="overlay-content"
            onClick={(e) => e.stopPropagation()}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="movableContainer">
              <h1
                className="signupText"
                style={{
                  position: "fixed",
                  left: "50%",
                  transform: "translateX(-50%)",
                  marginTop: "-125px",
                  marginLeft: "-25px",
                }}
              >
                Log In
              </h1>
              <div
                className="emailBox"
                style={{ marginTop: "-275px", marginLeft: "225px" }}
              >
                <input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Email"
                  className="inputField"
                />
              </div>
              <div
                className="passwordBox"
                style={{ marginTop: "-275px", marginLeft: "225px" }}
              >
                <input
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Password"
                  className="inputField"
                />
              </div>
              <button
                className="signupButton"
                onClick={handleLoginSubmit}
                style={{
                  marginTop: "-225px",
                  marginLeft: "225px",
                  width: "375px",
                }}
              >
                Log In
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Sign Up Overlay */}
      {showSignUpOverlay && (
        <div
          className="overlay"
          onClick={handleOverlayClose}
          style={{ overflowX: "hidden" }}
        >
          {" "}
          {/* Prevent horizontal scrolling */}
          <div
            className="overlay-content"
            onClick={(e) => e.stopPropagation()}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="movableContainer">
              <h1
                className="signupText"
                style={{
                  position: "fixed",
                  left: "50%",
                  transform: "translateX(-50%)",
                  marginTop: "-125px",
                  marginLeft: "-25px",
                }}
              >
                Sign Up
              </h1>
              <div
                className="fullNameBox"
                style={{ marginTop: "-225px", marginLeft: "41px" }}
              >
                <input
                  type="text"
                  value={fullName}
                  onChange={handleFullNameChange}
                  placeholder="Full Name"
                  className="inputField"
                />
              </div>
              <div
                className="emailBox"
                style={{ marginTop: "-225px", marginLeft: "225px" }}
              >
                <input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Email"
                  className="inputField"
                />
              </div>
              <div
                className="passwordBox"
                style={{ marginTop: "-225px", marginLeft: "225px" }}
              >
                <input
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Password"
                  className="inputField"
                />
              </div>
              <button
                className="signupButton"
                onClick={handleSignUpSubmit}
                style={{
                  marginTop: "-175px",
                  marginLeft: "225px",
                  width: "375px",
                }}
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WelcomePage;
