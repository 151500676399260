import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CircleLogo from "../assets/images/CircleLogo.svg";
import VexVibeLogo from "../assets/images/VexVibe.svg";
import Dock from "../components/Dock";
import Sidebar from "../components/Sidebar";
import TournamentSidebar from "../components/TournamentSidebar";

const WelcomeTeam = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1439);
  const [isWideDesktop, setIsWideDesktop] = useState(window.innerWidth >= 1580);
  const [activeTab, setActiveTab] = useState("home");

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 769);
      setIsWideDesktop(window.innerWidth >= 1580);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const storedMessage = localStorage.getItem("welcomeMessage");
    if (storedMessage) {
      setMessage(storedMessage);
      localStorage.removeItem("welcomeMessage");
      setTimeout(() => {
        setMessage("");
      }, 5000);
    }
  }, []);

  const messageStyle = {
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
    marginBottom: "20px",
    textAlign: "center",
    width: "100%",
    maxWidth: "344px",
    position: "absolute",
    top: "200px",
  };

  const containerStyle = {
    backgroundColor: "#121516",
    color: "white",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5% 5% 80px",
    boxSizing: "border-box",
  };

  const contentStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    width: "100%",
  };

  const logoStyle = {
    width: "80%",
    maxWidth: "300px",
    marginBottom: "5%",
  };

  const circleLogoStyle = {
    width: "50%",
    maxWidth: "200px",
    marginBottom: "5%",
    marginTop: "-50%",
  };

  const headingStyle = {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Poppins, sans-serif",
    fontSize: "clamp(24px, 5vw, 38px)",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "125%",
    letterSpacing: "-0.03em",
    marginBottom: "5%",
  };

  const buttonContainerStyle = {
    width: "100%",
    maxWidth: "344px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const buttonStyle = {
    width: "100%",
    height: "65px",
    borderRadius: "14px",
    background: "#3B3B3B",
    color: "var(--BG, #F3F5F6)",
    border: "none",
    fontFamily: "Poppins, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.16px",
    cursor: "pointer",
    marginBottom: "10px",
  };

  if (isDesktop) {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "#121516",
          minHeight: "100vh",
        }}
      >
        {/* Left Sidebar */}
        <div
          style={{
            width: "350px",
            position: "fixed",
            left: 0,
            top: 0,
            height: "100vh",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "12px",
              left: "12px",
              animation: "fadeInUp 1s ease-in-out",
            }}
          >
            <Sidebar
              activeTab={activeTab}
              handleTabClick={setActiveTab}
              events={[]}
              loading={false}
              handleCardClick={() => {}}
            />
          </div>
        </div>
        <div
          style={{
            width: "1px",
            height: "100vh",
            position: "fixed",
            left: "350px",
            top: 0,
            background: "var(--black, #2E313C)",
          }}
        ></div>

        {/* Main Content */}
        <div
          style={{
            flex: 1,
            padding: "20px",
            marginLeft: isWideDesktop ? "auto" : "350px",
            marginRight: isWideDesktop ? "auto" : "0",
            maxWidth: isWideDesktop ? "800px" : "calc(100% - 350px)",
            width: isWideDesktop ? "800px" : "auto",
            height: "calc(100vh - 100px)", // Reduced height
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start", // Changed from center to flex-start
            paddingTop: "100px", // Added top padding to move content up
            overflow: "hidden", // Prevent scrolling
          }}
        >
          <img
            src={VexVibeLogo}
            alt="VexVibe"
            style={{
              width: "80%",
              maxWidth: "300px",
              marginBottom: "5%",
            }}
          />
          {message && <div style={messageStyle}>{message}</div>}
          <img
            src={CircleLogo}
            alt="VexVibe Logo"
            style={{
              width: "50%",
              maxWidth: "200px",
              marginBottom: "5%",
            }}
          />
          <h2
            style={{
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Poppins, sans-serif",
              fontSize: "clamp(24px, 5vw, 38px)",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "125%",
              letterSpacing: "-0.03em",
              marginBottom: "5%",
            }}
          >
            Join or Create a team
          </h2>
          <div
            style={{
              width: "100%",
              maxWidth: "344px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <button
              style={{
                width: "100%",
                height: "65px",
                borderRadius: "14px",
                background: "#3B3B3B",
                color: "var(--BG, #F3F5F6)",
                border: "none",
                fontFamily: "Poppins, sans-serif",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.16px",
                cursor: "pointer",
                marginBottom: "10px",
              }}
              onClick={() => navigate("/JoinTeam")}
            >
              Join a Team
            </button>
            <button
              style={{
                width: "100%",
                height: "65px",
                borderRadius: "14px",
                background: "#3B3B3B",
                color: "var(--BG, #F3F5F6)",
                border: "none",
                fontFamily: "Poppins, sans-serif",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.16px",
                cursor: "pointer",
                marginBottom: "10px",
              }}
              onClick={() => navigate("/CreateTeam")}
            >
              Create a team
            </button>
          </div>
        </div>

        {/* Right Sidebar (Tournament) */}
        {isWideDesktop && <TournamentSidebar />}
      </div>
    );
  }

  return (
    <div style={containerStyle}>
      <img src={VexVibeLogo} alt="VexVibe" style={logoStyle} />
      <div style={contentStyle}>
        {message && <div style={messageStyle}>{message}</div>}
        <img src={CircleLogo} alt="VexVibe Logo" style={circleLogoStyle} />
        <h2 style={headingStyle}>Join or Create a team</h2>
        <div style={buttonContainerStyle}>
          <button style={buttonStyle} onClick={() => navigate("/JoinTeam")}>
            Join a Team
          </button>
          <button style={buttonStyle} onClick={() => navigate("/CreateTeam")}>
            Create a team
          </button>
        </div>
      </div>
      <Dock />
    </div>
  );
};

export default WelcomeTeam;
