import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { firestore } from "../firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  runTransaction,
  serverTimestamp,
} from "firebase/firestore";
import AuthContext from "../context/AuthContext";
import Dock from "../components/Dock";
import defaultImage from "../assets/images/CircleLogo.svg";
import likeIcon from "../assets/images/Like.svg";
import unlikeIcon from "../assets/images/Unlike.svg";
import axios from "axios";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { addDoc, deleteDoc } from "firebase/firestore";
import { FaFilter, FaEllipsisV } from "react-icons/fa"; // Add FaEllipsisV here
import ProfileSettings from "../assets/images/ProfileSettings.svg";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateDoc } from "firebase/firestore";
import { FaTimes } from "react-icons/fa"; // Add this import
import Sidebar from "../components/Sidebar";
import TournamentSidebar from "../components/TournamentSidebar";

const renderDesktopSkeleton = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#121516",
      minHeight: "100vh",
      color: "#808080",
    }}
  >
    <div
      style={{
        flex: 1,
        padding: "20px",
        maxWidth: "800px",
        width: "75%",
      }}
    >
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <Skeleton
          circle
          width={104}
          height={104}
          style={{ marginRight: "20px" }}
          baseColor="#808080"
          highlightColor="#A9A9A9"
        />
        <div style={{ flex: 1 }}>
          <Skeleton
            width={200}
            height={24}
            style={{ marginBottom: "10px" }}
            baseColor="#808080"
            highlightColor="#A9A9A9"
          />
          <Skeleton
            width={300}
            height={16}
            baseColor="#808080"
            highlightColor="#A9A9A9"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Skeleton
          width={80}
          height={40}
          baseColor="#808080"
          highlightColor="#A9A9A9"
        />
        <Skeleton
          width={80}
          height={40}
          baseColor="#808080"
          highlightColor="#A9A9A9"
        />
        <Skeleton
          width={80}
          height={40}
          baseColor="#808080"
          highlightColor="#A9A9A9"
        />
      </div>
      <Skeleton
        height={40}
        style={{ marginBottom: "20px" }}
        baseColor="#808080"
        highlightColor="#A9A9A9"
      />
      {[1, 2, 3].map((_, index) => (
        <div key={index} style={{ marginBottom: "20px" }}>
          <DesktopPostSkeleton />
        </div>
      ))}
    </div>
  </div>
);

const DesktopPostSkeleton = () => (
  <div
    className="postSkeleton"
    style={{
      background: "#292b2c",
      borderRadius: "10px",
      padding: "10px",
      marginBottom: "20px",
      width: "100%",
    }}
  >
    <div
      className="postHeaderSkeleton"
      style={{ display: "flex", alignItems: "center" }}
    >
      <div
        className="profileImageSkeleton"
        style={{
          width: "40px",
          height: "40px",
          background: "#808080",
          borderRadius: "50%",
          marginRight: "10px",
        }}
      ></div>
      <div
        className="usernameSkeleton"
        style={{
          width: "100px",
          height: "20px",
          background: "#808080",
          borderRadius: "5px",
        }}
      ></div>
    </div>
    <div
      className="contentSkeleton"
      style={{
        width: "100%",
        height: "60px",
        background: "#808080",
        borderRadius: "5px",
        margin: "10px 0",
      }}
    ></div>
    <div
      className="imageSkeleton"
      style={{
        width: "100%",
        height: "200px",
        background: "#808080",
        borderRadius: "10px",
      }}
    ></div>
    <div
      className="likeSectionSkeleton"
      style={{
        width: "100px",
        height: "20px",
        background: "#808080",
        borderRadius: "5px",
        marginTop: "10px",
      }}
    ></div>
  </div>
);

// <style>
//   {`
//     @keyframes pulse {
//       0% { opacity: 0.6; }
//       50% { opacity: 1; }
//       100% { opacity: 0.6; }
//     }
//   `}
// </style>;

const TeamProfilePage = () => {
  const { teamId } = useParams();
  const [teamProfile, setTeamProfile] = useState(null);
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isTogglingLike, setIsTogglingLike] = useState(false);
  const [activeTab, setActiveTab] = useState("team");
  const [rankings, setRankings] = useState([]);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState("date");
  const [season, setSeason] = useState("all");
  const [seasons, setSeasons] = useState(["V5RC 2024-2025: High Stakes"]);
  const [awards, setAwards] = useState([]);
  const [followersCount, setFollowersCount] = useState(0);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isTogglingFollow, setIsTogglingFollow] = useState(false);
  const [isTeamMember, setIsTeamMember] = useState(false);
  const [showMembersOverlay, setShowMembersOverlay] = useState(false);
  const [showFollowersOverlay, setShowFollowersOverlay] = useState(false);
  const [membersList, setMembersList] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(
    "V5RC 2024-2025: High Stakes"
  );
  const [showSeasonFilter, setShowSeasonFilter] = useState(false);
  const [awardSeasons, setAwardSeasons] = useState([
    "V5RC 2024-2025: High Stakes",
  ]);
  const [selectedAwardSeason, setSelectedAwardSeason] = useState(
    "V5RC 2024-2025: High Stakes"
  );
  const [showAwardSeasonFilter, setShowAwardSeasonFilter] = useState(false);
  const [showDropdown, setShowDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const [isUserTeamMember, setIsUserTeamMember] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [teamRegion, setTeamRegion] = useState("");
  const [teamImage, setTeamImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [showTeamSettings, setShowTeamSettings] = useState(false);
  const [teamBio, setTeamBio] = useState("");
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1439);
  const [isWideDesktop, setIsWideDesktop] = useState(window.innerWidth >= 1580);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 769);
      setIsWideDesktop(window.innerWidth >= 1580);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const regions = [
    "Alabama",
    "Alaska",
    "Alberta/Saskatchewan",
    "American Samoa",
    "Andorra",
    "Arizona",
    "Arkansas",
    "Australia",
    "Azerbaijan",
    "Bahrain",
    "Bangladesh",
    "Belgium",
    "Bermuda",
    "Brazil",
    "British Columbia (BC)",
    "Burundi",
    "California - Region 1",
    "California - Region 2",
    "California - Region 3",
    "California - Region 4",
    "Chile",
    "China",
    "Chinese Taipei",
    "Colombia",
    "Colorado",
    "Cook Islands",
    "Czech Republic",
    "Côte d'Ivoire",
    "Delaware",
    "Delmarva",
    "District of Columbia",
    "Egypt",
    "Estonia",
    "Ethiopia",
    "Finland",
    "Florida - North/Central",
    "Florida - South",
    "France",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Guam",
    "Guatemala",
    "Haiti",
    "Hawaii",
    "Hong Kong",
    "Idaho",
    "Illinois",
    "India",
    "Indiana",
    "Indonesia",
    "Iowa",
    "Ireland",
    "Japan",
    "Jordan",
    "Kansas",
    "Kazakhstan",
    "Kentucky",
    "Kenya",
    "Kuwait",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Lithuania",
    "Louisiana",
    "Luxembourg",
    "Macau",
    "Maine",
    "Malaysia",
    "Manitoba",
    "Maryland",
    "Mexico",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Morocco",
    "Myanmar",
    "Nebraska",
    "Netherlands",
    "Nevada",
    "New Brunswick/Nova Scotia/Prince Edward Island",
    "New Hampshire/Vermont",
    "New Jersey",
    "New Mexico",
    "New York-North",
    "New York-South",
    "New Zealand",
    "Newfoundland and Labrador",
    "Nigeria",
    "North Carolina",
    "North Dakota",
    "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oman",
    "Ontario",
    "Oregon",
    "Panama",
    "Paraguay",
    "Pennsylvania - East",
    "Pennsylvania - West",
    "Philippines",
    "Poland",
    "Puerto Rico",
    "Qatar",
    "Quebec",
    "Romania",
    "Russia",
    "Saudi Arabia",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "South Africa",
    "South Carolina",
    "South Dakota",
    "South Korea",
    "Southern New England",
    "Spain",
    "Suriname",
    "Switzerland",
    "Tennessee",
    "Texas - Region 1",
    "Texas - Region 2",
    "Texas - Region 3",
    "Texas - Region 4",
    "Texas - Region 5",
    "Texas - Region 6",
    "Thailand",
    "Tunisia",
    "Turkmenistan",
    "Türkiye",
    "Uganda",
    "Unassigned",
    "United Arab Emirates",
    "United Kingdom",
    "Utah",
    "Vancouver Island (BC)",
    "Vietnam",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
    "Zimbabwe",
  ];

  const toggleTeamSettings = () => {
    setShowTeamSettings(!showTeamSettings);
  };

  useEffect(() => {
    if (teamProfile) {
      setTeamRegion(teamProfile.region);
      setImagePreview(teamProfile.profileImage);
      setTeamBio(teamProfile.bio || ""); // Add this line
    }
  }, [teamProfile]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setTeamImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSaveTeamChanges = async () => {
    if (!teamProfile || !currentUser) return;

    setIsLoading(true);
    try {
      const teamRef = doc(firestore, "teams", teamProfile.teamId);
      const updateData = {
        region: teamRegion,
        bio: teamBio,
      };

      if (teamImage) {
        const storage = getStorage();
        const imageRef = ref(storage, `team_images/${teamProfile.teamId}`);
        await uploadBytes(imageRef, teamImage);
        const imageUrl = await getDownloadURL(imageRef);
        updateData.profileImage = imageUrl;
      } else if (imagePreview === defaultImage) {
        // If the image was removed, update the profileImage to null or a default value
        updateData.profileImage = null; // or set it to the URL of your default image
      }

      await updateDoc(teamRef, updateData);

      // Update local state
      setTeamProfile((prev) => ({
        ...prev,
        region: teamRegion,
        bio: teamBio,
        profileImage: updateData.profileImage || defaultImage,
      }));

      setShowTeamSettings(false);
    } catch (error) {
      console.error("Error updating team settings:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const checkTeamMembership = async () => {
      if (teamProfile && currentUser) {
        const teamDoc = await getDoc(
          doc(firestore, "teams", teamProfile.teamId)
        );
        if (teamDoc.exists()) {
          const teamData = teamDoc.data();
          setIsUserTeamMember(teamData.members.includes(currentUser.uid));
        }
      }
    };

    checkTeamMembership();
  }, [teamProfile, currentUser]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderSkeleton = () => (
    <SkeletonTheme baseColor="#3c3c3c" highlightColor="#444">
      <div style={{ padding: "20px", marginTop: "60px" }}>
        <Skeleton
          circle
          width={104}
          height={104}
          style={{ marginBottom: "20px" }}
        />
        <Skeleton width={200} height={24} style={{ marginBottom: "20px" }} />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "40px",
          }}
        >
          <Skeleton width={80} height={40} />
          <Skeleton width={80} height={40} />
          <Skeleton width={80} height={40} />
        </div>

        <Skeleton height={40} style={{ marginBottom: "20px" }} />

        {[1, 2, 3].map((_, index) => (
          <div key={index} style={{ marginBottom: "20px" }}>
            <Skeleton height={100} />
          </div>
        ))}
      </div>
    </SkeletonTheme>
  );

  const handleDeletePost = async (postId) => {
    if (!currentUser) return;

    try {
      await deleteDoc(doc(firestore, "posts", postId));
      setPosts(posts.filter((post) => post.id !== postId));
      setShowDropdown(null);
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  useEffect(() => {
    const fetchTeamData = async () => {
      setIsLoading(true);
      try {
        let teamDocRef;

        if (teamId) {
          // If teamId is provided in the URL, use it
          teamDocRef = doc(firestore, "teams", teamId);
        } else if (currentUser) {
          // If no teamId is provided, fetch the current user's team
          const userRef = doc(firestore, "users", currentUser.uid);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists() && userSnap.data().teamId) {
            teamDocRef = doc(firestore, "teams", userSnap.data().teamId);
          } else {
            // If the user doesn't have a team, redirect to create team page or show a message
            setError("You are not part of any team.");
            setIsLoading(false);
            return;
          }
        } else {
          // If no user is logged in, redirect to login page
          navigate("/login");
          return;
        }

        const teamSnap = await getDoc(teamDocRef);

        if (teamSnap.exists()) {
          const teamData = teamSnap.data();
          setTeamProfile({
            teamId: teamSnap.id,
            teamName: teamData.team_name,
            profileImage: teamData.profileImage || defaultImage,
            description: teamData.description || "",
            region: teamData.region || "",
            country: teamData.country || "",
            members: teamData.members || [],
            bio: teamData.bio || "",
            VEX: teamData.VEX || "", // Add this line to include VEX data
          });

          await fetchTeamPosts(teamSnap.id);
          const robotEventsTeamId = await fetchRobotEventsTeamId(teamName);
          if (robotEventsTeamId) {
            await fetchRankings(robotEventsTeamId, teamName); // Pass teamName here
            await fetchAwards(robotEventsTeamId);
          } else {
            console.warn(
              "Couldn't find Robot Events team ID. Rankings and awards may not be available."
            );
            setRankings([]);
            setAwards([]);
          }
        } else {
          console.log("No such team!");
          setError("Team not found");
        }
      } catch (error) {
        console.error("Error fetching team data:", error);
        setError("Error fetching team data");
      }
      setIsLoading(false);
    };

    fetchTeamData();
  }, [teamId, currentUser, navigate]);

  const fetchRobotEventsTeamId = async (teamName) => {
    try {
      const teamResponse = await axios.get(
        `https://www.robotevents.com/api/v2/teams`,
        {
          params: {
            number: [teamName],
            grade: ["High School"],
            myTeams: false,
          },
          headers: {
            Authorization:
              "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMTQ0ZDRkYmExYzA0OTgzNGE4YmQ3YWE5YjIxOTY2NDdjMTMyOWNiMGE0ZWEwMTlkMDEyOGYwNjZhOTVlNWZlZWZlYzFmNDkyNGVjNTRhODAiLCJpYXQiOjE3MTkwOTQ1MTQuNDUxNTQ1LCJuYmYiOjE3MTkwOTQ1MTQuNDUxNTQ4MSwiZXhwIjoyNjY1NzgyOTE0LjQ0MzYzNCwic3ViIjoiMTI3Nzg2Iiwic2NvcGVzIjpbXX0.FZHrqboWUPv_dUehpNrhiG-HAr-LLQMSCYQTsKsfh6xKPCBrqj3wkzwlgt23EFBvUHVRH0w3x5LGGrRI2yT3yXaudObgYnEMp0TqjUMyiC4QXvSJsdEKXhlqE_FULdgxiklH0d9itKqjcs-w3ALF6NcGAvG8C62j2FOyn25RNmplx0AEMOt6Yvt1GPZQj_ifH74V12sVaYsTN55sRbkCkJzNaA0vdzXiurr7_Xw3cQK28tMGAjw2oG7srr3n7IU1MCCEnpudxRU5Kye6ztv1KsL7mY5Ip5lq0jMdrNJ1hXzVEDrWHlG0Xu3qGZiWv5ic3i21NwgjnwkUnRbQlK3pNz6Zcg4tCtGabkApgYYJYBYRVJhmeKzu_lPBVErLE5dIFIJm20n1c5lmmLac0w-plSOk5lPnERE8me7ICE3vK6rcG5TYL6CZaJUA0TLVEedMAObaAJ_VEsh0dU786TmgRGwHMYIU5lr6F5bm7NJOOex8OGeKn0NUoPece715NWMi7SFKyrkdaH1WFodLVGCY4zNbOYImkgVfhnOb2re3YsOOiUnEXyaak9yfyU1orZJHQy1xXzImarsL1T50-2z-UvoiLbvll7aAyBDXVmevYRNiEYjmyzYo8MwI2ENr7RqytZVaf5aM_3xvpKiOFzU-MVNYIypNyB1p2uzd-ctcoNw",
          },
        }
      );

      if (teamResponse.data.data.length === 0) {
        console.warn(`No team found for ${teamName} in Robot Events API`);
        return null;
      }

      return teamResponse.data.data[0]?.id;
    } catch (error) {
      console.error("Error fetching Robot Events team ID:", error);
      return null;
    }
  };

  const fetchTeamPosts = async (teamId) => {
    try {
      const postsQuery = query(
        collection(firestore, "posts"),
        where("teamId", "==", teamId),
        orderBy("createdAt", "desc")
      );
      const postsSnapshot = await getDocs(postsQuery);
      const fetchedPosts = await Promise.all(
        postsSnapshot.docs.map(async (docSnapshot) => {
          const postData = docSnapshot.data();
          const teamRef = doc(firestore, "teams", postData.teamId);
          const teamSnap = await getDoc(teamRef);
          const teamData = teamSnap.exists() ? teamSnap.data() : null;

          // Fetch the current like state for the post
          const likeRef = doc(
            firestore,
            `posts/${docSnapshot.id}/likes/${currentUser?.uid}`
          );
          const likeSnap = await getDoc(likeRef);

          return {
            id: docSnapshot.id,
            ...postData,
            teamName: teamData?.team_name || "Unknown Team",
            teamProfileImage: teamData?.profileImage || defaultImage,
            isLiked: likeSnap.exists(),
            likeCount: postData.likeCount || 0,
          };
        })
      );
      setPosts(fetchedPosts);
    } catch (error) {
      console.error("Error fetching team posts:", error);
    }
  };

  const handleLikeClick = async (postId, isLiked) => {
    if (!currentUser) {
      console.error("User not authenticated");
      return;
    }

    if (isTogglingLike) return;

    setIsTogglingLike(true);

    const postRef = doc(firestore, "posts", postId);
    const likeRef = doc(firestore, `posts/${postId}/likes/${currentUser.uid}`);

    try {
      await runTransaction(firestore, async (transaction) => {
        const postSnapshot = await transaction.get(postRef);
        if (!postSnapshot.exists()) {
          throw new Error("Post does not exist!");
        }

        const likeSnapshot = await transaction.get(likeRef);
        let newLikeCount = postSnapshot.data().likeCount || 0;
        let newIsLiked = isLiked;

        if (isLiked && likeSnapshot.exists()) {
          // Unlike the post
          transaction.delete(likeRef);
          newLikeCount = Math.max(0, newLikeCount - 1);
          newIsLiked = false;
        } else if (!isLiked && !likeSnapshot.exists()) {
          // Like the post
          transaction.set(likeRef, {
            userId: currentUser.uid,
            createdAt: serverTimestamp(),
          });
          newLikeCount += 1;
          newIsLiked = true;
        }

        // Update the like count in the post document
        transaction.update(postRef, { likeCount: newLikeCount });

        // Update the local state after the transaction is successful
        setPosts((prevPosts) =>
          prevPosts.map((post) =>
            post.id === postId
              ? {
                  ...post,
                  isLiked: newIsLiked,
                  likeCount: newLikeCount,
                }
              : post
          )
        );
      });
    } catch (error) {
      console.error("Error toggling like: ", error);
    } finally {
      setIsTogglingLike(false);
    }
  };

  const fetchRankings = async (robotEventsTeamId, teamName) => {
    // Add teamName parameter here
    if (!robotEventsTeamId) {
      console.warn("No Robot Events team ID provided for rankings");
      setRankings([]);
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      // Step 1: Get the team's ID
      const teamResponse = await axios.get(
        `https://www.robotevents.com/api/v2/teams`,
        {
          params: {
            number: [teamName],
            grade: ["High School"],
            myTeams: false,
          },
          headers: {
            Authorization:
              "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMTQ0ZDRkYmExYzA0OTgzNGE4YmQ3YWE5YjIxOTY2NDdjMTMyOWNiMGE0ZWEwMTlkMDEyOGYwNjZhOTVlNWZlZWZlYzFmNDkyNGVjNTRhODAiLCJpYXQiOjE3MTkwOTQ1MTQuNDUxNTQ1LCJuYmYiOjE3MTkwOTQ1MTQuNDUxNTQ4MSwiZXhwIjoyNjY1NzgyOTE0LjQ0MzYzNCwic3ViIjoiMTI3Nzg2Iiwic2NvcGVzIjpbXX0.FZHrqboWUPv_dUehpNrhiG-HAr-LLQMSCYQTsKsfh6xKPCBrqj3wkzwlgt23EFBvUHVRH0w3x5LGGrRI2yT3yXaudObgYnEMp0TqjUMyiC4QXvSJsdEKXhlqE_FULdgxiklH0d9itKqjcs-w3ALF6NcGAvG8C62j2FOyn25RNmplx0AEMOt6Yvt1GPZQj_ifH74V12sVaYsTN55sRbkCkJzNaA0vdzXiurr7_Xw3cQK28tMGAjw2oG7srr3n7IU1MCCEnpudxRU5Kye6ztv1KsL7mY5Ip5lq0jMdrNJ1hXzVEDrWHlG0Xu3qGZiWv5ic3i21NwgjnwkUnRbQlK3pNz6Zcg4tCtGabkApgYYJYBYRVJhmeKzu_lPBVErLE5dIFIJm20n1c5lmmLac0w-plSOk5lPnERE8me7ICE3vK6rcG5TYL6CZaJUA0TLVEedMAObaAJ_VEsh0dU786TmgRGwHMYIU5lr6F5bm7NJOOex8OGeKn0NUoPece715NWMi7SFKyrkdaH1WFodLVGCY4zNbOYImkgVfhnOb2re3YsOOiUnEXyaak9yfyU1orZJHQy1xXzImarsL1T50-2z-UvoiLbvll7aAyBDXVmevYRNiEYjmyzYo8MwI2ENr7RqytZVaf5aM_3xvpKiOFzU-MVNYIypNyB1p2uzd-ctcoNw",
          },
        }
      );

      const teamId = teamResponse.data.data[0]?.id;
      if (!teamId) {
        throw new Error("Team ID not found");
      }

      // Step 2: Fetch the team's rankings
      const rankingsResponse = await axios.get(
        `https://www.robotevents.com/api/v2/teams/${teamId}/rankings`,
        {
          headers: {
            Authorization:
              "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMTQ0ZDRkYmExYzA0OTgzNGE4YmQ3YWE5YjIxOTY2NDdjMTMyOWNiMGE0ZWEwMTlkMDEyOGYwNjZhOTVlNWZlZWZlYzFmNDkyNGVjNTRhODAiLCJpYXQiOjE3MTkwOTQ1MTQuNDUxNTQ1LCJuYmYiOjE3MTkwOTQ1MTQuNDUxNTQ4MSwiZXhwIjoyNjY1NzgyOTE0LjQ0MzYzNCwic3ViIjoiMTI3Nzg2Iiwic2NvcGVzIjpbXX0.FZHrqboWUPv_dUehpNrhiG-HAr-LLQMSCYQTsKsfh6xKPCBrqj3wkzwlgt23EFBvUHVRH0w3x5LGGrRI2yT3yXaudObgYnEMp0TqjUMyiC4QXvSJsdEKXhlqE_FULdgxiklH0d9itKqjcs-w3ALF6NcGAvG8C62j2FOyn25RNmplx0AEMOt6Yvt1GPZQj_ifH74V12sVaYsTN55sRbkCkJzNaA0vdzXiurr7_Xw3cQK28tMGAjw2oG7srr3n7IU1MCCEnpudxRU5Kye6ztv1KsL7mY5Ip5lq0jMdrNJ1hXzVEDrWHlG0Xu3qGZiWv5ic3i21NwgjnwkUnRbQlK3pNz6Zcg4tCtGabkApgYYJYBYRVJhmeKzu_lPBVErLE5dIFIJm20n1c5lmmLac0w-plSOk5lPnERE8me7ICE3vK6rcG5TYL6CZaJUA0TLVEedMAObaAJ_VEsh0dU786TmgRGwHMYIU5lr6F5bm7NJOOex8OGeKn0NUoPece715NWMi7SFKyrkdaH1WFodLVGCY4zNbOYImkgVfhnOb2re3YsOOiUnEXyaak9yfyU1orZJHQy1xXzImarsL1T50-2z-UvoiLbvll7aAyBDXVmevYRNiEYjmyzYo8MwI2ENr7RqytZVaf5aM_3xvpKiOFzU-MVNYIypNyB1p2uzd-ctcoNw",
          },
        }
      );

      // Step 3: Fetch event details for each ranking
      const rankingsWithSeasons = await Promise.all(
        rankingsResponse.data.data.map(async (ranking) => {
          const eventResponse = await axios.get(
            `https://www.robotevents.com/api/v2/events/${ranking.event.id}`,
            {
              headers: {
                Authorization:
                  "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMTQ0ZDRkYmExYzA0OTgzNGE4YmQ3YWE5YjIxOTY2NDdjMTMyOWNiMGE0ZWEwMTlkMDEyOGYwNjZhOTVlNWZlZWZlYzFmNDkyNGVjNTRhODAiLCJpYXQiOjE3MTkwOTQ1MTQuNDUxNTQ1LCJuYmYiOjE3MTkwOTQ1MTQuNDUxNTQ4MSwiZXhwIjoyNjY1NzgyOTE0LjQ0MzYzNCwic3ViIjoiMTI3Nzg2Iiwic2NvcGVzIjpbXX0.FZHrqboWUPv_dUehpNrhiG-HAr-LLQMSCYQTsKsfh6xKPCBrqj3wkzwlgt23EFBvUHVRH0w3x5LGGrRI2yT3yXaudObgYnEMp0TqjUMyiC4QXvSJsdEKXhlqE_FULdgxiklH0d9itKqjcs-w3ALF6NcGAvG8C62j2FOyn25RNmplx0AEMOt6Yvt1GPZQj_ifH74V12sVaYsTN55sRbkCkJzNaA0vdzXiurr7_Xw3cQK28tMGAjw2oG7srr3n7IU1MCCEnpudxRU5Kye6ztv1KsL7mY5Ip5lq0jMdrNJ1hXzVEDrWHlG0Xu3qGZiWv5ic3i21NwgjnwkUnRbQlK3pNz6Zcg4tCtGabkApgYYJYBYRVJhmeKzu_lPBVErLE5dIFIJm20n1c5lmmLac0w-plSOk5lPnERE8me7ICE3vK6rcG5TYL6CZaJUA0TLVEedMAObaAJ_VEsh0dU786TmgRGwHMYIU5lr6F5bm7NJOOex8OGeKn0NUoPece715NWMi7SFKyrkdaH1WFodLVGCY4zNbOYImkgVfhnOb2re3YsOOiUnEXyaak9yfyU1orZJHQy1xXzImarsL1T50-2z-UvoiLbvll7aAyBDXVmevYRNiEYjmyzYo8MwI2ENr7RqytZVaf5aM_3xvpKiOFzU-MVNYIypNyB1p2uzd-ctcoNw",
              },
            }
          );
          return {
            ...ranking,
            event: {
              ...ranking.event,
              season: eventResponse.data.season,
            },
          };
        })
      );

      setRankings(rankingsWithSeasons);
      const uniqueSeasons = [
        ...new Set(
          rankingsWithSeasons.map((ranking) => ranking.event?.season?.name)
        ),
      ].filter(Boolean);
      const allSeasons = ["V5RC 2024-2025: High Stakes", ...uniqueSeasons];
      setSeasons(allSeasons);

      setSelectedSeason("V5RC 2024-2025: High Stakes");
      setRankings(rankingsWithSeasons);
    } catch (err) {
      console.error("Error fetching rankings:", err);
      setError("Failed to fetch rankings. Please try again.");
      setRankings([]);
    } finally {
      setIsLoading(false);
    }
  };

  const sortedRankings = [...rankings].sort((a, b) => {
    if (sortBy === "date") {
      return new Date(b.event.end) - new Date(a.event.end);
    } else if (sortBy === "rank") {
      return a.rank - b.rank;
    }
    return 0;
  });

  const filteredRankings = sortedRankings.filter(
    (ranking) => season === "all" || ranking.season.name === season
  );

  const fetchAwards = async (teamId) => {
    setIsLoading(true);
    setError(null);
    try {
      const awardsResponse = await axios.get(
        `https://www.robotevents.com/api/v2/teams/${teamId}/awards`,
        {
          headers: {
            Authorization:
              "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMTQ0ZDRkYmExYzA0OTgzNGE4YmQ3YWE5YjIxOTY2NDdjMTMyOWNiMGE0ZWEwMTlkMDEyOGYwNjZhOTVlNWZlZWZlYzFmNDkyNGVjNTRhODAiLCJpYXQiOjE3MTkwOTQ1MTQuNDUxNTQ1LCJuYmYiOjE3MTkwOTQ1MTQuNDUxNTQ4MSwiZXhwIjoyNjY1NzgyOTE0LjQ0MzYzNCwic3ViIjoiMTI3Nzg2Iiwic2NvcGVzIjpbXX0.FZHrqboWUPv_dUehpNrhiG-HAr-LLQMSCYQTsKsfh6xKPCBrqj3wkzwlgt23EFBvUHVRH0w3x5LGGrRI2yT3yXaudObgYnEMp0TqjUMyiC4QXvSJsdEKXhlqE_FULdgxiklH0d9itKqjcs-w3ALF6NcGAvG8C62j2FOyn25RNmplx0AEMOt6Yvt1GPZQj_ifH74V12sVaYsTN55sRbkCkJzNaA0vdzXiurr7_Xw3cQK28tMGAjw2oG7srr3n7IU1MCCEnpudxRU5Kye6ztv1KsL7mY5Ip5lq0jMdrNJ1hXzVEDrWHlG0Xu3qGZiWv5ic3i21NwgjnwkUnRbQlK3pNz6Zcg4tCtGabkApgYYJYBYRVJhmeKzu_lPBVErLE5dIFIJm20n1c5lmmLac0w-plSOk5lPnERE8me7ICE3vK6rcG5TYL6CZaJUA0TLVEedMAObaAJ_VEsh0dU786TmgRGwHMYIU5lr6F5bm7NJOOex8OGeKn0NUoPece715NWMi7SFKyrkdaH1WFodLVGCY4zNbOYImkgVfhnOb2re3YsOOiUnEXyaak9yfyU1orZJHQy1xXzImarsL1T50-2z-UvoiLbvll7aAyBDXVmevYRNiEYjmyzYo8MwI2ENr7RqytZVaf5aM_3xvpKiOFzU-MVNYIypNyB1p2uzd-ctcoNw",
          },
        }
      );
      setAwards(awardsResponse.data.data);
      const awardsWithSeasons = await Promise.all(
        awardsResponse.data.data.map(async (award) => {
          const eventResponse = await axios.get(
            `https://www.robotevents.com/api/v2/events/${award.event.id}`,
            {
              headers: {
                Authorization:
                  "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMTQ0ZDRkYmExYzA0OTgzNGE4YmQ3YWE5YjIxOTY2NDdjMTMyOWNiMGE0ZWEwMTlkMDEyOGYwNjZhOTVlNWZlZWZlYzFmNDkyNGVjNTRhODAiLCJpYXQiOjE3MTkwOTQ1MTQuNDUxNTQ1LCJuYmYiOjE3MTkwOTQ1MTQuNDUxNTQ4MSwiZXhwIjoyNjY1NzgyOTE0LjQ0MzYzNCwic3ViIjoiMTI3Nzg2Iiwic2NvcGVzIjpbXX0.FZHrqboWUPv_dUehpNrhiG-HAr-LLQMSCYQTsKsfh6xKPCBrqj3wkzwlgt23EFBvUHVRH0w3x5LGGrRI2yT3yXaudObgYnEMp0TqjUMyiC4QXvSJsdEKXhlqE_FULdgxiklH0d9itKqjcs-w3ALF6NcGAvG8C62j2FOyn25RNmplx0AEMOt6Yvt1GPZQj_ifH74V12sVaYsTN55sRbkCkJzNaA0vdzXiurr7_Xw3cQK28tMGAjw2oG7srr3n7IU1MCCEnpudxRU5Kye6ztv1KsL7mY5Ip5lq0jMdrNJ1hXzVEDrWHlG0Xu3qGZiWv5ic3i21NwgjnwkUnRbQlK3pNz6Zcg4tCtGabkApgYYJYBYRVJhmeKzu_lPBVErLE5dIFIJm20n1c5lmmLac0w-plSOk5lPnERE8me7ICE3vK6rcG5TYL6CZaJUA0TLVEedMAObaAJ_VEsh0dU786TmgRGwHMYIU5lr6F5bm7NJOOex8OGeKn0NUoPece715NWMi7SFKyrkdaH1WFodLVGCY4zNbOYImkgVfhnOb2re3YsOOiUnEXyaak9yfyU1orZJHQy1xXzImarsL1T50-2z-UvoiLbvll7aAyBDXVmevYRNiEYjmyzYo8MwI2ENr7RqytZVaf5aM_3xvpKiOFzU-MVNYIypNyB1p2uzd-ctcoNw",
              },
            }
          );
          return {
            ...award,
            season: eventResponse.data.season,
          };
        })
      );

      setAwards(awardsWithSeasons);

      const uniqueSeasons = [
        ...new Set(awardsWithSeasons.map((award) => award.season.name)),
      ];
      const orderedSeasons = [
        "V5RC 2024-2025: High Stakes",
        "VRC 2023-2024: Over Under",
        "VRC 2022-2023: Spin Up",
        ...uniqueSeasons.filter(
          (season) =>
            ![
              "V5RC 2024-2025: High Stakes",
              "VRC 2023-2024: Over Under",
              "VRC 2022-2023: Spin Up",
              "VRC 2021-2022: Tipping Point",
            ].includes(season)
        ),
      ];
      setAwardSeasons(orderedSeasons);
    } catch (err) {
      setError("Failed to fetch awards. Please try again.");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchFollowersCount = async () => {
      if (teamProfile && teamProfile.teamId) {
        const followersQuery = query(
          collection(firestore, "teams", teamProfile.teamId, "followers")
        );
        const followersSnapshot = await getDocs(followersQuery);
        setFollowersCount(followersSnapshot.size);

        // Check if the current user is following this team
        if (currentUser) {
          const userFollowQuery = query(
            followersQuery,
            where("followerId", "==", currentUser.uid)
          );
          const userFollowSnapshot = await getDocs(userFollowQuery);
          setIsFollowing(!userFollowSnapshot.empty);
        }
      }
    };

    fetchFollowersCount();
  }, [teamProfile, currentUser]);

  useEffect(() => {
    const checkTeamMembership = async () => {
      if (teamProfile && currentUser) {
        const teamDoc = await getDoc(
          doc(firestore, "teams", teamProfile.teamId)
        );
        if (teamDoc.exists()) {
          const teamData = teamDoc.data();
          setIsTeamMember(teamData.members.includes(currentUser.uid));
        }
      }
    };

    checkTeamMembership();
  }, [teamProfile, currentUser]);

  const handleFollowClick = async () => {
    if (!currentUser || !teamProfile) return;

    setIsTogglingFollow(true);

    const followerId = currentUser.uid;
    const teamId = teamProfile.teamId;

    const followersCollectionRef = collection(
      firestore,
      "teams",
      teamId,
      "followers"
    );
    const followingCollectionRef = collection(
      firestore,
      "users",
      followerId,
      "followingTeams"
    );

    try {
      await runTransaction(firestore, async (transaction) => {
        const followQuery = query(
          followersCollectionRef,
          where("followerId", "==", followerId)
        );
        const followSnap = await getDocs(followQuery);

        if (followSnap.empty) {
          // Follow the team
          await addDoc(followersCollectionRef, {
            followerId: followerId,
            createdAt: serverTimestamp(),
          });
          await addDoc(followingCollectionRef, {
            teamId: teamId,
            createdAt: serverTimestamp(),
          });
          setIsFollowing(true);
          setFollowersCount((prev) => prev + 1);
        } else {
          // Unfollow the team
          followSnap.forEach(async (doc) => {
            await deleteDoc(doc.ref);
          });
          const unfollowQuery = query(
            followingCollectionRef,
            where("teamId", "==", teamId)
          );
          const unfollowSnap = await getDocs(unfollowQuery);
          unfollowSnap.forEach(async (doc) => {
            await deleteDoc(doc.ref);
          });
          setIsFollowing(false);
          setFollowersCount((prev) => prev - 1);
        }
      });
    } catch (error) {
      console.error("Error toggling follow status: ", error);
    } finally {
      setIsTogglingFollow(false);
    }
  };

  const fetchMembersList = async () => {
    if (!teamProfile || !teamProfile.teamId) return;

    try {
      const teamRef = doc(firestore, "teams", teamProfile.teamId);
      const teamSnap = await getDoc(teamRef);

      if (teamSnap.exists()) {
        const teamData = teamSnap.data();
        const memberIds = teamData.members || [];

        const membersData = await Promise.all(
          memberIds.map(async (memberId) => {
            const userRef = doc(firestore, "users", memberId);
            const userSnap = await getDoc(userRef);
            if (userSnap.exists()) {
              return { userId: memberId, ...userSnap.data() };
            }
            return null;
          })
        );
        setMembersList(membersData.filter(Boolean));
      }
    } catch (error) {
      console.error("Error fetching members list:", error);
    }
  };

  const fetchFollowersList = async () => {
    if (!teamProfile || !teamProfile.teamId) return;

    try {
      const followersQuery = query(
        collection(firestore, "teams", teamProfile.teamId, "followers")
      );
      const followersSnapshot = await getDocs(followersQuery);
      const followersData = await Promise.all(
        followersSnapshot.docs.map(async (docSnapshot) => {
          const followerId = docSnapshot.data().followerId;
          const userRef = doc(firestore, "users", followerId);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            return { userId: followerId, ...userSnap.data() };
          }
          return null;
        })
      );
      setFollowersList(followersData.filter(Boolean));
    } catch (error) {
      console.error("Error fetching followers list:", error);
    }
  };

  const handleRemoveProfilePicture = () => {
    setTeamImage(null);
    setImagePreview(defaultImage);
  };

  const handleMembersClick = async () => {
    await fetchMembersList();
    setShowMembersOverlay(true);
  };

  const handleFollowersClick = async () => {
    await fetchFollowersList();
    setShowFollowersOverlay(true);
  };

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("overlay")) {
      handleOverlayClose();
    }
  };

  const handleOverlayClose = () => {
    setShowMembersOverlay(false);
    setShowFollowersOverlay(false);
  };

  const handleProfileNavigation = (username) => {
    navigate(`/users/${username}`);
    handleOverlayClose();
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "team":
        return <div style={{ marginTop: "0px" }}>{renderTeamContent()}</div>;
      case "rankings":
        return renderRankingsContent();
      case "awards":
        return renderAwardsContent();
      default:
        return null;
    }
  };

  const renderTeamContent = () => (
    <>
      <div style={{ padding: "20px" }}>
        <div
          style={{
            position: "absolute",
            top: "70px",
            left: "25px",
            width: "104px",
            height: "104px",
            borderRadius: "50%",
            overflow: "hidden",
          }}
        >
          <img
            src={teamProfile?.profileImage}
            alt={teamProfile?.teamName}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: "65px",
            left: "145px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h2
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              marginRight: "10px",
            }}
          >
            {teamProfile?.teamName}
          </h2>
          {/* Add the VEX badge here */}
          {teamProfile?.VEX && (
            <span
              style={{
                backgroundColor: "#2196F3", // Light blue
                color: "white",
                padding: "2px 6px",
                borderRadius: "12px",
                fontSize: "12px",
                fontWeight: "bold",
                marginRight: "10px",
              }}
            >
              {teamProfile.VEX}
            </span>
          )}
          {currentUser &&
            teamProfile &&
            currentUser.uid !== teamProfile.teamId &&
            !isTeamMember && (
              <button
                onClick={handleFollowClick}
                disabled={isTogglingFollow}
                style={{
                  display: "flex",
                  width: "75px",
                  height: "30px",
                  padding: "4px",
                  alignItems: "center",
                  justifyContent: "center",
                  flexShrink: 0,
                  borderRadius: "100px",
                  background: "#4d5050",
                  color: "#FFF",
                  fontSize: "12px",
                  cursor: "pointer",
                  border: "none",
                }}
              >
                <span
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "44px",
                    letterSpacing: "0.13px",
                  }}
                >
                  {isFollowing ? "Unfollow" : "Follow"}
                </span>
              </button>
            )}
        </div>
        <p
          style={{
            position: "absolute",
            top: "115px",
            left: "145px",
            fontSize: "14px",
            color: "#c6c6c6",
            maxWidth: "calc(100% - 170px)",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {teamProfile?.bio || "No bio available"}
        </p>
      </div>

      <div
        style={{
          position: "absolute",
          top: "200px",
          left: "0",
          right: "0",
          display: "flex",
          justifyContent: "space-between",
          padding: "0 25px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div
            style={{ color: "#c6c6c6", fontSize: "20px", fontWeight: "700" }}
          >
            {posts.length}
          </div>
          <div
            style={{ color: "#c6c6c6", fontSize: "20px", fontWeight: "700" }}
          >
            Posts
          </div>
        </div>
        <div
          style={{ textAlign: "center", cursor: "pointer" }}
          onClick={handleMembersClick}
        >
          <div
            style={{ color: "#c6c6c6", fontSize: "20px", fontWeight: "700" }}
          >
            {teamProfile?.members.length}
          </div>
          <div
            style={{ color: "#c6c6c6", fontSize: "20px", fontWeight: "700" }}
          >
            {teamProfile?.members.length === 1 ? "Member" : "Members"}
          </div>
        </div>
        <div
          style={{ textAlign: "center", cursor: "pointer" }}
          onClick={handleFollowersClick}
        >
          <div
            style={{ color: "#c6c6c6", fontSize: "20px", fontWeight: "700" }}
          >
            {followersCount}
          </div>
          <div
            style={{ color: "#c6c6c6", fontSize: "20px", fontWeight: "700" }}
          >
            Followers
          </div>
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          top: "275px",
          left: "24px",
          right: "24px",
          display: "flex",
          padding: "4px",
          alignItems: "flex-start",
          borderRadius: "100px",
          background: "#000",
        }}
      >
        <div
          style={{
            flex: "1 0 0",
            padding: "8px 20px",
            textAlign: "center",
            borderRadius: "100px",
            background: "#101828",
            color: "#fff",
            fontSize: "12px",
            fontWeight: "500",
          }}
        >
          Posts
        </div>
      </div>

      <div
        style={{
          marginTop: "265px",
          padding: "0 24px",
        }}
      >
        {posts.map((post) => (
          <div
            key={post.id}
            style={{
              marginBottom: "20px",
              backgroundColor: "#1e1e1e",
              padding: "12px",
              borderRadius: "8px",
              marginLeft: "-10px",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    marginRight: "10px",
                  }}
                >
                  <img
                    src={teamProfile?.profileImage || defaultImage}
                    alt={teamProfile?.teamName}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                  {teamProfile?.teamName}
                </span>
              </div>
              {currentUser &&
                teamProfile &&
                currentUser.uid === teamProfile.members[0] && (
                  <div style={{ position: "relative" }} ref={dropdownRef}>
                    <FaEllipsisV
                      style={{ fontSize: "20px", cursor: "pointer" }}
                      onClick={() => setShowDropdown(post.id)}
                    />
                    {showDropdown === post.id && (
                      <div
                        style={{
                          position: "absolute",
                          right: 0,
                          top: "100%",
                          backgroundColor: "#2c2c2c",
                          borderRadius: "5px",
                          padding: "5px",
                          zIndex: 1000,
                        }}
                      >
                        <div
                          onClick={() => handleDeletePost(post.id)}
                          style={{
                            cursor: "pointer",
                            padding: "5px 10px",
                            color: "#FFF",
                            fontFamily: "Manrope",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "160%",
                            letterSpacing: "0.12px",
                          }}
                        >
                          Delete
                        </div>
                      </div>
                    )}
                  </div>
                )}
            </div>
            <p
              style={{
                fontSize: "14px",
                margin: "10px 0",
                marginLeft: "45px",
                color: "#fff",
              }}
            >
              {post.content}
            </p>
            {post.imageUrl && (
              <img
                src={post.imageUrl}
                alt="Post image"
                style={{
                  width: "80%",
                  maxHeight: "300px",
                  objectFit: "cover",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  marginLeft: "40px",
                }}
              />
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "45px",
                marginTop: "5px",
              }}
            >
              <img
                src={post.isLiked ? likeIcon : unlikeIcon}
                alt={post.isLiked ? "Unlike" : "Like"}
                onClick={() => handleLikeClick(post.id, post.isLiked)}
                style={{
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                  marginRight: "5px",
                }}
              />
              <span style={{ fontSize: "16px", color: "#FFFFFF" }}>
                {post.likeCount || 0}
              </span>
            </div>
          </div>
        ))}

        {showMembersOverlay && (
          <div
            className="overlay"
            onClick={handleOverlayClick}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <div
              className="overlay-content"
              style={{
                backgroundColor: "#1e1e1e",
                color: "#ffffff",
                padding: "20px",
                borderRadius: "10px",
                maxWidth: "300px",
                maxHeight: "80vh",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <h2
                style={{
                  fontSize: "24px",
                  textAlign: "center",
                  position: "absolute",
                  top: 16,
                  backgroundColor: "#1e1e1e",
                  margin: 0,
                  zIndex: 1,
                }}
              >
                Members
              </h2>
              {membersList.length > 0 ? (
                membersList.map((user) => (
                  <div
                    key={user.userId}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "15px",
                      marginTop: "15px",
                      width: "100%", // Ensure the container takes full width
                      transform: "translateY(-100px)", // Move up 100px
                    }}
                  >
                    <img
                      src={user.profileImage || defaultImage}
                      alt={user.username}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                    />
                    <div style={{ flex: 1 }}>
                      {" "}
                      {/* Add flex: 1 to take remaining space */}
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        {user.username}
                      </div>
                    </div>
                    <button
                      onClick={() => handleProfileNavigation(user.username)}
                      style={{
                        backgroundColor: "#3a3a3a",
                        color: "#ffffff",
                        border: "none",
                        padding: "8px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      View Profile
                    </button>
                  </div>
                ))
              ) : (
                <p>No members found.</p>
              )}
            </div>
          </div>
        )}

        {/* Overlay for displaying followers */}
        {showFollowersOverlay && (
          <div
            className="overlay"
            onClick={handleOverlayClick}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <div
              className="overlay-content"
              style={{
                backgroundColor: "#1e1e1e",
                color: "#ffffff",
                padding: "20px",
                borderRadius: "10px",
                maxWidth: "300px",
                maxHeight: "80vh",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <h2
                style={{
                  fontSize: "24px",
                  textAlign: "center",
                  position: "absolute",
                  top: 16,
                  backgroundColor: "#1e1e1e",
                  margin: 0,
                  zIndex: 1,
                }}
              >
                Followers
              </h2>
              {followersList.length > 0 ? (
                followersList.map((user) => (
                  <div
                    key={user.userId}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "15px",
                      marginTop: "-450px",
                    }}
                  >
                    <img
                      src={user.profileImage || defaultImage}
                      alt={user.username}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        marginRight: "5px",
                      }}
                    />
                    <div>
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          marginRight: "25px",
                        }}
                      >
                        {user.username}
                      </div>
                    </div>
                    <button
                      onClick={() => handleProfileNavigation(user.username)}
                      style={{
                        marginLeft: "auto",
                        backgroundColor: "#3a3a3a",
                        color: "#ffffff",
                        border: "none",
                        padding: "8px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      View Profile
                    </button>
                  </div>
                ))
              ) : (
                <p>No followers found.</p>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );

  const renderRankingsContent = () => (
    <div style={{ marginTop: "50px" }}>
      <div style={{ padding: "0 24px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: "10px",
            position: "relative",
          }}
        >
          <div
            onClick={() => setShowSeasonFilter(!showSeasonFilter)}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              background: "#2c2c2c",
              padding: "5px 8px",
              borderRadius: "5px",
              fontSize: "12px",
            }}
          >
            <FaFilter style={{ marginRight: "5px", fontSize: "10px" }} />
            <span>Filter</span>
          </div>
          <span style={{ marginLeft: "10px", fontSize: "14px", color: "#888" }}>
            {selectedSeason}
          </span>
          {showSeasonFilter && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                left: 0,
                background: "#2c2c2c",
                borderRadius: "5px",
                padding: "5px",
                zIndex: 1000,
                fontSize: "12px",
                width: "200px",
              }}
            >
              {seasons.map((season) => (
                <div
                  key={season}
                  onClick={() => {
                    setSelectedSeason(season);
                    setShowSeasonFilter(false);
                  }}
                  style={{ cursor: "pointer", padding: "3px 5px" }}
                >
                  {season}
                </div>
              ))}
            </div>
          )}
        </div>
        {isLoading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          rankings
            .filter((ranking) => ranking.event?.season?.name === selectedSeason)
            .map((ranking, index) => (
              <div
                key={index}
                style={{
                  background: "#1e1e1e",
                  borderRadius: "8px",
                  padding: "16px",
                  marginBottom: "16px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                }}
              >
                <h3
                  style={{
                    margin: "0 0 8px 0",
                    fontSize: "18px",
                    color: "#fff",
                  }}
                >
                  {ranking.event?.name || "Unknown Event"}
                </h3>
                <p
                  style={{
                    margin: "0 0 8px 0",
                    fontSize: "14px",
                    color: "#888",
                  }}
                >
                  {(() => {
                    const seasonId = ranking.event?.season?.id;
                    switch (seasonId) {
                      case 190:
                        return "V5RC 2024-2025: High Stakes";
                      case 181:
                        return "VRC 2023-2024: Over Under";
                      case 173:
                        return "VRC 2022-2023: Spin Up";
                      case 154:
                        return "VRC 2021-2022: Tipping Point";
                      default:
                        return ranking.event?.season?.name || "Unknown Season";
                    }
                  })()}
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "14px", color: "#888" }}>Rank:</div>
                    <div
                      style={{
                        fontSize: "18px",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      {ranking.rank || "N/A"}
                    </div>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "14px", color: "#888" }}>
                      W-L-T:
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      {`${ranking.wins || 0}-${ranking.losses || 0}-${
                        ranking.ties || 0
                      }`}
                    </div>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "14px", color: "#888" }}>
                      WP/AP/SP:
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      {`${ranking.wp || 0}/${ranking.ap || 0}/${
                        ranking.sp || 0
                      }`}
                    </div>
                  </div>
                </div>
              </div>
            ))
        )}
        {rankings.filter(
          (ranking) => ranking.event?.season?.name === selectedSeason
        ).length === 0 && (
          <div
            style={{
              borderRadius: "8px",
              padding: "16px",
              marginBottom: "16px",
              textAlign: "center",
              color: "white",
            }}
          >
            No tournaments yet for {selectedSeason}
          </div>
        )}
      </div>
    </div>
  );

  const renderAwardsContent = () => (
    <div style={{ marginTop: "50px" }}>
      <div style={{ padding: "0 24px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: "10px",
            position: "relative",
          }}
        >
          <div
            onClick={() => setShowAwardSeasonFilter(!showAwardSeasonFilter)}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              background: "#2c2c2c",
              padding: "5px 8px",
              borderRadius: "5px",
              fontSize: "12px",
            }}
          >
            <FaFilter style={{ marginRight: "5px", fontSize: "10px" }} />
            <span>Filter</span>
          </div>
          <span style={{ marginLeft: "10px", fontSize: "14px", color: "#888" }}>
            {selectedAwardSeason}
          </span>
          {showAwardSeasonFilter && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                left: 0,
                background: "#2c2c2c",
                borderRadius: "5px",
                padding: "5px",
                zIndex: 1000,
                fontSize: "12px",
                width: "200px",
              }}
            >
              {awardSeasons.map((season) => (
                <div
                  key={season}
                  onClick={() => {
                    setSelectedAwardSeason(season);
                    setShowAwardSeasonFilter(false);
                  }}
                  style={{ cursor: "pointer", padding: "3px 5px" }}
                >
                  {season}
                </div>
              ))}
            </div>
          )}
        </div>
        {isLoading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error}</div>
        ) : awards.length === 0 ? (
          <div>No awards found for this team.</div>
        ) : (
          awards
            .filter((award) => award.season.name === selectedAwardSeason)
            .map((award, index) => (
              <div
                key={index}
                style={{
                  background: "#1e1e1e",
                  borderRadius: "8px",
                  padding: "16px",
                  marginBottom: "16px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                }}
              >
                <h3
                  style={{
                    margin: "0 0 8px 0",
                    fontSize: "18px",
                    color: "#fff",
                  }}
                >
                  {award.title}
                </h3>
                <p
                  style={{
                    margin: "0 0 4px 0",
                    fontSize: "14px",
                    color: "#888",
                  }}
                >
                  {award.event.name}
                </p>
                <p
                  style={{
                    margin: "0 0 8px 0",
                    fontSize: "14px",
                    color: "#888",
                  }}
                >
                  {award.season.name}
                </p>
                <div style={{ textAlign: "left" }}>
                  <div style={{ fontSize: "14px", color: "#888" }}>
                    Qualifications:
                  </div>
                  <div style={{ fontSize: "14px", color: "#fff" }}>
                    {award.qualifications.length > 0
                      ? award.qualifications.join(", ")
                      : "None"}
                  </div>
                </div>
              </div>
            ))
        )}
        {awards.filter((award) => award.season.name === selectedAwardSeason)
          .length === 0 && (
          <div
            style={{
              background: "#1e1e1e",
              borderRadius: "8px",
              padding: "16px",
              marginBottom: "16px",
              textAlign: "center",
              color: "#888",
            }}
          >
            No awards yet for {selectedAwardSeason}
          </div>
        )}
      </div>
    </div>
  );

  const renderTeamSettings = () => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "#121516",
          borderRadius: "12px",
          padding: "24px",
          width: "90%",
          maxWidth: "400px",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <h2
          style={{
            color: "#FFF",
            fontSize: "24px",
            marginBottom: "24px",
            textAlign: "center",
          }}
        >
          Team Settings
        </h2>
        <div style={{ marginBottom: "24px", position: "relative" }}>
          <label
            htmlFor="teamImage"
            style={{
              display: "block",
              width: "120px",
              height: "120px",
              borderRadius: "60px",
              margin: "0 auto",
              backgroundImage: `url(${imagePreview || defaultImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              cursor: "pointer",
              border: "2px solid #2c2c2c",
            }}
          >
            <input
              type="file"
              id="teamImage"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </label>
          {imagePreview !== defaultImage && (
            <div
              onClick={handleRemoveProfilePicture}
              style={{
                position: "absolute",
                bottom: "0",
                right: "calc(50% - 70px)",
                backgroundColor: "red",
                borderRadius: "50%",
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <FaTimes color="white" size={16} />
            </div>
          )}
        </div>
        <div style={{ marginBottom: "24px" }}>
          <label
            htmlFor="teamRegion"
            style={{ color: "#FFF", display: "block", marginBottom: "8px" }}
          >
            Region
          </label>
          <select
            id="teamRegion"
            value={teamRegion}
            onChange={(e) => setTeamRegion(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              backgroundColor: "#2c2c2c",
              border: "none",
              borderRadius: "4px",
              color: "#FFF",
              fontSize: "16px",
            }}
          >
            <option value="">Select a region</option>
            {regions.map((region) => (
              <option key={region} value={region}>
                {region}
              </option>
            ))}
          </select>
        </div>
        <div style={{ marginBottom: "24px" }}>
          <label
            htmlFor="teamBio"
            style={{ color: "#FFF", display: "block", marginBottom: "8px" }}
          >
            Team Bio
          </label>
          <textarea
            id="teamBio"
            value={teamBio}
            onChange={(e) => setTeamBio(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              backgroundColor: "#2c2c2c",
              border: "none",
              borderRadius: "4px",
              color: "#FFF",
              fontSize: "16px",
              minHeight: "100px",
              resize: "vertical",
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            onClick={toggleTeamSettings}
            style={{
              padding: "10px 20px",
              backgroundColor: "#2c2c2c",
              color: "#FFF",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Cancel
          </button>
          <button
            onClick={handleSaveTeamChanges}
            style={{
              padding: "10px 20px",
              backgroundColor: "#3498db",
              color: "#FFF",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <div
        style={{
          backgroundColor: "#121516",
          minHeight: "100vh",
          color: "#FFF",
          fontFamily: "Manrope",
        }}
      >
        <style>
          {`
            @keyframes rolling {
              0% { background-position: -200% 0; }
              100% { background-position: 200% 0; }
            }
            .react-loading-skeleton {
              background-color: #3c3c3c !important;
              background-image: linear-gradient(
                90deg,
                #3c3c3c,
                #444,
                #3c3c3c
              ) !important;
              background-size: 200% 100% !important;
              animation: rolling 1.5s infinite linear !important;
            }
          `}
        </style>
        {isDesktop ? renderDesktopSkeleton() : renderSkeleton()}
        {!isDesktop && <Dock />}
      </div>
    );
  }

  if (isDesktop) {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "#121516",
          minHeight: "100vh",
          color: "#FFF",
        }}
      >
        <div
          style={{
            width: "350px",
            position: "fixed",
            left: 0,
            top: 0,
            height: "100vh",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "12px",
              left: "12px",
              animation: "fadeInUp 1s ease-in-out",
            }}
          >
            <Sidebar
              activeTab={activeTab}
              handleTabClick={setActiveTab}
              events={[]}
              loading={isLoading}
              handleCardClick={() => {}}
            />
          </div>
        </div>
        <div
          style={{
            width: "1px",
            height: "100vh",
            position: "fixed",
            left: "350px",
            top: 0,
            background: "var(--black, #2E313C)",
          }}
        ></div>
        <div
          style={{
            flex: 1,
            padding: "20px",
            marginLeft: isWideDesktop ? "auto" : "350px",
            marginRight: isWideDesktop ? "auto" : "0",
            maxWidth: isWideDesktop ? "800px" : "calc(100% - 350px)",
            width: isWideDesktop ? "800px" : "auto",
            overflowY: "auto",
            height: "100vh",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          {isLoading ? (
            <div style={{ padding: "20px", marginTop: "60px" }}>
              <Skeleton
                circle
                width={104}
                height={104}
                style={{ marginBottom: "20px" }}
              />
              <Skeleton
                width={200}
                height={24}
                style={{ marginBottom: "20px" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "40px",
                }}
              >
                <Skeleton width={80} height={40} />
                <Skeleton width={80} height={40} />
                <Skeleton width={80} height={40} />
              </div>
              <Skeleton height={40} style={{ marginBottom: "20px" }} />
              {[1, 2, 3].map((_, index) => (
                <div key={index} style={{ marginBottom: "20px" }}>
                  <Skeleton height={100} />
                </div>
              ))}
            </div>
          ) : (
            <>
              <div style={{ padding: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <img
                    src={teamProfile?.profileImage || defaultImage}
                    alt={teamProfile?.teamName}
                    style={{
                      width: "104px",
                      height: "104px",
                      borderRadius: "52px",
                      marginRight: "20px",
                    }}
                  />
                  <div style={{ flex: 1 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h2
                        style={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          color: "#FFF",
                          marginRight: "10px",
                        }}
                      >
                        {teamProfile?.teamName}
                      </h2>
                      {/* Add the VEX badge here */}
                      {teamProfile?.VEX && (
                        <span
                          style={{
                            backgroundColor: "#2196F3",
                            color: "white",
                            padding: "2px 6px",
                            borderRadius: "12px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            marginRight: "10px",
                          }}
                        >
                          {teamProfile.VEX}
                        </span>
                      )}
                      {currentUser &&
                        teamProfile &&
                        currentUser.uid !== teamProfile.teamId &&
                        !isTeamMember && (
                          <button
                            onClick={handleFollowClick}
                            disabled={isTogglingFollow}
                            style={{
                              display: "flex",
                              width: "75px",
                              height: "30px",
                              padding: "4px",
                              alignItems: "center",
                              justifyContent: "center",
                              flexShrink: 0,
                              borderRadius: "100px",
                              background: "#4d5050",
                              color: "#FFF",
                              fontSize: "12px",
                              cursor: "pointer",
                              border: "none",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Roboto",
                                fontSize: "13px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "44px",
                                letterSpacing: "0.13px",
                              }}
                            >
                              {isFollowing ? "Unfollow" : "Follow"}
                            </span>
                          </button>
                        )}
                    </div>
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#c6c6c6",
                        maxWidth: "500px",
                        marginTop: "5px",
                      }}
                    >
                      {teamProfile?.bio || "No bio available"}
                    </p>
                  </div>
                  {isTeamMember && (
                    <img
                      src={ProfileSettings}
                      alt="Settings"
                      onClick={toggleTeamSettings}
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                        marginLeft: "auto",
                      }}
                    />
                  )}

                  {showTeamSettings && (
                    <div
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1000,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#121516",
                          borderRadius: "12px",
                          padding: "24px",
                          width: "90%",
                          maxWidth: "400px",
                          maxHeight: "80vh",
                          overflowY: "auto",
                        }}
                      >
                        <h2
                          style={{
                            color: "#FFF",
                            fontSize: "24px",
                            marginBottom: "24px",
                            textAlign: "center",
                          }}
                        >
                          Team Settings
                        </h2>
                        <div
                          style={{ marginBottom: "24px", position: "relative" }}
                        >
                          <label
                            htmlFor="teamImage"
                            style={{
                              display: "block",
                              width: "120px",
                              height: "120px",
                              borderRadius: "60px",
                              margin: "0 auto",
                              backgroundImage: `url(${
                                imagePreview || defaultImage
                              })`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              cursor: "pointer",
                              border: "2px solid #2c2c2c",
                            }}
                          >
                            <input
                              type="file"
                              id="teamImage"
                              accept="image/*"
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                            />
                          </label>
                          {imagePreview !== defaultImage && (
                            <div
                              onClick={handleRemoveProfilePicture}
                              style={{
                                position: "absolute",
                                bottom: "0",
                                right: "calc(50% - 70px)",
                                backgroundColor: "red",
                                borderRadius: "50%",
                                width: "24px",
                                height: "24px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <FaTimes color="white" size={16} />
                            </div>
                          )}
                        </div>
                        <div style={{ marginBottom: "24px" }}>
                          <label
                            htmlFor="teamRegion"
                            style={{
                              color: "#FFF",
                              display: "block",
                              marginBottom: "8px",
                            }}
                          >
                            Region
                          </label>
                          <select
                            id="teamRegion"
                            value={teamRegion}
                            onChange={(e) => setTeamRegion(e.target.value)}
                            style={{
                              width: "100%",
                              padding: "8px",
                              backgroundColor: "#2c2c2c",
                              border: "none",
                              borderRadius: "4px",
                              color: "#FFF",
                              fontSize: "16px",
                            }}
                          >
                            <option value="">Select a region</option>
                            {regions.map((region) => (
                              <option key={region} value={region}>
                                {region}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div style={{ marginBottom: "24px" }}>
                          <label
                            htmlFor="teamBio"
                            style={{
                              color: "#FFF",
                              display: "block",
                              marginBottom: "8px",
                            }}
                          >
                            Team Bio
                          </label>
                          <textarea
                            id="teamBio"
                            value={teamBio}
                            onChange={(e) => setTeamBio(e.target.value)}
                            style={{
                              width: "100%",
                              padding: "8px",
                              backgroundColor: "#2c2c2c",
                              border: "none",
                              borderRadius: "4px",
                              color: "#FFF",
                              fontSize: "16px",
                              minHeight: "100px",
                              resize: "vertical",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <button
                            onClick={toggleTeamSettings}
                            style={{
                              padding: "10px 20px",
                              backgroundColor: "#2c2c2c",
                              color: "#FFF",
                              border: "none",
                              borderRadius: "4px",
                              cursor: "pointer",
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            onClick={handleSaveTeamChanges}
                            style={{
                              padding: "10px 20px",
                              backgroundColor: "#3498db",
                              color: "#FFF",
                              border: "none",
                              borderRadius: "4px",
                              cursor: "pointer",
                            }}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "25px", // Reduced margin for closer spacing
                  }}
                >
                  <div style={{ textAlign: "center", color: "#FFF" }}>
                    <div style={{ fontSize: "20px", fontWeight: "700" }}>
                      {posts.length}
                    </div>
                    <div>Posts</div>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      color: "#FFF",
                    }}
                    onClick={handleMembersClick}
                  >
                    <div style={{ fontSize: "20px", fontWeight: "700" }}>
                      {teamProfile?.members.length}
                    </div>
                    <div>
                      {teamProfile?.members.length === 1 ? "Member" : "Members"}
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      color: "#FFF",
                    }}
                    onClick={handleFollowersClick}
                  >
                    <div style={{ fontSize: "20px", fontWeight: "700" }}>
                      {followersCount}
                    </div>
                    <div>Followers</div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                    background: "#000",
                    borderRadius: "100px",
                    padding: "4px",
                  }}
                >
                  {["Team", "Rankings", "Awards"].map((tab) => (
                    <div
                      key={tab}
                      onClick={() => setActiveTab(tab.toLowerCase())}
                      style={{
                        flex: "1 0 0",
                        padding: "8px 20px",
                        textAlign: "center",
                        borderRadius: "100px",
                        background:
                          activeTab === tab.toLowerCase()
                            ? "#101828"
                            : "transparent",
                        color: "#fff",
                        fontSize: "14px",
                        fontWeight: "500",
                        cursor: "pointer",
                        transition: "background 0.3s ease",
                      }}
                    >
                      {tab}
                    </div>
                  ))}
                </div>
              </div>
              {activeTab === "team" && (
                <div style={{ marginTop: "-25px" }}>
                  {posts.map((post) => (
                    <div
                      key={post.id}
                      style={{
                        marginBottom: "20px",
                        backgroundColor: "#1e1e1e",
                        padding: "12px",
                        borderRadius: "8px",
                        marginLeft: "30px",
                        width: "89%",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: "32px",
                              height: "32px",
                              borderRadius: "50%",
                              overflow: "hidden",
                              marginRight: "10px",
                            }}
                          >
                            <img
                              src={teamProfile?.profileImage || defaultImage}
                              alt={teamProfile?.teamName}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              color: "#FFF",
                            }}
                          >
                            {teamProfile?.teamName}
                          </span>
                        </div>
                        {currentUser &&
                          teamProfile &&
                          currentUser.uid === teamProfile.members[0] && (
                            <div
                              style={{ position: "relative" }}
                              ref={dropdownRef}
                            >
                              <FaEllipsisV
                                style={{
                                  fontSize: "20px",
                                  cursor: "pointer",
                                  color: "#FFF",
                                }}
                                onClick={() => setShowDropdown(post.id)}
                              />
                              {showDropdown === post.id && (
                                <div
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    top: "100%",
                                    backgroundColor: "#2c2c2c",
                                    borderRadius: "5px",
                                    padding: "5px",
                                    zIndex: 1000,
                                  }}
                                >
                                  <div
                                    onClick={() => handleDeletePost(post.id)}
                                    style={{
                                      cursor: "pointer",
                                      padding: "5px 10px",
                                      color: "#FFF",
                                      fontFamily: "Manrope",
                                      fontSize: "14px",
                                      fontStyle: "normal",
                                      fontWeight: 500,
                                      lineHeight: "160%",
                                      letterSpacing: "0.12px",
                                    }}
                                  >
                                    Delete
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "10px 0",
                          marginLeft: "45px",
                          color: "#fff",
                        }}
                      >
                        {post.content}
                      </p>
                      {post.imageUrl && (
                        <img
                          src={post.imageUrl}
                          alt="Post image"
                          style={{
                            width: "80%",
                            maxHeight: "300px",
                            objectFit: "cover",
                            borderRadius: "8px",
                            marginBottom: "10px",
                            marginLeft: "40px",
                          }}
                        />
                      )}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "45px",
                          marginTop: "5px",
                        }}
                      >
                        <img
                          src={post.isLiked ? likeIcon : unlikeIcon}
                          alt={post.isLiked ? "Unlike" : "Like"}
                          onClick={() => handleLikeClick(post.id, post.isLiked)}
                          style={{
                            cursor: "pointer",
                            width: "20px",
                            height: "20px",
                            marginRight: "5px",
                          }}
                        />
                        <span style={{ fontSize: "16px", color: "#FFFFFF" }}>
                          {post.likeCount || 0}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {activeTab === "rankings" && (
                <div style={{ marginTop: "50px" }}>
                  <div style={{ padding: "0 24px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginBottom: "10px",
                        position: "relative",
                      }}
                    >
                      <div
                        onClick={() => setShowSeasonFilter(!showSeasonFilter)}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          background: "#2c2c2c",
                          padding: "5px 8px",
                          borderRadius: "5px",
                          fontSize: "12px",
                        }}
                      >
                        <FaFilter
                          style={{ marginRight: "5px", fontSize: "10px" }}
                        />
                        <span>Filter</span>
                      </div>
                      <span
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          color: "#888",
                        }}
                      >
                        {selectedSeason}
                      </span>
                      {showSeasonFilter && (
                        <div
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            background: "#2c2c2c",
                            borderRadius: "5px",
                            padding: "5px",
                            zIndex: 1000,
                            fontSize: "12px",
                            width: "200px",
                          }}
                        >
                          {seasons.map((season) => (
                            <div
                              key={season}
                              onClick={() => {
                                setSelectedSeason(season);
                                setShowSeasonFilter(false);
                              }}
                              style={{
                                cursor: "pointer",
                                padding: "3px 5px",
                                color: "#FFF",
                              }}
                            >
                              {season}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {isLoading ? (
                      <div style={{ color: "#FFF" }}>Loading...</div>
                    ) : error ? (
                      <div style={{ color: "#FFF" }}>{error}</div>
                    ) : (
                      rankings
                        .filter(
                          (ranking) =>
                            ranking.event?.season?.name === selectedSeason
                        )
                        .map((ranking, index) => (
                          <div
                            key={index}
                            style={{
                              background: "#1e1e1e",
                              borderRadius: "8px",
                              padding: "16px",
                              marginBottom: "16px",
                              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                            }}
                          >
                            <h3
                              style={{
                                margin: "0 0 8px 0",
                                fontSize: "18px",
                                color: "#fff",
                              }}
                            >
                              {ranking.event?.name || "Unknown Event"}
                            </h3>
                            <p
                              style={{
                                margin: "0 0 8px 0",
                                fontSize: "14px",
                                color: "#888",
                              }}
                            >
                              {(() => {
                                const seasonId = ranking.event?.season?.id;
                                switch (seasonId) {
                                  case 190:
                                    return "V5RC 2024-2025: High Stakes";
                                  case 181:
                                    return "VRC 2023-2024: Over Under";
                                  case 173:
                                    return "VRC 2022-2023: Spin Up";
                                  case 154:
                                    return "VRC 2021-2022: Tipping Point";
                                  default:
                                    return (
                                      ranking.event?.season?.name ||
                                      "Unknown Season"
                                    );
                                }
                              })()}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                              }}
                            >
                              <div style={{ textAlign: "center" }}>
                                <div
                                  style={{ fontSize: "14px", color: "#888" }}
                                >
                                  Rank:
                                </div>
                                <div
                                  style={{
                                    fontSize: "18px",
                                    color: "#fff",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {ranking.rank || "N/A"}
                                </div>
                              </div>
                              <div style={{ textAlign: "center" }}>
                                <div
                                  style={{ fontSize: "14px", color: "#888" }}
                                >
                                  W-L-T:
                                </div>
                                <div
                                  style={{
                                    fontSize: "18px",
                                    color: "#fff",
                                    fontWeight: "bold",
                                  }}
                                >{`${ranking.wins || 0}-${
                                  ranking.losses || 0
                                }-${ranking.ties || 0}`}</div>
                              </div>
                              <div style={{ textAlign: "center" }}>
                                <div
                                  style={{ fontSize: "14px", color: "#888" }}
                                >
                                  WP/AP/SP:
                                </div>
                                <div
                                  style={{
                                    fontSize: "18px",
                                    color: "#fff",
                                    fontWeight: "bold",
                                  }}
                                >{`${ranking.wp || 0}/${ranking.ap || 0}/${
                                  ranking.sp || 0
                                }`}</div>
                              </div>
                            </div>
                          </div>
                        ))
                    )}
                    {rankings.filter(
                      (ranking) =>
                        ranking.event?.season?.name === selectedSeason
                    ).length === 0 && (
                      <div
                        style={{
                          borderRadius: "8px",
                          padding: "16px",
                          marginBottom: "16px",
                          textAlign: "center",
                          color: "white",
                        }}
                      >
                        No tournaments yet for {selectedSeason}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {activeTab === "awards" && (
                <div style={{ marginTop: "50px" }}>
                  <div style={{ padding: "0 24px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginBottom: "10px",
                        position: "relative",
                      }}
                    >
                      <div
                        onClick={() =>
                          setShowAwardSeasonFilter(!showAwardSeasonFilter)
                        }
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          background: "#2c2c2c",
                          padding: "5px 8px",
                          borderRadius: "5px",
                          fontSize: "12px",
                        }}
                      >
                        <FaFilter
                          style={{ marginRight: "5px", fontSize: "10px" }}
                        />
                        <span>Filter</span>
                      </div>
                      <span
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          color: "#888",
                        }}
                      >
                        {selectedAwardSeason}
                      </span>
                      {showAwardSeasonFilter && (
                        <div
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            background: "#2c2c2c",
                            borderRadius: "5px",
                            padding: "5px",
                            zIndex: 1000,
                            fontSize: "12px",
                            width: "200px",
                          }}
                        >
                          {awardSeasons.map((season) => (
                            <div
                              key={season}
                              onClick={() => {
                                setSelectedAwardSeason(season);
                                setShowAwardSeasonFilter(false);
                              }}
                              style={{
                                cursor: "pointer",
                                padding: "3px 5px",
                                color: "#FFF",
                              }}
                            >
                              {season}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {isLoading ? (
                      <div style={{ color: "#FFF" }}>Loading...</div>
                    ) : error ? (
                      <div style={{ color: "#FFF" }}>{error}</div>
                    ) : awards.length === 0 ? (
                      <div style={{ color: "#FFF" }}>
                        No awards found for this team.
                      </div>
                    ) : (
                      awards
                        .filter(
                          (award) => award.season.name === selectedAwardSeason
                        )
                        .map((award, index) => (
                          <div
                            key={index}
                            style={{
                              background: "#1e1e1e",
                              borderRadius: "8px",
                              padding: "16px",
                              marginBottom: "16px",
                              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                            }}
                          >
                            <h3
                              style={{
                                margin: "0 0 8px 0",
                                fontSize: "18px",
                                color: "#fff",
                              }}
                            >
                              {award.title}
                            </h3>
                            <p
                              style={{
                                margin: "0 0 4px 0",
                                fontSize: "14px",
                                color: "#888",
                              }}
                            >
                              {award.event.name}
                            </p>
                            <p
                              style={{
                                margin: "0 0 8px 0",
                                fontSize: "14px",
                                color: "#888",
                              }}
                            >
                              {award.season.name}
                            </p>
                            <div style={{ textAlign: "left" }}>
                              <div style={{ fontSize: "14px", color: "#888" }}>
                                Qualifications:
                              </div>
                              <div style={{ fontSize: "14px", color: "#fff" }}>
                                {award.qualifications.length > 0
                                  ? award.qualifications.join(", ")
                                  : "None"}
                              </div>
                            </div>
                          </div>
                        ))
                    )}
                    {awards.filter(
                      (award) => award.season.name === selectedAwardSeason
                    ).length === 0 && (
                      <div
                        style={{
                          background: "#1e1e1e",
                          borderRadius: "8px",
                          padding: "16px",
                          marginBottom: "16px",
                          textAlign: "center",
                          color: "#888",
                        }}
                      >
                        No awards yet for {selectedAwardSeason}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {isWideDesktop && <TournamentSidebar />}
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "#121516",
        minHeight: "100vh",
        color: "#FFF",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontFamily: "Manrope",
        fontSize: "24px",
        fontWeight: 700,
        paddingBottom: "100px",
      }}
    >
      <div
        className="tab-selector"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: isUserTeamMember ? "-405px" : "-405px",
          width: isUserTeamMember ? "calc(100% - 90px)" : "calc(100% - 0px)",
          maxWidth: isUserTeamMember
            ? "calc(100% - 90px)"
            : "calc(100% - 50px)",
          position: "relative",
          left: isUserTeamMember ? "0px" : "0px",
        }}
      >
        {isTeamMember && activeTab === "team" && (
          <img
            src={ProfileSettings}
            alt="Settings"
            onClick={toggleTeamSettings}
            style={{
              position: "absolute",
              top: "70px",
              right: "25px",
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
          />
        )}
        {["Team", "Rankings", "Awards"].map((tab) => (
          <div
            key={tab}
            onClick={() => setActiveTab(tab.toLowerCase())}
            style={{
              flex: "1 0 0",
              padding: "8px 20px",
              textAlign: "center",
              borderRadius: "100px",
              background:
                activeTab === tab.toLowerCase() ? "#101828" : "transparent",
              color: "#fff",
              fontSize: "12px",
              fontWeight: "500",
              cursor: "pointer",
              transition: "background 0.3s ease",
            }}
          >
            {tab}
          </div>
        ))}
      </div>
  
      <div
        style={{
          marginTop: "390px",
          width: "calc(100% - 32px)",
          maxWidth: "calc(100% - 32px)",
        }}
      >
        {renderTabContent()}
      </div>
  
      {showTeamSettings && renderTeamSettings()}
      <Dock />
    </div>
  );
};

export default TeamProfilePage;
