import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase";

const StoryHeader = ({ heading, profileImage, userId, currentUser }) => {
  const [isViewed, setIsViewed] = useState(false);

  useEffect(() => {
    const checkViewedStatus = async () => {
      if (!currentUser) return;

      const viewedStoriesRef = doc(
        firestore,
        `users/${currentUser.uid}/viewedStories/${userId}`
      );
      const docSnap = await getDoc(viewedStoriesRef);

      if (docSnap.exists() && docSnap.data().viewedStories.length > 0) {
        setIsViewed(true);
      }
    };

    checkViewedStatus();
  }, [currentUser, userId]);

  const handleProfileClick = () => {
    window.location.href = `/users/${heading}`;
  };

  return (
    <div
      className={`storyHeader ${isViewed ? "viewed" : ""}`}
      onClick={handleProfileClick}
    >
      <img src={profileImage} alt={heading} className="storyProfileImage" />
    </div>
  );
};

export default StoryHeader;
