import React, { useRef, useEffect } from "react";

const EventChat = ({
  tournament,
  messages,
  eventChatMessage,
  setEventChatMessage,
  handleSendEventChatMessage,
  sendIcon,
}) => {
  const mainRef = useRef(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.scrollTop = mainRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="tab-content">
      <header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px",
          backgroundColor: "#121516",
          color: "white",
          marginTop: "-40px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h1 style={{ fontSize: "18px", fontWeight: "bold" }}>
            {tournament.title}
          </h1>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "red",
              padding: "4px 8px",
              borderRadius: "9999px",
              marginRight: "8px",
            }}
          >
            <svg
              style={{ width: "8px", height: "8px", marginRight: "4px" }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <circle cx="12" cy="12" r="10" />
            </svg>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>LIVE</span>
          </div>
          <svg
            style={{ width: "24px", height: "24px" }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"
            />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        </div>
      </header>
      <main
        style={{
          flex: 1,
          overflowY: "auto",
          padding: "16px",
          maxHeight: "525px",
        }}
        ref={mainRef}
      >
        {messages.map((message) => (
          <div
            key={message.id}
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom:
                message.id === messages[messages.length - 1].id ? "0" : "16px",
            }}
          >
            <a href={`/users/${message.username}`}>
              <img
                src={message.profileImageUrl}
                alt="User"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  marginRight: "8px",
                }}
              />
            </a>
            <div
              style={{
                backgroundColor: "#2C2C2E",
                padding: "8px 16px",
                borderRadius: "8px",
                color: "white",
                maxWidth: "170px",
                overflowWrap: "break-word",
                wordBreak: "break-all",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                hyphens: "auto",
              }}
            >
              <div style={{ marginBottom: "4px" }}>
                <a
                  href={`/users/${message.username}`}
                  style={{
                    fontWeight: "bold",
                    marginRight: "8px",
                    color: "white",
                    textDecoration: "none",
                    marginLeft: "-5px",
                  }}
                >
                  {message.username}
                </a>
                <span style={{ fontSize: "12px", color: "#ccc" }}>
                  {new Date(message.timestamp).toLocaleTimeString()}
                </span>
              </div>
              <p
                style={{
                  margin: 0,
                  textAlign: "left",
                  wordWrap: "break-word",
                  hyphens: "auto",
                }}
              >
                {message.text}
              </p>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </main>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "16px",
          backgroundColor: "#2C2C2E",
          borderTop: "1px solid gray",
          position: "fixed",
          bottom: 0,
          width: "98%",
          marginLeft: "-25px",
        }}
      >
        <input
          type="text"
          placeholder="Type your message..."
          value={eventChatMessage}
          onChange={(e) => setEventChatMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSendEventChatMessage();
            }
          }}
          style={{
            flex: 1,
            padding: "8px",
            backgroundColor: "#444",
            borderRadius: "20px",
            color: "white",
            border: "none",
          }}
        />
        <button
          onClick={handleSendEventChatMessage}
          style={{
            marginLeft: "8px",
            backgroundColor: "#5856d6",
            border: "none",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <img
            src={sendIcon}
            alt="Send"
            style={{ width: "24px", height: "24px", color: "white" }}
          />
        </button>
      </div>
    </div>
  );
};

export default EventChat;
