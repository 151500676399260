import React, { useState, useEffect, useContext, useCallback } from "react";
import Dock from "../components/Dock"; // Assuming Dock is in the components folder
import algoliasearch from "algoliasearch/lite";
import defaultImage from "../assets/images/CircleLogo.svg";
import {
  doc,
  getDoc,
  query,
  collection,
  getDocs,
  orderBy,
  runTransaction,
  serverTimestamp,
} from "firebase/firestore";
import { firestore } from "../firebase";
import AuthContext from "../context/AuthContext"; // Import AuthContext for currentUser
import likeIcon from "../assets/images/Like.svg"; // Like icon
import unlikeIcon from "../assets/images/Unlike.svg"; // Unlike icon
import sendIcon from "../assets/images/send.svg";
import threeDotIcon from "../assets/images/3dot.svg";
import "../assets/styles/homePage.css"; // CSS styles
import { auth } from "../firebase";
import Sidebar from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
import TournamentSidebar from "../components/TournamentSidebar";
import MyRegion from "../components/search/myRegion";
import RecommendedPosts from "../components/search/RecommendedPosts";

const fadeInUpKeyframes = `
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const postStyle = {
  opacity: 0,
  transform: "translateY(20px)",
  animation: "fadeInUp 0.5s forwards",
};

const SearchPage = () => {
  const [activeTab, setActiveTab] = useState("recommended");
  const searchClient = algoliasearch(
    "JKUJEZR4KI",
    "713978c4b038973075544a2b055edd7b"
  );
  const [postsResults, setPostsResults] = useState([]);
  const [usersResults, setUsersResults] = useState([]);
  const [regionPosts, setRegionPosts] = useState([]); // State for region posts
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useContext(AuthContext); // Access currentUser from AuthContext
  const [userRegion, setUserRegion] = useState(null);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1439);
  const navigate = useNavigate();
  const [isWideDesktop, setIsWideDesktop] = useState(window.innerWidth >= 1580);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 769);
      setIsWideDesktop(window.innerWidth >= 1580);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (searchQuery.trim()) {
        navigate(`/results?query=${encodeURIComponent(searchQuery)}`);
      }
    }
  };

  const [cachedPosts, setCachedPosts] = useState({});

  // Function to get cached data or fetch new data
  const getCachedOrFetchData = useCallback(async (key, fetchFunction) => {
    const cachedData = localStorage.getItem(key);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      // Check if the cache is less than 5 minutes old
      if (Date.now() - timestamp < 5 * 60 * 1000) {
        return data;
      }
    }
    const newData = await fetchFunction();
    localStorage.setItem(
      key,
      JSON.stringify({ data: newData, timestamp: Date.now() })
    );
    return newData;
  }, []);

  const fetchRecommendedPosts = useCallback(async () => {
    setIsLoading(true);
    try {
      const postsData = await getCachedOrFetchData(
        "recommendedPosts",
        async () => {
          const postsQuery = query(
            collection(firestore, "posts"),
            orderBy("likeCount", "desc")
          );
          const querySnapshot = await getDocs(postsQuery);
          const postsData = await Promise.all(
            querySnapshot.docs.map(async (docSnapshot) => {
              const postData = docSnapshot.data();
              if (!postData.userId && !postData.teamId) return null;

              let userData;
              if (postData.teamId) {
                // Fetch team data
                const teamDocRef = doc(firestore, "teams", postData.teamId);
                const teamDocSnap = await getDoc(teamDocRef);
                if (!teamDocSnap.exists()) return null;
                userData = teamDocSnap.data();
                userData.username = userData.team_name; // Use team name as username
              } else {
                // Fetch user data
                const userDocRef = doc(firestore, "users", postData.userId);
                const userDocSnap = await getDoc(userDocRef);
                if (!userDocSnap.exists()) return null;
                userData = userDocSnap.data();
              }

              // Check if the current user has liked the post
              const likeDocRef = doc(
                firestore,
                `posts/${docSnapshot.id}/likes/${currentUser?.uid}`
              );
              const likeDocSnap = await getDoc(likeDocRef);
              const isLiked = likeDocSnap.exists();

              return {
                id: docSnapshot.id,
                ...postData,
                username: userData.username,
                profileImage: userData.profileImage || defaultImage,
                isLiked,
              };
            })
          );
          return postsData.filter((post) => post !== null);
        }
      );
      setPostsResults(postsData);
      setCachedPosts((prevState) => ({ ...prevState, recommended: postsData }));
    } catch (error) {
      console.error("Error fetching recommended posts:", error);
    } finally {
      setIsLoading(false);
    }
  }, [getCachedOrFetchData, currentUser]);

  const fetchRegionPosts = useCallback(async () => {
    setIsLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(firestore, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        const userRegion = userDocSnap.exists()
          ? userDocSnap.data().region
          : null;
        setUserRegion(userRegion);

        if (userRegion) {
          const postsData = await getCachedOrFetchData(
            `regionPosts_${userRegion}`,
            async () => {
              const postsQuery = query(
                collection(firestore, "posts"),
                orderBy("createdAt", "desc")
              );
              const querySnapshot = await getDocs(postsQuery);
              const postsData = await Promise.all(
                querySnapshot.docs.map(async (docSnapshot) => {
                  const postData = docSnapshot.data();
                  if (!postData.userId && !postData.teamId) return null;

                  let userData;
                  if (postData.teamId) {
                    // Fetch team data
                    const teamDocRef = doc(firestore, "teams", postData.teamId);
                    const teamDocSnap = await getDoc(teamDocRef);
                    if (!teamDocSnap.exists()) return null;
                    userData = teamDocSnap.data();
                    userData.username = userData.team_name; // Use team name as username
                  } else {
                    // Fetch user data
                    const userDocRef = doc(firestore, "users", postData.userId);
                    const userDocSnap = await getDoc(userDocRef);
                    if (!userDocSnap.exists()) return null;
                    userData = userDocSnap.data();
                  }

                  if (userData.region !== userRegion) return null; // Skip if user/team is not from the same region

                  // Check if the current user has liked the post
                  const likeDocRef = doc(
                    firestore,
                    `posts/${docSnapshot.id}/likes/${currentUser?.uid}`
                  );
                  const likeDocSnap = await getDoc(likeDocRef);
                  const isLiked = likeDocSnap.exists();

                  return {
                    id: docSnapshot.id,
                    ...postData,
                    username: userData.username,
                    profileImage: userData.profileImage || defaultImage,
                    isLiked,
                  };
                })
              );
              return postsData.filter((post) => post !== null);
            }
          );
          setRegionPosts(postsData);
          setCachedPosts((prevState) => ({
            ...prevState,
            myRegion: postsData,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching region posts:", error);
    } finally {
      setIsLoading(false);
    }
  }, [getCachedOrFetchData, currentUser]);

  useEffect(() => {
    if (currentUser) {
      if (activeTab === "recommended") {
        if (cachedPosts.recommended) {
          setPostsResults(cachedPosts.recommended);
        } else {
          fetchRecommendedPosts();
        }
      } else if (activeTab === "myRegion") {
        if (cachedPosts.myRegion) {
          setRegionPosts(cachedPosts.myRegion);
        } else {
          fetchRegionPosts();
        }
      }
    }
  }, [
    activeTab,
    currentUser,
    fetchRecommendedPosts,
    fetchRegionPosts,
    cachedPosts,
  ]);

  const handleLikeClick = async (postId, isLiked) => {
    if (!currentUser) {
      console.error("User not authenticated");
      return;
    }

    // Optimistically update the UI
    setPostsResults((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? {
              ...post,
              isLiked: !isLiked,
              likeCount: isLiked ? post.likeCount - 1 : post.likeCount + 1,
            }
          : post
      )
    );

    const postRef = doc(firestore, "posts", postId);
    const likeRef = doc(firestore, `posts/${postId}/likes/${currentUser.uid}`);

    try {
      await runTransaction(firestore, async (transaction) => {
        const postSnapshot = await transaction.get(postRef);
        if (!postSnapshot.exists()) {
          throw new Error("Post does not exist!");
        }

        const likeSnapshot = await transaction.get(likeRef);
        let newLikeCount = postSnapshot.data().likeCount || 0;

        if (isLiked && likeSnapshot.exists()) {
          transaction.delete(likeRef);
          newLikeCount = Math.max(0, newLikeCount - 1);
        } else if (!isLiked && !likeSnapshot.exists()) {
          transaction.set(likeRef, {
            userId: currentUser.uid,
            createdAt: serverTimestamp(),
          });
          newLikeCount += 1;

          // Create notification
          const notificationRef = collection(firestore, "notifications");
          transaction.set(doc(notificationRef), {
            type: "like",
            content: `${currentUser.displayName || "Someone"} liked your post`,
            recipientId: postSnapshot.data().userId,
            senderId: currentUser.uid,
            postId: postId,
            createdAt: serverTimestamp(),
          });
        }

        transaction.update(postRef, { likeCount: newLikeCount });
      });
    } catch (error) {
      console.error("Error toggling like: ", error);
      // Revert the optimistic UI update
      setPostsResults((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId
            ? {
                ...post,
                isLiked: isLiked,
                likeCount: isLiked ? post.likeCount + 1 : post.likeCount - 1,
              }
            : post
        )
      );
    }
  };

  if (isDesktop) {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "#121516",
          minHeight: "100vh",
        }}
      >
        {/* Left Sidebar */}
        <div
          style={{
            width: "350px",
            position: "fixed",
            left: 0,
            top: 0,
            height: "100vh",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "12px",
              left: "12px",
              animation: "fadeInUp 1s ease-in-out",
            }}
          >
            <Sidebar
              activeTab={activeTab}
              handleTabClick={handleTabChange}
              events={[]} // Pass empty array for events
              loading={isLoading}
              handleCardClick={() => {}} // Pass empty function for handleCardClick
            />
          </div>
        </div>
        <div
          style={{
            width: "1px",
            height: "100vh",
            position: "fixed",
            left: "350px",
            top: 0,
            background: "var(--black, #2E313C)",
          }}
        ></div>

        {/* Main Content */}
        <div
          style={{
            flex: 1,
            padding: "20px",
            marginLeft: isWideDesktop ? "auto" : "350px",
            marginRight: isWideDesktop ? "auto" : "0",
            maxWidth: isWideDesktop ? "800px" : "calc(100% - 350px)",
            width: isWideDesktop ? "800px" : "auto",
            overflowY: "auto",
            height: "100vh",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h1
              style={{
                color: "#FFF",
                fontSize: "24px",
                margin: "0",
              }}
            >
              Discovery
            </h1>

            {/* New Team Search button */}
            <button
              onClick={() => navigate("/TeamSearch")}
              style={{
                padding: "10px 17px",
                backgroundColor: "#4A90E2",
                color: "white",
                border: "none",
                borderRadius: "42px",
                fontFamily: "Manrope, sans-serif",
                fontSize: "14px",
                fontWeight: "600",
                cursor: "pointer",
                transition: "all 0.3s ease",
                boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "12px",
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#3A7BC8";
                e.target.style.transform = "translateY(-2px)";
                e.target.style.boxShadow = "0 5px 7px rgba(0, 0, 0, 0.15)";
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "#4A90E2";
                e.target.style.transform = "translateY(0)";
                e.target.style.boxShadow = "0 3px 5px rgba(0, 0, 0, 0.1)";
              }}
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: "7px" }}
              >
                <path
                  d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle
                  cx="9"
                  cy="7"
                  r="4"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23 21v-2a4 4 0 0 0-3-3.87"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16 3.13a4 4 0 0 1 0 7.75"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Team Search
            </button>
          </div>

          {/* Search Input */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              background: "#FFF",
              borderRadius: "12.84px",
              padding: "8px 16px",
              marginBottom: "20px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
            >
              <circle
                cx="15.125"
                cy="15.125"
                r="9.625"
                stroke="#666873"
                strokeWidth="2"
              />
              <path
                d="M15.125 11C14.5833 11 14.0469 11.1067 13.5464 11.314C13.046 11.5213 12.5912 11.8251 12.2082 12.2082C11.8251 12.5912 11.5213 13.046 11.314 13.5464C11.1067 14.0469 11 14.5833 11 15.125"
                stroke="#666873"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M27.5 27.5L23.375 23.375"
                stroke="#666873"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchInputChange}
              onKeyDown={handleKeyDown}
              style={{
                border: "none",
                outline: "none",
                marginLeft: "8px",
                flex: 1,
                color: "#666873",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.14px",
                background: "transparent",
              }}
            />
          </div>

          {/* Tab Selector */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                width: "100%",
                padding: "4px",
                alignItems: "center",
                borderRadius: "100px",
                background: "#000",
                zIndex: 1,
              }}
            >
              {["recommended", "myRegion", "tutorials"].map((tab, index) => (
                <button
                  key={tab}
                  style={{
                    flex: 1,
                    padding: "10px",
                    border: "none",
                    background: "transparent",
                    color: "#fff",
                    cursor: "pointer",
                    position: "relative",
                    zIndex: 2,
                    fontSize: "14px",
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: 500,
                    letterSpacing: "0.02em",
                    textTransform: "capitalize",
                  }}
                  onClick={() => handleTabChange(tab)}
                >
                  {tab === "myRegion" ? "My Region" : tab}
                </button>
              ))}
              <div
                style={{
                  position: "absolute",
                  left: `${
                    ["recommended", "myRegion", "tutorials"].indexOf(
                      activeTab
                    ) * 33.33
                  }%`,
                  top: "4px",
                  bottom: "4px",
                  width: "33.33%",
                  background: "#101828",
                  borderRadius: "100px",
                  transition: "left 0.3s ease",
                  zIndex: 1,
                }}
              />
            </div>
          </div>

          {/* Content Area */}
          <div>
            {isLoading ? (
              <div style={{ textAlign: "center", color: "#FFF" }}></div>
            ) : (
              <div>
                {activeTab === "tutorials" ? (
                  <div
                    style={{
                      color: "#FFF",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    {/* Content for tutorials tab */}
                  </div>
                ) : (
                  (activeTab === "recommended"
                    ? postsResults
                    : regionPosts
                  ).map((post, index) => (
                    <div
                      key={post.id}
                      style={{
                        backgroundColor: "#252525",
                        borderRadius: "12px",
                        padding: "24px",
                        marginBottom: "24px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        animation: "fadeInUp 0.5s forwards",
                        animationDelay: `${index * 0.1}s`,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "16px",
                        }}
                      >
                        <img
                          src={post.profileImage || defaultImage}
                          alt="Profile"
                          style={{
                            width: "48px",
                            height: "48px",
                            borderRadius: "50%",
                            marginRight: "16px",
                            cursor: "pointer",
                            objectFit: "cover",
                          }}
                          onClick={() => navigate(`/users/${post.username}`)}
                        />
                        <div>
                          <div
                            style={{
                              fontWeight: "600",
                              color: "#fff",
                              fontSize: "16px",
                              marginBottom: "4px",
                            }}
                          >
                            {post.username}
                          </div>
                          <div style={{ color: "#a0a0a0", fontSize: "12px" }}>
                            {new Date(
                              post.createdAt.seconds * 1000
                            ).toLocaleString()}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          color: "#e0e0e0",
                          fontSize: "15px",
                          marginBottom: "16px",
                          wordWrap: "break-word",
                          lineHeight: "1.5",
                          marginLeft: "65px",
                          marginTop: "-10px",
                        }}
                      >
                        {post.content}
                      </div>
                      {post.imageUrl && (
                        <div
                          style={{
                            marginBottom: "16px",
                            maxWidth: "calc(100% + 10px)",
                            maxHeight: "400px",
                            overflow: "hidden",
                            borderRadius: "16px",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            marginLeft: "60px",
                            marginTop: "10px",
                          }}
                        >
                          <img
                            src={post.imageUrl}
                            alt="Post"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "400px",
                              objectFit: "contain",
                              cursor: "pointer",
                              borderRadius: "16px",
                            }}
                            onClick={() => navigate(`/posts/${post.id}`)}
                          />
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "60px",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={post.isLiked ? likeIcon : unlikeIcon}
                              alt={post.isLiked ? "Unlike" : "Like"}
                              onClick={() =>
                                handleLikeClick(post.id, post.isLiked)
                              }
                              style={{
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                              }}
                            />
                            <span
                              style={{ color: "#FFFFFF", fontSize: "14px" }}
                            >
                              {post.likeCount}
                            </span>
                          </div>
                          <img
                            src={sendIcon}
                            alt="Share"
                            style={{
                              cursor: "pointer",
                              width: "20px",
                              height: "20px",
                              marginLeft: "15px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        </div>

        {/* Right Sidebar (Tournament) */}
        {isWideDesktop && <TournamentSidebar></TournamentSidebar>}
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "#121516",
        height: "100vh",
        position: "relative",
        paddingTop: "20px", // Ensure some space at the top
      }}
    >
      <button
        onClick={() => (window.location.href = "/TeamSearch")}
        style={{
          position: "absolute",
          top: "16px",
          right: "16px",
          padding: "9.6px 16px", // Reduced by 20%
          backgroundColor: "#4A90E2",
          color: "white",
          border: "none",
          borderRadius: "40px", // Reduced by 20%
          fontFamily: "Manrope, sans-serif",
          fontSize: "12.8px", // Reduced by 20%
          fontWeight: "600",
          cursor: "pointer",
          transition: "all 0.3s ease",
          boxShadow: "0 3.2px 4.8px rgba(0, 0, 0, 0.1)", // Reduced by 20%
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onMouseOver={(e) => {
          e.target.style.backgroundColor = "#3A7BC8";
          e.target.style.transform = "translateY(-1.6px)"; // Reduced by 20%
          e.target.style.boxShadow = "0 4.8px 6.4px rgba(0, 0, 0, 0.15)"; // Reduced by 20%
        }}
        onMouseOut={(e) => {
          e.target.style.backgroundColor = "#4A90E2";
          e.target.style.transform = "translateY(0)";
          e.target.style.boxShadow = "0 3.2px 4.8px rgba(0, 0, 0, 0.1)"; // Reduced by 20%
        }}
      >
        <svg
          width="16" // Reduced by 20%
          height="16" // Reduced by 20%
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginRight: "6.4px" }} // Reduced by 20%
        >
          <path
            d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            cx="9"
            cy="7"
            r="4"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23 21v-2a4 4 0 0 0-3-3.87"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 3.13a4 4 0 0 1 0 7.75"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Team Search
      </button>
      <style>{fadeInUpKeyframes}</style>
      <div
        style={{
          color: "var(--White, #FFF)",
          textAlign: "center",
          fontFamily: "Manrope",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "160%", // 28.8px
          letterSpacing: "0.18px",
          marginLeft: "-260px",
          marginTop: "-10px",
        }}
      >
        Discovery
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          background: "#FFF",
          borderRadius: "12.84px",
          padding: "8px 16px",
          marginTop: "20px", // Space between Discovery text and search box
          marginLeft: "26px",
          marginRight: "16px",
          width: "84%",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
        >
          <circle
            cx="15.125"
            cy="15.125"
            r="9.625"
            stroke="#666873"
            strokeWidth="2"
          />
          <path
            d="M15.125 11C14.5833 11 14.0469 11.1067 13.5464 11.314C13.046 11.5213 12.5912 11.8251 12.2082 12.2082C11.8251 12.5912 11.5213 13.046 11.314 13.5464C11.1067 14.0469 11 14.5833 11 15.125"
            stroke="#666873"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M27.5 27.5L23.375 23.375"
            stroke="#666873"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearchInputChange}
          onKeyDown={handleKeyDown}
          style={{
            border: "none",
            outline: "none",
            marginLeft: "8px",
            flex: 1,
            color: "#666873",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "-0.14px",
            background: "transparent",
          }}
        />
      </div>
      <div
        className="tab-selector"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "-280px", // Space between search box and tab selector
        }}
      >
        <div
          className={`tab ${activeTab === "recommended" ? "active" : ""}`}
          onClick={() => handleTabChange("recommended")}
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            color: activeTab === "recommended" ? "#FFF" : "#666873",
          }}
        >
          Recommended
        </div>
        <div
          className={`tab ${activeTab === "myRegion" ? "active" : ""}`}
          onClick={() => handleTabChange("myRegion")}
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            color: activeTab === "myRegion" ? "#FFF" : "#666873",
          }}
        >
          My Region
        </div>
        <div
          className={`tab ${activeTab === "tutorials" ? "active" : ""}`}
          onClick={() => handleTabChange("tutorials")}
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            color: activeTab === "tutorials" ? "#FFF" : "#666873",
          }}
        >
          Tutorials
        </div>
      </div>
      <div
        style={{
          marginTop: "90px",
          padding: "0 16px",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "90%", maxWidth: "600px" }}>
          {activeTab === "recommended" && (
            <RecommendedPosts
              isLoading={isLoading}
              postsResults={postsResults}
              postStyle={postStyle}
              handleLikeClick={handleLikeClick}
            />
          )}
          {activeTab === "myRegion" && (
            <MyRegion
              isLoading={isLoading}
              regionPosts={regionPosts}
              postStyle={postStyle}
              handleLikeClick={handleLikeClick}
            />
          )}
          {activeTab === "tutorials" && (
            <div>{/* Content for tutorials tab */}</div>
          )}
        </div>
        <Dock />
      </div>
    </div>
  );
};

export default SearchPage;
