import React from 'react';

const LazyVideo = ({ src }) => {
  return (
    <video
      src={src}
      controls
      style={{
        maxWidth: "calc(100% - 20px)",
        maxHeight: "90%",
        display: "block",
        marginLeft: "40px",
        marginRight: "auto",
        borderRadius: "10px",
        marginBottom: "20px",
        objectFit: "contain",
      }}
    />
  );
};

export default LazyVideo;