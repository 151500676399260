import React from 'react';

const TermsOfServicePage = () => {
  return (
    <div style={{ color: 'white' }}>
      <h1>Terms of Service for VexVibe.com</h1>
      <h2>Introduction</h2>
      <p>These Terms of Service ("Terms") govern your access to and use of VexVibe.com. By creating an account and using the services, you agree to be bound by these Terms.</p>
      <h2>Account Requirements</h2>
      <p>You must be at least 13 years old to create an account on VexVibe.com. Accounts must be registered with a valid email address and undergo age verification.</p>
      <h2>User Responsibilities</h2>
      <p>You are responsible for all activities that occur under your account. You retain ownership of any content you submit to the site but grant us a global license to host, distribute, and publicly display such content.</p>
      <h2>Content and Conduct</h2>
      <p>You may post text, photos, and videos related to VEX Robotics. Prohibited content includes but is not limited to hate speech, illegal activities, and harassment.</p>
      <h2>Advertisements</h2>
      <p>Advertisements on our platform are delivered through Google AdMob. We do not directly target ads but allow Google to manage ad placement based on their policies.</p>
      <h2>Termination and Suspension</h2>
      <p>We reserve the right to suspend or terminate accounts that violate these Terms or engage in illegal activities.</p>
      <h2>Dispute Resolution</h2>
      <p>Disputes should be addressed by contacting our support team at info@vexvibe.com.</p>
      <h2>Liabilities and Warranties</h2>
      <p>We provide VexVibe.com "as is" and disclaim all warranties of any kind. Our liability is limited to the fullest extent permitted by law.</p>
      <h2>Governing Law</h2>
      <p>These Terms are governed by the laws of Canada.</p>
      <h2>Changes to the Terms</h2>
      <p>We reserve the right to modify these Terms at any time. Changes will be communicated through email.</p>
      <h2>Contact Information</h2>
      <p>For any inquiries or concerns regarding these Terms, please contact us at info@vexvibe.com.</p>
    </div>
  );
};

export default TermsOfServicePage;