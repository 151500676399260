import React, { useEffect, useContext, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import {
  getDatabase,
  ref,
  onValue,
  push,
  serverTimestamp,
  set,
  remove,
} from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import imageCompression from "browser-image-compression"; // Import image compression library
import fallbackImage from "../assets/images/CircleLogo.svg";
import sendIcon from "../assets/images/send.svg";
import uploadIcon from "../assets/images/Post.svg"; // Import the upload icon
import threeDotIcon from "../assets/images/3dot.svg"; // Import the 3-dot icon
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import { format } from "date-fns"; // Import date-fns for formatting timestamps
import { update } from "firebase/database";
import { get } from "firebase/database";
import { onDisconnect } from "firebase/database";

const ChatPage = () => {
  const { currentUser } = useContext(AuthContext);
  const { contactId } = useParams();
  const [contact, setContact] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [currentUserProfileImage, setCurrentUserProfileImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null); // State for selected file (image or video)
  const [filePreview, setFilePreview] = useState(null); // State for file preview
  const [openDropdownId, setOpenDropdownId] = useState(null); // State for open dropdown menu
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
        if (userDoc.exists()) {
          setCurrentUserProfileImage(
            userDoc.data().profileImage || fallbackImage
          );
        }
      }
    };

    const fetchContactData = async () => {
      if (contactId) {
        const contactDoc = await getDoc(doc(firestore, "users", contactId));
        if (contactDoc.exists()) {
          setContact(contactDoc.data());
        } else {
          // Handle group chat
          const db = getDatabase();
          const chatRef = ref(db, `group_chats/${contactId}`);
          const chatSnapshot = await get(chatRef);
          if (chatSnapshot.exists()) {
            const chatData = chatSnapshot.val();
            setContact({
              username: chatData.name,
              profileImage: fallbackImage, // Use a default image for group chats
            });
          }
        }
      }
    };

    const fetchMessages = async () => {
      if (!currentUser || !contactId) return;

      const db = getDatabase();
      const chatId = contactId.includes("_")
        ? contactId
        : [currentUser.uid, contactId].sort().join("_");

      // Add current user and contact to chat participants
      await set(ref(db, `chatParticipants/${chatId}/${currentUser.uid}`), true);
      if (!contactId.includes("_")) {
        await set(ref(db, `chatParticipants/${chatId}/${contactId}`), true);
      }

      // Fetch messages
      const messagesRef = ref(db, `chats/${chatId}`);
      let messagesArray = [];
      onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        messagesArray = data
          ? Object.keys(data).map((key) => ({ id: key, ...data[key] }))
          : [];
        setMessages(messagesArray);
        scrollToBottom();

        // Mark messages as read
        messagesArray.forEach(async (message) => {
          if (message.senderId !== currentUser.uid && !message.read) {
            await set(ref(db, `chats/${chatId}/${message.id}/read`), true);
          }
        });
      });

      // Mark messages as read on disconnect
      const onDisconnectRef = ref(db, `chats/${chatId}`);
      onDisconnect(onDisconnectRef).update(
        messagesArray.reduce((acc, message) => {
          if (message.senderId !== currentUser.uid && !message.read) {
            acc[`${message.id}/read`] = true;
          }
          return acc;
        }, {})
      );
    };

    fetchUserData();
    fetchContactData();
    fetchMessages();

    // Cleanup function to mark messages as read on unmount
    return () => {
      const markMessagesAsRead = async () => {
        if (!currentUser || !contactId) return;

        const db = getDatabase();
        const chatId = [currentUser.uid, contactId].sort().join("_");

        const messagesRef = ref(db, `chats/${chatId}`);
        const snapshot = await get(messagesRef);
        const data = snapshot.val();
        if (data) {
          const updates = {};
          Object.keys(data).forEach((key) => {
            if (data[key].senderId !== currentUser.uid && !data[key].read) {
              updates[`${key}/read`] = true;
            }
          });
          await update(messagesRef, updates);
        }
      };

      markMessagesAsRead();
    };
  }, [currentUser, contactId]);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === "" && !selectedFile) return;
    if (!currentUser || !contactId) return;

    const db = getDatabase();
    const chatId = contactId.includes("_")
      ? contactId
      : [currentUser.uid, contactId].sort().join("_");

    let fileUrl = null;
    if (selectedFile) {
      try {
        let uploadedFile = selectedFile;
        if (selectedFile.type.startsWith("image/")) {
          // Compress the image before uploading
          uploadedFile = await imageCompression(selectedFile, {
            maxSizeMB: 1, // Maximum size in MB
            maxWidthOrHeight: 800, // Max width/height
            useWebWorker: true, // Use web workers for better performance
          });
        }

        const storage = getStorage();
        const fileRef = storageRef(
          storage,
          `chatFiles/${chatId}/${Date.now()}_${selectedFile.name}`
        );
        const snapshot = await uploadBytes(fileRef, uploadedFile);
        fileUrl = await getDownloadURL(snapshot.ref);
      } catch (error) {
        return;
      }
    }

    const messageData = {
      senderId: currentUser.uid,
      text: newMessage,
      timestamp: serverTimestamp(),
      fileUrl: fileUrl || null,
      fileType: selectedFile ? selectedFile.type : null, // Save file type
      read: false, // Mark message as unread initially
    };

    try {
      await push(ref(db, `chats/${chatId}`), messageData);
      setNewMessage("");
      setSelectedFile(null); // Clear selected file
      setFilePreview(null); // Clear file preview
      scrollToBottom();
    } catch (error) {}
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFilePreview(URL.createObjectURL(file)); // Create a preview URL for the selected file
    }
  };

  const handleProfileClick = () => {
    if (contact && contact.username) {
      navigate(`/users/${contact.username}`);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleDropdownToggle = (messageId) => {
    setOpenDropdownId(openDropdownId === messageId ? null : messageId);
  };

  const handleDeleteMessage = async (messageId) => {
    if (!currentUser || !contactId) return;

    const db = getDatabase();
    const chatId = [currentUser.uid, contactId].sort().join("_");
    const messageRef = ref(db, `chats/${chatId}/${messageId}`);

    try {
      await remove(messageRef);
      setOpenDropdownId(null); // Close the dropdown after deleting
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        background: "linear-gradient(to bottom, #121516, #1A1E1F)",
        color: "white",
        fontFamily: "'Inter', sans-serif",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
          borderBottom: "1px solid rgba(255,255,255,0.1)",
          backgroundColor: "rgba(0,0,0,0.2)",
        }}
      >
        <svg
          style={{ width: "24px", height: "24px", cursor: "pointer" }}
          onClick={() => navigate(-1)}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19l-7-7 7-7"
          />
        </svg>
        {contact && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              flex: 1,
            }}
            onClick={handleProfileClick}
          >
            <img
              src={contact.profileImage || fallbackImage}
              alt={contact.username}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
            <div
              style={{
                fontWeight: 700,
                fontSize: "16px",
                marginLeft: "10px",
              }}
            >
              {contact.username}
            </div>
          </div>
        )}
      </div>
      <div
        ref={messagesContainerRef}
        style={{
          flex: 1,
          padding: "16px",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {messages.map((message) => (
          <div
            key={message.id}
            style={{
              display: "flex",
              flexDirection:
                message.senderId === currentUser.uid ? "row-reverse" : "row",
              alignItems: "flex-end",
              marginBottom: "16px",
              position: "relative",
              animation: "fadeInUp 0.3s ease-in-out",
            }}
          >
            <img
              src={
                message.senderId === currentUser.uid
                  ? currentUserProfileImage
                  : contact?.profileImage || fallbackImage
              }
              alt={
                message.senderId === currentUser.uid ? "You" : contact?.username
              }
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                margin: "0 8px",
              }}
            />
            <div
              style={{
                maxWidth: "70%",
                backgroundColor:
                  message.senderId === currentUser.uid
                    ? "#0c54c6"
                    : "rgba(255,255,255,0.1)",
                padding: "12px 16px",
                borderRadius: "18px",
                color: "white",
                boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
              }}
            >
              {message.text}
              {message.fileUrl && message.fileType && (
                <>
                  {message.fileType.startsWith("image/") && (
                    <img
                      src={message.fileUrl}
                      alt="Sent file"
                      style={{
                        maxWidth: "100%",
                        marginTop: "10px",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => window.open(message.fileUrl, "_blank")}
                    />
                  )}
                  {message.fileType.startsWith("video/") && (
                    <video
                      controls
                      style={{
                        maxWidth: "100%",
                        marginTop: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      <source src={message.fileUrl} type={message.fileType} />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </>
              )}
              <div
                style={{
                  fontSize: "10px",
                  color: "rgba(255,255,255,0.6)",
                  marginTop: "5px",
                  textAlign:
                    message.senderId === currentUser.uid ? "right" : "left",
                }}
              >
                {message.timestamp
                  ? format(new Date(message.timestamp), "p")
                  : ""}
              </div>
            </div>
            {message.senderId === currentUser.uid && (
              <div style={{ position: "relative" }}>
                <img
                  src={threeDotIcon}
                  alt="Options"
                  style={{
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                    marginLeft: "8px",
                    transform: "translateY(-15px)",
                  }}
                  onClick={() => handleDropdownToggle(message.id)}
                />
                {openDropdownId === message.id && (
                  <div
                    style={{
                      position: "absolute",
                      top: "25px",
                      right: "0",
                      backgroundColor: "#2C2C2E",
                      borderRadius: "8px",
                      zIndex: 1,
                      boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
                    }}
                  >
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        color: "white",
                        padding: "8px 16px",
                        cursor: "pointer",
                        width: "100%",
                        textAlign: "left",
                      }}
                      onClick={() => handleDeleteMessage(message.id)}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "16px",
          backgroundColor: "rgba(0,0,0,0.2)",
          borderTop: "1px solid rgba(255,255,255,0.1)",
        }}
      >
        <input
          type="file"
          accept="image/*,video/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="file-upload"
        />
        <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
          <img
            src={uploadIcon}
            alt="Upload"
            style={{ width: "30px", height: "30px", marginRight: "12px" }}
          />
        </label>
        {filePreview && (
          <div
            style={{
              width: "40px",
              height: "40px",
              marginRight: "12px",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            {selectedFile?.type.startsWith("image/") ? (
              <img
                src={filePreview}
                alt="Preview"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <video
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              >
                <source src={filePreview} type={selectedFile?.type} />
              </video>
            )}
          </div>
        )}
        <input
          type="text"
          placeholder="Type your message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          style={{
            flex: 1,
            padding: "12px 16px",
            backgroundColor: "rgba(255,255,255,0.1)",
            borderRadius: "20px",
            color: "white",
            border: "none",
            fontSize: "14px",
          }}
        />
        <img
          src={sendIcon}
          alt="Send"
          onClick={handleSendMessage}
          style={{
            width: "30px",
            height: "30px",
            cursor: "pointer",
            marginLeft: "12px",
          }}
        />
      </div>
      <style>
        {`
          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
    </div>
  );
};

export default ChatPage;
