import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Dock from "../components/Dock";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import TournamentSidebar from "../components/TournamentSidebar";
import { useSwipeable } from "react-swipeable";

const cardStyle = {
  background: "#1d2021",
  borderRadius: "12.84px",
  margin: "10px auto",
  padding: "16px",
  width: "90%",
  maxWidth: "350px",
  height: "auto",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  cursor: "pointer",
  opacity: 0,
  transform: "translateY(20px)",
  animation: "fadeInUp 0.5s forwards",
  transition: "box-shadow 0.3s ease",
};

const desktopCardStyle = {
  background: "#1d2021",
  borderRadius: "12.84px",
  margin: "10px auto",
  padding: "16px",
  width: "150%",
  maxWidth: "350px",
  height: "auto",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  cursor: "pointer",
  opacity: 0,
  transform: "translateY(20px)",
  animation: "fadeInUp 0.5s forwards",
  transition: "box-shadow 0.3s ease",
};

const cardHoverStyle = `
  .card:hover {
    box-shadow: 0 0 25px rgba(128, 128, 128, 1);
  }
`;

const cardContainerStyle = {
  paddingBottom: "100px",
};

const fadeInUpKeyframes = `
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const fadeInAnimation = `
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;

const slideInAnimation = `
  @keyframes slideIn {
    from { transform: translateX(-20px); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }
`;

const TournamentsPage = () => {
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [sigEvents, setSigEvents] = useState([]);
  const [activeTab, setActiveTab] = useState("myRegion");
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const db = getFirestore();
  const auth = getAuth();
  let navigate = useNavigate();
  const apiKey =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiOTlmNmYyMzYwNTY0ZTY1NmJiODlkNTUwNmYzZjRiMmMxNDA0M2E2YjZlMWVkNWRmODVkNThjYzkzOWFiYzk0YjIyYTYwYjA4MzcyNTZmNjYiLCJpYXQiOjE3MTY2NzM2MTguMTM0NTE0MSwibmJmIjoxNzE2NjczNjE4LjEzNDUxNywiZXhwIjoyNjYzMzYyMDE4LjEyODY2NSwic3ViIjoiMTI3Nzg2Iiwic2NvcGVzIjpbXX0.jhOKVtQQI1acAwIRrAR78xw7l__XHewZzJDYBf3OMzHZ4zsIh_6jWG1ulswGCN6128v_dwPji6s8JmVQj_tmcn-KyDLQACkY8ZJHQXZLIuTSrNGXFKE-DLqFKO9an04kmZoMKtZay86bCuSMiHoJGkd9WctvUdAkjzNdsWBz8lwD48hLY5h2F5U55YhX2areEoPyioOsN1QHe9ExoXOk8fW88vpgvw7_aZnmhTp5BV6WICnIlP1PCLWxUI_EwI8X32QJ1PJhOUFJ6eEb7iM2KPT3jlJmJBy675wDCix84H11jSUCJs8F-LIXRERVERniWElTWp9tIJYRk1HRQN4bhCEnuzL8U01oO49Ghc-k9vYQnY3WlHwAJEGuPQtQt8NttI2DcBDcBM3acQXHHHfvKvk6pc1__bwM32W41Ealqku1dL5Jw8MNUCFqAthDd--mjtkyevuGJsLwu1NobdI94wNDHna8iuRg27XwRGXJKGy32scGsmeBnplhEdOBbVu44Ns1MGJuxmLLffICQWVdEl4Gwth63r4AzBiX__iMYwJw39MfWpiyYrDZRX7Z6n12m2WeeZqrdmRAuSyzVSeZIqFs6TgqbgAuFOMo68aN_6InrbMD62PvXhdH8CL6wR05_v_Gz10OJtAWdwboXB780tdd7X5mdP2KcBZHbqvSbl4";
  const seasonId = 190;
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1439);
  const [isWideDesktop, setIsWideDesktop] = useState(window.innerWidth >= 1580);
  const [userRegion, setUserRegion] = useState(null);
  const [tournamentActiveTab, setTournamentActiveTab] = useState("myRegion");
  const [loadedEvents, setLoadedEvents] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 769);
      setIsWideDesktop(window.innerWidth >= 1580);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [slideDirection, setSlideDirection] = useState(null);

  const handleSwipe = (direction) => {
    const tabs = ["myRegion", "sigEvents", "all"];
    const currentIndex = tabs.indexOf(activeTab);
    let newIndex;

    if (direction === "LEFT") {
      newIndex = (currentIndex + 1) % tabs.length;
      setSlideDirection("left");
    } else if (direction === "RIGHT") {
      newIndex = (currentIndex - 1 + tabs.length) % tabs.length;
      setSlideDirection("right");
    }

    handleTabClick(tabs[newIndex], newIndex);
  };

  const handleTabClick = (tab, index) => {
    setActiveTab(tab);
    setActiveTabIndex(index);
    setSlideDirection(null); // Reset slide direction when clicking tabs
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe("LEFT"),
    onSwipedRight: () => handleSwipe("RIGHT"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleTournamentTabClick = async (tab) => {
    setTournamentActiveTab(tab);
    setPage(1);
    setHasMore(true);
    setEvents([]);

    if (tab === "myRegion") {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userRegion = userDoc.data().region;
            fetchUserRegionEvents(userRegion, 1);
          } else {
            console.error("User document does not exist");
          }
        } else {
          console.error("No user is logged in");
        }
      } catch (error) {
        console.error("Error fetching user region:", error);
      }
    } else if (tab === "all") {
      fetchAllWorldwideEvents(1);
    }
  };

  const loadUserRegionTournaments = async () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userRegion = userDoc.data().region;
          setUserRegion(userRegion);
          await fetchUserRegionEvents(userRegion);
        } else {
          console.error("User document does not exist");
        }
      } else {
        console.error("No user is logged in");
      }
    } catch (error) {
      console.error("Error fetching user region:", error);
    } finally {
      setLoading(false);
    }
  };

  const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

  const getCachedEvents = (key) => {
    const cachedData = localStorage.getItem(key);
    if (cachedData) {
      const { timestamp, data } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return data;
      }
    }
    return null;
  };

  const setCachedEvents = (key, data) => {
    const cacheData = {
      timestamp: Date.now(),
      data: data,
    };
    localStorage.setItem(key, JSON.stringify(cacheData));
  };

  const fetchUserRegionEvents = async (region, page, since = null) => {
    try {
      const params = {
        season: seasonId,
        region: region,
        eventType: "tournament",
        page: page,
      };
      if (since) {
        params.start = new Date(since).toISOString();
      }
      const response = await axios.get(
        `https://www.robotevents.com/api/v2/events`,
        {
          params: params,
          headers: {
            Authorization: `Bearer ${apiKey}`,
            accept: "application/json",
          },
        }
      );
      console.log("Regional events response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch regional events:", error);
      return { data: [] };
    }
  };

  const fetchAllWorldwideEvents = async (page, since = null) => {
    try {
      const params = {
        season: [seasonId],
        eventTypes: ["tournament"],
        page: page,
      };
      if (since) {
        params.start = new Date(since).toISOString();
      }
      const response = await axios.get(
        `https://www.robotevents.com/api/v2/events`,
        {
          params: params,
          headers: {
            Authorization: `Bearer ${apiKey}`,
            accept: "application/json",
          },
        }
      );
      console.log("Worldwide events response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch worldwide events:", error);
      return { data: [] };
    }
  };

  const fetchAllPages = async (fetchFunction, since = null) => {
    let page = 1;
    let allData = [];
    let hasMore = true;

    while (hasMore) {
      try {
        const response = await fetchFunction(page, since);
        if (response && response.data && Array.isArray(response.data)) {
          if (response.data.length === 0) {
            hasMore = false;
          } else {
            allData = [...allData, ...response.data];
            if (response.meta && response.meta.last_page === page) {
              hasMore = false;
            } else {
              page++;
            }
          }
        } else {
          console.error("Unexpected response format:", response);
          hasMore = false;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        hasMore = false;
      }
    }

    return allData;
  };

  const fetchSigEvents = async (page, since = null) => {
    try {
      const params = {
        season: [seasonId],
        eventTypes: ["tournament"],
        level: ["Signature"],
        page: page,
      };
      if (since) {
        params.start = new Date(since).toISOString();
      }
      const response = await axios.get(
        `https://www.robotevents.com/api/v2/events`,
        {
          params: params,
          headers: {
            Authorization: `Bearer ${apiKey}`,
            accept: "application/json",
          },
        }
      );
      console.log("Signature events response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch signature events:", error);
      return { data: [] };
    }
  };

  const fetchAndDisplayEvents = async (
    fetchFunction,
    setEventsFunction,
    cacheKey,
    userRegion = null
  ) => {
    setLoading(true);
    let page = 1;
    let hasMore = true;
    let allData = [];

    // Check cache first
    const cachedData = getCachedEvents(cacheKey);
    if (cachedData) {
      setEventsFunction(cachedData);
      setLoading(false);
      return;
    }

    while (hasMore) {
      try {
        const response = await fetchFunction(userRegion, page);
        if (response && response.data && Array.isArray(response.data)) {
          allData = [...allData, ...response.data];
          setEventsFunction((prevEvents) => {
            const newEvents = [...prevEvents, ...response.data];
            return newEvents.sort(
              (a, b) => new Date(a.start) - new Date(b.start)
            );
          });

          if (page === 1) {
            setLoading(false);
          }

          if (response.meta && response.meta.last_page === page) {
            hasMore = false;
          } else {
            page++;
            setIsLoadingMore(true);
          }
        } else {
          console.error("Unexpected response format:", response);
          hasMore = false;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        hasMore = false;
      }
    }

    setIsLoadingMore(false);
    // Cache the fetched data
    setCachedEvents(cacheKey, allData);
  };

  const fetchAllData = async (userRegion) => {
    setLoading(true);
    try {
      await Promise.all([
        fetchAndDisplayEvents(
          (_, page) => fetchUserRegionEvents(userRegion, page),
          setEvents,
          `regionalEvents_${userRegion}`
        ),
        fetchAndDisplayEvents(
          fetchAllWorldwideEvents,
          setAllEvents,
          "worldwideEvents"
        ),
        fetchAndDisplayEvents(fetchSigEvents, setSigEvents, "sigEvents"),
      ]);

      setLastFetchTime(Date.now());
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchNewEvents = async (userRegion) => {
    if (!lastFetchTime) return;

    try {
      const [newRegionalEvents, newWorldwideEvents, newSignatureEvents] =
        await Promise.all([
          fetchAllPages(
            (page) => fetchUserRegionEvents(userRegion, page),
            lastFetchTime
          ),
          fetchAllPages((page) => fetchAllWorldwideEvents(page), lastFetchTime),
          fetchAllPages((page) => fetchSigEvents(page), lastFetchTime),
        ]);

      setEvents((prevEvents) =>
        [...newRegionalEvents, ...prevEvents].sort(
          (a, b) => new Date(a.start) - new Date(b.start)
        )
      );
      setAllEvents((prevEvents) =>
        [...newWorldwideEvents, ...prevEvents].sort(
          (a, b) => new Date(a.start) - new Date(b.start)
        )
      );
      setSigEvents((prevEvents) =>
        [...newSignatureEvents, ...prevEvents].sort(
          (a, b) => new Date(a.start) - new Date(b.start)
        )
      );

      // Update cache with new events
      const cachedRegionalEvents = getCachedEvents(
        `regionalEvents_${userRegion}`
      );
      const cachedWorldwideEvents = getCachedEvents("worldwideEvents");
      const cachedSigEvents = getCachedEvents("sigEvents");

      setCachedEvents(`regionalEvents_${userRegion}`, [
        ...newRegionalEvents,
        ...(cachedRegionalEvents || []),
      ]);
      setCachedEvents("worldwideEvents", [
        ...newWorldwideEvents,
        ...(cachedWorldwideEvents || []),
      ]);
      setCachedEvents("sigEvents", [
        ...newSignatureEvents,
        ...(cachedSigEvents || []),
      ]);

      setLastFetchTime(Date.now());
    } catch (error) {
      console.error("Error fetching new events:", error);
    }
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const fetchUserData = async () => {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userRegion = userDoc.data().region;
          console.log(`User region: ${userRegion}`);
          fetchAllData(userRegion);
        } else {
          console.log("No such user!");
          fetchAllData(null);
        }
      };
      fetchUserData();
    } else {
      console.log("No user logged in");
      fetchAllData(null);
    }
  }, [auth, db]);

  const filteredEvents = useMemo(() => {
    const eventsToFilter =
      activeTab === "myRegion"
        ? events
        : activeTab === "sigEvents"
        ? sigEvents
        : allEvents;
    return eventsToFilter.filter(
      (event) =>
        (event.name &&
          event.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (event.location &&
          event.location.region &&
          event.location.region
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))
    );
  }, [activeTab, events, sigEvents, allEvents, searchTerm]);

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleCardClick = (sku) => {
    console.log("Navigating to SKU:", sku);
    navigate(`/tournament/${sku}`);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const tabStyle = {
    display: "flex",
    padding: "8px 20px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flex: "1 0 0",
    borderRadius: "100px",
    cursor: "pointer",
    color: "#fff",
    fontFamily: "Manrope",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "160%",
    letterSpacing: "0.12px",
    transition: "background-color 0.3s ease",
  };

  const activeTabStyle = {
    ...tabStyle,
    background: "#101828",
  };

  const tabSelectorStyle = {
    position: "absolute",
    top: "70px",
    left: "24px",
    right: "24px",
    display: "flex",
    width: "calc(100% - 48px)",
    padding: "4px",
    alignItems: "flex-start",
    borderRadius: "100px",
    background: "#000",
  };

  const searchBoxStyle = {
    width: "344px",
    height: "65.524px",
    flexShrink: 0,
    borderRadius: "12.84px",
    background: "#FFF",
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    margin: "20px auto",
    boxSizing: "border-box",
  };

  const searchInputStyle = {
    flex: 1,
    border: "none",
    outline: "none",
    background: "transparent",
    color: "#666873",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.14px",
  };

  if (isDesktop) {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "#121516",
          minHeight: "100vh",
        }}
      >
        <style>
          {fadeInAnimation}
          {slideInAnimation}
          {fadeInUpKeyframes}
          {cardHoverStyle}
        </style>
        {/* Left Sidebar */}
        <div
          style={{
            width: "350px",
            position: "fixed",
            left: 0,
            top: 0,
            height: "100vh",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "12px",
              left: "12px",
              animation: "fadeInUp 1s ease-in-out",
            }}
          >
            <Sidebar
              activeTab={activeTab}
              handleTabClick={handleTabClick}
              events={events}
              loading={loading}
              handleCardClick={handleCardClick}
            />
          </div>
        </div>
        <div
          style={{
            width: "1px",
            height: "100vh",
            position: "fixed",
            left: "350px",
            top: 0,
            background: "var(--black, #2E313C)",
          }}
        ></div>

        {/* Main Content */}
        <div
          style={{
            flex: 1,
            padding: "20px",
            marginLeft: isWideDesktop ? "auto" : "350px",
            marginRight: isWideDesktop ? "auto" : "0",
            maxWidth: isWideDesktop ? "800px" : "calc(100% - 350px)",
            width: isWideDesktop ? "800px" : "auto",
            overflowY: "auto",
            height: "100vh",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h1
              style={{
                color: "#FFF",
                fontSize: "24px",
                margin: "0",
              }}
            >
              Tournaments
            </h1>
          </div>

          {/* Search Input */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              background: "#FFF",
              borderRadius: "12.84px",
              padding: "8px 16px",
              marginBottom: "20px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
            >
              <circle
                cx="15.125"
                cy="15.125"
                r="9.625"
                stroke="#666873"
                strokeWidth="2"
              />
              <path
                d="M15.125 11C14.5833 11 14.0469 11.1067 13.5464 11.314 13.046 11.8251 12.2082 11.8251 12.5912 11.5213 13.046 13.5464 14.0469 14.5833 15.125"
                stroke="#666873"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M27.5 27.5L23.375 23.375"
                stroke="#666873"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                border: "none",
                outline: "none",
                marginLeft: "8px",
                flex: 1,
                color: "#666873",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.14px",
                background: "transparent",
              }}
            />
          </div>

          {/* Tab Selector */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                width: "100%",
                padding: "4px",
                alignItems: "center",
                borderRadius: "100px",
                background: "#000",
                zIndex: 1,
              }}
            >
              {["myRegion", "sigEvents", "all"].map((tab, index) => (
                <button
                  key={tab}
                  style={{
                    flex: 1,
                    padding: "10px",
                    border: "none",
                    background: "transparent",
                    color: "#fff",
                    cursor: "pointer",
                    position: "relative",
                    zIndex: 2,
                    fontSize: "14px",
                  }}
                  onClick={() => handleTabClick(tab, index)}
                >
                  {tab === "myRegion"
                    ? "My Region"
                    : tab === "sigEvents"
                    ? "Sig Events"
                    : "All"}
                </button>
              ))}
              <div
                style={{
                  position: "absolute",
                  left: `${activeTabIndex * 33.33}%`,
                  top: "4px",
                  bottom: "4px",
                  width: "33.33%",
                  background: "#101828",
                  borderRadius: "100px",
                  transition: "left 0.3s ease",
                  zIndex: 1,
                }}
              />
            </div>
          </div>

          {/* Content Area */}
          <div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <div className="spinner"></div>
              </div>
            ) : (
              <div style={cardContainerStyle}>
                {filteredEvents.map((event, index) => (
                  <div
                    key={`${event.id}-${index}`}
                    className="card"
                    style={{
                      ...desktopCardStyle,
                      animationDelay: `${index * 0.1}s`,
                    }}
                    onClick={() => handleCardClick(event.sku)}
                  >
                    <div
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        fontFamily: "Roboto",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "25px",
                        letterSpacing: "0.16px",
                        wordWrap: "break-word",
                        borderBottom: "1px solid #gray-700",
                        paddingBottom: "12px",
                      }}
                    >
                      {event.name}
                    </div>
                    <div
                      style={{
                        color: "#B7B7B7",
                        textAlign: "center",
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "25px",
                        letterSpacing: "0.12px",
                        wordWrap: "break-word",
                      }}
                    >
                      Date: {new Date(event.start).toLocaleDateString()}
                    </div>
                    <div
                      style={{
                        color: "#B7B7B7",
                        textAlign: "center",
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "25px",
                        letterSpacing: "0.12px",
                        wordWrap: "break-word",
                      }}
                    >
                      Event Region: {event.location.region}
                    </div>
                    <div
                      style={{
                        color: "#B7B7B7",
                        textAlign: "center",
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "25px",
                        letterSpacing: "0.12px",
                        wordWrap: "break-word",
                      }}
                    >
                      Spots Open: {event.capacity - event.registered}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Right Sidebar (Tournament) */}
        {isWideDesktop && <TournamentSidebar />}
      </div>
    );
  }

  return (
    <div {...swipeHandlers}>
      <style>{fadeInUpKeyframes}</style>
      <style>{cardHoverStyle}</style>
      <div
        style={{
          color: "var(--white, #FFF)",
          textAlign: "center",
          fontFamily: "Roboto",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "25px",
          letterSpacing: "0.16px",
          textTransform: "capitalize",
          margin: "30px 0",
          marginLeft: "-210px",
          marginTop: "20px",
        }}
      >
        Tournaments
      </div>
      <div style={tabSelectorStyle}>
        <div
          style={activeTab === "myRegion" ? activeTabStyle : tabStyle}
          onClick={() => handleTabClick("myRegion", 0)}
        >
          My Region
        </div>
        <div
          style={activeTab === "sigEvents" ? activeTabStyle : tabStyle}
          onClick={() => handleTabClick("sigEvents", 1)}
        >
          Sig Events
        </div>
        <div
          style={activeTab === "all" ? activeTabStyle : tabStyle}
          onClick={() => handleTabClick("all", 2)}
        >
          All
        </div>
      </div>
      <div
        style={{
          ...searchBoxStyle,
          marginTop: "90px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          style={{ marginRight: "10px" }}
        >
          <circle
            cx="15.125"
            cy="15.125"
            r="9.625"
            stroke="#666873"
            strokeWidth="2"
          />
          <path
            d="M15.125 11C14.5833 11 14.0469 11.1067 13.5464 11.314 13.046 11.8251 12.2082 11.8251 12.5912 11.5213 13.046 13.5464 14.0469 14.5833 15.125"
            stroke="#666873"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M27.5 27.5L23.375 23.375"
            stroke="#666873"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
        <input
          type="text"
          placeholder="Search"
          style={searchInputStyle}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div
        style={{
          display: "flex",
          transition: "transform 0.3s ease-out",
          transform: slideDirection
            ? `translateX(${slideDirection === "left" ? "-100%" : "100%"})`
            : "translateX(0)",
        }}
      >
        <div style={{ flex: "0 0 100%", width: "100%" }}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <div className="loading-spinner"></div>
            </div>
          ) : (
            <div style={{ paddingTop: "5px", textAlign: "center" }}>
              {filteredEvents.length > 0 ? (
                <div style={cardContainerStyle}>
                  {filteredEvents.map((event, index) => (
                    <div
                      key={`${event.id}-${index}`}
                      className="card"
                      style={{
                        ...cardStyle,
                        animationDelay: `${index * 0.1}s`,
                      }}
                      onClick={() => handleCardClick(event.sku)}
                    >
                      <div
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          fontFamily: "Roboto",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "25px",
                          letterSpacing: "0.16px",
                          wordWrap: "break-word",
                          borderBottom: "1px solid #gray-700",
                          paddingBottom: "12px",
                        }}
                      >
                        {event.name}
                      </div>
                      <div
                        style={{
                          color: "#B7B7B7",
                          textAlign: "center",
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "25px",
                          letterSpacing: "0.12px",
                          wordWrap: "break-word",
                        }}
                      >
                        Date: {new Date(event.start).toLocaleDateString()}
                      </div>
                      <div
                        style={{
                          color: "#B7B7B7",
                          textAlign: "center",
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "25px",
                          letterSpacing: "0.12px",
                          wordWrap: "break-word",
                        }}
                      >
                        Event Region: {event.location.region}
                      </div>
                      <div
                        style={{
                          color: "#B7B7B7",
                          textAlign: "center",
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "25px",
                          letterSpacing: "0.12px",
                          wordWrap: "break-word",
                        }}
                      >
                        Spots Open: {event.capacity - event.registered}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>No events found</div>
              )}
            </div>
          )}
        </div>
      </div>
      <style>
        {`
        .loading-spinner {
          border: 8px solid rgba(255, 255, 255, 0.1);
          border-top: 8px solid #FFFFFF;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          animation: spin 1s ease-in-out infinite;
        }
  
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
      </style>
      <Dock />
    </div>
  );
};

export default TournamentsPage;
