import React, { useState } from "react";
import CircleLogo from "../assets/images/CircleLogo.svg";
import AILogo from "../assets/images/AI.svg";

const WelcomeOverlay = ({ onClose }) => {
  const [step, setStep] = useState(1);

  const nextStep = () => {
    if (step < 4) {
      setStep(step + 1);
    } else {
      onClose();
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background:
          "linear-gradient(135deg, rgba(18, 21, 22, 0.9) 0%, rgba(36, 42, 43, 0.9) 50%, rgba(52, 73, 94, 0.9) 100%)",
        backdropFilter: "blur(10px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        fontFamily: "'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      }}
    >
      <div
        style={{
          backgroundColor: "rgba(29, 32, 33, 0.8)",
          padding: "40px",
          borderRadius: "24px",
          maxWidth: "500px",
          width: "90%",
          height: "auto",
          maxHeight: "80vh",
          textAlign: "center",
          color: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.3)",
        }}
      >
        <div>
          <img
            src={step === 3 ? AILogo : CircleLogo}
            alt={step === 3 ? "VibeAI Logo" : "VexVibe Logo"}
            style={{ width: "80px", marginBottom: "24px" }}
          />
          <h2
            style={{
              fontSize: "36px",
              marginBottom: "24px",
              fontWeight: "700",
              letterSpacing: "-0.5px",
              color: "#ffffff",
              textShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            {step === 1 && "Welcome to VexVibe!"}
            {step === 2 && "Discover and Compete"}
            {step === 3 && "Meet VibeAI"}
            {step === 4 && "Get Started"}
          </h2>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: "24px",
          }}
        >
          {step === 1 && (
            <div>
              <p
                style={{
                  fontSize: "18px",
                  marginBottom: "16px",
                  lineHeight: "1.6",
                  fontWeight: "400",
                  color: "#f0f0f0",
                }}
              >
                VexVibe is your new home for all things VEX Robotics.
              </p>
              <p style={{ fontSize: "18px", lineHeight: "1.6", fontWeight: "400", color: "#f0f0f0" }}>
                Connect with teams, share your achievements, and stay updated on
                tournaments.
              </p>
            </div>
          )}
          {step === 2 && (
            <div>
              <p
                style={{
                  fontSize: "18px",
                  marginBottom: "16px",
                  lineHeight: "1.6",
                  fontWeight: "400",
                  color: "#f0f0f0",
                }}
              >
                Discover tournaments worldwide, follow live match results, and
                explore your team's performance history.
              </p>
              <p style={{ fontSize: "18px", lineHeight: "1.6", fontWeight: "400", color: "#f0f0f0" }}>
                VexVibe helps you prepare for competitions with VibeAI Analysis,
                helping you understand your team's performance and how to
                improve per tournament and per match.
              </p>
            </div>
          )}
          {step === 3 && (
            <div>
              <p
                style={{
                  fontSize: "18px",
                  marginBottom: "16px",
                  lineHeight: "1.6",
                  fontWeight: "400",
                  color: "#f0f0f0",
                }}
              >
                Meet VibeAI, your intelligent companion for all things VEX Robotics.
              </p>
              <p style={{ fontSize: "18px", lineHeight: "1.6", fontWeight: "400", color: "#f0f0f0" }}>
                VibeAI offers team analysis, event insights, trip planning, and answers to all your VEX-related questions. It's always free and constantly improving to support your robotics journey.
              </p>
            </div>
          )}
          {step === 4 && (
            <div>
              <p
                style={{
                  fontSize: "18px",
                  marginBottom: "16px",
                  lineHeight: "1.6",
                  fontWeight: "400",
                  color: "#f0f0f0",
                }}
              >
                Start your journey by setting up your team profile and interacting with the community.
              </p>
              <p style={{ fontSize: "18px", lineHeight: "1.6", fontWeight: "400", color: "#f0f0f0" }}>
                Welcome to the VexVibe community!
              </p>
            </div>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {step > 1 && (
            <button
              onClick={prevStep}
              style={{
                padding: "12px 24px",
                backgroundColor: "rgba(149, 165, 166, 0.8)",
                color: "white",
                border: "none",
                borderRadius: "50px",
                cursor: "pointer",
                fontSize: "16px",
                fontWeight: "600",
                transition: "all 0.3s ease",
                letterSpacing: "0.5px",
              }}
            >
              Back
            </button>
          )}
          <button
            onClick={nextStep}
            style={{
              padding: "12px 24px",
              backgroundColor: "#3498db",
              color: "white",
              border: "none",
              borderRadius: "50px",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "600",
              transition: "all 0.3s ease",
              marginLeft: step > 1 ? "16px" : "auto",
              letterSpacing: "0.5px",
            }}
          >
            {step < 4 ? "Next" : "Get Started"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeOverlay;
