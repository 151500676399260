// src/components/UserTagInput.jsx

import React, { useState, useEffect } from "react";
import { firestore } from "../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

const UserTagInput = ({ onTagUser, postContent, setPostContent }) => {
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      const regex = /@(\w+)$/;
      const match = postContent.match(regex);
      if (match) {
        const searchText = match[1];
        if (searchText.length > 0) {
          const usernamesRef = collection(firestore, "usernames");
          const q = query(
            usernamesRef,
            where("username", ">=", searchText),
            where("username", "<=", searchText + "\uf8ff")
          );
          const querySnapshot = await getDocs(q);
          const userSuggestions = querySnapshot.docs.map(
            (doc) => doc.data().username
          );
          setSuggestions(userSuggestions);
        } else {
          setSuggestions([]);
        }
      } else {
        setSuggestions([]);
      }
    };

    fetchSuggestions();
  }, [postContent]);

  const handleTagUser = (username) => {
    const regex = /@(\w+)$/;
    const match = postContent.match(regex);
    if (match) {
      const lastAtIndex = match.index;
      const newPostContent =
        postContent.slice(0, lastAtIndex) + `@${username} `;
      setPostContent(newPostContent);
      onTagUser(username);
      setSuggestions([]);
    }
  };

  return (
    <div>
      {suggestions.length > 0 && (
        <ul>
          {suggestions.map((username) => (
            <li key={username} onClick={() => handleTagUser(username)}>
              {username}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default UserTagInput;
