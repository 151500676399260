import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import defaultImage from "../assets/images/CircleLogo.svg";
import Dock from "../components/Dock";
import { doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import TournamentSidebar from "../components/TournamentSidebar";

const TeamSearch = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [teams, setTeams] = useState([]);
  const [activeTab, setActiveTab] = useState("myRegion");
  const [userRegion, setUserRegion] = useState("");
  const navigate = useNavigate();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1439);
  const [isWideDesktop, setIsWideDesktop] = useState(window.innerWidth >= 1580);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 769);
      setIsWideDesktop(window.innerWidth >= 1580);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleTeamClick = (teamName) => {
    navigate(`/TeamDetails/${encodeURIComponent(teamName)}`);
  };

  useEffect(() => {
    const fetchUserRegion = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const region = userDoc.data().region;
          setUserRegion(region);
        }
      }
    };

    fetchUserRegion();
    const fetchTeams = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const db = getFirestore();
        const teamsCollection = collection(db, "teams");
        const teamsSnapshot = await getDocs(teamsCollection);
        const teamsData = teamsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTeams(teamsData);
      } catch (err) {
        console.error("Error fetching teams:", err);
        setError(
          "An error occurred while fetching teams. Please try again later."
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeams();
  }, []);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getTeamName = (team) => {
    if (team.team_name && team.team_name !== "") {
      return team.team_name;
    } else if (team.VEX && team.VEX !== "") {
      return team.VEX;
    } else {
      return "Unnamed Team";
    }
  };

  const filteredTeams = teams.filter((team) => {
    if (!team) return false;

    let teamName = getTeamName(team);

    // Convert to string if it's not already
    teamName = String(teamName);

    const result = teamName.toLowerCase().includes(searchQuery.toLowerCase());
    return result;
  });

  const sortedFilteredTeams = [...filteredTeams].sort((a, b) => {
    const nameA = getTeamName(a).toLowerCase();
    const nameB = getTeamName(b).toLowerCase();
    return nameA.localeCompare(nameB, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  });

  const filteredTeamsByRegion =
    activeTab === "myRegion"
      ? sortedFilteredTeams.filter((team) => team.region === userRegion)
      : sortedFilteredTeams;

  if (isDesktop) {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "#121516",
          minHeight: "100vh",
        }}
      >
        {/* Left Sidebar */}
        <div
          style={{
            width: "350px",
            position: "fixed",
            left: 0,
            top: 0,
            height: "100vh",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "12px",
              left: "12px",
              animation: "fadeInUp 1s ease-in-out",
            }}
          >
            <Sidebar
              activeTab={activeTab}
              handleTabClick={setActiveTab}
              events={[]}
              loading={isLoading}
              handleCardClick={() => {}}
            />
          </div>
        </div>
        <div
          style={{
            width: "1px",
            height: "100vh",
            position: "fixed",
            left: "350px",
            top: 0,
            background: "var(--black, #2E313C)",
          }}
        ></div>

        {/* Main Content */}
        <div
          style={{
            flex: 1,
            padding: "20px",
            marginLeft: isWideDesktop ? "auto" : "350px",
            marginRight: isWideDesktop ? "auto" : "0",
            maxWidth: isWideDesktop ? "800px" : "calc(100% - 350px)",
            width: isWideDesktop ? "800px" : "auto",
            overflowY: "auto",
            height: "100vh",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <h1
            style={{
              color: "#FFF",
              fontSize: "24px",
              fontWeight: 700,
              marginBottom: "20px",
              marginTop: "0px",
            }}
          >
            Team Search
          </h1>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "95%",
              height: "65.524px",
              borderRadius: "12.84px",
              background: "#FFF",
              padding: "0 16px",
              marginBottom: "20px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
            >
              <circle
                cx="15.125"
                cy="15.125"
                r="9.625"
                stroke="#666873"
                strokeWidth="2"
              />
              <path
                d="M15.125 11C14.5833 11 14.0469 11.1067 13.5464 11.314C13.046 11.5213 12.5912 11.8251 12.2082 12.2082C11.8251 12.5912 11.5213 13.046 11.314 13.5464C11.1067 14.0469 11 14.5833 11 15.125"
                stroke="#666873"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M27.5 27.5L23.375 23.375"
                stroke="#666873"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchInputChange}
              style={{
                border: "none",
                outline: "none",
                marginLeft: "8px",
                flex: 1,
                color: "#666873",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.14px",
                background: "transparent",
              }}
            />
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              width: "100%",
              padding: "4px",
              alignItems: "center",
              borderRadius: "100px",
              background: "#000",
              zIndex: 1,
              marginBottom: "20px",
            }}
          >
            {["myRegion", "all"].map((tab) => (
              <button
                key={tab}
                style={{
                  flex: 1,
                  padding: "10px",
                  border: "none",
                  background: "transparent",
                  color: "#FFF",
                  cursor: "pointer",
                  position: "relative",
                  zIndex: 2,
                  fontFamily: "Manrope",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "160%",
                  letterSpacing: "0.12px",
                }}
                onClick={() => setActiveTab(tab)}
              >
                {tab === "myRegion" ? "My Region" : "All"}
              </button>
            ))}
            <div
              style={{
                position: "absolute",
                left: activeTab === "myRegion" ? "0%" : "50%",
                top: "4px",
                bottom: "4px",
                width: "50%",
                background: "#101828",
                borderRadius: "100px",
                transition: "left 0.3s ease",
                zIndex: 1,
              }}
            />
          </div>
          <div style={{ paddingBottom: "25px" }}>
            {isLoading ? (
              <div style={{ textAlign: "center", color: "#FFF" }}>
                Loading...
              </div>
            ) : error ? (
              <div style={{ textAlign: "center", color: "#FFF" }}>{error}</div>
            ) : (
              filteredTeamsByRegion.map((team) => (
                <div
                  key={team.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                    cursor: "pointer",
                    background: "#1E1E1E",
                    borderRadius: "10px",
                    padding: "15px",
                  }}
                  onClick={() => handleTeamClick(getTeamName(team))}
                >
                  <img
                    src={team.profileImage || defaultImage}
                    alt={getTeamName(team)}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      marginRight: "16px",
                      objectFit: "cover",
                    }}
                  />
                  <div>
                    <h2
                      style={{
                        fontSize: "18px",
                        color: "#FFF",
                        marginBottom: "5px",
                      }}
                    >
                      {getTeamName(team)}
                    </h2>
                    <p style={{ fontSize: "14px", color: "#666873" }}>
                      {team.members?.length || 0} members
                    </p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Right Sidebar (Tournament) */}
        {isWideDesktop && <TournamentSidebar />}
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "#121516",
        minHeight: "100vh",
        color: "#FFF",
        fontFamily: "Manrope",
        padding: "20px",
      }}
    >
      <h1
        style={{
          fontSize: "24px",
          fontWeight: 700,
          marginBottom: "20px",
          marginTop: "0px",
        }}
      >
        Team Search
      </h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "93%", // Changed from fixed width to 100%
          height: "65.524px",
          flexShrink: 0,
          borderRadius: "12.84px",
          background: "#FFF",
          padding: "0 16px",
          marginBottom: "20px", // Added margin to separate from tab selector
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
        >
          <circle
            cx="15.125"
            cy="15.125"
            r="9.625"
            stroke="#666873"
            strokeWidth="2"
          />
          <path
            d="M15.125 11C14.5833 11 14.0469 11.1067 13.5464 11.314C13.046 11.5213 12.5912 11.8251 12.2082 12.2082C11.8251 12.5912 11.5213 13.046 11.314 13.5464C11.1067 14.0469 11 14.5833 11 15.125"
            stroke="#666873"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M27.5 27.5L23.375 23.375"
            stroke="#666873"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearchInputChange}
          style={{
            border: "none",
            outline: "none",
            marginLeft: "8px",
            flex: 1,
            color: "#666873",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "-0.14px",
            background: "transparent",
          }}
        />
      </div>
      <div
        style={{
          position: "relative",
          display: "flex",
          width: "100%",
          padding: "4px",
          alignItems: "center",
          borderRadius: "100px",
          background: "#000",
          zIndex: 1,
          marginTop: "20px",
          marginLeft: "0px",
        }}
      >
        {["myRegion", "all"].map((tab, index) => (
          <button
            key={tab}
            style={{
              flex: 1,
              padding: "10px",
              border: "none",
              background: "transparent",
              color: "#FFF",
              cursor: "pointer",
              position: "relative",
              zIndex: 2,
              fontFamily: "Manrope",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "160%",
              letterSpacing: "0.12px",
            }}
            onClick={() => setActiveTab(tab)}
          >
            {tab === "myRegion" ? "My Region" : "All"}
          </button>
        ))}
        <div
          style={{
            position: "absolute",
            left: activeTab === "myRegion" ? "0%" : "50%",
            top: "4px",
            bottom: "4px",
            width: "50%",
            background: "#101828",
            borderRadius: "100px",
            transition: "left 0.3s ease",
            zIndex: 1,
          }}
        />
      </div>
      <div style={{ paddingBottom: "75px" }}>
        {isLoading ? (
          <div style={{ textAlign: "center", color: "#FFF" }}>Loading...</div>
        ) : error ? (
          <div style={{ textAlign: "center", color: "#FFF" }}>{error}</div>
        ) : (
          filteredTeamsByRegion.map((team) => (
            <div
              key={team.id}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0px",
                cursor: "pointer",
              }}
              onClick={() => handleTeamClick(getTeamName(team))}
            >
              <img
                src={team.profileImage || defaultImage}
                alt={getTeamName(team)}
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  marginRight: "16px",
                  objectFit: "cover",
                }}
              />
              <div>
                <h2
                  style={{
                    fontSize: "18px",
                    marginBottom: "0px",
                    marginTop: "17px",
                  }}
                >
                  {getTeamName(team)}
                </h2>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#666873",
                    marginTop: "5px",
                  }}
                >
                  {team.members?.length || 0} members
                </p>
              </div>
            </div>
          ))
        )}
      </div>
      <Dock />
    </div>
  );
};

export default TeamSearch;
