import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import algoliasearch from "algoliasearch/lite";
import Dock from "../components/Dock";
import defaultImage from "../assets/images/CircleLogo.svg";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { runTransaction, serverTimestamp } from "firebase/firestore";
import likeIcon from "../assets/images/Like.svg";
import unlikeIcon from "../assets/images/Unlike.svg";
import AuthContext from "../context/AuthContext";
import { useContext } from "react";
import { useCallback } from "react";
import Sidebar from "../components/Sidebar";
import TournamentSidebar from "../components/TournamentSidebar";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const fadeInUpKeyframes = `
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const postStyle = {
  opacity: 0,
  transform: "translateY(20px)",
  animation: "fadeInUp 0.5s forwards",
};

const ResultsPage = () => {
  const query = useQuery().get("query");
  const [searchQuery, setSearchQuery] = useState(query);
  const [activeTab, setActiveTab] = useState("all");
  const [usersResults, setUsersResults] = useState([]);
  const [postsResults, setPostsResults] = useState([]);
  const searchClient = algoliasearch(
    "JKUJEZR4KI",
    "713978c4b038973075544a2b055edd7b"
  );
  const { currentUser } = useContext(AuthContext);
  const [likedPosts, setLikedPosts] = useState({});
  const navigate = useNavigate();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1439);
  const [isWideDesktop, setIsWideDesktop] = useState(window.innerWidth >= 1580);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 769);
      setIsWideDesktop(window.innerWidth >= 1580);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleSearch = async (query) => {
      if (query.trim() !== "") {
        try {
          const usersIndex = searchClient.initIndex("users");
          const postsIndex = searchClient.initIndex("posts");

          const [usersResults, postsResults] = await Promise.all([
            usersIndex.search(query),
            postsIndex.search(query),
          ]);

          // Filter users and teams based on username or team_name
          const filteredUsersResults = usersResults.hits.filter((user) =>
            user.isTeam
              ? user.team_name.toLowerCase().includes(query.toLowerCase())
              : user.username.toLowerCase().includes(query.toLowerCase())
          );

          // Fetch followers count for each user and team
          const usersWithFollowers = await Promise.all(
            filteredUsersResults.map(async (user) => {
              const userDocRef = doc(
                firestore,
                user.isTeam ? "teams" : "users",
                user.objectID
              );
              const followersColRef = collection(userDocRef, "followers");
              const followersSnapshot = await getDocs(followersColRef);
              const followersCount = followersSnapshot.size;

              return {
                ...user,
                followersCount,
                username: user.isTeam ? user.team_name : user.username,
                profileImage: user.profileImage || defaultImage,
              };
            })
          );

          // Fetch user/team data from Firestore for each post
          const postsWithUserData = await Promise.all(
            postsResults.hits.map(async (post) => {
              const isTeamPost = post.teamId !== undefined;
              const docRef = doc(
                firestore,
                isTeamPost ? "teams" : "users",
                isTeamPost ? post.teamId : post.userId
              );
              const docSnap = await getDoc(docRef);
              const userData = docSnap.exists()
                ? docSnap.data()
                : { username: "Unknown User", profileImage: defaultImage };
              return {
                ...post,
                user: {
                  ...userData,
                  username: isTeamPost ? userData.team_name : userData.username,
                  profileImage: userData.profileImage || defaultImage,
                  isTeam: isTeamPost,
                },
              };
            })
          );

          // Filter out posts where the user/team does not have a username
          const filteredPostsResults = postsWithUserData.filter(
            (post) =>
              post.user.username && post.user.username !== "Unknown User"
          );

          setUsersResults(usersWithFollowers);
          setPostsResults(filteredPostsResults);
        } catch (error) {
          console.error("Error fetching search results:", error);
        }
      } else {
        setUsersResults([]);
        setPostsResults([]);
      }
    };

    handleSearch(searchQuery);
  }, [searchQuery, searchClient]);

  const handleLikeClick = async (postId, isLiked) => {
    if (!currentUser) {
      console.error("User must be authenticated to like posts");
      return;
    }

    // Optimistically update the UI
    setPostsResults((prevPosts) =>
      prevPosts.map((post) =>
        post.objectID === postId
          ? {
              ...post,
              isLikedByCurrentUser: !isLiked,
              likeCount: isLiked ? post.likeCount - 1 : post.likeCount + 1,
            }
          : post
      )
    );

    const postRef = doc(firestore, "posts", postId);
    const likeRef = doc(firestore, `posts/${postId}/likes/${currentUser.uid}`);

    try {
      await runTransaction(firestore, async (transaction) => {
        const postSnapshot = await transaction.get(postRef);
        if (!postSnapshot.exists()) {
          throw new Error("Post does not exist!");
        }

        const postData = postSnapshot.data();
        const likeSnapshot = await transaction.get(likeRef);
        let newLikeCount = postData.likeCount || 0;

        if (isLiked && likeSnapshot.exists()) {
          // Unlike the post
          transaction.delete(likeRef);
          newLikeCount = Math.max(0, newLikeCount - 1);
        } else if (!isLiked && !likeSnapshot.exists()) {
          // Like the post
          transaction.set(likeRef, {
            userId: currentUser.uid,
            createdAt: serverTimestamp(),
          });
          newLikeCount += 1;

          // Create notification only for user posts, not team posts
          if (postData.userId && !postData.teamId) {
            const notificationRef = collection(firestore, "notifications");
            transaction.set(doc(notificationRef), {
              type: "like",
              content: `${
                currentUser.displayName || "Someone"
              } liked your post`,
              recipientId: postData.userId,
              senderId: currentUser.uid,
              postId: postId,
              createdAt: serverTimestamp(),
            });
          }
        }

        transaction.update(postRef, { likeCount: newLikeCount });
      });
    } catch (error) {
      console.error("Error toggling like: ", error);
      // Revert the optimistic UI update
      setPostsResults((prevPosts) =>
        prevPosts.map((post) =>
          post.objectID === postId
            ? {
                ...post,
                isLikedByCurrentUser: isLiked,
                likeCount: isLiked ? post.likeCount + 1 : post.likeCount - 1,
              }
            : post
        )
      );
    }
  };

  useEffect(() => {
    const fetchLikeStatus = async () => {
      if (currentUser && postsResults.length > 0) {
        const likePromises = postsResults.map(async (post) => {
          const likeRef = doc(
            firestore,
            `posts/${post.objectID}/likes/${currentUser.uid}`
          );
          const likeSnap = await getDoc(likeRef);
          return [post.objectID, likeSnap.exists()];
        });

        const likeStatuses = await Promise.all(likePromises);
        const newLikedPosts = Object.fromEntries(likeStatuses);
        setLikedPosts(newLikedPosts);
      }
    };

    fetchLikeStatus();
  }, [currentUser, postsResults]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  if (isDesktop) {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "#121516",
          minHeight: "100vh",
        }}
      >
        {/* Left Sidebar */}
        <div
          style={{
            width: "350px",
            position: "fixed",
            left: 0,
            top: 0,
            height: "100vh",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "12px",
              left: "12px",
              animation: "fadeInUp 1s ease-in-out",
            }}
          >
            <Sidebar
              activeTab={activeTab}
              handleTabClick={setActiveTab}
              events={[]}
              loading={false}
              handleCardClick={() => {}}
            />
          </div>
        </div>
        <div
          style={{
            width: "1px",
            height: "100vh",
            position: "fixed",
            left: "350px",
            top: 0,
            background: "var(--black, #2E313C)",
          }}
        ></div>

        {/* Main Content */}
        <div
          style={{
            flex: 1,
            padding: "20px",
            marginLeft: isWideDesktop ? "auto" : "350px",
            marginRight: isWideDesktop ? "auto" : "0",
            maxWidth: isWideDesktop ? "800px" : "calc(100% - 350px)",
            width: isWideDesktop ? "800px" : "auto",
            overflowY: "auto",
            height: "100vh",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <h1
            style={{
              color: "#FFF",
              fontSize: "24px",
              fontWeight: 700,
              marginBottom: "20px",
              marginTop: "0px",
            }}
          >
            Discovery - Search
          </h1>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              background: "#FFF",
              borderRadius: "12.84px",
              padding: "8px 16px",
              marginBottom: "20px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
            >
              <circle
                cx="15.125"
                cy="15.125"
                r="9.625"
                stroke="#666873"
                strokeWidth="2"
              />
              <path
                d="M15.125 11C14.5833 11 14.0469 11.1067 13.5464 11.314C13.046 11.5213 12.5912 11.8251 12.2082 12.2082C11.8251 12.5912 11.5213 13.046 11.314 13.5464C11.1067 14.0469 11 14.5833 11 15.125"
                stroke="#666873"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M27.5 27.5L23.375 23.375"
                stroke="#666873"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  navigate(`/results?query=${encodeURIComponent(searchQuery)}`);
                }
              }}
              style={{
                border: "none",
                outline: "none",
                marginLeft: "8px",
                flex: 1,
                color: "#666873",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.14px",
                background: "transparent",
              }}
            />
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              width: "100%",
              padding: "4px",
              alignItems: "center",
              borderRadius: "100px",
              background: "#000",
              zIndex: 1,
              marginBottom: "20px",
            }}
          >
            {["all", "users", "posts"].map((tab) => (
              <button
                key={tab}
                style={{
                  flex: 1,
                  padding: "10px",
                  border: "none",
                  background: "transparent",
                  color: "#FFF",
                  cursor: "pointer",
                  position: "relative",
                  zIndex: 2,
                  fontFamily: "Manrope",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "160%",
                  letterSpacing: "0.12px",
                }}
                onClick={() => setActiveTab(tab)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
            <div
              style={{
                position: "absolute",
                left:
                  activeTab === "all"
                    ? "0%"
                    : activeTab === "users"
                    ? "33.33%"
                    : "66.66%",
                top: "4px",
                bottom: "4px",
                width: "33.33%",
                background: "#101828",
                borderRadius: "100px",
                transition: "left 0.3s ease",
                zIndex: 1,
              }}
            />
          </div>
          <div>
            {activeTab === "all" && (
              <>
                {usersResults.length > 0 && (
                  <>
                    <h2 style={{ color: "#FFF", marginBottom: "16px" }}>
                      Users
                    </h2>
                    {usersResults.map((user) => (
                      <div
                        key={user.objectID}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "24px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate(
                            `/${user.isTeam ? "teams" : "users"}/${
                              user.username || "Unknown User"
                            }`
                          )
                        }
                      >
                        <img
                          src={user.profileImage || defaultImage}
                          alt="Profile"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            marginRight: "16px",
                          }}
                        />
                        <div>
                          <h2
                            style={{
                              fontSize: "18px",
                              color: "#FFF",
                              margin: 0,
                            }}
                          >
                            {user.username} {user.isTeam && "(Team)"}
                          </h2>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#666873",
                              margin: 0,
                            }}
                          >
                            {user.followersCount} followers
                          </p>
                        </div>
                      </div>
                    ))}
                    <div
                      style={{
                        width: "100%",
                        height: "2px",
                        background: "#2E313C",
                        margin: "20px 0",
                      }}
                    />
                  </>
                )}
                {postsResults.length > 0 && (
                  <>
                    <h2 style={{ color: "#FFF", marginBottom: "16px" }}>
                      Posts
                    </h2>
                    {postsResults.map((post) => (
                      <div
                        key={post.objectID}
                        style={{
                          background: "#1e1e1e",
                          borderRadius: "10px",
                          padding: "20px",
                          marginBottom: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(`/posts/${post.objectID}`)}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "12px",
                          }}
                        >
                          <img
                            src={post.user.profileImage || defaultImage}
                            alt="User"
                            style={{
                              width: "32px",
                              height: "32px",
                              borderRadius: "50%",
                              marginRight: "12px",
                            }}
                          />
                          <span style={{ color: "#FFF", fontWeight: "bold" }}>
                            {post.user.username}
                          </span>
                        </div>
                        <p
                          style={{
                            color: "#FFF",
                            marginBottom: "12px",
                            marginLeft: "45px",
                          }}
                        >
                          {post.content}
                        </p>
                        {post.imageUrl && (
                          <img
                            src={post.imageUrl}
                            alt="Post"
                            style={{
                              maxWidth: "70%",
                              maxHeight: "600px",
                              borderRadius: "8px",
                              marginBottom: "12px",
                              marginLeft: "45px",
                            }}
                          />
                        )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "45px",
                          }}
                        >
                          <img
                            src={
                              post.isLikedByCurrentUser ? likeIcon : unlikeIcon
                            }
                            alt={post.isLikedByCurrentUser ? "Unlike" : "Like"}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleLikeClick(
                                post.objectID,
                                post.isLikedByCurrentUser
                              );
                            }}
                            style={{
                              cursor: "pointer",
                              width: "24px",
                              height: "24px",
                              marginRight: "8px",
                            }}
                          />
                          <span style={{ color: "#FFF" }}>
                            {post.likeCount}
                          </span>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
            {activeTab === "users" && (
              <>
                {usersResults.map((user) => (
                  <div
                    key={user.objectID}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "24px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigate(
                        `/${user.isTeam ? "teams" : "users"}/${
                          user.username || "Unknown User"
                        }`
                      )
                    }
                  >
                    <img
                      src={user.profileImage || defaultImage}
                      alt="Profile"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        marginRight: "16px",
                      }}
                    />
                    <div>
                      <h2
                        style={{ fontSize: "18px", color: "#FFF", margin: 0 }}
                      >
                        {user.username} {user.isTeam && "(Team)"}
                      </h2>
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#666873",
                          margin: 0,
                        }}
                      >
                        {user.followersCount} followers
                      </p>
                    </div>
                  </div>
                ))}
              </>
            )}
            {activeTab === "posts" && (
              <>
                {postsResults.map((post) => (
                  <div
                    key={post.objectID}
                    style={{
                      background: "#1e1e1e",
                      borderRadius: "10px",
                      padding: "20px",
                      marginBottom: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(`/posts/${post.objectID}`)}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "12px",
                      }}
                    >
                      <img
                        src={post.user.profileImage || defaultImage}
                        alt="User"
                        style={{
                          width: "32px",
                          height: "32px",
                          borderRadius: "50%",
                          marginRight: "12px",
                        }}
                      />
                      <span style={{ color: "#FFF", fontWeight: "bold" }}>
                        {post.user.username}
                      </span>
                    </div>
                    <p
                      style={{
                        color: "#FFF",
                        marginBottom: "12px",
                        marginLeft: "45px",
                      }}
                    >
                      {post.content}
                    </p>
                    {post.imageUrl && (
                      <img
                        src={post.imageUrl}
                        alt="Post"
                        style={{
                          maxWidth: "70%",
                          maxHeight: "600px",
                          borderRadius: "8px",
                          marginBottom: "12px",
                          marginLeft: "45px",
                        }}
                      />
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "45px",
                      }}
                    >
                      <img
                        src={post.isLikedByCurrentUser ? likeIcon : unlikeIcon}
                        alt={post.isLikedByCurrentUser ? "Unlike" : "Like"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleLikeClick(
                            post.objectID,
                            post.isLikedByCurrentUser
                          );
                        }}
                        style={{
                          cursor: "pointer",
                          width: "24px",
                          height: "24px",
                          marginRight: "8px",
                        }}
                      />
                      <span style={{ color: "#FFF" }}>{post.likeCount}</span>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>

        {/* Right Sidebar (Tournament) */}
        {isWideDesktop && <TournamentSidebar />}
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "#121516",
        minHeight: "100vh",
        color: "#FFF",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontFamily: "Manrope",
        fontSize: "24px",
        fontWeight: 700,
      }}
    >
      <style>
        {`
          .post:not(:last-child)::after {
            content: none; /* Remove the line between posts */
          }
          ${fadeInUpKeyframes}
        `}
      </style>
      <div
        style={{
          color: "var(--White, #FFF)",
          textAlign: "center",
          fontFamily: "Manrope",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "160%", // 28.8px
          letterSpacing: "0.18px",
          position: "absolute",
          top: "12px",
          left: "16px",
        }}
      >
        Discovery - Search
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          background: "#FFF",
          borderRadius: "12.84px",
          padding: "8px 16px", // Removed the duplicate padding key
          position: "absolute",
          top: "65px", // 25px below the Discovery text
          left: "16px",
          right: "16px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
        >
          <circle
            cx="15.125"
            cy="15.125"
            r="9.625"
            stroke="#666873"
            strokeWidth="2"
          />
          <path
            d="M15.125 11C14.5833 11 14.0469 11.1067 13.5464 11.314C13.046 11.5213 12.5912 11.8251 12.2082 12.2082C11.8251 12.5912 11.5213 13.046 11.314 13.5464C11.1067 14.0469 11 14.5833 11 15.125"
            stroke="#666873"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M27.5 27.5L23.375 23.375"
            stroke="#666873"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
        <input
          type="text"
          placeholder={searchQuery}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              window.location.href = `/results?query=${encodeURIComponent(
                searchQuery
              )}`;
            }
          }}
          style={{
            border: "none",
            outline: "none",
            marginLeft: "8px",
            flex: 1,
            color: "#666873",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "-0.14px",
            background: "transparent",
          }}
        />
      </div>
      <div
        className="tab-selector"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "-280px",
          marginLeft: "-10px",
          width: "calc(100% - 32px)", // Same width as the search box
          maxWidth: "calc(100% - 32px)", // Ensure it doesn't exceed the search box width
        }}
      >
        <div
          className={`tab ${activeTab === "all" ? "active" : ""}`}
          onClick={() => handleTabChange("all")}
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            color: activeTab === "all" ? "#FFF" : "#666873",
          }}
        >
          All
        </div>
        <div
          className={`tab ${activeTab === "users" ? "active" : ""}`}
          onClick={() => handleTabChange("users")}
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            color: activeTab === "users" ? "#FFF" : "#666873",
          }}
        >
          Users
        </div>
        <div
          className={`tab ${activeTab === "posts" ? "active" : ""}`}
          onClick={() => handleTabChange("posts")}
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            color: activeTab === "posts" ? "#FFF" : "#666873",
          }}
        >
          Posts
        </div>
      </div>
      <div
        style={{
          marginTop: "210px",
          marginLeft: "15px",
          width: "calc(100% - 32px)",
          maxWidth: "calc(100% - 32px)",
          paddingBottom: "75px",
        }}
      >
        {activeTab === "all" && (
          <>
            {usersResults.map((user, index) => (
              <div
                key={user.objectID}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom:
                    index === usersResults.length - 1 ? "0" : "24px",
                }}
              >
                <a
                  href={`/${user.isTeam ? "teams" : "users"}/${
                    user.username || "Unknown User"
                  }`}
                >
                  <img
                    src={user.profileImage || defaultImage}
                    alt="Profile"
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />
                </a>
                <div>
                  <a
                    href={`/${user.isTeam ? "teams" : "users"}/${
                      user.username || "Unknown User"
                    }`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {user.username} {user.isTeam && "(Team)"}
                  </a>
                  <div style={{ fontSize: "12px", color: "#666873" }}>
                    {user.followersCount} followers
                  </div>
                </div>
              </div>
            ))}
            <div
              style={{
                marginTop: "20px",
                width: "calc(100% - 10px)", // Same width as the tab selector
                height: "2px",
                flexShrink: 0,
                background: "var(--black, #2E313C)",
              }}
            ></div>
            <div
              style={{
                marginTop: "20px", // 20px under the div line
                marginLeft: "0px",
                width: "calc(100% - 32px)",
                maxWidth: "calc(100% - 32px)",
              }}
            >
              {postsResults.map((post) => (
                <div
                  key={post.objectID}
                  style={{
                    background: "rgba(37, 37, 37, 0.7)",
                    backdropFilter: "blur(10px)",
                    WebkitBackdropFilter: "blur(10px)",
                    borderRadius: "16px",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                    padding: "20px",
                    marginBottom: "20px",
                    transition: "all 0.3s ease",
                    width: "95%",
                    maxWidth: "500px",
                  }}
                  onClick={() => navigate(`/posts/${post.objectID}`)}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <img
                      src={post.user.profileImage || defaultImage}
                      alt="User"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                    />
                    <span
                      style={{
                        color: "#fff",
                        fontFamily: "Roboto, sans-serif",
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "25px",
                        letterSpacing: "0.14px",
                        marginTop: "-5px",
                      }}
                    >
                      {post.user.username}
                    </span>
                  </div>
                  <div
                    style={{
                      color: "#fff",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      marginBottom: "10px",
                      marginLeft: "50px",
                      wordWrap: "break-word",
                      marginTop: "-20px",
                    }}
                  >
                    <p>{post.content}</p>
                  </div>
                  {post.imageUrl && (
                    <img
                      src={post.imageUrl}
                      alt="Post"
                      style={{
                        maxWidth: "80%",
                        maxHeight: "500px",
                        display: "block",
                        marginLeft: "50px",
                        marginRight: "auto",
                        borderRadius: "10px",
                        marginBottom: "20px",
                        objectFit: "contain",
                        marginTop: "10px",
                      }}
                    />
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "50px",
                    }}
                  >
                    <img
                      src={post.isLikedByCurrentUser ? likeIcon : unlikeIcon}
                      alt={post.isLikedByCurrentUser ? "Unlike" : "Like"}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLikeClick(
                          post.objectID,
                          post.isLikedByCurrentUser
                        );
                      }}
                      style={{
                        cursor: "pointer",
                        width: "27px",
                        height: "26px",
                        marginRight: "7px",
                      }}
                    />
                    <span
                      style={{
                        color: "#fff",
                        fontFamily: "Manrope, sans-serif",
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "12px",
                        letterSpacing: "-0.24px",
                      }}
                    >
                      {post.likeCount}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {activeTab === "users" && (
          <>
            {usersResults.map((user, index) => (
              <div
                key={user.objectID}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom:
                    index === usersResults.length - 1 ? "0" : "24px",
                }}
              >
                <a href={`/users/${user.username || "Unknown User"}`}>
                  <img
                    src={user.profileImage || defaultImage}
                    alt="Profile"
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />
                </a>
                <div>
                  <a
                    href={`/users/${user.username || "Unknown User"}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {user.username}
                  </a>
                  <div style={{ fontSize: "12px", color: "#666873" }}>
                    {user.followersCount} followers
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
        {activeTab === "posts" && (
          <>
            {postsResults.map((post) => (
              <div
                key={post.objectID}
                style={{
                  background: "rgba(37, 37, 37, 0.7)",
                  backdropFilter: "blur(10px)",
                  WebkitBackdropFilter: "blur(10px)",
                  borderRadius: "16px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                  padding: "20px",
                  marginBottom: "20px",
                  transition: "all 0.3s ease",
                  width: "90%",
                  maxWidth: "500px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                onClick={() => navigate(`/posts/${post.objectID}`)}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={post.user.profileImage || defaultImage}
                    alt="User"
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />
                  <span
                    style={{
                      color: "#fff",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "25px",
                      letterSpacing: "0.14px",
                    }}
                  >
                    {post.user.username}
                  </span>
                </div>
                <div
                  style={{
                    color: "#fff",
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    marginBottom: "10px",
                    marginLeft: "50px",
                    wordWrap: "break-word",
                    marginTop: "-20px",
                  }}
                >
                  <p>{post.content}</p>
                </div>
                {post.imageUrl && (
                  <img
                    src={post.imageUrl}
                    alt="Post"
                    style={{
                      maxWidth: "80%",
                      maxHeight: "500px",
                      display: "block",
                      marginLeft: "50px",
                      marginRight: "auto",
                      borderRadius: "10px",
                      marginBottom: "20px",
                      objectFit: "contain",
                    }}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "50px",
                  }}
                >
                  <img
                    src={post.isLikedByCurrentUser ? likeIcon : unlikeIcon}
                    alt={post.isLikedByCurrentUser ? "Unlike" : "Like"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleLikeClick(post.objectID, post.isLikedByCurrentUser);
                    }}
                    style={{
                      cursor: "pointer",
                      width: "27px",
                      height: "26px",
                      marginRight: "7px",
                    }}
                  />
                  <span
                    style={{
                      color: "#fff",
                      fontFamily: "Manrope, sans-serif",
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "12px",
                      letterSpacing: "-0.24px",
                    }}
                  >
                    {post.likeCount}
                  </span>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <Dock />
    </div>
  );
};

export default ResultsPage;
