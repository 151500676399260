import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <div style={{ color: 'white' }}>
      <h1>Privacy Policy for VexVibe.com</h1>
      <h2>Introduction</h2>
      <p>Welcome to VexVibe.com, a dedicated social media platform for VEX Robotics enthusiasts. This Privacy Policy outlines how we handle your personal information to provide and improve our services.</p>
      <h2>Personal Information Collected</h2>
      <p>To create an account and use VexVibe.com, we collect the following types of personal information:</p>
      <ul>
        <li>Name: To personalize your account experience.</li>
        <li>Gender: For demographic analysis and to tailor content.</li>
        <li>Email Address: For account verification, password recovery, and essential communication.</li>
        <li>Username: Unique identifier for account setup and interaction.</li>
      </ul>
      <h2>Use of Collected Information</h2>
      <p>We use your personal data to:</p>
      <ul>
        <li>Manage and operate your account.</li>
        <li>Improve platform functionality and user experience.</li>
        <li>Analyze user interaction and trends through Google Analytics.</li>
        <li>Manage security and operational needs via Google Cloud Services.</li>
      </ul>
      <h2>Data Sharing and Disclosure</h2>
      <p>Personal information is not shared with third parties except as necessary to provide our services. These third parties include Google Cloud, Firebase, Firestore, and, for advertising purposes, Google AdMob.</p>
      <h2>International Data Transfers</h2>
      <p>Your information is stored on servers located in the US-West1 region. We comply with GDPR standards for international data transfer and protection.</p>
      <h2>Data Security</h2>
      <p>Our platform uses Google Cloud's robust security measures to protect your personal information from unauthorized access, alteration, or destruction.</p>
      <h2>User Rights</h2>
      <p>You have the right to access, update, or delete your personal information at any time. Account deletion and data access can be requested through a designated form on our platform.</p>
      <h2>Children’s Privacy</h2>
      <p>We do not knowingly collect personal information from children under the age of 13. Users must verify they are at least 13 years old to register.</p>
      <h2>Policy Updates</h2>
      <p>We may update this policy to reflect changes to our practices or for other operational, legal, or regulatory reasons. Changes will be notified via email.</p>
      <h2>Contact Information</h2>
      <p>If you have questions or concerns about this policy, please contact us at info@vexvibe.com.</p>
    </div>
  );
};

export default PrivacyPolicyPage;