import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import defaultAvatar from "../assets/images/CircleLogo.svg";
import { useNavigate } from "react-router-dom";
import Dock from "../components/Dock";
import Sidebar from "../components/Sidebar";
import TournamentSidebar from "../components/TournamentSidebar";

const JoinTeam = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("myRegion");
  const [searchQuery, setSearchQuery] = useState("");
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1439);
  const [isWideDesktop, setIsWideDesktop] = useState(window.innerWidth >= 1580);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 769);
      setIsWideDesktop(window.innerWidth >= 1580);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const checkUserTeam = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const db = getFirestore();
        const userDoc = await getDoc(doc(db, "users", user.uid));
        const userData = userDoc.data();

        if (userData && userData.team) {
          // Redirect to the specific team page if the user is already on a team
          navigate(`/teams/${userData.team}`);
        } else {
          fetchTeams();
        }
      }
    };

    const fetchTeams = async () => {
      const db = getFirestore();
      const teamsCollection = collection(db, "teams");
      const teamsSnapshot = await getDocs(teamsCollection);
      const teamsData = teamsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTeams(teamsData);
    };

    checkUserTeam();
  }, [navigate]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const filteredTeams = teams.filter((team) =>
    team.team_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleJoinTeam = async (teamId) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error("User not authenticated");
      return;
    }

    const db = getFirestore();

    if (selectedTeam === teamId) {
      setSelectedTeam(null);
      // Remove the join request from Firestore
      try {
        await setDoc(
          doc(db, "join_team_requests", user.uid, teamId, "request"),
          {
            status: "cancelled",
          }
        );
      } catch (error) {
        console.error("Error cancelling join request:", error);
      }
    } else {
      setSelectedTeam(teamId);
      // Create a new join request in Firestore
      try {
        await setDoc(
          doc(db, "join_team_requests", user.uid, teamId, "request"),
          {
            teamId: teamId,
            status: "pending",
            timestamp: new Date(),
          }
        );
      } catch (error) {
        console.error("Error creating join request:", error);
      }
    }
  };

  if (isDesktop) {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "#121516",
          minHeight: "100vh",
        }}
      >
        {/* Left Sidebar */}
        <div
          style={{
            width: "350px",
            position: "fixed",
            left: 0,
            top: 0,
            height: "100vh",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "12px",
              left: "12px",
              animation: "fadeInUp 1s ease-in-out",
            }}
          >
            <Sidebar
              activeTab={activeTab}
              handleTabClick={handleTabChange}
              events={[]}
              loading={isLoading}
              handleCardClick={() => {}}
            />
          </div>
        </div>
        <div
          style={{
            width: "1px",
            height: "100vh",
            position: "fixed",
            left: "350px",
            top: 0,
            background: "var(--black, #2E313C)",
          }}
        ></div>

        {/* Main Content */}
        <div
          style={{
            flex: 1,
            padding: "20px",
            marginLeft: isWideDesktop ? "auto" : "350px",
            marginRight: isWideDesktop ? "auto" : "0",
            maxWidth: isWideDesktop ? "800px" : "calc(100% - 350px)",
            width: isWideDesktop ? "800px" : "auto",
            overflowY: "auto",
            height: "100vh",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <h1
            style={{
              color: "#FFF",
              fontSize: "24px",
              fontWeight: 700,
              marginBottom: "20px",
              marginTop: "0px",
            }}
          >
            Join a team
          </h1>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "65.524px",
              borderRadius: "12.84px",
              background: "#FFF",
              padding: "0 16px",
              marginBottom: "20px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
            >
              <circle
                cx="15.125"
                cy="15.125"
                r="9.625"
                stroke="#666873"
                strokeWidth="2"
              />
              <path
                d="M15.125 11C14.5833 11 14.0469 11.1067 13.5464 11.314C13.046 11.5213 12.5912 11.8251 12.2082 12.2082C11.8251 12.5912 11.5213 13.046 11.314 13.5464C11.1067 14.0469 11 14.5833 11 15.125"
                stroke="#666873"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M27.5 27.5L23.375 23.375"
                stroke="#666873"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                border: "none",
                outline: "none",
                marginLeft: "8px",
                flex: 1,
                color: "#666873",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.14px",
                background: "transparent",
              }}
            />
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              width: "100%",
              padding: "4px",
              alignItems: "center",
              borderRadius: "100px",
              background: "#000",
              zIndex: 1,
              marginBottom: "20px",
            }}
          >
            {["myRegion", "all"].map((tab) => (
              <button
                key={tab}
                style={{
                  flex: 1,
                  padding: "10px",
                  border: "none",
                  background: "transparent",
                  color: "#FFF",
                  cursor: "pointer",
                  position: "relative",
                  zIndex: 2,
                  fontFamily: "Manrope",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "160%",
                  letterSpacing: "0.12px",
                }}
                onClick={() => handleTabChange(tab)}
              >
                {tab === "myRegion" ? "My Region" : "All"}
              </button>
            ))}
            <div
              style={{
                position: "absolute",
                left: activeTab === "myRegion" ? "0%" : "50%",
                top: "4px",
                bottom: "4px",
                width: "50%",
                background: "#101828",
                borderRadius: "100px",
                transition: "left 0.3s ease",
                zIndex: 1,
              }}
            />
          </div>
          <div>
            {isLoading ? (
              <div style={{ textAlign: "center", color: "#FFF" }}>
                Loading...
              </div>
            ) : (
              filteredTeams.map((team) => (
                <div
                  key={team.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#1E1E1E",
                    borderRadius: "12px",
                    padding: "15px",
                    marginBottom: "15px",
                    opacity: selectedTeam && selectedTeam !== team.id ? 0.5 : 1,
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={team.profileImage || defaultAvatar}
                      alt={team.team_name}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        marginRight: "15px",
                      }}
                    />
                    <div>
                      <div
                        style={{
                          color: "#FFF",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {team.team_name}
                      </div>
                      <div style={{ color: "#666873", fontSize: "14px" }}>
                        {team.members?.length === 1
                          ? "1 Member"
                          : `${team.members?.length || 0} Members`}
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => handleJoinTeam(team.id)}
                    style={{
                      backgroundColor:
                        selectedTeam === team.id ? "#666873" : "#333",
                      color: "#FFF",
                      border: "none",
                      borderRadius: "20px",
                      padding: "10px 20px",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "14px",
                      transition: "background-color 0.3s",
                    }}
                    disabled={selectedTeam && selectedTeam !== team.id}
                  >
                    {selectedTeam === team.id ? "Requested" : "Join Team"}
                  </button>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Right Sidebar (Tournament) */}
        {isWideDesktop && <TournamentSidebar />}
      </div>
    );
  }

  return (
    <div>
      <h1
        style={{
          color: "#FFF",
          textAlign: "center",
          fontFamily: "Manrope",
          fontSize: "22px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "160%",
          letterSpacing: "0.18px",
          position: "absolute",
          top: "0px",
          left: "35px",
        }}
      >
        Join a team
      </h1>
      <div
        className="tab-selector"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "-360px",
        }}
      >
        <div
          className={`tab ${activeTab === "myRegion" ? "active" : ""}`}
          onClick={() => handleTabChange("myRegion")}
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            color: activeTab === "myRegion" ? "#FFF" : "#666873",
          }}
        >
          My Region
        </div>
        <div
          className={`tab ${activeTab === "all" ? "active" : ""}`}
          onClick={() => handleTabChange("all")}
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            color: activeTab === "all" ? "#FFF" : "#666873",
          }}
        >
          All
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "344px",
          height: "65.524px",
          flexShrink: 0,
          borderRadius: "12.84px",
          background: "#FFF",
          margin: "20px auto 0",
          padding: "0 16px",
          marginTop: "125px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
        >
          <circle
            cx="15.125"
            cy="15.125"
            r="9.625"
            stroke="#666873"
            strokeWidth="2"
          />
          <path
            d="M15.125 11C14.5833 11 14.0469 11.1067 13.5464 11.314C13.046 11.5213 12.5912 11.8251 12.2082 12.2082C11.8251 12.5912 11.5213 13.046 11.314 13.5464C11.1067 14.0469 11 14.5833 11 15.125"
            stroke="#666873"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M27.5 27.5L23.375 23.375"
            stroke="#666873"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            border: "none",
            outline: "none",
            marginLeft: "8px",
            flex: 1,
            color: "#666873",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "-0.14px",
            background: "transparent",
          }}
        />
      </div>
      <div style={{ maxWidth: "375px", margin: "0 auto", marginTop: "25px" }}>
        {filteredTeams.map((team) => (
          <div
            key={team.id}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#1E1E1E",
              borderRadius: "12px",
              padding: "10px",
              marginBottom: "10px",
              opacity: selectedTeam && selectedTeam !== team.id ? 0.5 : 1,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={team.profileImage || defaultAvatar}
                alt={team.team_name}
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              />
              <div>
                <div style={{ color: "#FFF", fontWeight: "bold" }}>
                  {team.team_name}
                </div>
                <div style={{ color: "#666873", fontSize: "14px" }}>
                  {team.members?.length === 1
                    ? "1 Member"
                    : `${team.members?.length || 0} Members`}
                </div>
              </div>
            </div>
            <button
              onClick={() => handleJoinTeam(team.id)}
              style={{
                backgroundColor: selectedTeam === team.id ? "#666873" : "#333",
                color: "#FFF",
                border: "none",
                borderRadius: "20px",
                padding: "8px 16px",
                cursor: "pointer",
                fontWeight: "bold",
              }}
              disabled={selectedTeam && selectedTeam !== team.id}
            >
              {selectedTeam === team.id ? "Requested" : "Join Team"}
            </button>
          </div>
        ))}
      </div>

      <Dock />
    </div>
  );
};

export default JoinTeam;
