import React from "react";
import defaultImage from "../../assets/images/CircleLogo.svg";
import likeIcon from "../../assets/images/Like.svg";
import unlikeIcon from "../../assets/images/Unlike.svg";
import sendIcon from "../../assets/images/send.svg";
import threeDotIcon from "../../assets/images/3dot.svg";

const RecommendedPosts = ({
  isLoading,
  postsResults,
  postStyle,
  handleLikeClick,
}) => {
  return (
    <div>
      {isLoading ? (
        <div style={{ textAlign: "center", color: "#FFF" }}></div>
      ) : (
        <div style={{ marginTop: "10px" }}>
          {postsResults.map((post, index) => (
            <div
              key={post.id}
              className="post"
              style={{
                ...postStyle,
                animationDelay: `${index * 0.1}s`,
              }}
            >
              <div className="postHeader">
                <img
                  src={post.profileImage || defaultImage}
                  alt="Profile"
                  className="postProfileImage"
                  onClick={() =>
                    (window.location.href = `/users/${post.username}`)
                  }
                  style={{ cursor: "pointer" }}
                />
                <span className="postUsername">{post.username}</span>
                <span
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "10px",
                    fontSize: "12px",
                    color: "grey",
                    marginTop: "10px",
                  }}
                >
                  {new Date(post.createdAt.seconds * 1000).toLocaleString(
                    "en-US",
                    {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    }
                  )}
                </span>
                <img
                  src={threeDotIcon}
                  alt="Options"
                  className="postOptionsIcon"
                  onClick={() => {}}
                />
              </div>
              <div
                className="postContent"
                onClick={() => (window.location.href = `/posts/${post.id}`)}
                style={{ cursor: "pointer" }}
              >
                {post.content}
              </div>
              {post.imageUrl && (
                <div
                  style={{
                    marginTop: "10px",
                    cursor: "pointer",
                    paddingRight: "10px",
                  }}
                  onClick={() => (window.location.href = `/posts/${post.id}`)}
                >
                  <img
                    src={post.imageUrl}
                    alt="Post"
                    style={{
                      maxWidth: "calc(100% - 20px)",
                      maxHeight: "90%",
                      display: "block",
                      marginLeft: "40px",
                      marginRight: "auto",
                      borderRadius: "10px",
                      marginBottom: "20px",
                      objectFit: "contain",
                    }}
                  />
                </div>
              )}
              <div className="likeSection">
                <img
                  src={post.isLiked ? likeIcon : unlikeIcon}
                  alt={post.isLiked ? "Unlike" : "Like"}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleLikeClick(post.id, post.isLiked).catch(console.error);
                  }}
                  style={{
                    cursor: "pointer",
                    width: "27px",
                    height: "26px",
                  }}
                />
                <span className="likeCount">{post.likeCount}</span>
                <img
                  src={sendIcon}
                  alt="Send"
                  onClick={() => {}}
                  style={{
                    cursor: "pointer",
                    width: "26px",
                    height: "26px",
                    marginLeft: "15px",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RecommendedPosts;
