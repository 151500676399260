import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  arrayUnion,
} from "firebase/firestore";
import { firestore } from "../firebase";
import Stories from "react-insta-stories";
import defaultProfileImage from "../assets/images/CircleLogo.svg";
import StoryHeader from "./StoryHeader";
import moment from "moment";


const StoryViewer = ({ currentUser, profileUserId }) => {
  const [stories, setStories] = useState([]);
  const [showFullStory, setShowFullStory] = useState(false);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(null);
  const [viewedStories, setViewedStories] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const fetchStories = async () => { 
      if (!currentUser) return;

      let allStories = [];

      // Fetch viewed stories for the current user
      const viewedStoriesSnapshot = await getDocs(
        collection(firestore, `users/${currentUser.uid}/viewedStories`)
      );
      const viewedStoriesData = {};
      viewedStoriesSnapshot.forEach((doc) => {
        viewedStoriesData[doc.id] = doc.data().viewedStories || [];
      });
      setViewedStories(viewedStoriesData);

      // If profileUserId is provided, fetch stories only for that user
      if (profileUserId) {
        const storyRef = doc(firestore, "stories", profileUserId);
        const storySnapshot = await getDoc(storyRef);
        if (storySnapshot.exists()) {
          const userStoriesData = storySnapshot.data().stories || [];
          const userRef = doc(firestore, `users/${profileUserId}`);
          const userDocSnap = await getDoc(userRef);

          let profileImage, username;
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            profileImage = userData.profileImage || defaultProfileImage;
            username = userData.username || "Unknown";
          } else {
            profileImage = defaultProfileImage;
            username = "Unknown";
          }

          const now = new Date();
          const validStories = userStoriesData.filter((story) => {
            if (!story.createdAt) return false;
            const storyDate = new Date(story.createdAt.seconds * 1000);
            return (now - storyDate) / (1000 * 60 * 60 * 24) < 1;
          });

          const userStories = validStories.map((story) => ({
            id: story.id || `${profileUserId}-${story.createdAt.seconds}`,
            url: story.mediaUrl,
            type: story.mediaType,
            duration:
              story.duration || (story.mediaType === "video" ? 15000 : 5000),

              header: {
                heading: (
                  <span style={{ marginLeft: "45px", display: "block" }}>
                    {username}
                  </span>
                  ),
  
                subheading: (
                <span style={{ marginLeft: "45px", display: "block" }}>
                  {moment(story.createdAt.toDate()).fromNow()}
                  </span>
                  )
              }
          }));

          if (userStories.length > 0) {
            allStories.push({
              user: { userId: profileUserId, profileImage, username },
              stories: userStories,
              hasUnviewedStories: userStories.some(
                (story) => !viewedStoriesData[profileUserId]?.includes(story.id)
              ),
            });
          }
        }
      } else {
        // Fetch stories for followed users
        const followedUsersRef = collection(
          firestore,
          `users/${currentUser.uid}/following`
        );
        const followedUsersSnapshot = await getDocs(followedUsersRef);

        for (const followDoc of followedUsersSnapshot.docs) {
          const followedUserId = followDoc.data().followingId;
          const storyRef = doc(firestore, "stories", followedUserId);
          const storySnapshot = await getDoc(storyRef);

          if (storySnapshot.exists()) {
            const userStoriesData = storySnapshot.data().stories || [];
            const userRef = doc(firestore, `users/${followedUserId}`);
            const userDocSnap = await getDoc(userRef);

            let profileImage, username;
            if (userDocSnap.exists()) {
              const userData = userDocSnap.data();
              profileImage = userData.profileImage || defaultProfileImage;
              username = userData.username || "Unknown";
            } else {
              profileImage = defaultProfileImage;
              username = "Unknown";
            }

            const now = new Date();
            const validStories = userStoriesData.filter((story) => {
              if (!story.createdAt) return false;
              const storyDate = new Date(story.createdAt.seconds * 1000);
              return (now - storyDate) / (1000 * 60 * 60 * 24) < 1;
            });

            const userStories = validStories.map((story) => ({
              id: story.id || `${followedUserId}-${story.createdAt.seconds}`,
              url: story.mediaUrl,
              type: story.mediaType,
              duration:
                story.duration || (story.mediaType === "video" ? 15000 : 5000),

              header: {
                heading: (
                  <span style={{ marginLeft: "45px", display: "block" }}>
                    {username}
                  </span>
                  ),

                subheading: (
                <span style={{ marginLeft: "45px", display: "block" }}>
                  {moment(story.createdAt.toDate()).fromNow()}
                  </span>
                  )
              }
            }));

            if (userStories.length > 0) {
              allStories.push({
                user: { userId: followedUserId, profileImage, username },
                stories: userStories,
                hasUnviewedStories: userStories.some(
                  (story) =>
                    !viewedStoriesData[followedUserId]?.includes(story.id)
                ),
              });
            }
          }
        }
      }

      setStories(allStories);
    };

    fetchStories();
  }, [currentUser, profileUserId]);

  // useEffect(() => {
  //   if (currentUser) {
  //     const fetchFollowedStories = async () => {
  //       const followedUsersRef = collection(
  //         firestore,
  //         `users/${currentUser.uid}/following`
  //       );
  //       const followedUsersSnapshot = await getDocs(followedUsersRef);

  //       let allStories = [];

  //       // Fetch viewed stories for the current user
  //       const viewedStoriesSnapshot = await getDocs(
  //         collection(firestore, `users/${currentUser.uid}/viewedStories`)
  //       );
  //       const viewedStoriesData = {};
  //       viewedStoriesSnapshot.forEach((doc) => {
  //         viewedStoriesData[doc.id] = doc.data().viewedStories || [];
  //       });
  //       setViewedStories(viewedStoriesData);

  //       for (const followDoc of followedUsersSnapshot.docs) {
  //         const followedUserId = followDoc.data().followingId;
  //         const storyRef = doc(firestore, "stories", followedUserId);
  //         const storySnapshot = await getDoc(storyRef);

  //         if (storySnapshot.exists()) {
  //           const userStoriesData = storySnapshot.data().stories || [];
  //           const userRef = doc(firestore, `users/${followedUserId}`);
  //           const userDocSnap = await getDoc(userRef);

  //           let profileImage, username;
  //           if (userDocSnap.exists()) {
  //             const userData = userDocSnap.data();
  //             profileImage = userData.profileImage || defaultProfileImage;
  //             username = userData.username || "Unknown";
  //           } else {
  //             profileImage = defaultProfileImage;
  //             username = "Unknown";
  //           }

  //           const now = new Date();
  //           const validStories = userStoriesData.filter((story) => {
  //             if (!story.createdAt) {
  //               return false; // Ensure createdAt exists
  //             }
  //             const storyDate = new Date(story.createdAt.seconds * 1000);
  //             return (now - storyDate) / (1000 * 60 * 60 * 24) < 1; // Filter stories less than 24 hours old
  //           });

  //           const userStories = validStories.map((story) => ({
  //             id: story.id || `${followedUserId}-${story.createdAt.seconds}`, // Ensure each story has a unique ID
  //             url: story.mediaUrl,
  //             type: story.mediaType,
  //             duration:
  //               story.duration || (story.mediaType === "video" ? 15000 : 5000),
  //           }));

  //           if (userStories.length > 0) {
  //             allStories.push({
  //               user: { userId: followedUserId, profileImage, username },
  //               stories: userStories,
  //               hasUnviewedStories: userStories.some(
  //                 (story) =>
  //                   !viewedStoriesData[followedUserId]?.includes(story.id)
  //               ),
  //             });
  //           }
  //         }
  //       }

  //       setStories(allStories);
  //     };

  //     fetchFollowedStories();
  //   }
  // }, [currentUser]);

  const markStoryAsViewed = async (userId, storyId) => {
    if (!currentUser || !storyId) return;

    const viewedStoriesRef = doc(
      firestore,
      `users/${currentUser.uid}/viewedStories/${userId}`
    );

    try {
      await setDoc(
        viewedStoriesRef,
        {
          viewedStories: arrayUnion(storyId),
        },
        { merge: true }
      );

      // Update local state
      setViewedStories((prev) => ({
        ...prev,
        [userId]: [...(prev[userId] || []), storyId],
      }));

      // Update stories state to reflect viewed status
      setStories((prevStories) =>
        prevStories.map((storyBundle) =>
          storyBundle.user.userId === userId
            ? {
                ...storyBundle,
                hasUnviewedStories: storyBundle.stories.some(
                  (story) => !viewedStories[userId]?.includes(story.id)
                ),
              }
            : storyBundle
        )
      );
    } catch (error) {
      console.error("Error marking story as viewed:", error);
    }
  };

  const handleStoryClick = (index) => {
    if (!isDropdownOpen) {
      setCurrentStoryIndex(index);
      setShowFullStory(true);
    }
  };

  const handleStoryEnd = (storyIndex) => {
    const currentStoryBundle = stories[currentStoryIndex];
    if (currentStoryBundle && currentStoryBundle.stories[storyIndex]) {
      const storyId = currentStoryBundle.stories[storyIndex].id;
      if (storyId) {
        markStoryAsViewed(currentStoryBundle.user.userId, storyId);
      }
    }
    handleStoryNext(storyIndex);
  };

  const handleStoryNext = (storyIndex) => {
    const userStories = stories[currentStoryIndex]?.stories || [];
    if (storyIndex + 1 < userStories.length) {
      // Continue to the next story of the current user
      return;
    } else {
      // Move to the next user's stories
      const nextIndex = currentStoryIndex + 1;
      if (nextIndex < stories.length) {
        setCurrentStoryIndex(nextIndex);
      } else {
        setShowFullStory(false);
      }
    }
  };

  const handleStoryPrev = (storyIndex) => {
    if (storyIndex > 0) {
      // Continue to the previous story of the current user
      return;
    } else {
      // Move to the previous user's stories
      const prevIndex = currentStoryIndex - 1;
      if (prevIndex >= 0) {
        setCurrentStoryIndex(prevIndex);
      } else {
        setShowFullStory(false);
      }
    }
  };

  return (
    <div className="storyViewer">
      <div className="storiesPreview">
        {stories.map((storyBundle, index) => (
          <div
            key={index}
            className={`storyPreview ${
              storyBundle.hasUnviewedStories ? "notViewed" : "viewed"
            }`}
            onClick={() => handleStoryClick(index)}
          >
            <div className="storyProfileImageContainer">
              <img
                className="storyProfileImage"
                src={storyBundle.user.profileImage}
                alt={storyBundle.user.username}
              />
            </div>
            <p style={{ color: "white", marginTop: "5px", fontWeight: "600" }}>
              {storyBundle.user.username}
            </p>
          </div>
        ))}
      </div>
      {showFullStory && currentStoryIndex !== null && (
        <div className="storyOverlay">
          <StoryHeader
            heading={stories[currentStoryIndex].user.username}
            profileImage={stories[currentStoryIndex].user.profileImage}
          />
          <Stories
            stories={stories[currentStoryIndex].stories}
            defaultInterval={5000}
            width="100%"
            height="100%"
            onAllStoriesEnd={() => setShowFullStory(false)}
            onStoryEnd={handleStoryEnd}
            onStoryStart={(storyIndex) => {
              const currentStoryBundle = stories[currentStoryIndex];
              if (
                currentStoryBundle &&
                currentStoryBundle.stories[storyIndex]
              ) {
                const storyId = currentStoryBundle.stories[storyIndex].id;
                if (storyId) {
                  markStoryAsViewed(currentStoryBundle.user.userId, storyId);
                }
              }
            }}
            onPrevious={handleStoryPrev}
          />
        </div>
      )}
    </div>
  );
};

export default StoryViewer;
