// src/pages/HomePage.jsx

// Import statements
// React essentials
import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  Suspense,
  lazy,
  useCallback,
} from "react"; // Importing React and various hooks and components from React

// Router
import { useNavigate } from "react-router-dom"; // Importing useNavigate hook from react-router-dom for navigation

// Context
import AuthContext from "../context/AuthContext"; // Importing AuthContext to use authentication context

// Components
import Dock from "../components/Dock";
import LazyVideo from "../components/LazyVideo";

// Styles
import "../assets/styles/homePage.css";

// Assets
import logo from "../assets/images/VexVibe.svg"; // Importing logo image
import postIcon from "../assets/images/Post.svg"; // Importing post icon image
import closeIcon from "../assets/images/ClosePost.svg"; // Importing close post icon image
import defaultImage from "../assets/images/CircleLogo.svg"; // Importing default profile image
import photoIcon from "../assets/images/PhotoIcon.svg"; // Importing photo upload icon image
import likeIcon from "../assets/images/Like.svg"; // Importing like icon image
import unlikeIcon from "../assets/images/Unlike.svg"; // Importing unlike icon image
import threeDotIcon from "../assets/images/3dot.svg"; // Importing three dot icon image
import sendIcon from "../assets/images/send.svg"; // Importing send icon image
import chatIcon from "../assets/images/Chat.svg"; // Importing chat icon image
import commentIcon from "../assets/images/Comment.svg"; // Importing comment icon image

import AdPost from "../components/AdPost"; // Importing AdPost component
import { Link } from "react-router-dom"; // Importing Link component from react-router-dom

import axios from "axios"; // Importing axios for making HTTP requests
import { getAuth } from "firebase/auth"; // Importing getAuth from Firebase authentication
import { getFirestore } from "firebase/firestore"; // Importing getFirestore from Firebase Firestore

import Sidebar from "../components/Sidebar"; // Importing Sidebar component
import { ChevronDownIcon } from "@heroicons/react/solid"; // Importing ChevronDownIcon from heroicons library
import { where, limit, startAt, endAt } from "firebase/firestore";

// Firebase services
import { firestore, storage } from "../firebase"; // Importing Firestore and Storage from Firebase configuration
import {
  doc,
  getDoc,
  collection,
  addDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
  deleteDoc,
  increment,
  query,
  orderBy,
  getDocs,
  startAfter,
} from "firebase/firestore"; // Importing Firestore functions for database operations
import { ref, getDownloadURL, uploadBytes } from "firebase/storage"; // Importing Storage functions for file operations

// Utilities
import { debounce } from "lodash"; // Importing debounce function from lodash
import { runTransaction } from "firebase/firestore"; // Importing runTransaction from Firestore for atomic transactions
import imageCompression from "browser-image-compression"; // Importing imageCompression for compressing images in the browser
import { Share } from "@capacitor/share"; // Importing Share utility from Capacitor for sharing functionality

import UserTagInput from "../components/UserTagInput"; // Importing UserTagInput component
import MyStory from "../components/MyStory"; // Importing MyStory component
import StoryViewer from "../components/StoryViewer"; // Importing StoryViewer component
import TournamentSidebar from "../components/TournamentSidebar"; // Importing TournamentSidebar component
import { set } from "date-fns";

import WelcomeOverlay from "../components/WelcomeOverlay";

const PostSkeleton = () => (
  // Main container with a dark background
  <div className="postSkeleton" style={{ background: "#121516" }}>
    {/* Header section */}
    <div className="postHeaderSkeleton">
      {/* Profile image placeholder */}
      <div className="profileImageSkeleton"></div>
      {/* Username placeholder */}
      <div className="usernameSkeleton"></div>
    </div>

    {/* Content placeholder */}
    <div className="contentSkeleton"></div>

    {/* Image placeholder */}
    <div className="imageSkeleton"></div>

    {/* Like section placeholder */}
    <div className="likeSectionSkeleton"></div>
  </div>
);

const DesktopPostSkeleton = () => (
  // Main container for the skeleton post with styles for appearance and animation
  <div
    className="postSkeleton"
    style={{
      background: "#292b2c", // Dark background color
      borderRadius: "10px", // Rounded corners
      padding: "10px", // Padding inside the container
      marginBottom: "20px", // Space below the container
      animation: "pulse 0.25s infinite", // Pulse animation for loading effect
      width: "1500%", // Width set very wide to simulate loading content off-screen
      transform: "translateX(-500px)", // Translate to simulate the content being loaded in
    }}
  >
    {/* Header section with profile image and username placeholders */}
    <div
      className="postHeaderSkeleton"
      style={{
        display: "flex", // Flexbox layout for alignment
        alignItems: "center", // Center items vertically
        color: "#121516", // Text color
      }}
    >
      {/* Profile image placeholder */}
      <div
        className="profileImageSkeleton"
        style={{
          width: "40px", // Width of the image
          height: "40px", // Height of the image
          background: "#3c3c3c", // Dark background color
          borderRadius: "50%", // Circular shape
          marginRight: "10px", // Space to the right of the image
          color: "#121516", // Text color
        }}
      ></div>
      {/* Username placeholder */}
      <div
        className="usernameSkeleton"
        style={{
          width: "100px", // Width of the username placeholder
          height: "20px", // Height of the username placeholder
          background: "#3c3c3c", // Dark background color
          borderRadius: "5px", // Rounded corners
        }}
      ></div>
    </div>
    {/* Content placeholder */}
    <div
      className="contentSkeleton"
      style={{
        width: "100%", // Full width of the container
        height: "60px", // Height of the content placeholder
        background: "#3c3c3c", // Dark background color
        borderRadius: "5px", // Rounded corners
        margin: "10px 0", // Vertical margin
      }}
    ></div>
    {/* Image placeholder */}
    <div
      className="imageSkeleton"
      style={{
        width: "100%", // Full width of the container
        height: "200px", // Height of the image placeholder
        background: "#3c3c3c", // Dark background color
        borderRadius: "10px", // Rounded corners
      }}
    ></div>
    {/* Like section placeholder */}
    <div
      className="likeSectionSkeleton"
      style={{
        width: "100px", // Width of the like section placeholder
        height: "20px", // Height of the like section placeholder
        background: "#3c3c3c", // Dark background color
        borderRadius: "5px", // Rounded corners
        marginTop: "10px", // Margin on top
      }}
    ></div>
  </div>
);

const cardStyle = {
  background: "#1d2021", // Background color of the card
  borderRadius: "12.84px", // Rounded corners of the card
  margin: "10px auto", // Vertical margin and automatic horizontal centering
  padding: "16px", // Padding inside the card
  width: "90%", // Width of the card relative to its container
  maxWidth: "350px", // Maximum width of the card to prevent it from getting too wide
  height: "auto", // Height will adjust automatically based on content
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Shadow effect for depth
  cursor: "pointer", // Cursor changes to pointer when hovering over the card
  opacity: 0, // Initial opacity for the fade-in effect
  transform: "translateY(20px)", // Initial position for the slide-up effect
  animation: "fadeInUp 0.5s forwards", // Animation for fading in and sliding up
  transition: "box-shadow 0.3s ease", // Transition for smooth shadow change on hover
};

// Define the keyframes for the "fadeInUp" animation as a template literal string
const fadeInUpKeyframes = `
  @keyframes fadeInUp {
    // The "to" keyword indicates the final state of the animation
    to {
      opacity: 1; // Final opacity is fully visible
      transform: translateY(0); // Final position is at its original place (no translation)
    }
  }
`;

const skeletonStyles = `
  .postSkeleton {
    background: #292b2c !important; /* Ensure this background color is applied */
    border-radius: 10px;             /* Rounded corners for the skeleton card */
    padding: 10px;                   /* Padding inside the skeleton card */
    margin-bottom: 20px;             /* Space below the skeleton card */
    animation: pulse 0.25s infinite; /* Pulse animation for loading effect */
    width: 90%;                      /* Width of the skeleton card relative to its container */
  }
  .postHeaderSkeleton {
    display: flex;                   /* Flexbox layout for alignment */
    align-items: center;             /* Center items vertically */
    color: #121516;                  /* Text color (not used here but may be inherited) */
  }
  .profileImageSkeleton {
    width: 40px;                     /* Width of the profile image placeholder */
    height: 40px;                    /* Height of the profile image placeholder */
    background: #3c3c3c;             /* Background color for the profile image placeholder */
    border-radius: 50%;              /* Circular shape */
    margin-right: 10px;              /* Space to the right of the profile image placeholder */
    color: #121516;                  /* Text color (not used here but may be inherited) */
  }
  .usernameSkeleton {
    width: 100px;                    /* Width of the username placeholder */
    height: 20px;                    /* Height of the username placeholder */
    background: #3c3c3c;             /* Background color for the username placeholder */
    border-radius: 5px;              /* Rounded corners */
  }
  .contentSkeleton {
    width: 100%;                     /* Full width of the content placeholder */
    height: 60px;                    /* Height of the content placeholder */
    background: #3c3c3c;             /* Background color for the content placeholder */
    border-radius: 5px;              /* Rounded corners */
    margin: 10px 0;                  /* Vertical margin for spacing */
  }
  .imageSkeleton {
    width: 100%;                     /* Full width of the image placeholder */
    height: 200px;                   /* Height of the image placeholder */
    background: #3c3c3c;             /* Background color for the image placeholder */
    border-radius: 10px;             /* Rounded corners */
  }
  .likeSectionSkeleton {
    width: 100px;                    /* Width of the like section placeholder */
    height: 20px;                    /* Height of the like section placeholder */
    background: #3c3c3c;             /* Background color for the like section placeholder */
    border-radius: 5px;              /* Rounded corners */
    margin-top: 10px;                /* Space above the like section placeholder */
  }
  @keyframes pulse {
    0% {
      background-color: #f0f0f0;     /* Light grey background color at the start */
    }
    50% {
      background-color: #e0e0e0;     /* Darker grey background color at midpoint */
    }
    100% {
      background-color: #f0f0f0;     /* Light grey background color at the end */
    }
  }
`;

// // CSS string for card hover effect
// const cardHoverStyle = `
//   .card:hover {
//     box-shadow: 0 0 25px rgba(128, 128, 128, 1); // Adds a shadow around the card on hover
//   }
// `;

// // Inline style object for card container
// const cardContainerStyle = {
//   paddingBottom: "100px", // Adds padding at the bottom of the container to create space
// };

// HomePage component
const HomePage = () => {
  // Hooks

  // State to track the current scroll position
  const [scrollPosition, setScrollPosition] = useState(0);

  const [lastDoc, setLastDoc] = useState(null);

  // Hook for navigation
  const navigate = useNavigate();

  // Context hook to get the current user from AuthContext
  const { currentUser } = useContext(AuthContext);

  // State to track if the create post modal is open
  const [isCreatePostOpen, setIsCreatePostOpen] = useState(false);

  // State to store the user's profile information
  const [userProfile, setUserProfile] = useState({
    username: "",
    profileImage: defaultImage,
  });

  // State to store the content of the post being created
  const [postContent, setPostContent] = useState("");

  // State to track if a post is currently being submitted
  const [isPosting, setIsPosting] = useState(false);

  // State to track if a post has been successfully submitted
  const [isPosted, setIsPosted] = useState(false);

  // State to store the selected image file for the post
  const [selectedImage, setSelectedImage] = useState(null);

  // State to manage the height of the text area dynamically
  const [textAreaHeight, setTextAreaHeight] = useState("0px");

  // Ref to access the text area DOM element directly
  const textAreaRef = useRef(null);

  // Ref for the icon to directly manipulate its style
  const iconRef = useRef(null);

  // Ref to store the initial height of the text area
  const initialTextAreaHeight = useRef(null);

  // State to manage the offset of the icon
  const [iconOffset, setIconOffset] = useState(0);

  // State to store the URL for the image preview
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  // State to store the list of posts
  const [posts, setPosts] = useState([]);

  // // State to track if a like action is being toggled
  // const [isTogglingLike, setIsTogglingLike] = useState(false);

  // // State to trigger a refresh of the posts
  // const [refreshPostsTrigger, setRefreshPostsTrigger] = useState(false);

  // State to track errors
  const [errorState, setErrorState] = useState(false);

  // State to track the loading status
  const [isLoading, setIsLoading] = useState(true);

  // State to manage the ID of the currently open dropdown
  const [openDropdownId, setOpenDropdownId] = useState(null);

  // State to track if the posts are being refreshed
  const [isRefreshing, setIsRefreshing] = useState(false);

  // State to track the progress of pull-to-refresh action
  const [pullProgress, setPullProgress] = useState(0);

  // // Ref to access the pull-to-refresh DOM element directly
  // const pullToRefreshRef = useRef(null);

  // // Constant to define the distance required to trigger a refresh
  // const pullToRefreshThreshold = 100;

  const [postPage, setPostPage] = useState(1);

  // State to manage the visibility of an informational box
  const [showInfoBox, setShowInfoBox] = useState(false);

  // State to track the timestamp of the last post
  const [lastPostTime, setLastPostTime] = useState(0);

  // State to store the list of tagged users
  const [taggedUsers, setTaggedUsers] = useState([]);

  // State to track if the viewport is desktop-sized
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1439);

  // State to track if the viewport is wide desktop-sized
  const [isWideDesktop, setIsWideDesktop] = useState(window.innerWidth >= 1580);

  // State to manage the user role for posting (e.g., as a user or a team)
  const [postAs, setPostAs] = useState("user");

  // State to manage the visibility of the post-as dropdown
  const [showPostAsDropdown, setShowPostAsDropdown] = useState(false);

  // Ref to access the dropdown DOM element directly
  const dropdownRef = useRef(null);

  // State to store the user's team information
  const [userTeam, setUserTeam] = useState(null);

  // State to manage the visibility of an overlay
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  // State to track the search query for tags
  const [tagSearchQuery, setTagSearchQuery] = useState("");

  // State to store the suggestions for tags based on the search query
  const [tagSuggestions, setTagSuggestions] = useState([]);

  // State to manage the visibility of tag suggestions
  const [showTagSuggestions, setShowTagSuggestions] = useState(false);

  // State to track the cursor position in the tag search input
  const [cursorPosition, setCursorPosition] = useState(0);

  // Ref to access the tag search input DOM element directly
  // const tagSearchRef = useRef(null);

  const containerRef = useRef(null);

  const db = getFirestore();
  const auth = getAuth();
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);

  const [loading, setLoading] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [postHasMore, setPostHasMore] = useState(true);
  const [eventHasMore, setEventHasMore] = useState(true);

  const [hasNewNotifications, setHasNewNotifications] = useState(false);

  useEffect(() => {
    const checkForNewNotifications = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const lastViewTimestamp = localStorage.getItem("lastNotificationView");
      if (!lastViewTimestamp) {
        setHasNewNotifications(true);
        return;
      }

      const notificationsRef = collection(firestore, "notifications");
      const q = query(
        notificationsRef,
        where("recipientId", "==", user.uid),
        orderBy("createdAt", "desc"),
        limit(1)
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const latestNotification = querySnapshot.docs[0].data();
        const latestNotificationTime = latestNotification.createdAt
          .toDate()
          .getTime();
        setHasNewNotifications(
          latestNotificationTime > parseInt(lastViewTimestamp)
        );
      }
    };

    checkForNewNotifications();
  }, []);

  const searchUsers = async (searchQuery) => {
    // If the search query is empty, clear the tag suggestions and return
    if (searchQuery.length === 0) {
      setTagSuggestions([]);
      return;
    }

    // Convert the search query to lowercase for case-insensitive matching
    const lowercaseQuery = searchQuery.toLowerCase();

    // Reference to the "users" collection in Firestore
    const usersRef = collection(firestore, "users");

    // Create a query to fetch users, ordered by username and limited to 10 results
    const q = query(
      usersRef,
      orderBy("username"),
      limit(10) // Limit the number of results to 10 for more potential matches
    );

    try {
      // Execute the query and get the documents
      const querySnapshot = await getDocs(q);

      // Map over the documents to extract user data and filter based on the search query
      const suggestions = querySnapshot.docs
        .map((doc) => ({
          id: doc.id, // User ID
          username: doc.data().username, // Username
          profileImage: doc.data().profileImage || defaultImage, // Profile image or default image
        }))
        .filter((user) => user.username.toLowerCase().includes(lowercaseQuery)); // Filter users by matching the search query

      // Update the tag suggestions state with the filtered user suggestions
      setTagSuggestions(suggestions);
    } catch (error) {
      // Log any errors that occur during the query
      console.error("Error fetching users:", error);

      // Clear the tag suggestions in case of an error
      setTagSuggestions([]);
    }
  };

  // Debounce the search function to avoid excessive Firestore reads
  const debouncedSearch = useRef(debounce(searchUsers, 300)).current;

  const fetchTopUsers = async () => {
    // Reference to the "users" collection in Firestore
    const usersRef = collection(firestore, "users");

    // Create a query to fetch users ordered by the number of followers in descending order, limited to 5 results
    const q = query(
      usersRef,
      orderBy("followers", "desc"), // Assuming you have a 'followers' field to sort by the number of followers
      limit(5) // Limit the results to the top 5 users
    );

    // Execute the query and get the documents
    const querySnapshot = await getDocs(q);

    // Map over the documents to extract user data
    const topUsers = querySnapshot.docs.map((doc) => ({
      id: doc.id, // User ID
      username: doc.data().username, // Username
      profileImage: doc.data().profileImage || defaultImage, // Profile image or default image
    }));

    // Update the tag suggestions state with the top users
    setTagSuggestions(topUsers);
  };

  const checkUsernameExists = async (username) => {
    const usernameRef = doc(firestore, "usernames", username);
    const docSnap = await getDoc(usernameRef);
    return !docSnap.exists();
  };

  const checkTeamExists = async (teamname) => {
    const teamsRef = collection(firestore, "teams");
    const q = query(teamsRef, where("team_name", "==", teamname.toUpperCase()));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const useAsyncUsernameCheck = (username) => {
    const [isValid, setIsValid] = useState(false);
    const [isUser, setIsUser] = useState(false);

    useEffect(() => {
      let isMounted = true;
      const checkValidity = async () => {
        const userExists = !(await checkUsernameExists(username));
        const teamExists = await checkTeamExists(username);
        if (isMounted) {
          setIsValid(userExists || teamExists);
          setIsUser(userExists);
        }
      };

      checkValidity();
      return () => {
        isMounted = false;
      };
    }, [username]);

    return { isValid, isUser };
  };

  const renderPostContent = useCallback((content) => {
    const words = content.split(/\s+/);

    return words.map((word, index) => {
      if (word.startsWith("@")) {
        const username = word.slice(1);
        return (
          <React.Fragment key={`${username}-${index}`}>
            <AsyncUsername username={username} word={word} />{" "}
          </React.Fragment>
        );
      }
      return word + " ";
    });
  }, []);

  // Memoized AsyncUsername component
  const AsyncUsername = React.memo(({ username, word }) => {
    const { isValid, isUser } = useAsyncUsernameCheck(username);

    if (!isValid) {
      return word; // Remove the space here
    }

    return (
      <Link
        to={isUser ? `/users/${username}` : `/teams/${username}`}
        style={{
          color: "#1DA1F2",
          textDecoration: "none",
          fontWeight: "bold",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {word}
      </Link>
    );
  });
  // Modify handlePostContentChange to format tags as they're typed
  const handlePostContentChange = (e) => {
    const content = e.target.value;
    const cursorPos = e.target.selectionStart;
    setPostContent(content);
    setCursorPosition(cursorPos);

    // Check for '@' symbol
    const lastAtSymbol = content.lastIndexOf("@", cursorPos);
    if (lastAtSymbol !== -1) {
      const tagQuery = content.slice(lastAtSymbol + 1, cursorPos);
      setTagSearchQuery(tagQuery);
      setShowTagSuggestions(true);
      debouncedSearch(tagQuery);
    } else {
      setShowTagSuggestions(false);
    }
  };

  // Modify handleTagSelection to insert the tag
  const handleTagSelection = (username) => {
    const content = postContent;
    const lastAtSymbol = content.lastIndexOf("@", cursorPosition);
    const newContent =
      content.slice(0, lastAtSymbol) +
      `@${username} ` +
      content.slice(cursorPosition);
    setPostContent(newContent);
    setShowTagSuggestions(false);

    // Set focus back to textarea and move cursor to end of inserted tag
    if (textAreaRef.current) {
      textAreaRef.current.focus();
      const newCursorPos = lastAtSymbol + username.length + 2; // +2 for @ and space
      textAreaRef.current.setSelectionRange(newCursorPos, newCursorPos);
    }
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserProfile(userData);
          if (userData.team) {
            setUserTeam(userData.team);
          }
        }
      }
    };
    fetchUserProfile();
  }, [currentUser]);

  const renderPostAsDropdown = () => (
    <div className="createUserProfile" ref={dropdownRef}>
      <img
        src={userProfile.profileImage}
        alt="Profile"
        className="createUserPfp"
      />
      <div
        onClick={togglePostAsDropdown}
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        <span className="createUserName">
          {postAs === "user" ? userProfile.username : userTeam}
        </span>
        <ChevronDownIcon
          style={{
            width: "20px",
            height: "20px",
            marginLeft: "5px",
            color: "white",
            marginTop: "-10px",
          }}
        />
      </div>
      {showPostAsDropdown && (
        <div
          className="postAsDropdown"
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            backgroundColor: "#2a2a2a",
            borderRadius: "5px",
            boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
            zIndex: 1000,
          }}
        >
          <div
            onClick={() => selectPostAs("user")}
            style={{
              padding: "10px",
              cursor: "pointer",
              hover: { backgroundColor: "#3a3a3a" },
              color: "white",
            }}
          >
            Post as {userProfile.username}
          </div>
          {userTeam && (
            <div
              onClick={() => selectPostAs("team")}
              style={{
                padding: "10px",
                cursor: "pointer",
                hover: { backgroundColor: "#3a3a3a" },
                color: "white",
              }}
            >
              Post as {userTeam}
            </div>
          )}
        </div>
      )}
    </div>
  );

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 769);
      setIsWideDesktop(window.innerWidth >= 1580);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Add this new useEffect
  useEffect(() => {
    if (isDesktop) {
      loadUserRegionTournaments();
    }
  }, [isDesktop]);

  const loadUserRegionTournaments = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userRegion = userDoc.data().region;
          fetchUserRegionEvents(userRegion, 1);
        } else {
          console.error("User document does not exist");
        }
      } else {
        console.error("No user is logged in");
      }
    } catch (error) {
      console.error("Error fetching user region:", error);
    }
  };

  const handleTagUser = (username) => {
    setTaggedUsers([...taggedUsers, username]);
  };

  const toggleInfoBox = () => {
    setShowInfoBox(!showInfoBox);
  };

  const handleClickOutside = (event) => {
    if (
      event.target.closest(".infoIcon") === null &&
      event.target.closest(".infoBox span") === null &&
      showInfoBox
    ) {
      setShowInfoBox(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowPostAsDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const togglePostAsDropdown = () => {
    setShowPostAsDropdown(!showPostAsDropdown);
  };

  const selectPostAs = (option) => {
    setPostAs(option);
    setShowPostAsDropdown(false);
  };

  const handleDropdownToggle = (postId) => {
    setOpenDropdownId(openDropdownId === postId ? null : postId);
  };

  const handleSharePost = async (postId, content) => {
    const postUrl = `https://vexvibe.com/posts/${postId}`;

    if (window.Capacitor && window.Capacitor.isNative) {
      // Running on a mobile app with Capacitor
      try {
        await Share.share({
          title: "Check out this post on VexVibe!",
          text: content,
          url: postUrl,
          dialogTitle: "Share with",
        });
      } catch (error) {
        console.error("Error sharing the post:", error);
      }
    } else if (navigator.share) {
      // Running on a website with Web Share API support
      try {
        await navigator.share({
          title: "Check out this post on VexVibe!",
          text: content,
          url: postUrl,
        });
      } catch (error) {
        console.error("Error sharing the post:", error);
      }
    } else {
      // Fallback for unsupported browsers or platforms
    }
  };

  const [sortedPosts, setSortedPosts] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // Fetch posts with a simplified algorithm for 20 users
  const fetchPosts = async (initialLoad = false) => {
    setIsLoading(true);
    try {
      let postsQuery;
      if (initialLoad) {
        postsQuery = query(
          collection(firestore, "posts"),
          orderBy("createdAt", "desc"),
          limit(15)
        );
        console.log("Initial load query");
      } else {
        if (!lastDoc) {
          console.error("No last document found for subsequent load");
          return;
        }

        postsQuery = query(
          collection(firestore, "posts"),
          orderBy("createdAt", "desc"),
          startAfter(lastDoc),
          limit(10)
        );
        console.log("Subsequent load query");
      }

      const querySnapshot = await getDocs(postsQuery);

      if (!querySnapshot.empty) {
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      } else {
        setPostHasMore(false);
      }

      const newPosts = await Promise.all(
        querySnapshot.docs.map(async (docSnapshot) => {
          const postData = docSnapshot.data();

          // Skip posts by the current user
          if (postData.userId === currentUser.uid) {
            return null;
          }

          if (!postData.userId && !postData.teamId) {
            console.error(
              "No userId or teamId found for post:",
              docSnapshot.id
            );
            return null; // Skip this post if userId and teamId are missing
          }

          try {
            let userData;
            if (postData.teamId) {
              // Fetch team data
              const teamDocRef = doc(firestore, "teams", postData.teamId);
              const teamDocSnap = await getDoc(teamDocRef);
              if (!teamDocSnap.exists()) {
                console.error("No such document for teamId:", postData.teamId);
                return null; // Skip this post if team document doesn't exist
              }
              userData = teamDocSnap.data();
              userData.username = userData.team_name; // Use team name as username
            } else {
              // Fetch user data
              const userDocRef = doc(firestore, "users", postData.userId);
              const userDocSnap = await getDoc(userDocRef);
              if (!userDocSnap.exists()) {
                console.error("No such document for userId:", postData.userId);
                return null; // Skip this post if user document doesn't exist
              }
              userData = userDocSnap.data();
            }

            // Check if the current user has liked the post
            const likeDocRef = doc(
              firestore,
              `posts/${docSnapshot.id}/likes/${currentUser.uid}`
            );
            const likeDocSnap = await getDoc(likeDocRef);
            const isLiked = likeDocSnap.exists();

            // Calculate Recency Score with balanced emphasis
            const now = new Date();
            const postDate = postData.createdAt.toDate();
            const timeDiff = now - postDate; // Time difference in milliseconds
            const daysDiff = timeDiff / (1000 * 60 * 60 * 24); // Convert to days
            const recencyScore = daysDiff < 1 ? 30 : daysDiff < 7 ? 20 : 10;

            // Calculate Engagement Score
            const likesQuery = collection(
              firestore,
              `posts/${docSnapshot.id}/likes`
            );
            const likesSnapshot = await getDocs(likesQuery);
            const likeCount = likesSnapshot.docs.length;
            const engagementScore = likeCount * 2; // Weighted engagement score

            // Calculate User Affinity Score
            const isFollowing = (
              await getDoc(
                doc(
                  firestore,
                  `users/${currentUser.uid}/following/${postData.userId}`
                )
              )
            ).exists();
            const userAffinityScore = isFollowing ? 3 : 1;

            const commentsRef = collection(
              firestore,
              `posts/${docSnapshot.id}/comments`
            );
            const commentsSnapshot = await getDocs(commentsRef);

            let totalCount = commentsSnapshot.docs.length;

            await Promise.all(
              commentsSnapshot.docs.map(async (commentDoc) => {
                const repliesRef = collection(
                  firestore,
                  `posts/${docSnapshot.id}/comments/${commentDoc.id}/replies`
                );
                const repliesSnapshot = await getDocs(query(repliesRef));
                totalCount += repliesSnapshot.docs.length;
              })
            );
            // Check if the post has been viewed by the current user
            const viewedRef = doc(
              firestore,
              `posts/${docSnapshot.id}/viewedBy/${currentUser.uid}`
            );
            const viewedSnap = await getDoc(viewedRef);
            const hasViewed = viewedSnap.exists() && viewedSnap.data().viewed;

            // Adjust score for viewed posts
            const viewPenalty = hasViewed ? -3 : 0;

            // Calculate total score with balanced recency and engagement
            const totalScore =
              recencyScore + engagementScore + userAffinityScore + viewPenalty;

            return {
              id: docSnapshot.id,
              totalScore,
              username: userData.username,
              profileImage: userData.profileImage || defaultImage,
              isLiked,
              ...postData,
              likeCount,
              commentCount: totalCount,
            };
          } catch (error) {
            console.error(
              "Error fetching user data for post:",
              docSnapshot.id,
              error
            );
            return null; // Skip this post on error fetching user data
          }
        })
      );

      // Filter out null entries before sorting
      const validPosts = newPosts.filter((post) => post !== null);
      // Sort posts by totalScore in descending order
      validPosts.sort((a, b) => b.totalScore - a.totalScore);

      setSortedPosts((prevPosts) => {
        const newPostIds = new Set(validPosts.map((post) => post.id));
        const uniquePrevPosts = prevPosts.filter(
          (post) => !newPostIds.has(post.id)
        );
        return [...uniquePrevPosts, ...validPosts];
      });
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setIsInitialLoad(false);
      setIsLoading(false);
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 900 &&
        postHasMore &&
        !isLoading
      ) {
        fetchPosts();
      }
    }
  };

  useEffect(() => {
    fetchPosts(true); // Initial load
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [hasMore, isLoading]);

  // Authentication check
  useEffect(() => {
    if (currentUser === undefined) {
      // Wait for the auth state to be determined
      return;
    }
    if (!currentUser) {
      navigate("/welcome");
    }
  }, [currentUser, navigate]);

  // Fetch username by user ID
  const fetchUsernameByUserId = async (userId) => {
    const usernameDocRef = doc(firestore, "usernames", userId);
    const docSnap = await getDoc(usernameDocRef);
    if (docSnap.exists()) {
      // Adjust according to how the username is stored
      return docSnap.data().username;
    } else {
      return "Unknown User";
    }
  };

  const updateLikeCount = async (postId, increment) => {
    const postRef = doc(firestore, "posts", postId);

    try {
      await runTransaction(firestore, async (transaction) => {
        const postDoc = await transaction.get(postRef);
        if (!postDoc.exists()) {
          throw "Document does not exist!";
        }

        const newLikeCount = (postDoc.data().likeCount || 0) + increment;
        // Prevent negative like counts
        if (newLikeCount >= 0) {
          transaction.update(postRef, { likeCount: newLikeCount });
        }
      });
    } catch (e) {
      // Handle transaction failure
    }
  };

  const handleLikeClick = async (postId, isLiked) => {
    if (!currentUser) {
      return;
    }

    // Optimistically update the UI
    setSortedPosts((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? {
              ...post,
              isLiked: !isLiked,
              likeCount: isLiked ? post.likeCount - 1 : post.likeCount + 1,
            }
          : post
      )
    );

    try {
      const postRef = doc(firestore, "posts", postId);
      const likeRef = doc(
        firestore,
        `posts/${postId}/likes/${currentUser.uid}`
      );

      await runTransaction(firestore, async (transaction) => {
        const postSnapshot = await transaction.get(postRef);
        if (!postSnapshot.exists()) {
          throw new Error("Post does not exist!");
        }

        const postData = postSnapshot.data();
        const likeSnapshot = await transaction.get(likeRef);
        let newLikeCount = postData.likeCount || 0;

        if (isLiked && likeSnapshot.exists()) {
          // Unlike the post
          transaction.delete(likeRef);
          newLikeCount = Math.max(0, newLikeCount - 1);
        } else if (!isLiked && !likeSnapshot.exists()) {
          // Like the post
          transaction.set(likeRef, {
            userId: currentUser.uid,
            createdAt: serverTimestamp(),
          });
          newLikeCount += 1;

          // Create notification only for user posts, not team posts
          if (postData.userId && !postData.teamId) {
            const notificationRef = collection(firestore, "notifications");
            transaction.set(doc(notificationRef), {
              type: "like",
              content: `${
                currentUser.displayName || "Someone"
              } liked your post`,
              recipientId: postData.userId,
              senderId: currentUser.uid,
              postId: postId,
              createdAt: serverTimestamp(),
            });
          }
        }

        transaction.update(postRef, { likeCount: newLikeCount });
      });
    } catch (error) {
      console.error("Error toggling like: ", error);
      // Revert the optimistic UI update in case of an error
      setSortedPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId
            ? {
                ...post,
                isLiked: isLiked,
                likeCount: isLiked ? post.likeCount + 1 : post.likeCount - 1,
              }
            : post
        )
      );
    }
  };

  const addLikeToPost = async (postId) => {
    const likeRef = doc(firestore, `posts/${postId}/likes/${currentUser.uid}`);
    try {
      await setDoc(likeRef, {
        userId: currentUser.uid,
        createdAt: serverTimestamp(),
      });
      // No need to manually increment likeCount here, as it will be calculated dynamically when fetching posts
    } catch (error) {
      console.error("Error adding like: ", error);
    }
  };

  const removeLikeFromPost = async (postId) => {
    const likeRef = doc(firestore, `posts/${postId}/likes/${currentUser.uid}`);
    try {
      await deleteDoc(likeRef);
      // No need to manually decrement likeCount here, as it will be calculated dynamically when fetching posts
    } catch (error) {
      console.error("Error removing like: ", error);
    }
  };

  // Effects
  // Adjust text area height based on content
  useEffect(() => {
    if (textAreaRef.current) {
      setTextAreaHeight(`${textAreaRef.current.scrollHeight}px`);
    }
  }, [postContent]);

  // Adjust icon offset based on text area content
  useEffect(() => {
    if (textAreaRef.current) {
      const dynamicHeight = textAreaRef.current.scrollHeight;
      setIconOffset(-40 + dynamicHeight - initialTextAreaHeight.current);
    }
  }, [postContent]);

  // Adjust icon position on resize
  useEffect(() => {
    if (textAreaRef.current && isCreatePostOpen) {
      const handleResize = () => {
        const newTopPosition =
          textAreaRef.current.offsetTop +
          textAreaRef.current.scrollHeight +
          -125;
        if (iconRef.current) {
          iconRef.current.style.top = `${newTopPosition}px`;
        }
      };

      textAreaRef.current.addEventListener("input", handleResize);

      return () => {
        if (textAreaRef.current) {
          textAreaRef.current.removeEventListener("input", handleResize);
        }
      };
    }
  }, [isCreatePostOpen]);

  // Fetch user profile data
  useEffect(() => {
    if (currentUser) {
      // Assuming currentUser.uid is the correct ID to use
      const userRef = doc(firestore, "users", currentUser.uid);
      getDoc(userRef).then(async (docSnap) => {
        if (docSnap.exists()) {
          const userData = docSnap.data();
          let profileImageUrl = defaultImage;
          if (userData.profileImage) {
            const imageRef = ref(storage, userData.profileImage);
            profileImageUrl = await getDownloadURL(imageRef).catch(
              () => defaultImage
            );
          }
          setUserProfile({
            username: userData.username,
            profileImage: profileImageUrl,
          });
        }
      });
    }
  }, [currentUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [blockedUserIds, setBlockedUserIds] = useState([]);

  useEffect(() => {
    const fetchBlockedUsers = async () => {
      if (currentUser) {
        const blockedUsersRef = collection(
          firestore,
          `users/${currentUser.uid}/blockedUsers`
        );
        const snapshot = await getDocs(blockedUsersRef);
        const userIds = snapshot.docs.map((doc) => doc.id);
        setBlockedUserIds(userIds);
      }
    };

    fetchBlockedUsers();
  }, [currentUser]);

  const handleBlockUser = async (userId) => {
    if (!currentUser) return;

    const confirmBlock = window.confirm(
      "Are you sure you want to block this user?"
    );
    if (!confirmBlock) return;

    const currentUserId = currentUser.uid;
    const blockedUserRef = doc(
      firestore,
      `users/${currentUserId}/blockedUsers/${userId}`
    );

    try {
      await setDoc(blockedUserRef, { blocked: true });
      setBlockedUserIds((prev) => [...prev, userId]);
      setPosts((prevPosts) =>
        prevPosts.filter((post) => post.userId !== userId)
      );
    } catch (error) {
      console.error("Error blocking user:", error);
    }
  };

  const handleReportUser = (userId) => {
    const confirmReport = window.confirm(
      "Are you sure you want to report this user?"
    );
    if (confirmReport) {
      // Add your report logic here
      console.log(`User ${userId} reported.`);
    }
  };

  // // // Fetch posts
  // useEffect(() => {
  //   const fetchPosts = async () => {
  //     if (!currentUser) {
  //       console.error(
  //         "No current user found. User must be logged in to fetch posts."
  //       );
  //       return;
  //     }
  //     setIsLoading(true);
  //     try {
  //       const postsQuery = query(
  //         collection(firestore, "posts"),
  //         orderBy("createdAt", "desc")
  //       );
  //       const querySnapshot = await getDocs(postsQuery);
  //       const postsData = await Promise.all(
  //         querySnapshot.docs.map(async (docSnapshot) => {
  //           const postData = docSnapshot.data();
  //           // Skip posts by the current user
  //           if (postData.userId === currentUser.uid) {
  //             return null;
  //           }
  //           if (!postData.userId) {
  //             console.error("No userId found for post:", docSnapshot.id);
  //             return null; // Skip this post if userId is missing
  //           }
  //           try {
  //             const userDocRef = doc(firestore, "users", postData.userId);
  //             const userDocSnap = await getDoc(userDocRef);
  //             if (!userDocSnap.exists()) {
  //               console.error("No such document for userId:", postData.userId);
  //               return null; // Skip this post if user document doesn't exist
  //             }
  //             const userData = userDocSnap.data();

  //             // Check if the current user has liked the post
  //             const likeDocRef = doc(
  //               firestore,
  //               `posts/${docSnapshot.id}/likes/${currentUser.uid}`
  //             );
  //             const likeDocSnap = await getDoc(likeDocRef);
  //             const isLiked = likeDocSnap.exists();

  //             return {
  //               id: docSnapshot.id,
  //               username: userData.username,
  //               profileImage: userData.profileImage || defaultImage,
  //               isLiked,
  //               ...postData,
  //             };
  //           } catch (error) {
  //             console.error(
  //               "Error fetching user data for post:",
  //               docSnapshot.id,
  //               error
  //             );
  //             return null; // Skip this post on error fetching user data
  //           }
  //         })
  //       );
  //       setPosts(postsData.filter((post) => post !== null)); // Filter out null entries
  //     } catch (error) {
  //       console.error("Error fetching posts:", error);
  //       setErrorState(true);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   if (currentUser) {
  //     fetchPosts().catch(console.error);
  //   }
  // }, [currentUser, firestore]);

  useEffect(() => {
    const checkUserExists = async () => {
      if (currentUser) {
        const userRef = doc(firestore, "users", currentUser.uid);
        const docSnap = await getDoc(userRef);
        if (!docSnap.exists()) {
          // If user document does not exist, navigate to /welcome
          navigate("/welcome");
        }
        // If user exists, you can continue with any other logic as needed
      } else {
        // If currentUser is not set, navigate to /welcome
        navigate("/welcome");
      }
    };

    checkUserExists().catch(console.error);
  }, [currentUser, navigate]);

  // Handlers
  // Toggle create post overlay
  const toggleCreatePost = () => {
    setIsCreatePostOpen(!isCreatePostOpen);
    setIsOverlayOpen(!isOverlayOpen);
  };

  // Handle image upload
  const handleMediaUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file size exceeds 50MB (50 * 1024 * 1024 bytes)
      if (file.size > 50 * 1024 * 1024) {
        alert("File size should not exceed 50MB.");
        return; // Stop the function if the file is too large
      }

      if (file.type.startsWith("image/")) {
        // Compression options for images
        const options = {
          maxSizeMB: 3, // (Maximum file size in MB after compression, adjust as needed)
          maxWidthOrHeight: 1920, // (Compressed image's maximum dimension in pixels, adjust as needed)
          useWebWorker: true, // (Use multi-threading for better performance)
        };

        try {
          const compressedFile = await imageCompression(file, options);
          setSelectedImage(compressedFile); // Set the compressed image for upload

          const reader = new FileReader();
          reader.onloadend = () => {
            setImagePreviewUrl(reader.result); // Display image preview
          };
          reader.readAsDataURL(compressedFile);
        } catch (error) {
          console.error("Error during image compression:", error);
          alert("Could not compress the image.");
        }
      } else if (file.type.startsWith("video/")) {
        setSelectedImage(file); // Set the video for upload

        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreviewUrl(reader.result); // Display video preview
        };
        reader.readAsDataURL(file);
      }
    }
  };

  //123
  // Handle post submission
  const handlePost = async () => {
    const currentTime = Date.now();
    const timeSinceLastPost = currentTime - lastPostTime;

    if (timeSinceLastPost < 10000) {
      alert("Please wait 10 seconds before posting again.");
      return;
    }

    if (isPosting) {
      return; // Prevent further posts if already posting
    }

    setIsPosting(true); // Disable the post button immediately

    setTaggedUsers(
      postContent.match(/@(\w+)/g)?.map((tag) => tag.slice(1)) || []
    );
    const processedContent = postContent.replace(/ {3,}/g, "  ").trim();

    if (
      processedContent.length <= 180 &&
      (processedContent.length > 0 || selectedImage)
    ) {
      setLastPostTime(currentTime); // Update the last post time to prevent spamming

      let imageUrl = null;
      let mediaType = null;

      if (selectedImage) {
        const uniqueFilename = `${currentUser.uid}/${Date.now()}-${
          selectedImage.name
        }`;
        const storageRef = ref(storage, `posts/${uniqueFilename}`);
        try {
          const snapshot = await uploadBytes(storageRef, selectedImage);
          imageUrl = await getDownloadURL(snapshot.ref);
          mediaType = selectedImage.type.startsWith("image/")
            ? "image"
            : "video";
        } catch (error) {
          setIsPosting(false); // Re-enable the post button
          return;
        }
      }

      try {
        const postData = {
          content: processedContent,
          createdAt: serverTimestamp(),
          taggedUsers: taggedUsers,
          likeCount: 0,
          ...(imageUrl && { imageUrl }),
          ...(mediaType && { mediaType }),
        };

        if (postAs === "user") {
          postData.userId = currentUser.uid;
        } else {
          postData.teamId = userTeam;
        }

        await addDoc(collection(firestore, "posts"), postData);

        setPostContent("");
        setSelectedImage(null);
        setImagePreviewUrl(null);
        setIsCreatePostOpen(false);
        setTaggedUsers([]);
        setIsPosted(true); // Optional: Show confirmation
      } catch (error) {
        console.error("Error adding post: ", error);
      } finally {
        setIsPosting(false); // Re-enable the post button
      }
    } else if (processedContent.length === 0) {
      console.error("Post content cannot be empty.");
      setIsPosting(false); // Re-enable the post button
    } else if (processedContent.length > 180) {
      console.error("Post content exceeds the 180 character limit.");
      setIsPosting(false); // Re-enable the post button
    }
  };

  // Define the styles for tabs
  const tabStyle = {
    display: "flex",
    padding: "8px 20px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flex: "1 0 0",
    borderRadius: "100px",
    cursor: "pointer",
    color: "#fff",
    fontFamily: "Manrope",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "160%",
    letterSpacing: "0.12px",
    transition: "background-color 0.3s ease",
  };

  const activeTabStyle = {
    ...tabStyle,
    background: "#101828",
  };

  // State for managing active tab
  const [activeTab, setActiveTab] = useState("myRegion");

  // Handler for tab clicks
  const handleTabClick = async (tab) => {
    setActiveTab(tab);
    setPage(1);
    setHasMore(true);
    setEvents([]);

    if (tab === "myRegion") {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userRegion = userDoc.data().region;
            fetchUserRegionEvents(userRegion, 1);
          } else {
            console.error("User document does not exist");
          }
        } else {
          console.error("No user is logged in");
        }
      } catch (error) {
        console.error("Error fetching user region:", error);
      }
    } else if (tab === "all") {
      fetchAllWorldwideEvents(1);
    }
  };

  const fetchAllWorldwideEvents = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://www.robotevents.com/api/v2/events`,
        {
          params: {
            season: seasonId,
            eventTypes: ["tournament"],
            page: page,
          },
          headers: {
            Authorization: `Bearer ${apiKey}`,
            accept: "application/json",
          },
        }
      );
      const sortedAllEvents = response.data.data.sort(
        (a, b) => new Date(a.start) - new Date(b.start)
      );
      if (sortedAllEvents.length === 0) {
        setHasMore(false);
      } else {
        setEvents((prevEvents) => [...prevEvents, ...sortedAllEvents]);
      }
    } catch (error) {
      console.error("Failed to fetch worldwide events:", error);
      setEvents([]);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchEvents = async () => {
    if (!eventHasMore || loading) return;

    setLoading(true);
    try {
      const response = await axios.get(`https://api.example.com/events`, {
        params: { page },
        headers: { Authorization: `Bearer your-api-key` },
      });
      const newEvents = response.data.events;
      setEvents((prevEvents) => [...prevEvents, ...newEvents]);
      setEventHasMore(newEvents.length > 0);
    } catch (error) {
      console.error("Failed to fetch events:", error);
    }
    setLoading(false);
  };

  const handlePostClick = (postId) => {
    sessionStorage.setItem("scrollPosition", scrollPosition);
    navigate(`/posts/${postId}`);
  };

  const handleProfileClick = (teamId, username) => {
    sessionStorage.setItem("scrollPosition", scrollPosition);
    const encodedUsername = encodeURIComponent(username);
    navigate(
      teamId ? `/teams/${encodedUsername}` : `/users/${encodedUsername}`
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(containerRef.current.scrollTop);
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // useEffect(() => {
  //   if (!isLoading && containerRef.current) {
  //     const savedPosition = sessionStorage.getItem("scrollPosition");
  //     setTimeout(() => {
  //       if (savedPosition) {
  //         containerRef.current.scrollTop = parseInt(savedPosition, 0);
  //         sessionStorage.removeItem("scrollPosition");
  //       }
  //     }, 1000);
  //   }
  // }, [isLoading]);

  useEffect(() => {
    fetchEvents();
  }, [page]);

  const handleCardClick = (id) => {
    sessionStorage.setItem("scrollPosition", scrollPosition);
    navigate(`/events/${id}`);
  };

  const apiKey =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiOTlmNmYyMzYwNTY0ZTY1NmJiODlkNTUwNmYzZjRiMmMxNDA0M2E2YjZlMWVkNWRmODVkNThjYzkzOWFiYzk0YjIyYTYwYjA4MzcyNTZmNjYiLCJpYXQiOjE3MTY2NzM2MTguMTM0NTE0MSwibmJmIjoxNzE2NjczNjE4LjEzNDUxNywiZXhwIjoyNjYzMzYyMDE4LjEyODY2NSwic3ViIjoiMTI3Nzg2Iiwic2NvcGVzIjpbXX0.jhOKVtQQI1acAwIRrAR78xw7l__XHewZzJDYBf3OMzHZ4zsIh_6jWG1ulswGCN6128v_dwPji6s8JmVQj_tmcn-KyDLQACkY8ZJHQXZLIuTSrNGXFKE-DLqFKO9an04kmZoMKtZay86bCuSMiHoJGkd9WctvUdAkjzNdsWBz8lwD48hLY5h2F5U55YhX2areEoPyioOsN1QHe9ExoXOk8fW88vpgvw7_aZnmhTp5BV6WICnIlP1PCLWxUI_EwI8X32QJ1PJhOUFJ6eEb7iM2KPT3jlJmJBy675wDCix84H11jSUCJs8F-LIXRERVERniWElTWp9tIJYRk1HRQN4bhCEnuzL8U01oO49Ghc-k9vYQnY3WlHwAJEGuPQtQt8NttI2DcBDcBM3acQXHHHfvKvk6pc1__bwM32W41Ealqku1dL5Jw8MNUCFqAthDd--mjtkyevuGJsLwu1NobdI94wNDHna8iuRg27XwRGXJKGy32scGsmeBnplhEdOBbVu44Ns1MGJuxmLLffICQWVdEl4Gwth63r4AzBiX__iMYwJw39MfWpiyYrDZRX7Z6n12m2WeeZqrdmRAuSyzVSeZIqFs6TgqbgAuFOMo68aN_6InrbMD62PvXhdH8CL6wR05_v_Gz10OJtAWdwboXB780tdd7X5mdP2KcBZHbqvSbl4"; // Replace with your actual API key
  const seasonId = "190";

  const fetchUserRegionEvents = async (region, page) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://www.robotevents.com/api/v2/events`,
        {
          params: {
            season: seasonId,
            region: region,
            eventType: "tournament",
            page: page,
          },
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      const sortedEvents = response.data.data.sort(
        (a, b) => new Date(a.start) - new Date(b.start)
      );
      if (sortedEvents.length === 0) {
        setHasMore(false);
      } else {
        setEvents((prevEvents) => [...prevEvents, ...sortedEvents]);
      }
    } catch (error) {
      console.error("Failed to fetch events:", error);
      setEvents([]);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   console.log(isLoading);
  // }, [events]);

  const [showWelcome, setShowWelcome] = useState(false);

  useEffect(() => {
    const checkWelcomeStatus = async () => {
      if (currentUser) {
        const userRef = doc(firestore, "users", currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (!userData.hasSeenWelcome) {
            setShowWelcome(true);
          }
        }
      }
    };

    checkWelcomeStatus();
  }, [currentUser]);

  const handleCloseWelcome = async () => {
    setShowWelcome(false);
    if (currentUser) {
      const userRef = doc(firestore, "users", currentUser.uid);
      await setDoc(userRef, { hasSeenWelcome: true }, { merge: true });
    }
  };

  if (isDesktop) {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "#121516",
          minHeight: "100vh",
        }}
      >
        {/* Left Sidebar */}
        <div
          style={{
            width: "350px",
            position: "fixed",
            left: 0,
            top: 0,
            height: "100vh",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "12px",
              left: "12px",
              animation: "fadeInUp 1s ease-in-out",
            }}
          >
            <Sidebar
              activeTab={activeTab}
              handleTabClick={handleTabClick}
              events={events}
              loading={loading}
              handleCardClick={handleCardClick}
            />
          </div>
        </div>

        {/* Main Content */}

        <div
          ref={containerRef}
          style={{
            flex: 1,
            padding: "20px",
            marginLeft: isWideDesktop ? "auto" : "350px",
            marginRight: isWideDesktop ? "auto" : "0",
            maxWidth: isWideDesktop ? "800px" : "75%",
            width: isWideDesktop ? "800px" : "75%",
            overflowY: "auto",
            height: "100vh",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            position: "relative", // Add this to make the post icon positioning relative to this div
          }}
        >
          {/* Add post icon */}
          <img
            src={postIcon}
            alt="Post"
            onClick={toggleCreatePost}
            style={{
              position: "absolute",
              top: "20px",
              left: "0px",
              width: "30px",
              height: "30px",
              cursor: "pointer",
              zIndex: 1000,
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  alignItems: "center",
                  marginLeft: "0px",
                  overflow: "hidden",
                }}
              >
                <div
                  className="postsDisplay"
                  style={{ overflow: "hidden", width: "100%" }}
                >
                  {isInitialLoad
                    ? Array.from({ length: 10 }).map((_, index) => (
                        <DesktopPostSkeleton key={index} />
                      ))
                    : sortedPosts.map((post) => (
                        <div
                          key={post.id}
                          style={{
                            background: "rgba(37, 37, 37, 0.7)",
                            backdropFilter: "blur(10px)",
                            WebkitBackdropFilter: "blur(10px)",
                            borderRadius: "16px",
                            border: "1px solid rgba(255, 255, 255, 0.1)",
                            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                            padding: "20px",
                            marginBottom: "20px",
                            transition: "all 0.3s ease",
                            animation: "fadeInUp 0.3s ease-in-out",
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.background =
                              "rgba(37, 37, 37, 0.8)";
                            e.currentTarget.style.transform =
                              "translateY(-2px)";
                            e.currentTarget.style.boxShadow =
                              "0 6px 35px rgba(0, 0, 0, 0.2)";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.background =
                              "rgba(37, 37, 37, 0.7)";
                            e.currentTarget.style.transform = "translateY(0)";
                            e.currentTarget.style.boxShadow =
                              "0 4px 30px rgba(0, 0, 0, 0.1)";
                          }}
                        >
                          <div
                            className="postHeader"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <img
                              src={post.profileImage || defaultImage}
                              alt="Profile"
                              className="postProfileImage"
                              onClick={() =>
                                handleProfileClick(post.teamId, post.username)
                              }
                              style={{
                                cursor: "pointer",
                                borderRadius: "50%",
                                width: "50px",
                                height: "50px",
                                marginRight: "10px",
                                objectFit: "cover",
                              }}
                            />
                            <div style={{ flex: 1 }}>
                              <span
                                className="postUsername"
                                style={{
                                  fontWeight: "bold",
                                  color: "#fff",
                                  marginLeft: "0px",
                                  marginTop: "-10px",
                                }}
                              >
                                {post.username}
                              </span>
                              <span
                                style={{
                                  display: "block",
                                  fontSize: "12px",
                                  color: "grey",
                                  marginTop: "5px",
                                }}
                              >
                                {new Date(
                                  post.createdAt.seconds * 1000
                                ).toLocaleString("en-US", {
                                  month: "long",
                                  day: "numeric",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                })}
                              </span>
                            </div>
                            <img
                              src={threeDotIcon}
                              alt="Options"
                              className="postOptionsIcon"
                              onClick={() => handleDropdownToggle(post.id)}
                              style={{ cursor: "pointer" }}
                            />
                            {openDropdownId === post.id && (
                              <div
                                className="postDropdown"
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "40px",
                                  background: "#333",
                                  borderRadius: "5px",
                                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                                }}
                              >
                                <button
                                  className="postDropdownButton"
                                  onClick={() => handleBlockUser(post.userId)}
                                  style={{
                                    display: "block",
                                    padding: "10px",
                                    color: "#fff",
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                    textAlign: "left",
                                    width: "100%",
                                  }}
                                >
                                  Block
                                </button>
                                <button
                                  className="postDropdownButton"
                                  onClick={() => handleReportUser(post.userId)}
                                  style={{
                                    display: "block",
                                    padding: "10px",
                                    color: "#fff",
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                    textAlign: "left",
                                    width: "100%",
                                  }}
                                >
                                  Report
                                </button>
                              </div>
                            )}
                          </div>
                          <div
                            className="postContent"
                            onClick={() => handlePostClick(post.id)}
                            style={{
                              cursor: "pointer",
                              color: "#fff",
                              marginBottom: "10px",
                              marginLeft: "60px",
                            }}
                          >
                            {renderPostContent(post.content)}
                          </div>
                          {post.imageUrl && (
                            <div
                              style={{
                                marginTop: "10px",
                                cursor: "pointer",
                                paddingRight: "10px",
                              }}
                              onClick={() => handlePostClick(post.id)}
                            >
                              {post.mediaType === "video" ? (
                                <Suspense
                                  fallback={<div>Loading video...</div>}
                                >
                                  <LazyVideo src={post.imageUrl} />
                                </Suspense>
                              ) : (
                                <img
                                  src={post.imageUrl}
                                  alt="Post"
                                  style={{
                                    maxWidth: "80%",
                                    maxHeight: "1000px",
                                    display: "block",
                                    marginLeft: "60px",
                                    marginRight: "auto",
                                    borderRadius: "10px",
                                    marginBottom: "20px",
                                    objectFit: "contain",
                                  }}
                                  loading="lazy"
                                />
                              )}
                            </div>
                          )}
                          <div
                            className="likeSection"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "60px",
                            }}
                          >
                            <img
                              src={post.isLiked ? likeIcon : unlikeIcon}
                              alt={post.isLiked ? "Unlike" : "Like"}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleLikeClick(post.id, post.isLiked).catch(
                                  console.error
                                );
                              }}
                              style={{
                                cursor: "pointer",
                                width: "27px",
                                height: "26px",
                                marginRight: "10px",
                              }}
                            />
                            <span
                              className="likeCount"
                              style={{ color: "#fff", marginRight: "15px" }}
                            >
                              {post.likeCount}
                            </span>
                            <img
                              src={commentIcon}
                              alt="Comment"
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "10px",
                              }}
                            />
                            <span
                              className="likeCount"
                              style={{ color: "#fff", marginRight: "15px" }}
                            >
                              {post.commentCount}
                            </span>
                            <img
                              src={sendIcon}
                              alt="Send"
                              onClick={() =>
                                handleSharePost(post.id, post.content)
                              }
                              style={{
                                cursor: "pointer",
                                width: "26px",
                                height: "26px",
                              }}
                            />
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {isCreatePostOpen && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 2000,
            }}
          >
            <div
              style={{
                backgroundColor: "#1d2021",
                borderRadius: "20px",
                padding: "30px",
                width: "500px",
                maxWidth: "90%",
                maxHeight: "90vh",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <img
                src={closeIcon}
                alt="Close"
                onClick={toggleCreatePost}
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                }}
              />
              <h2
                style={{
                  color: "#fff",
                  marginBottom: "20px",
                  marginTop: "0px",
                }}
              >
                New Post
              </h2>
              <div
                className="createUserProfile"
                ref={dropdownRef}
                style={{ position: "relative" }}
              >
                <img
                  src={userProfile.profileImage}
                  alt="Profile"
                  className="createUserPfp"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    marginRight: "10px",
                  }}
                />
                <div
                  onClick={togglePostAsDropdown}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    left: "50px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <span
                    className="createUserName"
                    style={{ color: "#fff", marginRight: "5px" }}
                  >
                    {postAs === "user" ? userProfile.username : userTeam}
                  </span>
                  <ChevronDownIcon
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "white",
                    }}
                  />
                </div>
                {showPostAsDropdown && (
                  <div
                    className="postAsDropdown"
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: "50px",
                      backgroundColor: "#2a2a2a",
                      borderRadius: "5px",
                      boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
                      zIndex: 1000,
                    }}
                  >
                    <div
                      onClick={() => selectPostAs("user")}
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        color: "white",
                      }}
                    >
                      Post as {userProfile.username}
                    </div>
                    {userTeam && (
                      <div
                        onClick={() => selectPostAs("team")}
                        style={{
                          padding: "10px",
                          cursor: "pointer",
                          color: "white",
                        }}
                      >
                        Post as {userTeam}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <textarea
                ref={textAreaRef}
                value={postContent}
                onChange={handlePostContentChange}
                style={{
                  width: "100%",
                  minHeight: "100px",
                  backgroundColor: "#2a2a2a",
                  color: "#fff",
                  border: "none",
                  borderRadius: "10px",
                  padding: "10px",
                  marginTop: "20px",
                  marginBottom: "20px",
                  resize: "vertical",
                }}
                placeholder="What's on your mind?"
              ></textarea>

              <div style={{ marginBottom: "20px" }}>
                <label
                  htmlFor="desktopMediaUpload"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={photoIcon}
                    alt="Upload"
                    style={{ width: "30px", height: "30px" }}
                  />
                </label>
                <input
                  id="desktopMediaUpload"
                  type="file"
                  accept="image/*,video/*"
                  style={{ display: "none" }}
                  onChange={handleMediaUpload}
                />
              </div>

              <UserTagInput
                onTagUser={handleTagUser}
                postContent={postContent}
                setPostContent={setPostContent}
              />

              <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                {imagePreviewUrl && (
                  <img
                    src={imagePreviewUrl}
                    alt="Preview"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "200px",
                      objectFit: "contain",
                      borderRadius: "10px",
                    }}
                  />
                )}
              </div>

              <button
                onClick={handlePost}
                disabled={isPosting}
                style={{
                  backgroundColor: "#4a90e2",
                  color: "#fff",
                  border: "none",
                  borderRadius: "20px",
                  padding: "10px 20px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Post
              </button>
            </div>
          </div>
        )}

        {/* Right Sidebar (Tournament) */}
        {isWideDesktop && <TournamentSidebar />}
      </div>
    );
  }

  // Component return
  return (
    <div className="homePageContainer" ref={containerRef}>
      {showWelcome && <WelcomeOverlay onClose={handleCloseWelcome} />}
      <style>{skeletonStyles}</style>
      <div
        className="chatIcon"
        onClick={() =>
          sessionStorage.setItem("scrollPosition", scrollPosition) ||
          navigate("/messages")
        }
        style={{
          position: "fixed",
          top: "15px",
          right: "15px",
          cursor: "pointer",
          zIndex: 100,
        }}
      >
        <img src={chatIcon} alt="Chat" />
      </div>
      <div
        className="notificationIcon"
        style={{
          position: "relative",
          top: "18px",
          right: "75px",
          cursor: "pointer",
          marginLeft: "425px",
          zIndex: 100,
        }}
        onClick={() => navigate("/notifications")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
          <path d="M13.73 21a2 2 0 0 1-3.46 0" />
        </svg>
        {hasNewNotifications && (
          <div
            style={{
              position: "absolute",
              top: "-5px",
              right: "-5px",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: "#1DA1F2",
            }}
          />
        )}
      </div>
      {/* Add the info box */}
      {showInfoBox && (
        <div
          className="infoBox"
          style={{
            position: "absolute",
            top: "40px",
            right: "10px",
            backgroundColor: "#121516",
            opacity: "0.95",
            color: "white",
            padding: "10px",
            borderRadius: "20px",
            fontSize: "14px",
            maxWidth: "200px",
            zIndex: 1,
            animation: "fadeIn 0.1s ease-in-out",
          }}
        >
          <p style={{ color: "lightgray" }}>
            Welcome to the VexVibe beta! We're constantly working to improve
            your experience, with new features and enhancements being added
            frequently.
          </p>
        </div>
      )}
      <img
        src={logo}
        alt="VexVibe Logo"
        style={{
          width: "60%",
          marginTop: "-15px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "25px",
          transform: "translateX(15px)", // Move right 25px
        }}
      />
      {/* <div className="logoDivider"></div> */}
      <img
        src={postIcon}
        alt="Post"
        className="postIcon"
        onClick={toggleCreatePost}
        style={{ zIndex: 100 }} // Increase z-index
      />
      {isCreatePostOpen && (
        <div className="createPostOverlay">
          <img
            src={closeIcon}
            alt="Close"
            className="closePostIcon"
            onClick={toggleCreatePost}
          />
          <div className="newPostText">New Post</div>
          {renderPostAsDropdown()}
          <textarea
            ref={textAreaRef}
            className="createPostTextfield"
            placeholder="What's on your mind?"
            value={postContent}
            onChange={handlePostContentChange}
          ></textarea>

          {showTagSuggestions && (
            <div
              className="tagSuggestions"
              style={{
                position: "absolute",
                top: `${textAreaRef.current.offsetTop + 30}px`, // Adjust as needed
                left: `${textAreaRef.current.offsetLeft}px`,
                backgroundColor: "#2a2a2a",
                borderRadius: "5px",
                boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
                zIndex: 1000,
              }}
            >
              {tagSuggestions.map((user) => (
                <div
                  key={user.id}
                  onClick={() => handleTagSelection(user.username)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                    cursor: "pointer",
                    hover: { backgroundColor: "#3a3a3a" },
                  }}
                >
                  <img
                    src={user.profileImage}
                    alt={user.username}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />
                  <span style={{ color: "white" }}>{user.username}</span>
                </div>
              ))}
            </div>
          )}

          <div style={{ position: "absolute", top: "115px", left: "30px" }}>
            <label htmlFor="mediaUpload">
              <img src={photoIcon} alt="Upload" style={{ cursor: "pointer" }} />
            </label>
            <input
              id="mediaUpload"
              type="file"
              accept="image/*,video/*"
              style={{ display: "none" }}
              onChange={handleMediaUpload}
            />
          </div>
          <UserTagInput
            onTagUser={handleTagUser}
            postContent={postContent}
            setPostContent={setPostContent}
          />
          <div className="imagePreviewArea">
            {imagePreviewUrl && (
              <img
                src={imagePreviewUrl}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                  borderRadius: "10px",
                }}
              />
            )}
          </div>
          <button
            className="createPostButton"
            onClick={handlePost}
            disabled={isPosting}
          >
            Post
          </button>
        </div>
      )}
      <div className={`loadingBar ${isPosting ? "show" : ""}`}></div>
      <div
        style={{
          position: "absolute",
          top: isRefreshing ? "50px" : "-50px",
          left: "50%",
          transform: "translateX(-50%)",
          transition: "top 0.3s ease-out",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "14px",
          color: "#888",
        }}
      >
        {isRefreshing ? "Refreshing..." : "Pull down to refresh"}
      </div>
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          height: "4px",
          backgroundColor: "#ccc",
          transformOrigin: "left",
          transform: `scaleX(${pullProgress})`,
          transition: "transform 0.2s ease-out",
        }}
      />
      <style>{fadeInUpKeyframes}</style>
      {currentUser && (
        <div className="storySection">
          <MyStory currentUser={currentUser} />
          <StoryViewer currentUser={currentUser} />
        </div>
      )}
      <div className="postsDisplay">
        {isInitialLoad
          ? Array.from({ length: 10 }).map((_, index) => (
              <PostSkeleton key={index} />
            ))
          : sortedPosts.map((post) => (
              <div key={post.id} className="post">
                <div className="postHeader">
                  <img
                    src={post.profileImage || defaultImage}
                    alt="Profile"
                    className="postProfileImage"
                    onClick={() =>
                      handleProfileClick(post.teamId, post.username)
                    }
                    style={{ cursor: "pointer" }}
                  />
                  <span className="postUsername">{post.username}</span>
                  <span
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "10px",
                      fontSize: "12px",
                      color: "grey",
                      marginTop: "10px",
                    }}
                  >
                    {new Date(post.createdAt.seconds * 1000).toLocaleString(
                      "en-US",
                      {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      }
                    )}
                  </span>
                  <img
                    src={threeDotIcon}
                    alt="Options"
                    className="postOptionsIcon"
                    onClick={() => handleDropdownToggle(post.id)}
                  />
                  {openDropdownId === post.id && (
                    <div className="postDropdown">
                      <button
                        className="postDropdownButton"
                        onClick={() => handleBlockUser(post.userId)}
                      >
                        Block
                      </button>
                      <button
                        className="postDropdownButton"
                        onClick={() => handleReportUser(post.userId)}
                      >
                        Report
                      </button>
                    </div>
                  )}
                </div>
                <div
                  className="postContent"
                  onClick={() => handlePostClick(post.id)}
                >
                  {renderPostContent(post.content)}
                </div>
                {post.imageUrl && (
                  <div
                    style={{
                      marginTop: "10px",
                      cursor: "pointer",
                      paddingRight: "10px",
                    }}
                    onClick={() => handlePostClick(post.id)}
                  >
                    {post.mediaType === "video" ? (
                      <Suspense fallback={<div>Loading video...</div>}>
                        <LazyVideo src={post.imageUrl} />
                      </Suspense>
                    ) : (
                      <img
                        src={post.imageUrl}
                        alt="Post"
                        style={{
                          maxWidth: "calc(100% - 20px)",
                          maxHeight: "90%",
                          display: "block",
                          marginLeft: "40px",
                          marginRight: "auto",
                          borderRadius: "10px",
                          marginBottom: "20px",
                          objectFit: "contain",
                        }}
                        loading="lazy"
                      />
                    )}
                  </div>
                )}
                <div className="likeSection">
                  <img
                    src={post.isLiked ? likeIcon : unlikeIcon}
                    alt={post.isLiked ? "Unlike" : "Like"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleLikeClick(post.id, post.isLiked);
                    }}
                    style={{ cursor: "pointer", width: "27px", height: "26px" }}
                  />
                  <span className="likeCount">{post.likeCount}</span>
                  <img
                    src={sendIcon}
                    alt="Send"
                    onClick={() => handleSharePost(post.id, post.content)}
                    style={{
                      cursor: "pointer",
                      width: "26px",
                      height: "26px",
                      marginLeft: "15px",
                    }}
                  />
                </div>
              </div>
            ))}
      </div>
      <Dock />
    </div>
  );
};

export default HomePage;
