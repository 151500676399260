import React from "react";

const Changelog = () => {
  const changes = [
    {
      version: "0.3.7",
      date: "July 17, 2024",
      changes: [
        {
          type: "update",
          description: "Added following to desktop",
        },
        {
          type: "update",
          description: "Fixed follow button position on mobile",
        },
        {
          type: "update",
          description: "Made loading contacts faster",
        },
        {
          type: "update",
          description: "Updated auto scroll to bottom on chat open",
        },
      ],
    },
    {
      version: "0.3.6",
      date: "July 16, 2024",
      changes: [
        {
          type: "update",
          description: "Updated posts UI on profile page",
        },
        {
          type: "fix",
          description: "Fixed scrolling on mobile",
        },
        {
          type: "fix",
          description: "Fixed a bug with posts on homepage",
        },
        {
          type: "feature",
          description: "Scroll position memory for homepage",
        },
      ],
    },
    {
      version: "0.3.5",
      date: "July 15, 2024",
      changes: [
        {
          type: "fix",
          description: "Bug fixes for team search",
        },
        {
          type: "update",
          description: "Desktop UI for team profile page",
        },
        {
          type: "update",
          description: "VibeAI Analysis",
        },
      ],
    },
    {
      version: "0.3.4",
      date: "July 14, 2024",
      changes: [
        {
          type: "feature",
          description: "Analysis page for Teams",
        },
        {
          type: "feature",
          description: "Team badge on profiles",
        },
        {
          type: "feature",
          description: "Posting on desktop",
        },
        {
          type: "feature",
          description: "Updated VibeAI for desktop",
        },
      ],
    },
    {
      version: "0.3.3",
      date: "July 13, 2024",
      changes: [
        {
          type: "feature",
          description: "Added comment counter",
        },
        {
          type: "feature",
          description: "Added timestamps to posts on profile page",
        },
        {
          type: "fix",
          description: "Bug fixes on home page",
        },
        {
          type: "fix",
          description: "Fixed UI positioning issues",
        },
        {
          type: "feature",
          description: "Added comment/reply deleting",
        },
      ],
    },
    {
      version: "0.3.2",
      date: "July 10, 2024",
      changes: [
        {
          type: "fix",
          description: "Bug fixes",
        },
        {
          type: "update",
          description: "Updated homepage UI on desktop",
        },
        {
          type: "update",
          description: "Updated the look of posts on mobile",
        },
        {
          type: "feature",
          description: "Fixed story views",
        },
        {
          type: "update",
          description: "Full desktop UI",
        },
        {
          type: "feature",
          description: "Tagging in posts",
        },
      ],
    },
    {
      version: "0.3.1",
      date: "July 5, 2024",
      changes: [
        {
          type: "feature",
          description: "Introduced VexVibe Premium at $5/month CAD",
        },
        {
          type: "feature",
          description: "VibeAI Trip Planner",
        },
        {
          type: "update",
          description: "Redesigned landing page",
        },
      ],
    },
    {
      version: "0.3.0",
      date: "July 3, 2024",
      changes: [
        {
          type: "update",
          description:
            "Completely redesigned landing page for better first impressions and user engagement",
        },
        {
          type: "improvement",
          description: "VibeAI UI update for both mobile and desktop platforms",
        },
        {
          type: "performance",
          description: "Made VibeAI ~50% faster",
        },
      ],
    },
  ];

  return (
    <div
      style={{
        backgroundColor: "#121516",
        minHeight: "100vh",
        padding: "20px",
        fontFamily: "'Manrope', sans-serif",
        color: "#e0e0e0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1
        style={{
          color: "#ffffff",
          fontSize: "32px",
          fontWeight: "700",
          marginBottom: "30px",
          textAlign: "center",
        }}
      >
        Changelog
      </h1>
      {changes.map((version, index) => (
        <div
          key={index}
          style={{
            backgroundColor: "#1a1e20",
            borderRadius: "12px",
            padding: "24px",
            marginBottom: "24px",
            width: "100%",
            maxWidth: "800px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              marginBottom: "16px",
            }}
          >
            <h2
              style={{
                color: "#3cfff3",
                fontSize: "24px",
                fontWeight: "700",
                margin: 0,
              }}
            >
              Version {version.version}
            </h2>
            <span
              style={{
                color: "#828796",
                fontSize: "14px",
              }}
            >
              {version.date}
            </span>
          </div>
          <ul
            style={{
              listStyleType: "none",
              padding: 0,
              margin: 0,
            }}
          >
            {version.changes.map((change, changeIndex) => (
              <li
                key={changeIndex}
                style={{
                  marginBottom: "12px",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "1.5",
                  }}
                >
                  {change.description}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Changelog;
