import React, { useEffect } from "react";
import AppRoutes from "./Routes";
import { sync } from "@capacitor/live-updates";
import { Capacitor } from "@capacitor/core";

function App() {
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      sync();
    }
  }, []);

  return <AppRoutes />;
}

export default App;
