import React, { useEffect, useState, useRef } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import { firestore } from "../firebase";
import defaultProfileImage from "../assets/images/CircleLogo.svg";
import Stories from "react-insta-stories";
import threeDotIcon from "../assets/images/3dot.svg";
import StoryHeader from "./StoryHeader";
import { useSwipeable } from "react-swipeable";
import moment from 'moment';


const MyStory = ({ currentUser }) => {
  const [storyUrl, setStoryUrl] = useState(null);
  const [hasStory, setHasStory] = useState(false);
  const [showStories, setShowStories] = useState(false);
  const [stories, setStories] = useState([]);
  const [username, setUsername] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [storyIndex, setStoryIndex] = useState(0);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);

  const handleDeleteStory = async () => {
    if (!currentUser || stories.length === 0) return;


    const storyRef = doc(firestore, `stories/${currentUser.uid}`);
    const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);

    try {
      const docSnapshot = await getDoc(storyRef);
      if (docSnapshot.exists()) {
        const currentStories = docSnapshot.data().stories || [];
        const updatedStories = currentStories.filter((_, index) => index !== storyIndex);

        await setDoc(storyRef, { stories: updatedStories }, { merge: true });

        // Update local state
        if (updatedStories.length === 0) {
          setHasStory(false);
          setStoryUrl(null);
          setStories([]);
          setShowStories(false);
        } else {
          const filteredStories = updatedStories.filter(
            (story) => story.createdAt && story.createdAt.toDate() > twentyFourHoursAgo
          );
          const userStories = filteredStories.map((story) => ({
            url: story.mediaUrl,
            type: story.mediaType,
            duration: story.duration || (story.mediaType === "video" ? 15000 : 5000),
            header: {
              heading: (
                <span style={{ marginLeft: "45px", display: "block" }}>
                  {username}
                </span>
                ),

              subheading: (<span style={{ marginLeft: "45px", display: "block" }}>
                {moment(story.createdAt.toDate()).fromNow()}
                </span>
                )
            }
          }));
          setStories(userStories);
          setStoryIndex(0);
          setShowStories(false);
          setTimeout(() => setShowStories(true), 0);
        }

        setShowDropdown(false);

        // Show delete success message
        setShowDeleteMessage(true);
        setTimeout(() => setShowDeleteMessage(false), 3000);
      }
    } catch (error) {
      console.error("Error deleting story:", error);
    }
  };

  const handleDropdownToggle = (event) => {
    event.stopPropagation();
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside, {
      capture: true,
      passive: false, // Add this line to disable passive mode
    });

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, {
        capture: true,
        passive: false, // Add this line to disable passive mode
      });
    };
  }, []);

  const handleOverlayClick = (event) => {
    if (!event.target.closest(".threeDotMenu")) {
      setShowDropdown(false);
    }
  };

  const fetchMyStory = async () => {
    if (!currentUser) return;

    const docRef = doc(firestore, `stories/${currentUser.uid}`);
    const userRef = doc(firestore, `users/${currentUser.uid}`);
    
    try {
      const [docSnapshot, userSnapshot] = await Promise.all([
        getDoc(docRef),
        getDoc(userRef),
      ]);

      if (docSnapshot.exists() && userSnapshot.exists()) {
        const storyData = docSnapshot.data().stories || [];
        const username = userSnapshot.data().username;
        setUsername(username);

        // Filter stories created in the last 24 hours
        const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
        const recentStories = storyData.filter(
          (story) => story.createdAt && story.createdAt.toDate() > twentyFourHoursAgo
        );

        if (recentStories.length > 0) {
          setHasStory(true);
          setStoryUrl(recentStories[0].mediaUrl);

          const userStories = recentStories.map((story) => ({
            url: story.mediaUrl,
            type: story.mediaType,
            duration: story.duration || (story.mediaType === "video" ? 15000 : 5000),
            header: {
              heading: (
                <span style={{ marginLeft: "45px", display: "block" }}>
                  {username}
                </span>
                ),

              subheading: (
              <span style={{ marginLeft: "45px", display: "block" }}>
                {moment(story.createdAt.toDate()).fromNow()}
                </span>
                )
            }
          }));

          setStories(userStories);
        } else {
          setHasStory(false);
          setStoryUrl(null);
          setStories([]);
        }
      } else {
        setHasStory(false);
      }
    } catch (error) {
      console.error("Error fetching stories:", error);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchMyStory();
    }
  }, [currentUser]);

  const handleStoryEnd = () => {
    setStoryIndex(0);
    setShowDropdown(false);
    setShowStories(false);
  };

  const handleAddStory = async (event) => {
    const file = event.target.files[0];
    if (!file || !currentUser) return;
  
    const uniqueFilename = `stories/${currentUser.uid}/${Date.now()}-${uuidv4()}-${file.name}`;
    const storage = getStorage();
    const storageRef = ref(storage, uniqueFilename);
  
    try {
      const snapshot = await uploadBytes(storageRef, file);
      const mediaUrl = await getDownloadURL(snapshot.ref);
      const storyData = {
        id: uniqueFilename,
        mediaUrl,
        mediaType: file.type.startsWith("video") ? "video" : "image",
        duration: file.type.startsWith("video") ? 15000 : 5000,
        createdAt: new Date(), // JavaScript Date object
      };
  
      const storyRef = doc(firestore, `stories/${currentUser.uid}`);
      const docSnapshot = await getDoc(storyRef);
  
      let existingStories = [];
      if (docSnapshot.exists()) {
        existingStories = docSnapshot.data().stories || [];
      }
  
      // Handle filtering stories from the last 24 hours
      const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
      const filterStories = existingStories.filter((story) => {
        const createdAt = story.createdAt;
        const storyDate =
          createdAt && typeof createdAt.toDate === "function"
            ? createdAt.toDate() // Firestore Timestamp
            : createdAt; // JavaScript Date
        return storyDate > twentyFourHoursAgo;
      });
  
      const updatedStories = [...filterStories, storyData];
  
      await setDoc(
        storyRef,
        {
          userId: currentUser.uid,
          stories: updatedStories,
        },
        { merge: true }
      );
  
      // Update local state
      setStoryUrl(mediaUrl);
      setHasStory(true);
  
      const userStories = updatedStories.map((story) => ({
        url: story.mediaUrl,
        type: story.mediaType,
        duration: story.duration || (story.mediaType === "video" ? 15000 : 5000),
        header: {
          heading: (
            <span style={{ marginLeft: "45px", display: "block" }}>
              {username}
            </span>
            ),

          subheading: (
            <span style={{ marginLeft: "45px", display: "block" }}>
              {moment(
              typeof story.createdAt.toDate === "function"
                ? story.createdAt.toDate()
                : story.createdAt
            ).fromNow()}
            </span>
            )
        },
      }));
  
      setStories(userStories);
      setStoryIndex(userStories.length - 1);
      setShowStories(true);
  
      // Clear the file input
      event.target.value = null;
    } catch (error) {
      console.error("Error uploading story:", error);
    }
  };
  
  useEffect(() => {
    if (storyIndex >= stories.length) {
      setStoryIndex(0);
    } else if (storyIndex < 0) {
      setStoryIndex(0);
    }
  }, [storyIndex]);

  const handleStoryClick = () => {
    if (hasStory) {
      setShowStories(true);
    } else {
      document.getElementById("storyUpload").click();
    }
  };

  const userSwipeHandlers = useSwipeable({
    onSwipedUp: () => {
      if (showStories) {
        handleStoryEnd();
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });


  const handlePlusButtonClick = (event) => {
    event.stopPropagation();
    document.getElementById("storyUpload").click();
  };

  return (
    <div className="myStoryContainer">
      {showStories ? (
        <div className="storyOverlay" onClick={handleOverlayClick} {...userSwipeHandlers}>
          <div className="storyOverlayContent" style={{ position: 'relative' }}>
            <StoryHeader
            profileImage = {currentUser.profileImage || defaultProfileImage}
            heading = {username}
            />
             <div style={{
              position: 'absolute',
              top: '20px',
              right: '10px',
              zIndex: 1000
            }}>
              <img
                src={threeDotIcon}
                alt="Menu"
                onClick={handleDropdownToggle}
                style={{
                  width: '24px',
                  height: '24px',
                  cursor: 'pointer'
                }}
              />
              {showDropdown && (
                <div style={{
                  position: 'absolute',
                  top: '100%',
                  right: 0,
                  backgroundColor: 'white',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
                }}>
                  <button 
                    onClick={handleDeleteStory}
                    style={{
                      display: 'block',
                      width: '100%',
                      padding: '8px 12px',
                      border: 'none',
                      background: 'none',
                      textAlign: 'left',
                      cursor: 'pointer'
                    }}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
            
            {stories.length > 0 ? (
              <Stories
                stories={stories}
                defaultInterval={5000}
                width="100%"
                height="100%"
                onStoryStart={(index) => setStoryIndex(index)}
                onAllStoriesEnd={handleStoryEnd}
              />
            ) : (
              <div className="storyPlaceholder">
                <p style={{ color: "white", fontSize: "18px" }}>
                  No stories to show
                </p>
              </div>
            )}

          </div>
        </div>
      ) : (
        <div
          className={`myStory ${hasStory ? "hasStory" : ""}`}
          onClick={handleStoryClick}
        >
          <div className="storyProfileImageContainer">
            <img
              className="storyProfileImage"
              src={currentUser.profileImage || defaultProfileImage}
              alt="Add Story"
            />
            {hasStory && (
              <div className="plusButton" onClick={handlePlusButtonClick}>
                +
              </div>
            )}
          </div>
          <p
            style={{
              fontSize: "16px",
              color: "white",
              fontFamily: "Segoe UI",
              fontWeight: "600",
              marginTop: "10px",
            }}
          >
            {hasStory ? "My Story" : "Add to Story"}
          </p>
        </div>
      )}
      <input
        id="storyUpload"
        type="file"
        accept="image/*,video/*"
        style={{ display: "none" }}
        onChange={handleAddStory}
      />
      {showDeleteMessage && (
        <div style={{
          position: 'fixed',
          bottom: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: '#4CAF50',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '5px',
          zIndex: 1000,
          boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
        }}>
          Story deleted successfully!
        </div>
      )}
    </div>
  );
};

export default MyStory;
