import React from "react";
import likeIcon from "../../assets/images/Like.svg";
import unlikeIcon from "../../assets/images/Unlike.svg";

const Overview = ({
  tournament,
  showUploadCard,
  setShowUploadCard,
  selectedFile,
  handleFileSelect,
  caption,
  setCaption,
  isUploading,
  handlePublish,
  uploadProgress,
  uploadError,
  mediaPosts,
  handleLikeClick,
}) => {
  return (
    <div
      className="tab-content"
      style={{ padding: "0 15px", marginBottom: "75px" }}
    >
      <h2
        style={{
          color: "#FFF",
          fontFamily: "Roboto, sans-serif",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "25px",
          letterSpacing: "0.32px",
          marginBottom: "15px",
          textAlign: "center",
        }}
      >
        {tournament.title}
      </h2>
      <div
        style={{
          backgroundColor: "rgba(18, 21, 22, 0.8)",
          color: "white",
          padding: "16px",
          borderRadius: "12px",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            rowGap: "12px",
          }}
        >
          {[
            ["Date", tournament.date.replace(/add to calendar/i, "").trim()],
            ["Capacity", tournament.capacity],
            ["Spots Open", tournament.spots_open],
            ["Early Bird Sign up", tournament.early_bird_registration_opens],
            ["Standard Sign up", tournament.standard_registration_opens],
            ["Price", tournament.price],
          ].map(([label, value], index) => (
            <React.Fragment key={index}>
              <div style={{ textAlign: "left", fontSize: "14px" }}>{label}</div>
              <div style={{ textAlign: "right", fontSize: "14px" }}>
                {value}
              </div>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "#333",
                  gridColumn: "1 / span 2",
                }}
              ></div>
            </React.Fragment>
          ))}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          marginBottom: "20px",
        }}
      >
        {["General", "Agenda", "Teams"].map((buttonText) => (
          <button
            key={buttonText}
            style={{
              backgroundColor: "rgba(41, 43, 44, 0.8)",
              borderRadius: "8px",
              padding: "12px 16px",
              color: "white",
              cursor: "pointer",
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "bold",
              border: "none",
              transition: "background-color 0.2s",
            }}
            onClick={() =>
              (window.location.href = `${
                tournament.eventUrl
              }#${buttonText.toLowerCase()}`)
            }
          >
            {buttonText}
          </button>
        ))}
      </div>

      {/* Tournament Media */}
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <h3 style={{ color: "#fff", fontSize: "18px", textAlign: "left" }}>
            Tournament Media
          </h3>
          <button
            onClick={() => setShowUploadCard(!showUploadCard)}
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "9px 18px",
              backgroundColor: "#3498db",
              color: "white",
              borderRadius: "30px",
              border: "none",
              cursor: "pointer",
              fontSize: "14px",
              fontWeight: "500",
              transition: "background-color 0.3s ease",
              boxShadow: "0 2px 10px rgba(52, 152, 219, 0.3)",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{ marginRight: "6px" }}
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="17 8 12 3 7 8"></polyline>
              <line x1="12" y1="3" x2="12" y2="15"></line>
            </svg>
            {showUploadCard ? "Cancel" : "Add"}
          </button>
        </div>

        {showUploadCard && (
          <div
            style={{
              backgroundColor: "rgba(24, 25, 26, 0.9)",
              borderRadius: "12px",
              padding: "24px",
              marginBottom: "24px",
              boxShadow: "0 8px 32px rgba(0, 0, 0, 0.15)",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <label
              htmlFor="file-upload"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "12px",
                backgroundColor: "rgba(255, 255, 255, 0.08)",
                borderRadius: "8px",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ marginRight: "8px", color: "#fff" }}
              >
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                <polyline points="17 8 12 3 7 8" />
                <line x1="12" y1="3" x2="12" y2="15" />
              </svg>
              {selectedFile ? selectedFile.name : "Choose File"}
            </label>
            <input
              id="file-upload"
              type="file"
              accept="image/*,video/*"
              onChange={handleFileSelect}
              style={{ display: "none" }}
            />
            <textarea
              placeholder="Add a caption (optional)"
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
              style={{
                width: "92.5%",
                padding: "12px",
                backgroundColor: "rgba(255, 255, 255, 0.08)",
                color: "white",
                border: "none",
                borderRadius: "8px",
                resize: "vertical",
                minHeight: "80px",
                fontFamily: "inherit",
              }}
            />
            <button
              onClick={handlePublish}
              disabled={isUploading}
              style={{
                padding: "14px 24px",
                backgroundColor: "#3498db",
                color: "white",
                borderRadius: "30px",
                border: "none",
                cursor: "pointer",
                fontSize: "16px",
                fontWeight: "600",
                transition: "all 0.3s ease",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                outline: "none",
                opacity: isUploading ? "0.7" : "1",
                transform: isUploading ? "scale(0.98)" : "scale(1)",
              }}
            >
              {isUploading ? (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <svg
                    style={{
                      marginRight: "8px",
                      animation: "spin 1s linear infinite",
                    }}
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                    />
                  </svg>
                  Uploading...
                </span>
              ) : (
                "Publish"
              )}
            </button>
          </div>
        )}

        {isUploading && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#f1f3f5",
              borderRadius: "30px",
              overflow: "hidden",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              marginBottom: "15px",
            }}
          >
            <div
              style={{
                width: `${uploadProgress}%`,
                height: "6px",
                backgroundColor: "#3498db",
                transition: "width 0.3s ease",
              }}
            />
            <div
              style={{
                textAlign: "center",
                padding: "6px 0",
                fontSize: "12px",
                color: "#333",
              }}
            >
              {Math.round(uploadProgress)}% Uploaded
            </div>
          </div>
        )}

        {uploadError && (
          <p
            style={{
              color: "#e74c3c",
              marginTop: "10px",
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {uploadError}
          </p>
        )}
        <div style={{ marginTop: "20px" }}>
          {mediaPosts.map((post) => (
            <div
              key={post.id}
              style={{
                backgroundColor: "rgba(37, 37, 37, 0.7)",
                backdropFilter: "blur(10px)",
                WebkitBackdropFilter: "blur(10px)",
                borderRadius: "16px",
                border: "1px solid rgba(255, 255, 255, 0.1)",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                padding: "15px",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={post.profileImage}
                  alt="User"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    marginRight: "10px",
                  }}
                />
                <span
                  style={{
                    color: "#fff",
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  {post.username}
                </span>
              </div>
              {post.mediaUrl.includes("image") ? (
                <img
                  src={post.mediaUrl}
                  alt="Tournament Media"
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    marginBottom: "10px",
                  }}
                />
              ) : (
                <video
                  src={post.mediaUrl}
                  controls
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    marginBottom: "10px",
                  }}
                />
              )}
              {post.caption && (
                <p
                  style={{
                    color: "#fff",
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "14px",
                    marginBottom: "10px",
                  }}
                >
                  {post.caption}
                </p>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={post.isLikedByCurrentUser ? likeIcon : unlikeIcon}
                  alt={post.isLikedByCurrentUser ? "Unlike" : "Like"}
                  onClick={() =>
                    handleLikeClick(post.id, post.isLikedByCurrentUser, true)
                  }
                  style={{
                    cursor: "pointer",
                    width: "24px",
                    height: "24px",
                    marginRight: "7px",
                  }}
                />
                <span
                  style={{
                    color: "#fff",
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  {post.likes}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Overview;
