import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/CircleLogo.svg";
import amazon from "../assets/images/amazon_PNG6.png";
import Twilio from "../assets/images/Twilio_Logo.png";
import Miro from "../assets/images/miro-logo.png";
import Algolia from "../assets/images/Algolia-logo-white.png";
import Microsoft from "../assets/images/R.png";
import Scaleway from "../assets/images/Scaleway-Logo-Purple.png";

const LandingPage = () => {
  return (
    <div
      style={{
        backgroundColor: "#121516",
        color: "#fff",
        minHeight: "100vh",
        background:
          "linear-gradient(135deg, rgb(18, 21, 22) 0%, rgb(36, 42, 43) 50%, rgb(52, 73, 94) 100%)",
        backgroundAttachment: "fixed",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          zIndex: 1000,
          padding: "10px",
        }}
      >
        <header
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "14px 20px",
            background: "rgba(18, 21, 22, 0.3)",
            backdropFilter: "blur(15px)",
            width: "99%",
            maxWidth: "1452px",
            borderRadius: "20px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            // Add responsive styles
            "@media (min-width: 768px)": {
              padding: "20px 29px",
            },
          }}
        >
          <Link
            to="/"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              textDecoration: "none",
              color: "#fff",
              // Add responsive styles
              "@media (min-width: 768px)": {
                gap: "14px",
              },
            }}
          >
            <img
              src={logo}
              alt="VexVibe Logo"
              style={{
                height: "29px",
                width: "29px",
                // Add responsive styles
                "@media (min-width: 768px)": {
                  height: "39px",
                  width: "39px",
                },
              }}
            />
            <span
              style={{
                fontSize: "22px",
                fontWeight: "bold",
                // Add responsive styles
                "@media (min-width: 768px)": {
                  fontSize: "29px",
                },
              }}
            >
              VexVibe
            </span>
          </Link>
          <button
            style={{
              padding: "10px 20px",
              fontSize: "14px",
              fontWeight: "bold",
              backgroundColor: "#3498db",
              color: "#fff",
              border: "none",
              borderRadius: "61px",
              cursor: "pointer",
              transition: "all 0.3s ease",
              boxShadow: "0 3px 7px rgba(52, 152, 219, 0.3)",
              // Add responsive styles
              "@media (min-width: 768px)": {
                padding: "14px 29px",
                fontSize: "20px",
              },
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#2980b9";
              e.target.style.transform = "translateY(-2px)";
              e.target.style.boxShadow = "0 5px 7px rgba(52, 152, 219, 0.4)";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "#3498db";
              e.target.style.transform = "translateY(0)";
              e.target.style.boxShadow = "0 3px 5px rgba(52, 152, 219, 0.3)";
            }}
            onClick={() => (window.location.href = "/welcome")}
          >
            Open VexVibe
          </button>
        </header>
      </div>
      <main>
        <div
          style={{
            background:
              "linear-gradient(135deg, rgba(18, 21, 22, 0.9) 0%, rgba(36, 42, 43, 0.9) 50%, rgba(52, 73, 94, 0.9) 100%)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(5px)",
          }}
        >
          <section
            style={{
              padding: "180px 5% 225px",
              textAlign: "center",
              marginBottom: "-225px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            ></div>
            <div
              style={{
                position: "relative",
                zIndex: 1,
                maxWidth: "800px",
                margin: "0 auto",
              }}
            >
              <h1
                style={{
                  fontSize: "56px",
                  fontWeight: "bold",
                  marginBottom: "24px",
                  lineHeight: 1.2,
                }}
              >
                Connect with the VEX Robotics Community
              </h1>
              <p
                style={{
                  fontSize: "20px",
                  color: "#ccc",
                  marginBottom: "40px",
                  lineHeight: 1.6,
                  maxWidth: "700px",
                  margin: "0 auto 48px",
                  textAlign: "center",
                }}
              >
                VexVibe is a social media platform made for the VEX Robotics
                community, where you can connect with other teams, see match
                results in real time, and know everything that's going on in VEX
              </p>
              <button
                style={{
                  padding: "15px 30px",
                  fontSize: "18px",
                  backgroundColor: "#3498db",
                  color: "#fff",
                  border: "none",
                  borderRadius: "50px",
                  cursor: "pointer",
                  transition: "background-color 0.3s",
                  fontWeight: "bold",
                }}
                onClick={() => (window.location.href = "/welcome")}
              >
                Join Now
              </button>
              <p
                style={{
                  fontSize: "14px",
                  color: "#999",
                  marginTop: "20px",
                }}
              >
                Launching September 1st, 2024
              </p>
            </div>
          </section>

          <section
            style={{
              padding: "80px 32px",
              textAlign: "center",
            }}
          >
            <h2
              style={{
                fontSize: "42px",
                fontWeight: "bold",
                marginBottom: "40px",
              }}
            >
              Supported by
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "40px",
              }}
            >
              {[amazon, Twilio, Miro, Algolia, Scaleway].map((logo, index) => (
                <div
                  key={index}
                  style={{
                    background: "rgba(255,255,255,0.1)",
                    borderRadius: "12px",
                    padding: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "180px",
                    height: "100px",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    ":hover": {
                      transform: "translateY(-5px)",
                      boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
                    },
                  }}
                >
                  <img
                    src={logo}
                    alt="Partner Logo"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              ))}
            </div>
          </section>
        </div>

        <div
          style={{
            background: "linear-gradient(to bottom, #121516, #1a1f2c, #2c3e50)",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background:
                "radial-gradient(circle at 50% 50%, rgba(52, 152, 219, 0.05) 0%, rgba(52, 152, 219, 0) 70%)",
            }}
          />

          <section
            style={{
              padding: "100px 32px",
              position: "relative",
              zIndex: 1,
              marginBottom: "-50px",
            }}
          >
            <h2
              style={{
                fontSize: "48px",
                fontWeight: "bold",
                marginBottom: "48px",
                textAlign: "center",
                background: "linear-gradient(90deg, #3498db, #2ecc71)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Features
            </h2>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                gap: "32px",
                maxWidth: "1200px",
                margin: "0 auto",
              }}
            >
              {[
                {
                  title: "Real-time Match Results",
                  description:
                    "Stay updated with live match results from tournaments you're attending.",
                  icon: "",
                },
                {
                  title: "Integrated Notifications",
                  description:
                    "Receive instant alerts for likes, comments, replies, and messages.",
                  icon: "",
                },
                {
                  title: "Live Streaming",
                  description:
                    "Watch and share live streams from exciting robotics events.",
                  icon: "",
                },
                {
                  title: "Robotics-focused Social Feed",
                  description:
                    "Explore content tailored for the VEX Robotics community.",
                  icon: "",
                },
                {
                  title: "Advanced Search",
                  description:
                    "Find teams, users, and posts with our powerful Algolia-powered search.",
                  icon: "",
                },
                {
                  title: "AI Chat Assistant",
                  description:
                    "Get help from VibeAI, our advanced AI model built for VEX Robotics.",
                  icon: "",
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  style={{
                    background: "rgba(255, 255, 255, 0.05)",
                    borderRadius: "16px",
                    padding: "32px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    ":hover": {
                      transform: "translateY(-5px)",
                      boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
                    },
                  }}
                >
                  <div style={{ fontSize: "48px", marginBottom: "16px" }}>
                    {feature.icon}
                  </div>
                  <h3
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      marginBottom: "16px",
                      color: "#3498db",
                    }}
                  >
                    {feature.title}
                  </h3>
                  <p
                    style={{
                      color: "#ecf0f1",
                      fontSize: "16px",
                      lineHeight: "1.6",
                    }}
                  >
                    {feature.description}
                  </p>
                </div>
              ))}
            </div>
          </section>

          <section
            style={{
              padding: "100px 32px",
              position: "relative",
              zIndex: 1,
              marginBottom: "-100px",
            }}
          >
            <h2
              style={{
                fontSize: "48px",
                fontWeight: "bold",
                marginBottom: "48px",
                textAlign: "center",
                background: "linear-gradient(90deg, #3498db, #2ecc71)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              VibeAI
            </h2>
            <div
              style={{
                marginTop: "48px",
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                gap: "32px",
                maxWidth: "1200px",
                margin: "0 auto",
              }}
            >
              {[
                {
                  title: "Advanced AI Assistant",
                  description:
                    "VibeAI is a powerful AI model with extensive knowledge about VEX Robotics. It can understand and generate text, support vision tasks, search the web, and use plugins to enhance its capabilities.",
                },
                {
                  title: "Fast and Accurate",
                  description:
                    "VibeAI is one of the world's fastest AI models, with the same (sometimes more) power and knowledge as other frontier models like GPT-4o, but with more specialized knowledge about VEX Robotics and even faster speeds.",
                },
                {
                  title: "VEX Knowledge",
                  description:
                    "Access to up-to-date information about VEX V5 games, including High Stakes and Rapid Relay. VibeAI can fetch specific rule sections and game information to provide you with strategic information and updates.",
                },
                {
                  title: "Web Search Integration",
                  description:
                    "With VibeAI's web search capabilities, it can provide real-time access to the most recent information, keeping you updated on the latest developments in VEX Robotics and beyond, with no hallucination or false information.",
                },
                {
                  title: "Tournament Information",
                  description:
                    "Get detailed information about VEX tournaments, including schedules, results, and specific tournament details using the tournaments plugin.",
                },
                {
                  title: "Vision Capabilities",
                  description:
                    "VibeAI can analyze and describe images, recognizing objects, text, and scenes to assist with various visual tasks related to VEX Robotics.",
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  style={{
                    background: "rgba(255, 255, 255, 0.05)",
                    borderRadius: "16px",
                    padding: "32px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    ":hover": {
                      transform: "translateY(-5px)",
                      boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
                    },
                  }}
                >
                  <div style={{ fontSize: "48px", marginBottom: "16px" }}>
                    {feature.icon}
                  </div>
                  <h3
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      marginBottom: "16px",
                      color: "#3498db",
                    }}
                  >
                    {feature.title}
                  </h3>
                  <p
                    style={{
                      color: "#ecf0f1",
                      fontSize: "16px",
                      lineHeight: "1.6",
                    }}
                  >
                    {feature.description}
                  </p>
                </div>
              ))}
            </div>
            <div style={{ textAlign: "center", marginTop: "64px" }}>
              <button
                onClick={() => (window.location.href = "/ai-chat")}
                style={{
                  padding: "16px 32px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  background: "linear-gradient(90deg, #3498db, #2ecc71)",
                  color: "#fff",
                  border: "none",
                  borderRadius: "50px",
                  cursor: "pointer",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  ":hover": {
                    transform: "translateY(-2px)",
                    boxShadow: "0 5px 15px rgba(52, 152, 219, 0.4)",
                  },
                }}
              >
                Try VibeAI, Unlimited Access, Always Free
              </button>
            </div>
          </section>

          {/* Team Features section */}
          <section
            style={{
              padding: "100px 32px",
              position: "relative",
              zIndex: 1,
              marginBottom: "-100px",
            }}
          >
            <h2
              style={{
                fontSize: "48px",
                fontWeight: "bold",
                marginBottom: "24px",
                textAlign: "center",
                background: "linear-gradient(90deg, #3498db, #2ecc71)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Teams
            </h2>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                gap: "32px",
                maxWidth: "1200px",
                margin: "0 auto",
              }}
            >
              {[
                {
                  title: "Team Accounts",
                  description:
                    "Create a team account that everyone in your team can access and post on, and view your team's stats and achievements.",
                },
                {
                  title: "Tournaments",
                  description:
                    "Stay up-to-date with the tournaments you're signed up for, view live match results, chat with other teams, and check rankings.",
                },
                {
                  title: "AI Predictions",
                  description:
                    "Leverage AI models to predict tournament outcomes, awards, team rankings, and more to help you strategize and prepare (coming soon)",
                },
                // {
                //   title: "Resource Sharing",
                //   description:
                //     "Easily share documents, CAD files, and other resources within your team.",
                // },
                // {
                //   title: "Team Chat",
                //   description:
                //     "Communicate seamlessly with your team members through integrated chat features.",
                // },
                // {
                //   title: "Role Management",
                //   description:
                //     "Assign and manage team roles to streamline responsibilities and workflows.",
                // },
              ].map((feature, index) => (
                <div
                  key={index}
                  style={{
                    background: "rgba(255, 255, 255, 0.05)",
                    borderRadius: "16px",
                    padding: "32px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    ":hover": {
                      transform: "translateY(-5px)",
                      boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
                    },
                  }}
                >
                  <div style={{ fontSize: "48px", marginBottom: "16px" }}>
                    {feature.icon}
                  </div>
                  <h3
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      marginBottom: "16px",
                      color: "#3498db",
                    }}
                  >
                    {feature.title}
                  </h3>
                  <p
                    style={{
                      color: "#ecf0f1",
                      fontSize: "16px",
                      lineHeight: "1.6",
                    }}
                  >
                    {feature.description}
                  </p>
                </div>
              ))}
            </div>
            <div style={{ textAlign: "center", marginTop: "64px" }}>
              <button
                onClick={() => (window.location.href = "/CreateTeam")}
                style={{
                  padding: "16px 32px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  background: "linear-gradient(90deg, #3498db, #2ecc71)",
                  color: "#fff",
                  border: "none",
                  borderRadius: "50px",
                  cursor: "pointer",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  ":hover": {
                    transform: "translateY(-2px)",
                    boxShadow: "0 5px 15px rgba(52, 152, 219, 0.4)",
                  },
                }}
              >
                Create Your Team
              </button>
            </div>
          </section>

          {/* Tournament Features section */}
          <section
            style={{ padding: "100px 32px", position: "relative", zIndex: 1 }}
          >
            <h2
              style={{
                fontSize: "48px",
                fontWeight: "bold",
                marginBottom: "48px",
                textAlign: "center",
                background: "linear-gradient(90deg, #e74c3c, #f39c12)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Tournaments
            </h2>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                gap: "32px",
                maxWidth: "1200px",
                margin: "0 auto",
              }}
            >
              {[
                {
                  title: "Find Tournaments",
                  description:
                    "Find regional and signature tournaments in your area and in the world and see which ones your signed up for.",
                },
                {
                  title: "Live Chat",
                  description:
                    "Chat with other teams, mentors, and event organizers in real-time during tournaments.",
                },
                {
                  title: "Alliance Finder",
                  description:
                    "Find and connect with potential alliance partners based on team performance and compatibility.",
                },
                {
                  title: "Live Match Results",
                  description:
                    "Stay up-to-date with live match results, rankings, and scores during tournaments.",
                },
                {
                  title: "AI Match Prediction",
                  description:
                    "Leverage AI models to predict match outcomes and strategize accordingly.",
                },
                {
                  title: "AI Post-Game Analysis",
                  description:
                    "Leverage AI models to analyze match performance and identify areas for improvement.",
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  style={{
                    background: "rgba(255, 255, 255, 0.05)",
                    borderRadius: "16px",
                    padding: "32px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    ":hover": {
                      transform: "translateY(-5px)",
                      boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
                    },
                  }}
                >
                  <div style={{ fontSize: "48px", marginBottom: "16px" }}>
                    {feature.icon}
                  </div>
                  <h3
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      marginBottom: "16px",
                      color: "#f39c12",
                    }}
                  >
                    {feature.title}
                  </h3>
                  <p
                    style={{
                      color: "#ecf0f1",
                      fontSize: "16px",
                      lineHeight: "1.6",
                    }}
                  >
                    {feature.description}
                  </p>
                </div>
              ))}
            </div>
            <div style={{ textAlign: "center", marginTop: "64px" }}>
              <button
                onClick={() => (window.location.href = "/tournament")}
                style={{
                  padding: "16px 32px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  background: "linear-gradient(90deg, #e74c3c, #f39c12)",
                  color: "#fff",
                  border: "none",
                  borderRadius: "50px",
                  cursor: "pointer",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  ":hover": {
                    transform: "translateY(-2px)",
                    boxShadow: "0 5px 15px rgba(231, 76, 60, 0.4)",
                  },
                }}
              >
                Explore Tournaments
              </button>
            </div>
          </section>
        </div>
      </main>

      {/* Footer */}
      <footer
        style={{
          background: "linear-gradient(to bottom, #2c3e50, #1a1f2c)",
          padding: "64px 32px 32px",
          color: "#ecf0f1",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            background:
              "linear-gradient(to right, transparent, rgba(236, 240, 241, 0.1), transparent)",
          }}
        />
        <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
              gap: "32px",
              marginBottom: "48px",
            }}
          >
            <div>
              <Link
                to="/"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  fontSize: "24px",
                  fontWeight: "bold",
                  textDecoration: "none",
                  color: "#fff",
                  marginBottom: "16px",
                }}
              >
                <img
                  src={logo}
                  alt="Logo"
                  style={{ height: "32px", width: "32px" }}
                />
                <span>VexVibe</span>
              </Link>
              <p style={{ fontSize: "14px", color: "#bdc3c7" }}>
                Connecting the VEX Robotics community.
              </p>
            </div>

            <div>
              <h3
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: "16px",
                }}
              >
                Contact Us
              </h3>
              <p
                style={{
                  fontSize: "14px",
                  color: "#bdc3c7",
                  marginBottom: "8px",
                }}
              >
                Email: support@vexvibe.com
              </p>
            </div>
          </div>
          <div
            style={{
              borderTop: "1px solid rgba(236, 240, 241, 0.1)",
              paddingTop: "24px",
              textAlign: "center",
              fontSize: "14px",
              color: "#bdc3c7",
            }}
          >
            <p>
              © 2024 VexVibe. All rights reserved. VexVibe is a BC General
              Partnership.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
