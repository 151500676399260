import { createContext, useState, useEffect, useContext } from "react";
import { auth } from "../firebase"; // Ensure this path is correct

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null); // Ensure initial state is null
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false); // Set loading to false after auth state is determined
    });

    return unsubscribe;
  }, []);

  const value = { currentUser, loading }; // Provide loading state

  return (
    <AuthContext.Provider value={value}>
      {!loading && children} {/* Render children only when not loading */}
    </AuthContext.Provider>
  );
}

export default AuthContext;