import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import { Capacitor } from "@capacitor/core";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Sidebar from "./components/Sidebar";
import HomePage from "./pages/HomePage";
import WelcomePage from "./pages/WelcomePage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import OnboardingPage from "./pages/OnboardingPage";
import ProfilePage from "./pages/ProfilePage";
import SearchPage from "./pages/SearchPage";
import TeamsPage from "./pages/TeamsPage";
import TournamentsPage from "./pages/TournamentsPage";
import PostPage from "./pages/PostPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import Changelog from "./pages/Changelog";
import MessagesPage from "./pages/MessagesPage";
import ChatPage from "./pages/ChatPage";
import ResultsPage from "./pages/ResultsPage";
import LandingPage from "./pages/LandingPage";
import TournamentDetails from "./pages/TournamentDetails";
import CreateTeam from "./pages/CreateTeam";
import AIChatPage from "./pages/AIChatPage";
import JoinTeam from "./pages/JoinTeam";
import TeamProfilePage from "./pages/TeamProfilePage";
import WelcomeTeam from "./pages/WelcomeTeam";
import NotificationsPage from "./pages/NotificationsPage";
import SuccessPage from "./pages/SuccessPage";
import TeamSearch from "./pages/TeamSearch";
import TeamDetails from "./pages/TeamDetails"; // Add this import

const PrivateRoute = ({ element }) => {
  const { currentUser, loading } = useAuth();
  if (loading) return null; // Render nothing while loading
  return currentUser ? element : <Navigate to="/welcome" />;
};

const AppRoutes = () => {
  const isNative = Capacitor.isNativePlatform();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={isNative ? <Navigate to="/home" /> : <LandingPage />}
        />
        <Route path="/home" element={<HomePage />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/onboarding" element={<OnboardingPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route
          path="/teams"
          element={<PrivateRoute element={<TeamProfilePage />} />}
        />
        <Route path="/tournament" element={<TournamentsPage />} />
        <Route path="/posts/:postId" element={<PostPage />} />
        <Route path="/users/:username" element={<ProfilePage />} />
        <Route path="/teams/:teamId" element={<TeamProfilePage />} />
        <Route path="/join-team" element={<JoinTeam />} />
        <Route path="/create-team" element={<CreateTeam />} />
        <Route path="/legal/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/legal/terms" element={<TermsOfServicePage />} />
        <Route path="/changelog" element={<Changelog />} />
        <Route path="/messages" element={<MessagesPage />} />
        <Route path="/chat/:contactId" element={<ChatPage />} />
        <Route
          path="/ai-chat"
          element={<PrivateRoute element={<AIChatPage />} />}
        />
        <Route path="/results" element={<ResultsPage />} />
        <Route path="/tournament/:id" element={<TournamentDetails />} />
        <Route path="/CreateTeam" element={<CreateTeam />} />
        <Route
          path="/group_chat/:chatId"
          element={<PrivateRoute element={<ChatPage />} />}
        />
        <Route path="/WelcomeTeam" element={<WelcomeTeam />} />
        <Route path="/JoinTeam" element={<JoinTeam />} />
        <Route path="/notifications" element={<NotificationsPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/TeamSearch" element={<TeamSearch />} />
        <Route path="/TeamDetails/:teamName" element={<TeamDetails />} />{" "}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
