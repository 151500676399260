import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCy9hQHuTgOVur5iUBOrRavjeOddb9B95Q",
  authDomain: "vexvibe-frontend.firebaseapp.com",
  projectId: "vexvibe-frontend",
  storageBucket: "vexvibe-frontend.appspot.com",
  messagingSenderId: "982123394034",
  appId: "1:982123394034:web:608136b617520cbc20316b",
  measurementId: "G-QQTF3401Q0",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const db = getFirestore(app);

const saveChatMessage = async (userId, message) => {
  try {
    const chatRef = collection(firestore, "aiHistory", userId, "chats");
    await addDoc(chatRef, message);
  } catch (error) {
    console.error("Error saving chat message: ", error);
  }
};

const saveChatThread = async (userId, threadId, messages) => {
  try {
    const threadRef = doc(firestore, "aiHistory", userId, "threads", threadId);
    await setDoc(
      threadRef,
      { messages, timestamp: new Date() },
      { merge: true }
    );
  } catch (error) {
    console.error("Error saving chat thread: ", error);
  }
};

export {
  app,
  auth,
  firestore,
  storage,
  functions,
  saveChatMessage,
  saveChatThread,
  db,
};
