import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  doc,
  getDoc,
} from "firebase/firestore";
import { firestore, auth } from "../firebase";

const NotificationsPage = () => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const notificationsRef = collection(firestore, "notifications");
    const q = query(
      notificationsRef,
      where("recipientId", "==", user.uid),
      orderBy("createdAt", "desc")
    );

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const notificationsData = await Promise.all(
        querySnapshot.docs.map(async (document) => {
          const data = document.data();
          if (data.senderId) {
            try {
              const userDocRef = doc(firestore, "users", data.senderId);
              const userDoc = await getDoc(userDocRef);
              const userData = userDoc.data();
              return {
                id: document.id,
                ...data,
                senderUsername: userData?.username || "Unknown User",
              };
            } catch (error) {
              console.error("Error fetching user data:", error);
              return {
                id: document.id,
                ...data,
                senderUsername: "Unknown User",
              };
            }
          }
          return {
            id: document.id,
            ...data,
          };
        })
      );

      // Group notifications by postId and senderId, but exclude comment notifications
      const groupedNotifications = notificationsData.reduce(
        (acc, notification) => {
          if (notification.type === "comment") {
            // Don't group comment notifications
            acc[notification.id] = notification;
          } else {
            const key = `${notification.postId}-${notification.senderId}`;
            if (
              !acc[key] ||
              new Date(notification.createdAt.toDate()) >
                new Date(acc[key].createdAt.toDate())
            ) {
              acc[key] = notification;
            }
          }
          return acc;
        },
        {}
      );

      setNotifications(Object.values(groupedNotifications));
    });

    // Update the last viewed timestamp when the page is loaded
    localStorage.setItem("lastNotificationView", Date.now().toString());

    return () => unsubscribe();
  }, []);

  const groupNotifications = (notifications) => {
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const oneWeek = 7 * oneDay;

    return [
      {
        title: "Today",
        items: notifications.filter(
          (n) => new Date(n.createdAt.toDate()) >= new Date(now - oneDay)
        ),
      },
      {
        title: "This Week",
        items: notifications.filter((n) => {
          const date = new Date(n.createdAt.toDate());
          return (
            date >= new Date(now - oneWeek) && date < new Date(now - oneDay)
          );
        }),
      },
      {
        title: "Earlier",
        items: notifications.filter(
          (n) => new Date(n.createdAt.toDate()) < new Date(now - oneWeek)
        ),
      },
    ];
  };

  const renderNotification = (notification) => {
    const getIcon = (type) => {
      switch (type) {
        case "like":
          return "❤️";
        case "comment":
          return "💬";
        case "follow":
          return "👤";
        case "team_post":
          return "🏆";
        default:
          return "🔔";
      }
    };

    const getContent = (notification) => {
      switch (notification.type) {
        case "like":
          return `${notification.senderUsername} liked your post`;
        case "follow":
          return `${notification.senderUsername} started following you`;
        case "comment":
          return `${notification.senderUsername} commented on your post: "${notification.commentContent}"`;
        // Add other cases as needed
        default:
          return notification.content || "You have a new notification";
      }
    };

    const handleNotificationClick = () => {
      switch (notification.type) {
        case "like":
        case "comment":
          navigate(`/posts/${notification.postId}`);
          break;
        case "follow":
          navigate(`/users/${notification.senderUsername}`);
          break;
        case "team_post":
          navigate(`/teams/${notification.teamId}`);
          break;
        default:
          console.log("No specific action for this notification type");
      }
    };

    return (
      <div
        key={notification.id}
        style={{
          display: "flex",
          alignItems: "center",
          padding: "16px",
          borderBottom: "1px solid #2a2e30",
          transition: "background-color 0.2s",
          cursor: "pointer",
        }}
        onClick={handleNotificationClick}
        onMouseEnter={(e) =>
          (e.currentTarget.style.backgroundColor = "#1a1e1f")
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.backgroundColor = "transparent")
        }
      >
        <div style={{ marginRight: "16px", fontSize: "24px" }}>
          {getIcon(notification.type)}
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ fontWeight: "500", marginBottom: "4px" }}>
            {getContent(notification)}
          </div>
          <div style={{ fontSize: "0.85em", color: "#888" }}>
            {new Date(notification.createdAt.toDate()).toLocaleString()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: "#121516",
        minHeight: "100vh",
        color: "#fff",
        fontFamily: "'Segoe UI', 'Roboto', sans-serif",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: 0,
          backgroundColor: "rgba(18, 21, 22, 0.95)",
          backdropFilter: "blur(10px)",
          padding: "16px 24px",
          borderBottom: "1px solid #2a2e30",
          display: "flex",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <svg
          onClick={() => navigate(-1)}
          viewBox="0 0 24 24"
          aria-hidden="true"
          style={{
            cursor: "pointer",
            width: "24px",
            height: "24px",
            marginRight: "16px",
          }}
        >
          <g>
            <path
              d="M7.414 13l5.043 5.04-1.414 1.42L3.586 12l7.457-7.46 1.414 1.42L7.414 11H21v2H7.414z"
              fill="white" // Changed to use fill instead of color
            />
          </g>
        </svg>
        <div
          style={{
            color: "var(--white, #FFF)",
            textAlign: "center",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "25px",
            letterSpacing: "0.16px",
            textTransform: "capitalize",
          }}
        >
          Notifications
        </div>
      </div>
      <div style={{ padding: "24px", maxWidth: "800px", margin: "0 auto" }}>
        {notifications.length > 0 ? (
          groupNotifications(notifications).map((group) => (
            <div key={group.title} style={{ marginBottom: "32px" }}>
              <h2
                style={{
                  fontSize: "0.9em",
                  color: "#888",
                  textTransform: "uppercase",
                  marginBottom: "12px",
                  letterSpacing: "0.05em",
                }}
              >
                {group.title}
              </h2>
              <div
                style={{
                  backgroundColor: "#1a1e1f",
                  borderRadius: "8px",
                  overflow: "hidden",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                {group.items.map((notification) =>
                  renderNotification(notification)
                )}
              </div>
            </div>
          ))
        ) : (
          <div
            style={{
              textAlign: "center",
              color: "#888",
              padding: "48px 0",
              fontSize: "1.1em",
            }}
          >
            <p>No notifications yet.</p>
            <p style={{ fontSize: "0.9em", marginTop: "8px" }}>
              We'll notify you when something new happens!
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
export default NotificationsPage;
