import React, { useState, useEffect } from "react";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import Dock from "../components/Dock";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import TournamentSidebar from "../components/TournamentSidebar";

const CreateTeam = () => {
  const [organizationNumber, setOrganizationNumber] = useState("");
  const [teamLetter, setTeamLetter] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [agreement, setAgreement] = useState(false);
  const navigate = useNavigate();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1439);
  const [isWideDesktop, setIsWideDesktop] = useState(window.innerWidth >= 1580);
  const [activeTab, setActiveTab] = useState("home");
  const [isLoading, setIsLoading] = useState(false);
  const [vexType, setVexType] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 769);
      setIsWideDesktop(window.innerWidth >= 1580);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSubmit = async () => {
    if (!agreement) {
      alert("You must agree to the terms.");
      return;
    }

    if (!vexType) {
      alert("Please select a VEX type.");
      return;
    }

    const requestData = {
      user_id: getAuth().currentUser.uid,
      team_name: `${organizationNumber}${teamLetter}`,
      additional_info: additionalInfo,
      request_status: "pending",
      timestamp: new Date(),
      vex_type: vexType,
    };

    try {
      const db = getFirestore();
      await addDoc(collection(db, "team_requests"), requestData);
      localStorage.setItem("welcomeMessage", "Request Sent");
      navigate("/WelcomeTeam");
    } catch (error) {
      console.error("Error submitting request: ", error);
      alert("Failed to submit request.");
    }
  };

  const vexTypeSelect = (
    <div style={{ marginBottom: "20px" }}>
      <select
        value={vexType}
        onChange={(e) => setVexType(e.target.value)}
        style={{
          backgroundColor: "#2C2F33",
          color: "#E0E0E0",
          padding: "15px",
          borderRadius: "10px",
          width: "100%",
          border: "1px solid #3B3B3B",
          fontSize: "16px",
          appearance: "none",
          transition: "border-color 0.3s",
        }}
      >
        <option value="" disabled>Select VEX Type</option>
        <option value="V5RC">VEX V5RC</option>
        <option value="VEX U">VEX U</option>
      </select>
    </div>
  );

  if (isDesktop) {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "#121516",
          minHeight: "100vh",
        }}
      >
        {/* Left Sidebar */}
        <div
          style={{
            width: "350px",
            position: "fixed",
            left: 0,
            top: 0,
            height: "100vh",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "12px",
              left: "12px",
              animation: "fadeInUp 1s ease-in-out",
            }}
          >
            <Sidebar
              activeTab={activeTab}
              handleTabClick={setActiveTab}
              events={[]}
              loading={isLoading}
              handleCardClick={() => {}}
            />
          </div>
        </div>
        <div
          style={{
            width: "1px",
            height: "100vh",
            position: "fixed",
            left: "350px",
            top: 0,
            background: "var(--black, #2E313C)",
          }}
        ></div>

        {/* Main Content */}
        <div
          style={{
            flex: 1,
            padding: "40px",
            marginLeft: isWideDesktop ? "auto" : "350px",
            marginRight: isWideDesktop ? "auto" : "0",
            maxWidth: isWideDesktop ? "800px" : "calc(100% - 350px)",
            width: isWideDesktop ? "800px" : "auto",
            overflowY: "auto",
            height: "100vh",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            borderRadius: "20px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h1
            style={{
              color: "#FFF",
              fontSize: "32px",
              fontWeight: 700,
              marginBottom: "30px",
              marginTop: "0px",
              borderBottom: "2px solid #3B3B3B",
              paddingBottom: "15px",
            }}
          >
            Create a Team
          </h1>
          <div style={{ marginBottom: "30px" }}>
            <h2
              style={{
                color: "#E0E0E0",
                fontSize: "20px",
                fontWeight: "600",
                marginBottom: "15px",
              }}
            >
              Team Information
            </h2>
            <div style={{ marginBottom: "20px" }}>
              <input
                type="text"
                placeholder="Organization Number (e.g., 6408)"
                value={organizationNumber}
                onChange={(e) => setOrganizationNumber(e.target.value)}
                style={{
                  backgroundColor: "#2C2F33",
                  color: "#E0E0E0",
                  padding: "15px",
                  borderRadius: "10px",
                  width: "100%",
                  border: "1px solid #3B3B3B",
                  fontSize: "16px",
                  transition: "border-color 0.3s",
                }}
              />
            </div>
            <div style={{ position: "relative", marginBottom: "20px" }}>
              <select
                id="team-letter"
                value={teamLetter}
                onChange={(e) => setTeamLetter(e.target.value)}
                style={{
                  backgroundColor: "#2C2F33",
                  color: "#E0E0E0",
                  padding: "15px",
                  borderRadius: "10px",
                  width: "100%",
                  border: "1px solid #3B3B3B",
                  fontSize: "16px",
                  appearance: "none",
                  transition: "border-color 0.3s",
                }}
              >
                <option value="" disabled selected style={{ color: "#A0A0A0" }}>
                  Select Team Letter
                </option>
                {[
                  "A", "B", "C", "D", "E", "F", "G", "H", "J", "K",
                  "N", "P", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
                ].map((letter) => (
                  <option key={letter} value={letter}>
                    Team {letter}
                  </option>
                ))}
              </select>
            </div>
            {vexTypeSelect}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: "30px",
              backgroundColor: "#2C2F33",
              padding: "20px",
              borderRadius: "10px",
              width: "100%",
            }}
          >
            <input
              type="checkbox"
              id="agreement"
              checked={agreement}
              onChange={(e) => setAgreement(e.target.checked)}
              style={{ marginRight: "15px", marginTop: "4px" }}
            />
            <label
              htmlFor="agreement"
              style={{ fontSize: "14px", color: "#E0E0E0", lineHeight: "1.6" }}
            >
              I confirm that I am a member of the team I am creating and will only allow official team members from the current season to join.
            </label>
          </div>
          <div style={{ marginBottom: "30px" }}>
            <textarea
              placeholder="Additional information or proof of team membership"
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
              style={{
                minHeight: "150px",
                backgroundColor: "#2C2F33",
                color: "#E0E0E0",
                padding: "15px",
                borderRadius: "10px",
                width: "100%",
                border: "1px solid #3B3B3B",
                fontSize: "16px",
                resize: "vertical",
                transition: "border-color 0.3s",
              }}
            />
          </div>
          <button
            onClick={handleSubmit}
            style={{
              width: "105%",
              height: "60px",
              borderRadius: "10px",
              background: "#1d2021",
              color: "white",
              border: "none",
              cursor: "pointer",
              fontSize: "18px",
              fontWeight: "bold",
              transition: "transform 0.2s, box-shadow 0.2s",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transform: "translateX(0%)",  // Move the button 5% to the left
            }}
            onMouseOver={(e) => {
              e.target.style.transform = "translateY(-2px)";
              e.target.style.boxShadow = "0 6px 8px rgba(0, 0, 0, 0.2)";
            }}
            onMouseOut={(e) => {
              e.target.style.transform = "translateY(0)";
              e.target.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
            }}
          >
            Request Team Creation
          </button>
        </div>

        {/* Right Sidebar (Tournament) */}
        {isWideDesktop && <TournamentSidebar />}
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "#121516",
        padding: "32px",
        borderRadius: "16px",
        maxWidth: "500px",
        margin: "0 auto",
        width: "90%",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h1
        style={{
          color: "#FFF",
          fontFamily: "Manrope, sans-serif",
          fontSize: "28px",
          fontWeight: 700,
          marginBottom: "24px",
          textAlign: "center",
        }}
      >
        Create a Team
      </h1>
      <div style={{ marginBottom: "32px" }}>
        <h2
          style={{
            color: "#FFF",
            fontFamily: "Manrope, sans-serif",
            fontSize: "20px",
            fontWeight: 600,
            marginBottom: "16px",
          }}
        >
          Team Info
        </h2>
        <div style={{ marginBottom: "16px" }}>
          <input
            type="text"
            placeholder="Organization Number (e.g., 6408)"
            value={organizationNumber}
            onChange={(e) => setOrganizationNumber(e.target.value)}
            style={{
              backgroundColor: "#2C2F33",
              color: "#E0E0E0",
              padding: "12px 16px",
              borderRadius: "8px",
              width: "91.5%",
              border: "1px solid #3B3B3B",
              fontSize: "16px",
              transition: "border-color 0.3s",
            }}
          />
        </div>
        <div style={{ marginBottom: "16px" }}>
          <select
            id="team-letter"
            value={teamLetter}
            onChange={(e) => setTeamLetter(e.target.value)}
            style={{
              backgroundColor: "#2C2F33",
              color: "#E0E0E0",
              padding: "12px 16px",
              borderRadius: "8px",
              width: "100%",
              border: "1px solid #3B3B3B",
              fontSize: "16px",
              appearance: "none",
              transition: "border-color 0.3s",
            }}
          >
            <option value="" disabled>Select Team Letter</option>
            {['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'N', 'P', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'].map((letter) => (
              <option key={letter} value={letter}>Team {letter}</option>
            ))}
          </select>
        </div>
        {vexTypeSelect}
      </div>
      <div style={{ marginBottom: "24px" }}>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            color: "#d1d5db",
            cursor: "pointer",
          }}
        >
          <input
            type="checkbox"
            checked={agreement}
            onChange={(e) => setAgreement(e.target.checked)}
            style={{ marginRight: "12px", cursor: "pointer" }}
          />
          By checking this box I agree that I am a member of the team I am
          attempting to create and that I will only allow members who are
          officially part of this team in the current season to join.
        </label>
      </div>
      <div style={{ marginBottom: "24px" }}>
        <textarea
          placeholder="Any additional info or proof to help us confirm that you are part of the team you are trying to create"
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value)}
          style={{
            minHeight: "120px",
            backgroundColor: "#2C2F33",
            color: "#E0E0E0",
            padding: "12px 16px",
            borderRadius: "8px",
            width: "91.5%",
            border: "1px solid #3B3B3B",
            fontSize: "16px",
            resize: "vertical",
            transition: "border-color 0.3s",
          }}
        />
      </div>
      <button
        onClick={handleSubmit}
        style={{
          width: "100%",
          padding: "14px",
          borderRadius: "8px",
          background: "#1d2021",
          color: "white",
          border: "none",
          cursor: "pointer",
          fontSize: "18px",
          fontWeight: "bold",
          transition: "transform 0.2s, box-shadow 0.2s",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
        onMouseOver={(e) => {
          e.target.style.transform = "translateY(-2px)";
          e.target.style.boxShadow = "0 6px 8px rgba(0, 0, 0, 0.2)";
        }}
        onMouseOut={(e) => {
          e.target.style.transform = "translateY(0)";
          e.target.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
        }}
      >
        Request Team Creation
      </button>
      <Dock />
    </div>
  );
};

export default CreateTeam;
