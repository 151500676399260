import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { firestore } from "../firebase";
import { getDatabase, ref, get, child } from "firebase/database";
import { doc, getDoc, collection, getDocs, query } from "firebase/firestore";
import fallbackImage from "../assets/images/CircleLogo.svg"; // Ensure the path is correct
import AIimage from "../assets/images/AI.svg"; // Ensure the path is correct
import { set } from "firebase/database";
import Sidebar from "../components/Sidebar";
import TournamentSidebar from "../components/TournamentSidebar";

const MessagesPage = () => {
  const { currentUser } = useContext(AuthContext);
  const [contacts, setContacts] = useState(() => {
    const cachedContacts = localStorage.getItem("cachedContacts");
    return cachedContacts ? JSON.parse(cachedContacts) : [];
  });
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1439);
  const [isWideDesktop, setIsWideDesktop] = useState(window.innerWidth >= 1580);
  const [activeTab, setActiveTab] = useState("messages");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 769);
      setIsWideDesktop(window.innerWidth >= 1580);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [showCreateGroupChatOverlay, setShowCreateGroupChatOverlay] =
    useState(false);

  const handleCreateGroupChatClick = () => {
    setShowCreateGroupChatOverlay(true);
  };

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("overlay")) {
      setShowCreateGroupChatOverlay(false);
    }
  };

  const handleSelectContact = (contactId) => {
    setSelectedContacts((prevSelectedContacts) => {
      if (prevSelectedContacts.includes(contactId)) {
        return prevSelectedContacts.filter((id) => id !== contactId);
      } else {
        return [...prevSelectedContacts, contactId];
      }
    });
  };

  const [selectedContacts, setSelectedContacts] = useState([]);
  const [groupChatName, setGroupChatName] = useState("");

  const handleCreateGroupChat = async () => {
    if (selectedContacts.length < 2) return;

    const db = getDatabase();
    const chatId = selectedContacts.sort().join("_");
    const chatRef = ref(db, `group_chats/${chatId}`);

    const participants = selectedContacts.map((contactId) => {
      const contact = contacts.find((c) => c.id === contactId);
      return contact ? contact.username : "";
    });

    const groupName = participants.join(", ");
    setGroupChatName(groupName);

    await set(chatRef, {
      name: groupName,
      participants: selectedContacts,
      createdAt: Date.now(),
    });

    setShowCreateGroupChatOverlay(false);
    setSelectedContacts([]);
    navigate(`/group_chat/${chatId}`);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
        if (userDoc.exists()) {
          setUsername(userDoc.data().username);
        }
      }
    };

    const fetchContacts = async () => {
      if (!currentUser) return;

      const cachedContacts = localStorage.getItem("cachedContacts");
      if (cachedContacts) {
        setContacts(JSON.parse(cachedContacts));
      }

      const db = getDatabase();
      const chatsRef = ref(db, "chats");

      try {
        const followersQuery = query(
          collection(firestore, "users", currentUser.uid, "followers")
        );
        const followingQuery = query(
          collection(firestore, "users", currentUser.uid, "following")
        );

        const [followersSnapshot, followingSnapshot, chatsSnapshot] =
          await Promise.all([
            getDocs(followersQuery),
            getDocs(followingQuery),
            get(chatsRef),
          ]);

        const followerIds = followersSnapshot.docs.map(
          (doc) => doc.data().followerId
        );
        const followingIds = followingSnapshot.docs.map(
          (doc) => doc.data().followingId
        );
        const chatParticipantIds = [];

        if (chatsSnapshot.exists()) {
          const chatsData = chatsSnapshot.val();
          Object.keys(chatsData).forEach((chatId) => {
            const chat = chatsData[chatId];
            if (
              chat.participants &&
              chat.participants.includes(currentUser.uid)
            ) {
              chatParticipantIds.push(chatId);
            }
          });
        }

        const uniqueContactIds = Array.from(
          new Set([...followerIds, ...followingIds, ...chatParticipantIds])
        );

        const contactPromises = uniqueContactIds.map(async (contactId) => {
          try {
            const contactDoc = await getDoc(doc(firestore, "users", contactId));
            if (!contactDoc.exists()) {
              console.warn(`No user data found for contact ID ${contactId}`);
              return null;
            }

            const chatId = [currentUser.uid, contactId].sort().join("_");
            const chatSnapshot = await get(child(ref(db, "chats"), chatId));
            let lastMessage = "No messages yet";
            let lastMessageTimestamp = 0;
            let hasUnreadMessages = false;

            if (chatSnapshot.exists()) {
              const chatData = chatSnapshot.val();
              const lastMessageKey = Object.keys(chatData).pop();
              const lastMessageData = chatData[lastMessageKey];
              lastMessage = lastMessageData.text;
              lastMessageTimestamp = lastMessageData.timestamp;

              // Check for unread messages
              hasUnreadMessages = Object.values(chatData).some(
                (message) =>
                  message.senderId !== currentUser.uid && !message.read
              );
            }

            // Truncate lastMessage to 30 characters
            if (lastMessage.length > 30) {
              lastMessage = lastMessage.slice(0, 30) + "...";
            }

            return {
              id: contactId,
              ...contactDoc.data(),
              lastMessage,
              lastMessageTimestamp,
              hasUnreadMessages,
            };
          } catch (error) {
            console.error(`Error fetching contact ID ${contactId}:`, error);
            return null;
          }
        });

        const contactDocs = await Promise.all(contactPromises);
        const contactsData = contactDocs.filter((data) => data !== null);

        contactsData.sort(
          (a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp
        );

        // Add AI contact
        const aiContact = {
          id: "ai_contact",
          username: "VibeAI",
          profileImage: AIimage,
          lastMessage: "How can I assist you today?",
          lastMessageTimestamp: Date.now(),
          hasUnreadMessages: false,
        };

        contactsData.unshift(aiContact);

        setContacts((prevContacts) => {
          const updatedContacts = contactsData.map((newContact) => {
            const existingContact = prevContacts.find(
              (c) => c.id === newContact.id
            );
            if (
              existingContact &&
              JSON.stringify(existingContact) === JSON.stringify(newContact)
            ) {
              return existingContact; // No change, keep existing contact
            }
            return newContact; // New or updated contact
          });

          // Cache the updated contacts
          localStorage.setItem(
            "cachedContacts",
            JSON.stringify(updatedContacts)
          );
          return updatedContacts;
        });
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchUserData();
    fetchContacts();
  }, [currentUser]);

  const handleChatClick = (contactId) => {
    if (contactId === "ai_contact") {
      navigate("/ai-chat");
    } else {
      navigate(`/chat/${contactId}`);
    }
  };

  if (isDesktop) {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "#121516",
          minHeight: "100vh",
        }}
      >
        {/* Left Sidebar */}
        <div
          style={{
            width: "350px",
            position: "fixed",
            left: 0,
            top: 0,
            height: "100vh",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "12px",
              left: "12px",
              animation: "fadeInUp 1s ease-in-out",
            }}
          >
            <Sidebar
              activeTab={activeTab}
              handleTabClick={setActiveTab}
              events={[]}
              loading={isLoading}
              handleCardClick={() => {}}
            />
          </div>
        </div>
        <div
          style={{
            width: "1px",
            height: "100vh",
            position: "fixed",
            left: "350px",
            top: 0,
            background: "var(--black, #2E313C)",
          }}
        ></div>

        {/* Main Content */}
        <div
          style={{
            flex: 1,
            padding: "20px",
            marginLeft: isWideDesktop ? "auto" : "350px",
            marginRight: isWideDesktop ? "auto" : "0",
            maxWidth: isWideDesktop ? "800px" : "calc(100% - 350px)",
            width: isWideDesktop ? "800px" : "auto",
            overflowY: "auto",
            height: "100vh",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <h1
            style={{
              color: "#FFF",
              fontSize: "24px",
              fontWeight: 700,
              marginBottom: "20px",
              marginTop: "0px",
            }}
          >
            Messages
          </h1>
          <div style={{ marginBottom: "20px" }}>
            {contacts.length > 0 ? (
              contacts.map((contact, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "12px",
                    marginBottom: "8px",
                    cursor: "pointer",
                    borderRadius: "12px",
                    transition: "all 0.3s ease",
                    backgroundColor: "rgba(255,255,255,0.05)",
                    opacity: 0,
                    transform: "translateY(20px)",
                    animation: `fadeInFromBottom 0.5s ease forwards ${
                      index * 0.1
                    }s`,
                  }}
                  onClick={() => handleChatClick(contact.id)}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor =
                      "rgba(255,255,255,0.1)";
                    e.currentTarget.style.transform = "translateY(-2px)";
                    e.currentTarget.style.boxShadow =
                      "0 4px 12px rgba(0,0,0,0.1)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor =
                      "rgba(255,255,255,0.05)";
                    e.currentTarget.style.transform = "translateY(0)";
                    e.currentTarget.style.boxShadow = "none";
                  }}
                >
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      marginRight: "16px",
                      overflow: "hidden",
                      border: "2px solid #2C2F33",
                    }}
                  >
                    <img
                      src={contact.profileImage || fallbackImage}
                      alt={contact.username}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "4px",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "white",
                        }}
                      >
                        {contact.username}
                      </span>
                      {contact.hasUnreadMessages && (
                        <div
                          style={{
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                            backgroundColor: "#0c54c6",
                          }}
                        ></div>
                      )}
                    </div>
                    <div
                      style={{
                        color: "rgba(255,255,255,0.7)",
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                      }}
                    >
                      {contact.lastMessage}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{
                  textAlign: "center",
                  color: "gray",
                  marginTop: "16px",
                }}
              >
                No contacts to show.
              </div>
            )}
          </div>
          <style>
            {`
            @keyframes fadeInFromBottom {
              from {
                opacity: 0;
                transform: translateY(20px);
              }
              to {
                opacity: 1;
                transform: translateY(0);
              }
            }
          `}
          </style>
        </div>

        {/* Right Sidebar (Tournament) */}
        {isWideDesktop && <TournamentSidebar />}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: "linear-gradient(to bottom, #121516, #1A1E1F)",
        color: "white",
        width: "100vw",
        height: "100vh",
        fontFamily: "'Inter', sans-serif",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px",
          borderBottom: "1px solid rgba(255,255,255,0.1)",
          position: "sticky",
          top: 0,
          backgroundColor: "#121516",
          zIndex: 10,
        }}
      >
        <svg
          style={{ width: "24px", height: "24px", cursor: "pointer" }}
          onClick={() => navigate("/home")}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19l-7-7 7-7"
          />
        </svg>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
          }}
        >
          <span style={{ fontSize: "18px", fontWeight: "bold" }}>
            {username}
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <span
          style={{ fontWeight: "bold", fontSize: "20px", marginLeft: "5px" }}
        >
          Messages
        </span>
        {/* <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#0c54c6",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 5V19M5 12H19"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div> */}
      </div>
      <div style={{ flex: 1, overflowY: "auto", padding: "0 16px" }}>
        {contacts.length > 0 ? (
          contacts.map((contact, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "12px",
                marginBottom: "8px",
                cursor: "pointer",
                borderRadius: "12px",
                transition: "all 0.3s ease",
                backgroundColor: "rgba(255,255,255,0.05)",
                opacity: 0,
                transform: "translateY(20px)",
                animation: `fadeInFromBottom 0.5s ease forwards ${
                  index * 0.1
                }s`,
              }}
              onClick={() => handleChatClick(contact.id)}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "rgba(255,255,255,0.1)";
                e.currentTarget.style.transform = "translateY(-2px)";
                e.currentTarget.style.boxShadow = "0 4px 12px rgba(0,0,0,0.1)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255,255,255,0.05)";
                e.currentTarget.style.transform = "translateY(0)";
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  marginRight: "16px",
                  overflow: "hidden",
                  border: "2px solid #2C2F33",
                }}
              >
                <img
                  src={contact.profileImage || fallbackImage}
                  alt={contact.username}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "4px",
                  }}
                >
                  <span style={{ fontWeight: "600", fontSize: "16px" }}>
                    {contact.username}
                  </span>
                  {contact.hasUnreadMessages && (
                    <div
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor: "#0c54c6",
                      }}
                    ></div>
                  )}
                </div>
                <div
                  style={{
                    color: "rgba(255,255,255,0.7)",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "200px",
                  }}
                >
                  {contact.lastMessage}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div
            style={{ textAlign: "center", color: "gray", marginTop: "16px" }}
          >
            No contacts to show.
          </div>
        )}
        <style>
          {`
          @keyframes fadeInFromBottom {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
        </style>
      </div>
    </div>
  );
};

export default MessagesPage;
