import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/VexVibe.svg";
import logoName from "../assets/images/CircleLogo.svg";
import "../assets/styles/signupPage.css"; // Reusing the same styles as signup
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/"); // Navigate to home after login
    } catch (error) {
      console.error("Error logging in:", error);
      alert(error.message); // Provide user feedback
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(to bottom right, #121516, #242A2B)",
        color: "white",
      }}
    >
      <Link to="/welcome" className="backButton">
        {/* SVG backButton code here */}
      </Link>
      <img
        src={logo}
        alt="VexVibe Logo"
        style={{
          position: "absolute",
          top: 15,
          left: "50%",
          transform: "translateX(-50%)",
          height: "auto", // Maintain aspect ratio
          width: "80%",
        }}
      />
      <h1 className="signupText">Log in</h1>
      <img
        src={logoName}
        alt="Logo Name"
        className="logoName"
        style={{ marginRight: "25px", width: "125px", marginTop: "-25px" }}
      />
      <div className="emailBox" style={{ marginLeft: "185px" }}>
        {/* Email icon SVG */}
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Email"
          className="emailInput" // Consider renaming this class to 'inputField'
        />
      </div>
      <div className="passwordBox" style={{ marginLeft: "185px" }}>
        {/* Password icon SVG */}
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Password"
          className="passwordInput" // Consider renaming this class to 'inputField'
        />
      </div>
      <button className="signupButton" onClick={handleLogin} style={{ marginTop: "40px", marginLeft: "185px", width: "375px" }}>
        {" "}
        {/* Consider renaming this class to 'loginButton' */}
        Log In
      </button>
    </div>
  );
};

export default LoginPage;
