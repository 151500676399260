import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/VexVibe.svg";
import logoName from "../assets/images/CircleLogo.svg";
import "../assets/styles/signupPage.css";
import { auth, firestore } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

const SignupPage = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSignUp = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Ensure you're using `doc` and `firestore` correctly to reference a document
      await setDoc(doc(firestore, "users", user.uid), {
        fullName,
        email,
      });

      // Clear form fields and navigate to the onboarding page
      setFullName("");
      setEmail("");
      setPassword("");
      navigate("/onboarding");
    } catch (error) {
      console.error("Error signing up:", error);
      console.error("Error details:", error.code, error.message);
      if (error.code === "auth/email-already-in-use") {
        console.log(
          "This email is already in use. Please log in or use a different email."
        );
      }
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(to bottom right, #121516, #242A2B)",
        color: "white",
      }}
    >
      <Link to="/welcome" className="backButton">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="45"
          height="45"
          viewBox="0 0 45 45"
          fill="none"
        >
          <path
            d="M30.7895 0H14.2105C6.36227 0 0 6.36227 0 14.2105V30.7895C0 38.6377 6.36227 45 14.2105 45H30.7895C38.6377 45 45 38.6377 45 30.7895V14.2105C45 6.36227 38.6377 0 30.7895 0Z"
            fill="white"
          />
          <path
            d="M24.9211 16.9998L19 22.5466L24.9211 28.4677"
            stroke="#201D67"
            strokeWidth="2.36842"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Link>
      <img
        src={logo}
        alt="VexVibe Logo"
        className="signupLogo"
        style={{ marginTop: "-1080px", marginLeft: "100px" }}
      />
      <h1 className="signupText">Create your Account</h1>
      <img
        src={logoName}
        alt="Logo Name"
        className="logoName"
        style={{ width: "125px", marginTop: "-10px" }}
      />

      <div className="fullNameBox">
        <svg
          className="fullNameIcon"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
        >
          <path
            d="M9.6801 8.81333C11.8377 8.81333 13.5868 7.06426 13.5868 4.90667C13.5868 2.74907 11.8377 1 9.6801 1C7.52251 1 5.77344 2.74907 5.77344 4.90667C5.77344 7.06426 7.52251 8.81333 9.6801 8.81333Z"
            stroke="#666873"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.78662 19.6668V17.4402C1.78662 14.8402 3.89329 12.7468 6.47996 12.7468H13.5066C16.1066 12.7468 18.2 14.8535 18.2 17.4402V19.6668"
            stroke="#666873"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <input
          type="text"
          value={fullName}
          onChange={handleFullNameChange}
          placeholder="Full Name"
          className="fullNameInput"
        />
      </div>
      <div className="emailBox" style={{ marginLeft: "185px" }}>
        <svg
          className="emailIcon"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
        >
          <path
            d="M16.6648 15.08H3.33513C2.05081 15.08 1 14.0039 1 12.6663V3.41371C1 2.08617 2.04108 1 3.33513 1H16.6648C17.9492 1 19 2.07611 19 3.41371V12.6663C19 14.0039 17.9589 15.08 16.6648 15.08Z"
            stroke="#666873"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.9976 1.77405L11.3133 8.63302C10.5641 9.40742 9.43542 9.40742 8.6765 8.63302L1.99219 1.77405"
            stroke="#666873"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.0723 7.95929L17.9977 13.9031"
            stroke="#666873"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.00195 13.9033L7.99546 8.03998"
            stroke="#666873"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Email"
          className="emailInput"
        />
      </div>

      <div className="passwordBox" style={{ marginLeft: "185px" }}>
        <svg
          className="passwordIcon"
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="21"
          viewBox="0 0 19 21"
          fill="none"
        >
          <path
            d="M0.916504 16.9123V12.756C0.916504 10.9435 2.38526 9.46436 4.20817 9.46436H14.8019C16.6144 9.46436 18.0936 10.9331 18.0936 12.756V16.9123C18.0936 18.7248 16.6248 20.2039 14.8019 20.2039H4.20817C2.38526 20.2039 0.916504 18.7352 0.916504 16.9123Z"
            stroke="#666873"
            strokeWidth="1.5625"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.13525 9.4749V6.29781C4.13525 3.33948 6.5415 0.933228 9.49983 0.933228C12.4582 0.933228 14.8644 3.33948 14.8644 6.29781V9.4749H4.13525Z"
            stroke="#666873"
            strokeWidth="1.5625"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.5 16.9854V14.2979"
            stroke="#666873"
            strokeWidth="1.5625"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.49967 14.8396C10.0922 14.8396 10.5726 14.3592 10.5726 13.7666C10.5726 13.1741 10.0922 12.6937 9.49967 12.6937C8.90712 12.6937 8.42676 13.1741 8.42676 13.7666C8.42676 14.3592 8.90712 14.8396 9.49967 14.8396Z"
            stroke="#666873"
            strokeWidth="1.5625"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Password"
          className="passwordInput"
        />
      </div>
      <button className="signupButton" onClick={handleSignUp} style={{ marginLeft: "185px", marginTop: "40px", width: "375px" }}>
        Sign Up
      </button>
      <p className="loginText">
        Already have an account?{" "}
        <Link to="/login" className="loginLink" style={{ color: "lightblue" }}>
          Log In
        </Link>
      </p>
    </div>
  );
};

export default SignupPage;
