import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  orderBy,
  query,
  where,
  runTransaction,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import axios from "axios";
import { getDatabase, set } from "firebase/database";
import { limit } from "firebase/firestore";
import AuthContext from "../context/AuthContext";
import { firestore, storage, auth } from "../firebase";
import "../assets/styles/profilePage.css";
import defaultImage from "../assets/images/CircleLogo.svg";
import Dock from "../components/Dock";
import imageCompression from "browser-image-compression";
import ProfileSettingIcon from "../assets/images/ProfileSettings.svg";
import ProfileEditIcon from "../assets/images/ProfileEdit.svg";
import likeIcon from "../assets/images/Like.svg";
import unlikeIcon from "../assets/images/Unlike.svg";
import closePostIcon from "../assets/images/ClosePost.svg";
import { useParams } from "react-router-dom";
import threeDotIcon from "../assets/images/3dot.svg";
import { signOut } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getApp } from "firebase/app";
import Sidebar from "../components/Sidebar";
import { ref as dbRef } from "firebase/database";
import { db } from "../firebase";
import ProfileCheckmark from "../assets/images/ProfileCheckmark.svg";
import {
  useFloating,
  useInteractions,
  useClick,
  useDismiss,
  useRole,
  FloatingOverlay,
  FloatingFocusManager,
} from "@floating-ui/react-dom-interactions";
import { getFunctions, httpsCallable } from "firebase/functions";
import { loadStripe } from "@stripe/stripe-js";
import TournamentSidebar from "../components/TournamentSidebar";
import { Link } from "react-router-dom";

const ProfilePage = () => {
  const [activeTab, setActiveTab] = useState("posts");
  const [profile, setProfile] = useState(null);
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [showProfileSettings, setShowProfileSettings] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [usernames, setUsernames] = useState({});
  const [setIsTogglingLike] = useState(false);
  const { username, teamId } = useParams();
  const [isTeamProfile, setIsTeamProfile] = useState(false);
  const [followersCount, setFollowersCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [postCount, setPostCount] = useState(0);
  const [setShowDropdown] = useState(null);
  const [selectedPostId, setSelectedPostId] = useState(null);
  const isCurrentUserProfile = currentUser?.username === username;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followingList, setFollowingList] = useState([]);
  const [showFollowingOverlay, setShowFollowingOverlay] = useState(false);
  const [showFollowersOverlay, setShowFollowersOverlay] = useState(false);
  const [followersList, setFollowersList] = useState([]);
  const [showBlockedUsersOverlay, setShowBlockedUsersOverlay] = useState(false); // New state for blocked users overlay
  const [blockedUsersList, setBlockedUsersList] = useState([]); // New state for blocked users list
  const [isProfileImageOpen, setIsProfileImageOpen] = useState(false);
  const [bio, setBio] = useState(profile?.bio || "");
  const overlayRef = useRef(null);
  const [showUpgradeOverlay, setShowUpgradeOverlay] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1439);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userRegion, setUserRegion] = useState(null);
  const [tournamentActiveTab, setTournamentActiveTab] = useState("myRegion");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isWideDesktop, setIsWideDesktop] = useState(window.innerWidth >= 1580);
  const [hasStory, setHasStory] = useState(false);
  const [storyViewed, setStoryViewed] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 769);
      setIsWideDesktop(window.innerWidth >= 1580);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchProfileAndPosts = async () => {
      try {
        // Decode the username from the URL
        const decodedUsername = decodeURIComponent(username);

        // Fetch user profile
        const usernameDoc = await getDoc(
          doc(firestore, "usernames", decodedUsername)
        );
        if (usernameDoc.exists()) {
          const uid = usernameDoc.data().uid;
          const userDoc = await getDoc(doc(firestore, "users", uid));
          if (userDoc.exists()) {
            setProfile({ uid, ...userDoc.data() });
          }
        }
        // Fetch user posts
        if (profile && profile.uid) {
          const postsRef = collection(firestore, `users/${profile.uid}/posts`);
          const postsSnapshot = await getDocs(postsRef);
          const postsData = postsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setPosts(postsData);
        }

        // Fetch stories
        if (profile && profile.uid) {
          const storyRef = doc(firestore, "stories", profile.uid);
          const storyDoc = await getDoc(storyRef);

          if (
            storyDoc.exists() &&
            storyDoc.data().stories &&
            storyDoc.data().stories.length > 0
          ) {
            setHasStory(true);

            // Check if the story has been viewed by the current user
            if (currentUser) {
              const viewedStoriesRef = doc(
                firestore,
                `users/${currentUser.uid}/viewedStories/${profile.uid}`
              );
              const viewedStoriesDoc = await getDoc(viewedStoriesRef);

              if (
                viewedStoriesDoc.exists() &&
                viewedStoriesDoc.data().viewedStories
              ) {
                const allStoriesViewed = storyDoc
                  .data()
                  .stories.every((story) =>
                    viewedStoriesDoc.data().viewedStories.includes(story.id)
                  );
                setStoryViewed(allStoriesViewed);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching profile and posts:", error);
      }
    };

    fetchProfileAndPosts();
  }, [username, currentUser, profile]);

  const cardStyle = {
    background: "#1d2021",
    borderRadius: "12.84px",
    margin: "10px auto",
    padding: "16px",
    width: "90%",
    maxWidth: "350px",
    height: "auto",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    opacity: 0,
    transform: "translateY(20px)",
    animation: "fadeInUp 0.5s forwards",
    transition: "box-shadow 0.3s ease",
  };

  const fadeInUpKeyframes = `
    @keyframes fadeInUp {
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `;

  const apiKey =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiOTlmNmYyMzYwNTY0ZTY1NmJiODlkNTUwNmYzZjRiMmMxNDA0M2E2YjZlMWVkNWRmODVkNThjYzkzOWFiYzk0YjIyYTYwYjA4MzcyNTZmNjYiLCJpYXQiOjE3MTY2NzM2MTguMTM0NTE0MSwibmJmIjoxNzE2NjczNjE4LjEzNDUxNywiZXhwIjoyNjYzMzYyMDE4LjEyODY2NSwic3ViIjoiMTI3Nzg2Iiwic2NvcGVzIjpbXX0.jhOKVtQQI1acAwIRrAR78xw7l__XHewZzJDYBf3OMzHZ4zsIh_6jWG1ulswGCN6128v_dwPji6s8JmVQj_tmcn-KyDLQACkY8ZJHQXZLIuTSrNGXFKE-DLqFKO9an04kmZoMKtZay86bCuSMiHoJGkd9WctvUdAkjzNdsWBz8lwD48hLY5h2F5U55YhX2areEoPyioOsN1QHe9ExoXOk8fW88vpgvw7_aZnmhTp5BV6WICnIlP1PCLWxUI_EwI8X32QJ1PJhOUFJ6eEb7iM2KPT3jlJmJBy675wDCix84H11jSUCJs8F-LIXRERVERniWElTWp9tIJYRk1HRQN4bhCEnuzL8U01oO49Ghc-k9vYQnY3WlHwAJEGuPQtQt8NttI2DcBDcBM3acQXHHHfvKvk6pc1__bwM32W41Ealqku1dL5Jw8MNUCFqAthDd--mjtkyevuGJsLwu1NobdI94wNDHna8iuRg27XwRGXJKGy32scGsmeBnplhEdOBbVu44Ns1MGJuxmLLffICQWVdEl4Gwth63r4AzBiX__iMYwJw39MfWpiyYrDZRX7Z6n12m2WeeZqrdmRAuSyzVSeZIqFs6TgqbgAuFOMo68aN_6InrbMD62PvXhdH8CL6wR05_v_Gz10OJtAWdwboXB780tdd7X5mdP2KcBZHbqvSbl4"; // Replace with your actual API key
  const seasonId = "190"; // Update this with the correct season ID

  const loadUserRegionTournaments = async () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userRegion = userDoc.data().region;
          setUserRegion(userRegion);
          await fetchUserRegionEvents(userRegion);
        } else {
          console.error("User document does not exist");
        }
      } else {
        console.error("No user is logged in");
      }
    } catch (error) {
      console.error("Error fetching user region:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserRegionEvents = async (region, page) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://www.robotevents.com/api/v2/events`,
        {
          params: {
            season: seasonId,
            region: region,
            eventType: "tournament",
            page: page,
          },
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      const sortedEvents = response.data.data.sort(
        (a, b) => new Date(a.start) - new Date(b.start)
      );
      if (sortedEvents.length === 0) {
        setHasMore(false);
      } else {
        setEvents((prevEvents) => [...prevEvents, ...sortedEvents]);
      }
    } catch (error) {
      console.error("Failed to fetch events:", error);
      setEvents([]);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllWorldwideEvents = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://www.robotevents.com/api/v2/events`,
        {
          params: {
            season: seasonId,
            eventTypes: ["tournament"],
            page: page,
          },
          headers: {
            Authorization: `Bearer ${apiKey}`,
            accept: "application/json",
          },
        }
      );
      const sortedAllEvents = response.data.data.sort(
        (a, b) => new Date(a.start) - new Date(b.start)
      );
      if (sortedAllEvents.length === 0) {
        setHasMore(false);
      } else {
        setEvents((prevEvents) => [...prevEvents, ...sortedAllEvents]);
      }
    } catch (error) {
      console.error("Failed to fetch worldwide events:", error);
      setEvents([]);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setPosts([]);
    setIsLoading(true);

    if (tab === "posts") {
      fetchUserPosts();
    } else if (tab === "likes") {
      fetchUserLikedPosts();
    } else if (tab === "media") {
      fetchUserMediaPosts();
    }
  };

  const handleTournamentTabClick = async (tab) => {
    setTournamentActiveTab(tab);
    setPage(1);
    setHasMore(true);
    setEvents([]);

    if (tab === "myRegion") {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userRegion = userDoc.data().region;
            fetchUserRegionEvents(userRegion, 1);
          } else {
            console.error("User document does not exist");
          }
        } else {
          console.error("No user is logged in");
        }
      } catch (error) {
        console.error("Error fetching user region:", error);
      }
    } else if (tab === "all") {
      fetchAllWorldwideEvents(1);
    }
  };

  const handleSidebarTabClick = async (tab) => {
    setTournamentActiveTab(tab);
    setPage(1);
    setHasMore(true);
    setEvents([]);

    if (tab === "myRegion") {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userRegion = userDoc.data().region;
            fetchUserRegionEvents(userRegion, 1);
          } else {
            console.error("User document does not exist");
          }
        } else {
          console.error("No user is logged in");
        }
      } catch (error) {
        console.error("Error fetching user region:", error);
      }
    } else if (tab === "all") {
      fetchAllWorldwideEvents(1);
    }
  };

  const fetchUserPosts = async () => {
    try {
      const postsQuery = query(
        collection(firestore, "posts"),
        where("userId", "==", profile.userId),
        orderBy("createdAt", "desc"),
        limit(10)
      );
      const querySnapshot = await getDocs(postsQuery);
      const fetchedPosts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(fetchedPosts);
    } catch (error) {
      console.error("Error fetching user posts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserLikedPosts = async () => {
    try {
      const likesQuery = query(
        collection(firestore, "users", profile.userId, "likes"),
        orderBy("createdAt", "desc"),
        limit(10)
      );
      const likesSnapshot = await getDocs(likesQuery);
      const postIds = likesSnapshot.docs.map((doc) => doc.id);

      const postsPromises = postIds.map((id) =>
        getDoc(doc(firestore, "posts", id))
      );
      const postsDocs = await Promise.all(postsPromises);

      const fetchedPosts = postsDocs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((post) => post.id); // Filter out any undefined posts

      setPosts(fetchedPosts);
    } catch (error) {
      console.error("Error fetching liked posts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserMediaPosts = async () => {
    try {
      const mediaPostsQuery = query(
        collection(firestore, "posts"),
        where("userId", "==", profile.userId),
        where("imageUrl", "!=", null),
        orderBy("imageUrl"),
        orderBy("createdAt", "desc"),
        limit(10)
      );
      const querySnapshot = await getDocs(mediaPostsQuery);
      const fetchedPosts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(fetchedPosts);
    } catch (error) {
      console.error("Error fetching media posts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardClick = (eventId) => {
    navigate(`/events/${eventId}`);
  };

  const handleUpgradeClick = () => {
    setShowUpgradeOverlay(true);
  };

  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      if (currentUser && currentUser.uid) {
        const userRef = doc(firestore, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          setIsSubscribed(userData.subscriptionStatus === "active");
        }
      }
    };

    fetchSubscriptionStatus();
  }, [currentUser]);

  const handleManageSubscription = async () => {
    if (!currentUser || !currentUser.uid) {
      console.error("User not authenticated");
      return;
    }

    try {
      // Fetch the user's document from Firestore
      const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const subscriptionId = userData.subscriptionId;

        if (subscriptionId) {
          // Construct the URL with the subscription ID
          const portalUrl = `https://billing.stripe.com/p/login/bIY7ur13ZcXtglq000?prefilled_email=${encodeURIComponent(
            userData.email
          )}`;
          window.location.href = portalUrl;
        } else {
          console.error("No subscription ID found for user");
          // You might want to handle this case, perhaps by showing an error message to the user
        }
      } else {
        console.error("User document not found");
        // Handle the case where the user document doesn't exist
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle any errors that occur during the process
    }
  };
  const handleCloseOverlay = () => {
    setShowUpgradeOverlay(false);
  };

  // Function to handle the click event on the three-dot icon of a post
  const handleThreeDotClick = (postId) => {
    setSelectedPostId(postId); // Set the selected post ID
    setIsDropdownOpen(!isDropdownOpen); // Toggle the dropdown menu
  };

  // Function to handle clicks outside of the blocked users overlay
  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setShowBlockedUsersOverlay(false); // Close the blocked users overlay if clicked outside
    }
  };

  // useEffect to add or remove the mousedown event listener based on the state of showBlockedUsersOverlay
  useEffect(() => {
    if (showBlockedUsersOverlay) {
      document.addEventListener("mousedown", handleClickOutside); // Add event listener to detect clicks outside
    } else {
      document.removeEventListener("mousedown", handleClickOutside); // Remove event listener when overlay is not shown
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup event listener on component unmount
    };
  }, [showBlockedUsersOverlay]);

  // Function to handle the Escape key press event
  const handleEscapeKey = (event) => {
    if (event.key === "Escape") {
      setIsDropdownOpen(false); // Close the dropdown menu if Escape key is pressed
    }
  };

  // useEffect to add event listeners for mousedown and keydown events
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside); // Add event listener for mousedown
    document.addEventListener("keydown", handleEscapeKey); // Add event listener for keydown

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup mousedown event listener
      document.removeEventListener("keydown", handleEscapeKey); // Cleanup keydown event listener
    };
  }, []);

  // Function to handle the deletion of a post
  const handleDeletePost = async () => {
    if (selectedPostId) {
      // Check if a post is selected
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this post?"
      ); // Confirm deletion with the user
      if (confirmDelete) {
        try {
          await deleteDoc(doc(firestore, "posts", selectedPostId)); // Delete the post from Firestore
          setPosts(posts.filter((post) => post.id !== selectedPostId)); // Update the posts state to remove the deleted post
          setSelectedPostId(null); // Reset the selected post ID
          setShowDropdown(null); // Close the dropdown menu
        } catch (error) {
          console.error("Error deleting post: ", error); // Log any errors
        }
      }
    }
  };

  // Function to toggle the profile settings visibility
  const toggleProfileSettings = () => {
    setShowProfileSettings(!showProfileSettings); // Toggle the profile settings state
  };

  // useEffect to fetch followers, following, and post counts
  useEffect(() => {
    const fetchCounts = async () => {
      if (profile && profile.userId) {
        // Check if profile and userId are available
        const userId = profile.userId;

        // Fetch followers count
        const followersQuery = query(
          collection(firestore, "users", userId, "followers")
        );
        const followersSnapshot = await getDocs(followersQuery);
        setFollowersCount(followersSnapshot.size); // Set the followers count

        // Fetch following count
        const followingQuery = query(
          collection(firestore, "users", userId, "following")
        );
        const followingSnapshot = await getDocs(followingQuery);
        setFollowingCount(followingSnapshot.size); // Set the following count

        // Fetch post count
        const postsQuery = query(
          collection(firestore, "posts"),
          where("userId", "==", userId)
        );
        const postsSnapshot = await getDocs(postsQuery);
        setPostCount(postsSnapshot.size); // Set the post count
      }
    };

    fetchCounts();
  }, [profile]);

  const checkFollowStatus = useCallback(async () => {
    if (!currentUser || !profile) return;

    const followersCollectionRef = collection(
      firestore,
      "users",
      profile.userId,
      "followers"
    );
    const followQuery = query(
      followersCollectionRef,
      where("followerId", "==", currentUser.uid)
    );
    const followSnap = await getDocs(followQuery);
    setIsFollowing(followSnap.docs.length > 0);
    console.log(followSnap.docs.length);
  }, [currentUser, profile]);

  useEffect(() => {
    checkFollowStatus();
  }, [checkFollowStatus]);

  // Function to handle follow/unfollow click
  const handleFollowClick = async () => {
    if (!currentUser || !currentUser.uid) {
      console.error("User not authenticated or currentUser.uid is undefined");
      return;
    }

    if (!profile || !profile.userId) {
      console.error("Profile data is not loaded or missing userId");
      return;
    }

    const followerId = currentUser.uid;
    const followingId = profile.userId;

    const followersCollectionRef = collection(
      firestore,
      "users",
      followingId,
      "followers"
    );
    const followingCollectionRef = collection(
      firestore,
      "users",
      followerId,
      "following"
    );

    const followQuery = query(
      followersCollectionRef,
      where("followerId", "==", followerId)
    );
    const followSnap = await getDocs(followQuery);

    try {
      if (followSnap.empty) {
        // Follow logic
        await addDoc(followersCollectionRef, {
          followerId: followerId,
          createdAt: serverTimestamp(),
        });
        await addDoc(followingCollectionRef, {
          followingId: followingId,
          createdAt: serverTimestamp(),
        });
        setIsFollowing(true);

        // Create a notification for the user being followed
        const notificationsRef = collection(firestore, "notifications");
        await addDoc(notificationsRef, {
          type: "follow",
          senderId: followerId,
          recipientId: followingId,
          content: `${currentUser.username} started following you`,
          createdAt: serverTimestamp(),
        });
      } else {
        // Unfollow logic (unchanged)
        for (const doc of followSnap.docs) {
          try {
            await deleteDoc(doc.ref);
          } catch (error) {}
        }

        const unfollowQuery = query(
          followingCollectionRef,
          where("followingId", "==", followingId)
        );
        const unfollowSnap = await getDocs(unfollowQuery);
        for (const doc of unfollowSnap.docs) {
          try {
            await deleteDoc(doc.ref);
          } catch (error) {}
        }

        setIsFollowing(false);
      }
    } catch (error) {
      console.error("Error toggling follow status: ", error);
    }
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      setIsLoading(true);
      setProfile(null);
      setPosts([]);

      let userData;
      let isTeam = false;

      if (username) {
        const usersQuery = query(
          collection(firestore, "users"),
          where("username", "==", username)
        );
        const querySnapshot = await getDocs(usersQuery);
        if (!querySnapshot.empty) {
          userData = querySnapshot.docs[0].data();
          userData.userId = querySnapshot.docs[0].id;
          // Ensure we're fetching the team information
          if (userData.team) {
            console.log("Team found:", userData.team); // Add this log
          }
        } else {
          setIsLoading(false);
          return;
        }
      } else if (currentUser && currentUser.uid) {
        const userRef = doc(firestore, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          userData = userSnap.data();
          userData.userId = currentUser.uid;
          // Ensure we're fetching the team information for the current user
          if (userData.team) {
            console.log("Current user's team:", userData.team); // Add this log
          }
        } else {
          setIsLoading(false);
          return;
        }
      } else {
        setIsLoading(false);
        return;
      }

      if (userData) {
        setProfile({
          userId: userData.userId,
          username: userData.username,
          profileImage: userData.profileImage || defaultImage,
          postCount: userData.postCount || 0,
          followersCount: userData.followersCount || 0,
          followingCount: userData.followingCount || 0,
          fullName: userData.fullName || "",
          email: userData.email || "",
          gender: userData.gender || "",
          country: userData.country || "",
          region: userData.region || "",
          isTeam: isTeam,
          bio: userData.bio || "",
          team: userData.team || "", // Ensure we're setting the team in the profile state
        });
        setGender(userData.gender || "");
        setCountry(userData.country || "");
        setRegion(userData.region || "");
        setBio(userData.bio || ""); // Set the bio state
        setIsTeamProfile(isTeam);
      }

      // Fetch posts for the profile
      const postsQuery = query(
        collection(firestore, "posts"),
        where(isTeam ? "teamId" : "userId", "==", userData.userId),
        orderBy("createdAt", "desc")
      );
      const postsSnapshot = await getDocs(postsQuery);
      const fetchedPosts = postsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(fetchedPosts);

      setIsLoading(false);
    };

    fetchProfileData();
  }, [username, teamId, currentUser]);

  useEffect(() => {
    const fetchPosts = async () => {
      // Exit if profile or profile.userId is not available
      if (!profile || !profile.userId) return;

      try {
        // Create a query to fetch posts for the current profile user or team, ordered by creation date in descending order
        const postsQuery = query(
          collection(firestore, "posts"),
          where("userId", "==", profile.userId), // This should already work because we are setting `userId` properly in `fetchProfileData`
          orderBy("createdAt", "desc")
        );

        // Execute the query and get the snapshot of the results
        const querySnapshot = await getDocs(postsQuery);

        // Process each document in the snapshot
        const postsData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const postData = doc.data();
            let imageUrl = postData.imageUrl || "";

            // If there's an image URL, try to fetch the actual URL from Firebase Storage
            if (imageUrl) {
              const imageRef = ref(storage, imageUrl);
              try {
                imageUrl = await getDownloadURL(imageRef);
              } catch (error) {
                console.error("Error fetching image URL:", error);
                imageUrl = ""; // Fallback to an empty string or default image if error occurs
              }
            }

            // Return the post data with the fetched image URL and converted timestamp
            return {
              id: doc.id,
              ...postData,
              imageUrl, // Include the fetched image URL in the post data
              createdAt: postData.createdAt ? postData.createdAt.toMillis() : 0, // Convert Timestamp to milliseconds
            };
          })
        );

        // Update the state with the fetched posts
        setPosts(postsData);
      } catch (error) {
        console.error("Failed to fetch posts:", error);
      }
    };

    // Call the fetchPosts function to initiate the data fetching
    fetchPosts();
  }, [profile]); // Dependency array to re-run the effect when profile changes

  useEffect(() => {
    const fetchPostsAndUsernames = async () => {
      // If there is no current user, exit the function early
      if (!currentUser) return;

      let userId;
      if (username) {
        // Query the "users" collection to find the user with the given username
        const usersQuery = query(
          collection(firestore, "users"),
          where("username", "==", username)
        );
        const usersSnapshot = await getDocs(usersQuery);
        if (!usersSnapshot.empty) {
          // If a user with the given username is found, set the userId
          userId = usersSnapshot.docs[0].id;
        }
      } else {
        // If no username is provided, use the current user's ID
        userId = currentUser.uid;
      }

      if (userId) {
        // Query the "posts" collection to find posts by the user with the given userId
        const postsQuery = query(
          collection(firestore, "posts"),
          where("userId", "==", userId)
        );
        const postsSnapshot = await getDocs(postsQuery);
        const posts = [];
        const userIds = new Set();

        // Process each document in the posts snapshot
        for (const docSnapshot of postsSnapshot.docs) {
          const data = docSnapshot.data();
          userIds.add(data.userId); // Collect unique user IDs

          // Check if the current user has liked the post
          const likeRef = doc(
            firestore,
            `posts/${docSnapshot.id}/likes`,
            currentUser.uid
          );
          const likeSnap = await getDoc(likeRef);
          const isLiked = likeSnap.exists();

          // Add the post data to the posts array
          posts.push({ id: docSnapshot.id, ...data, isLiked });
        }

        // Sort the posts based on the createdAt field in descending order
        posts.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis());

        // Fetch usernames and profile images for the collected user IDs
        const usernamesData = {};
        for (const uid of userIds) {
          const userRef = doc(firestore, "users", uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();
            usernamesData[uid] = {
              username: userData.username,
              profileImage: userData.profileImage || defaultImage,
            };
          }
        }

        // Update the state with the fetched usernames and posts
        setUsernames(usernamesData);
        setPosts(posts);
      }
    };

    // Call the fetchPostsAndUsernames function to initiate the data fetching
    fetchPostsAndUsernames();
  }, [currentUser, username]);

  // Function to handle tab changes
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleTournamentTabChange = (tab) => {
    setTournamentActiveTab(tab);
  };

  // Function to handle the like button click
  const handleLikeClick = async (postId, isLiked) => {
    if (!currentUser) {
      console.error("User not authenticated");
      return;
    }

    // Optimistically update the UI
    setPosts((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? {
              ...post,
              isLiked: !isLiked,
              likeCount: isLiked ? post.likeCount - 1 : post.likeCount + 1,
            }
          : post
      )
    );

    const postRef = doc(firestore, "posts", postId);
    const likeRef = doc(firestore, `posts/${postId}/likes/${currentUser.uid}`);

    try {
      await runTransaction(firestore, async (transaction) => {
        const postSnapshot = await transaction.get(postRef);
        if (!postSnapshot.exists()) {
          throw new Error("Post does not exist!");
        }

        const likeSnapshot = await transaction.get(likeRef);
        let newLikeCount = postSnapshot.data().likeCount || 0;

        if (isLiked && likeSnapshot.exists()) {
          transaction.delete(likeRef);
          newLikeCount = Math.max(0, newLikeCount - 1);
        } else if (!isLiked && !likeSnapshot.exists()) {
          transaction.set(likeRef, {
            userId: currentUser.uid,
            createdAt: serverTimestamp(),
          });
          newLikeCount += 1;

          // Create notification
          const notificationRef = collection(firestore, "notifications");
          transaction.set(doc(notificationRef), {
            type: "like",
            content: `${currentUser.displayName || "Someone"} liked your post`,
            recipientId: postSnapshot.data().userId,
            senderId: currentUser.uid,
            postId: postId,
            createdAt: serverTimestamp(),
          });
        }

        transaction.update(postRef, { likeCount: newLikeCount });
      });
    } catch (error) {
      console.error("Error toggling like: ", error);
      // Revert the optimistic UI update
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId
            ? {
                ...post,
                isLiked: isLiked,
                likeCount: isLiked ? post.likeCount + 1 : post.likeCount - 1,
              }
            : post
        )
      );
    }
  };

  // Function to handle file input change for profile image upload
  const handleFileChange = async (e) => {
    const file = e.target.files[0]; // Get the selected file
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      setIsLoading(true); // Start loading animation
      try {
        // Options for image compression
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        // Compress the image file
        const compressedFile = await imageCompression(file, options);

        const storage = getStorage(); // Get Firebase storage instance
        const storageRef = ref(storage, "profileImages/" + currentUser.uid); // Reference to the storage location

        // Upload the compressed file to Firebase storage
        const snapshot = await uploadBytes(storageRef, compressedFile);

        // Get the download URL of the uploaded file
        const downloadURL = await getDownloadURL(snapshot.ref);

        // Update the profile state with the new profile image URL
        setProfile({ ...profile, profileImage: downloadURL });
        const userRef = doc(firestore, "users", currentUser.uid); // Reference to the user document
        // Update the user document with the new profile image URL
        await updateDoc(userRef, { profileImage: downloadURL });
        setIsLoading(false); // Stop loading animation
      } catch (error) {
        console.error("Error during compression or upload:", error);
        setIsLoading(false); // Ensure loading animation stops on error
      }
    } else {
      alert("Please upload an image file (PNG or JPEG)."); // Alert if the file is not a valid image
    }
  };

  // Function to handle the removal of the profile image
  const handleRemoveProfileImage = async () => {
    // Confirm with the user if they really want to remove their profile picture
    const confirmRemove = window.confirm(
      "Are you sure you want to remove your profile picture?"
    );
    if (!confirmRemove) return; // Exit if the user cancels the action

    try {
      // Reference to the user document in Firestore
      const userRef = doc(firestore, "users", currentUser.uid);
      // Update the user document to set the profile image to the default image
      await updateDoc(userRef, {
        profileImage: defaultImage, // Assuming defaultImage is the path or URL to a default image
      });
      // Update the local profile state to reflect the change
      setProfile((prevProfile) => ({
        ...prevProfile,
        profileImage: defaultImage,
      }));
      // Notify the user that the profile picture has been removed
      alert("Profile picture removed.");
    } catch (error) {
      // Log any errors that occur during the process
      console.error("Error removing profile picture: ", error);
    }
  };

  // Function to truncate text to a maximum length of 140 characters
  const truncateText = (text) => {
    // Check if the text length exceeds 140 characters
    if (text.length > 140) {
      // Truncate the text and append ellipsis
      return text.slice(0, 140) + "...";
    }
    // Return the original text if it's within the limit
    return text;
  };

  const inlineStyles = {
    post: {
      backgroundColor: "#1A1A1A",
      borderRadius: "8px",
      padding: "16px",
      marginBottom: "16px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      width: "100%",
      boxSizing: "border-box",
    },
    postHeader: {
      display: "flex",
      alignItems: "center",
      marginBottom: "8px",
    },
    postProfileImage: {
      width: "32px",
      height: "32px",
      borderRadius: "50%",
      marginRight: "8px",
    },
    postUsername: {
      fontWeight: "bold",
      color: "#fff",
    },
    postContent: {
      color: "#fff",
      marginBottom: "8px",
      marginTop: "-10px",
      cursor: "pointer",
      marginLeft: "40px",
    },
    likeSection: {
      display: "flex",
      alignItems: "center",
      marginTop: "-10px",
      marginBottom: "0px",
      marginLeft: "40px",
    },
    likeButton: {
      cursor: "pointer",
      width: "24px",
      height: "24px",
      marginLeft: "40px",
    },
    likeCount: {
      color: "#fff",
      fontFamily: "Manrope",
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "12px",
      letterSpacing: "-0.24px",
      marginLeft: "5px",
    },
    verificationPill: {
      display: "flex",
      width: "80px",
      height: "21px",
      padding: "4px",
      alignItems: "center",
      justifyContent: "center",
      flexShrink: 0,
      borderRadius: "100px",
      opacity: 0.25,
      background: "#828796",
      marginTop: "-30px",
      marginLeft: "120px",
    },
    verificationTeam: {
      color: "#fff",
      fontSize: "12px",
      fontWeight: "bold",
    },
  };

  const [region, setRegion] = useState(""); // Add this line

  const regionOptions = [
    "Alabama",
    "Alaska",
    "Alberta/Saskatchewan",
    "American Samoa",
    "Andorra",
    "Arizona",
    "Arkansas",
    "Australia",
    "Azerbaijan",
    "Bahrain",
    "Bangladesh",
    "Belgium",
    "Bermuda",
    "Brazil",
    "British Columbia (BC)",
    "Burundi",
    "California - Region 1",
    "California - Region 2",
    "California - Region 3",
    "California - Region 4",
    "Chile",
    "China",
    "Chinese Taipei",
    "Colombia",
    "Colorado",
    "Cook Islands",
    "Czech Republic",
    "Côte d’Ivoire",
    "Delaware",
    "Delmarva",
    "District of Columbia",
    "Egypt",
    "Estonia",
    "Ethiopia",
    "Finland",
    "Florida - North/Central",
    "Florida - South",
    "France",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Guam",
    "Guatemala",
    "Haiti",
    "Hawaii",
    "Hong Kong",
    "Idaho",
    "Illinois",
    "India",
    "Indiana",
    "Indonesia",
    "Iowa",
    "Ireland",
    "Japan",
    "Jordan",
    "Kansas",
    "Kazakhstan",
    "Kentucky",
    "Kenya",
    "Kuwait",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Lithuania",
    "Louisiana",
    "Luxembourg",
    "Macau",
    "Maine",
    "Malaysia",
    "Manitoba",
    "Maryland",
    "Mexico",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Morocco",
    "Myanmar",
    "Nebraska",
    "Netherlands",
    "Nevada",
    "New Brunswick/Nova Scotia/Prince Edward Island",
    "New Hampshire/Vermont",
    "New Jersey",
    "New Mexico",
    "New York-North",
    "New York-South",
    "New Zealand",
    "Newfoundland and Labrador",
    "Nigeria",
    "North Carolina",
    "North Dakota",
    "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oman",
    "Ontario",
    "Oregon",
    "Panama",
    "Paraguay",
    "Pennsylvania - East",
    "Pennsylvania - West",
    "Philippines",
    "Poland",
    "Puerto Rico",
    "Qatar",
    "Quebec",
    "Romania",
    "Russia",
    "Saudi Arabia",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "South Africa",
    "South Carolina",
    "South Dakota",
    "South Korea",
    "Southern New England",
    "Spain",
    "Suriname",
    "Switzerland",
    "Tennessee",
    "Texas - Region 1",
    "Texas - Region 2",
    "Texas - Region 3",
    "Texas - Region 4",
    "Texas - Region 5",
    "Texas - Region 6",
    "Thailand",
    "Tunisia",
    "Turkmenistan",
    "Türkiye",
    "Uganda",
    "Unassigned",
    "United Arab Emirates",
    "United Kingdom",
    "Utah",
    "Vancouver Island (BC)",
    "Vietnam",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
    "Zimbabwe",
  ];

  const [country, setCountry] = useState(""); // Ensure this state is defined

  const countryOptions = [
    "United States",
    "Canada",
    "United Kingdom",
    "Australia",
    "Germany",
    "France",
    "Spain",
    "Italy",
    "Brazil",
    "India",
    "China",
    "Japan",
    "Russia",
    "South Africa",
    "Mexico",
    "Netherlands",
    "Turkey",
    "Sweden",
    "Poland",
    "Switzerland",
    "Norway",
    "New Zealand",
    "Argentina",
    "Belgium",
    "Denmark",
    "Finland",
    "Greece",
    "Hungary",
    "Ireland",
    "Israel",
    "Portugal",
    "Romania",
    "South Korea",
    "Thailand",
    "Venezuela",
    "Vietnam",
    "Indonesia",
    "Egypt",
    "Philippines",
    "Pakistan",
    "Bangladesh",
    "Nigeria",
  ];

  // State to manage the selected gender
  const [gender, setGender] = useState("");

  // Options for gender selection
  const genderOptions = ["Male", "Female", "Other"];

  // Function to handle the dropdown toggle
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Function to handle blocking a user
  const handleBlockUser = async (userId) => {
    // Ensure there is a current user
    if (!currentUser) return;

    // Confirm the block action with the user
    const confirmBlock = window.confirm(
      "Are you sure you want to block this user?"
    );
    if (!confirmBlock) return;

    // Get the current user's ID
    const currentUserId = currentUser.uid;
    // Reference to the blocked user document in Firestore
    const blockedUserRef = doc(
      firestore,
      `users/${currentUserId}/blockedUsers/${userId}`
    );

    try {
      // Set the blocked status in Firestore
      await setDoc(blockedUserRef, { blocked: true });
      // Notify the user that the block was successful
      alert("User has been blocked.");
    } catch (error) {
      // Log any errors that occur during the block process
      console.error("Error blocking user:", error);
    }
  };

  // Function to handle messaging a user
  const handleMessageUser = async () => {
    // Ensure there is a current user and a profile to message
    if (!currentUser || !profile) return;

    // Get the initialized Firebase app instance
    const app = getApp();
    // Get the Firebase database instance
    const db = getDatabase(app);
    // Create a unique chat ID based on the user IDs
    const chatId = [currentUser.uid, profile.userId].sort().join("_");

    // Add both users to each other's contacts in the database
    await set(dbRef(db, `chatParticipants/${chatId}/${currentUser.uid}`), true);
    await set(dbRef(db, `chatParticipants/${chatId}/${profile.userId}`), true);

    // Navigate to the chat page with the profile user ID
    navigate(`/chat/${profile.userId}`);
  };

  // Function to handle user logout
  const handleLogout = async () => {
    try {
      // Sign out the user from Firebase authentication
      await signOut(auth);
      // Navigate to the welcome page
      navigate("/welcome");
      // Optionally, clear any user-related state or data here
    } catch (error) {
      // Log any errors that occur during the logout process
      console.error("Logout failed: ", error);
    }
  };

  // Function to fetch the list of followers for the profile user
  const fetchFollowersList = async () => {
    // Ensure the profile data is loaded and contains a userId
    if (!profile || !profile.userId) {
      console.error("Profile data is not loaded or missing userId");
      return;
    }

    try {
      // Query to get the followers collection for the profile user
      const followersQuery = query(
        collection(firestore, "users", profile.userId, "followers")
      );
      // Get the snapshot of the followers query
      const followersSnapshot = await getDocs(followersQuery);
      // Process each follower document in the snapshot
      const followersData = await Promise.all(
        followersSnapshot.docs.map(async (docSnapshot) => {
          const followerId = docSnapshot.data().followerId;
          // Reference to the follower user document in Firestore
          const userRef = doc(firestore, "users", followerId);
          // Get the snapshot of the follower user document
          const userSnap = await getDoc(userRef);
          // Return the follower data with the user ID
          return { userId: followerId, ...userSnap.data() };
        })
      );

      // Update the followers list state with the fetched data
      setFollowersList(followersData.filter(Boolean));
    } catch (error) {
      // Log any errors that occur during the fetch process
      console.error("Error fetching followers list:", error);
    }
  };

  // Function to handle the click event for followers
  const handleFollowersClick = async () => {
    // Fetch the list of followers
    await fetchFollowersList();
    // Show the followers overlay
    setShowFollowersOverlay(true);
  };

  // Function to fetch the list of users the profile user is following
  const fetchFollowingList = async () => {
    // Check if profile data is loaded and contains a userId
    if (!profile || !profile.userId) {
      console.error("Profile data is not loaded or missing userId");
      return;
    }

    try {
      // Query to get the following collection for the profile user
      const followingQuery = query(
        collection(firestore, "users", profile.userId, "following")
      );
      // Get the snapshot of the following query
      const followingSnapshot = await getDocs(followingQuery);
      // Process each following document in the snapshot
      const followingData = await Promise.all(
        followingSnapshot.docs.map(async (docSnapshot) => {
          const followingId = docSnapshot.data().followingId;
          // Reference to the following user document in Firestore
          const userRef = doc(firestore, "users", followingId);
          // Get the snapshot of the following user document
          const userSnap = await getDoc(userRef);
          // Return the following data with the user ID
          return { userId: followingId, ...userSnap.data() };
        })
      );

      // Update the following list state with the fetched data
      setFollowingList(followingData.filter(Boolean));
    } catch (error) {
      // Log any errors that occur during the fetch process
      console.error("Error fetching following list:", error);
    }
  };

  // Function to handle navigation to a user's profile
  const handleProfileNavigation = (username) => {
    // Navigate to the user's profile page
    navigate(`/users/${username}`);
    // Hide the followers and following overlays
    setShowFollowersOverlay(false);
    setShowFollowingOverlay(false);
  };

  // Function to handle clicks on the overlay
  const handleOverlayClick = (event) => {
    // Check if the clicked element has the "overlay" class
    if (event.target.classList.contains("overlay")) {
      // Close the overlay
      handleOverlayClose();
    }
  };

  // Function to handle the click event for following
  const handleFollowingClick = async () => {
    // Fetch the list of users the profile user is following
    await fetchFollowingList();
    // Show the following overlay
    setShowFollowingOverlay(true);
  };

  // Function to close the overlays
  const handleOverlayClose = () => {
    // Hide the following overlay
    setShowFollowingOverlay(false);
    // Hide the followers overlay
    setShowFollowersOverlay(false);
  };

  // Function to fetch the list of blocked users
  const fetchBlockedUsersList = async () => {
    // Check if the current user is authenticated
    if (!currentUser) return;

    try {
      // Query to get the blocked users collection for the current user
      const blockedUsersQuery = query(
        collection(firestore, "users", currentUser.uid, "blockedUsers")
      );
      // Get the snapshot of the blocked users query
      const blockedUsersSnapshot = await getDocs(blockedUsersQuery);
      // Process each blocked user document in the snapshot
      const blockedUsersData = await Promise.all(
        blockedUsersSnapshot.docs.map(async (docSnapshot) => {
          const blockedUserId = docSnapshot.id;
          // Reference to the blocked user document in Firestore
          const userRef = doc(firestore, "users", blockedUserId);
          // Get the snapshot of the blocked user document
          const userSnap = await getDoc(userRef);
          // Return the blocked user data with the user ID if the document exists
          if (userSnap.exists()) {
            return { userId: blockedUserId, ...userSnap.data() };
          }
          return null;
        })
      );

      // Update the blocked users list state with the fetched data, filtering out any null entries
      setBlockedUsersList(blockedUsersData.filter(Boolean));
    } catch (error) {
      // Log any errors that occur during the fetch process
      console.error("Error fetching blocked users list:", error);
    }
  };

  // Function to handle the click event for blocked users
  const handleBlockedUsersClick = async () => {
    // Fetch the list of blocked users
    await fetchBlockedUsersList();
    // Show the blocked users overlay
    setShowBlockedUsersOverlay(true);
  };

  // Function to handle unblocking a user
  const handleUnblockUser = async (userId) => {
    // Exit if there is no current user
    if (!currentUser) return;

    // Get the current user's ID
    const currentUserId = currentUser.uid;
    // Reference to the blocked user document in Firestore
    const blockedUserRef = doc(
      firestore,
      `users/${currentUserId}/blockedUsers/${userId}`
    );

    try {
      // Delete the blocked user document from Firestore
      await deleteDoc(blockedUserRef);
      // Update the blocked users list state by filtering out the unblocked user
      setBlockedUsersList(
        blockedUsersList.filter((user) => user.userId !== userId)
      );
    } catch (error) {
      // Log any errors that occur during the unblock process
      console.error("Error unblocking user:", error);
    }
  };

  const renderPostContent = (content) => {
    const parts = content.split(/@(\w+)/);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        // This is a username
        return (
          <Link
            key={index}
            to={`/users/${part}`}
            style={{
              color: "#1DA1F2", // Twitter blue color
              textDecoration: "none",
              fontWeight: "bold",
            }}
            onClick={(e) => e.stopPropagation()} // Prevent event from bubbling up to post click
          >
            @{part}
          </Link>
        );
      }
      return part;
    });
  };

  // Determine the margin-top for the posts display based on whether the profile belongs to the current user
  const postsDisplayMarginTop = isCurrentUserProfile ? "275px" : "300px";

  // Display a loading message if the profile data is still being fetched
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Display a message if the profile is not found
  if (!profile) {
    return <div>Profile not found.</div>;
  }

  // Function to handle saving profile changes
  const handleSaveProfileChanges = async () => {
    if (!currentUser || !currentUser.uid) {
      console.error("User not authenticated or currentUser.uid is undefined");
      return;
    }

    try {
      setIsLoading(true);
      const userRef = doc(firestore, "users", currentUser.uid);
      await updateDoc(userRef, {
        fullName: profile.fullName,
        username: profile.username,
        email: profile.email,
        gender: gender,
        country: country,
        region: region,
        profileImage: profile.profileImage,
        bio: bio, // Add this line
      });
      setShowProfileSettings(false);
      // Update the profile state to reflect the changes
      setProfile((prevProfile) => ({
        ...prevProfile,
        gender,
        country,
        region,
        bio, // Add this line
      }));
    } catch (error) {
      console.error("Failed to update profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Add this to your handleInputChange function
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "bio") {
      setBio(value);
    } else {
      setProfile((prevProfile) => ({
        ...prevProfile,
        [name]: value,
      }));
    }
  };

  if (isDesktop) {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "#121516",
          minHeight: "100vh",
        }}
      >
        {/* Left Sidebar */}
        <div
          style={{
            width: "350px",
            position: "fixed",
            left: 0,
            top: 0,
            height: "100vh",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "12px",
              left: "12px",
              animation: "fadeInUp 1s ease-in-out",
            }}
          >
            <Sidebar
              activeTab={activeTab}
              handleTabClick={handleTabClick}
              events={events}
              loading={loading}
              handleCardClick={handleCardClick}
            />
          </div>
        </div>
        <div
          style={{
            width: "1px",
            height: "100vh",
            position: "fixed",
            left: "350px",
            top: 0,
            background: "var(--black, #2E313C)",
          }}
        ></div>

        {/* Main Content */}
        <div
          style={{
            flex: 1,
            padding: "20px",
            marginLeft: isWideDesktop ? "auto" : "350px",
            marginRight: isWideDesktop ? "auto" : "0",
            maxWidth: isWideDesktop ? "800px" : "calc(100% - 350px)",
            width: isWideDesktop ? "800px" : "auto",
            overflowY: "auto",
            height: "100vh",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <div
            className="profile-header"
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              marginBottom: "20px",
              backgroundColor: "#1E2122",
              borderRadius: "12px",
              padding: "20px",
              position: "relative",
              minHeight: "150px",
            }}
          >
            <div style={{ flex: 1, marginRight: "20px", marginLeft: "175px" }}>
              <h1
                style={{ color: "#FFF", marginBottom: "5px", fontSize: "24px" }}
              >
                {profile?.username}
              </h1>
              {profile?.team && (
                <div
                  style={{
                    backgroundColor: "#4d5050",
                    color: "#FFF",
                    padding: "4px 8px",
                    borderRadius: "20px",
                    fontSize: "14px",
                    display: "inline-block",
                    marginBottom: "0px",
                    marginTop: "10px",
                    cursor: "pointer", // Add this to indicate it's clickable
                  }}
                  onClick={() => navigate(`/teams/${profile.team}`)} // Add this onClick handler
                >
                  {profile.team}
                </div>
              )}
              <p
                style={{
                  color: "#B7B7B7",
                  marginBottom: "15px",
                  fontSize: "14px",
                }}
              >
                {profile?.bio}
              </p>
              <div style={{ display: "flex", gap: "30px", marginTop: "10px" }}>
                <div style={{ textAlign: "center" }}>
                  <span
                    style={{
                      color: "#FFF",
                      fontWeight: "bold",
                      fontSize: "24px",
                      display: "block",
                    }}
                  >
                    {postCount}
                  </span>
                  <span style={{ color: "#B7B7B7", fontSize: "16px" }}>
                    Posts
                  </span>
                </div>
                <div
                  style={{ cursor: "pointer", textAlign: "center" }}
                  onClick={handleFollowersClick}
                >
                  <span
                    style={{
                      color: "#FFF",
                      fontWeight: "bold",
                      fontSize: "24px",
                      display: "block",
                    }}
                  >
                    {followersCount}
                  </span>
                  <span style={{ color: "#B7B7B7", fontSize: "16px" }}>
                    Followers
                  </span>
                </div>
                <div
                  style={{ cursor: "pointer", textAlign: "center" }}
                  onClick={handleFollowingClick}
                >
                  <span
                    style={{
                      color: "#FFF",
                      fontWeight: "bold",
                      fontSize: "24px",
                      display: "block",
                    }}
                  >
                    {followingCount}
                  </span>
                  <span style={{ color: "#B7B7B7", fontSize: "16px" }}>
                    Following
                  </span>
                </div>
              </div>
            </div>
            <div style={{ position: "relative", width: "100%" }}>
              <div
                style={{
                  position: "absolute",
                  top: "-50px",
                  left: "-440px", // Change 'right' to 'left' for better control
                  "@media (min-width: 1200px)": {
                    // Adjust this breakpoint as needed
                    left: "-175px", // Position for wide desktop
                  },
                }}
              >
                <img
                  src={profile ? profile.profileImage : defaultImage}
                  alt="Profile"
                  className="profile-picture"
                  style={{
                    width: "125px",
                    height: "125px",
                    borderRadius: "75px",
                    objectFit: "cover",
                    cursor: "pointer",
                    border: "3px solid #2E313C",
                  }}
                  onClick={() => setIsProfileImageOpen(true)}
                />
              </div>
            </div>

            {currentUser && currentUser.uid === profile.userId && (
              <div style={{ position: "absolute", top: "20px", right: "20px" }}>
                <img
                  src={ProfileSettingIcon}
                  alt="Profile Settings"
                  onClick={toggleProfileSettings}
                  style={{
                    cursor: "pointer",
                    width: "24px",
                    height: "24px",
                    opacity: 0.7,
                  }}
                />
              </div>
            )}

            {currentUser && profile && currentUser.uid !== profile.userId && (
              <button
                className="follow-button"
                onClick={handleFollowClick}
                style={{
                  display: "flex",
                  width: "75px",
                  height: "30px",
                  padding: "4px",
                  alignItems: "center",
                  justifyContent: "center",
                  flexShrink: 0,
                  borderRadius: "100px",
                  background: "#4d5050",
                  color: "#FFF",
                  fontSize: "12px",
                  cursor: "pointer",
                  border: "none",
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                }}
              >
                <span
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "44px",
                    letterSpacing: "0.13px",
                  }}
                >
                  {isFollowing ? "Unfollow" : "Follow"}
                </span>
              </button>
            )}
          </div>

          {/* Posts Display */}
          <div className="postsDisplay" style={{ marginTop: "20px" }}>
            {posts.map(
              ({ id, userId, content, imageUrl, isLiked, likeCount }) => (
                <div
                  key={id}
                  style={{
                    background: "rgba(37, 37, 37, 0.7)",
                    backdropFilter: "blur(10px)",
                    WebkitBackdropFilter: "blur(10px)",
                    borderRadius: "16px",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                    padding: "20px",
                    marginBottom: "20px",
                    transition: "all 0.3s ease",
                    width: "80%",
                    maxWidth: "800px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                  }}
                >
                  {/* Post header containing user information */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <img
                      src={usernames[userId]?.profileImage || defaultImage}
                      alt="User"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                    />
                    <span
                      style={{
                        color: "#fff",
                        fontFamily: "Roboto, sans-serif",
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "25px",
                        letterSpacing: "0.14px",
                        marginTop: "-10px",
                      }}
                    >
                      {usernames[userId]?.username}
                    </span>
                    {currentUser && currentUser.uid === userId && (
                      <div style={{ position: "relative", marginLeft: "auto" }}>
                        <img
                          src={threeDotIcon}
                          alt="Options"
                          style={{
                            cursor: "pointer",
                            width: "24px",
                            height: "24px",
                          }}
                          onClick={() => handleThreeDotClick(id)}
                        />
                        {isDropdownOpen && selectedPostId === id && (
                          <div
                            className="dropdown-menu"
                            style={{
                              position: "absolute",
                              top: "100%",
                              right: 0,
                              backgroundColor: "#34393c",
                              padding: "8px",
                              borderRadius: "12px",
                              cursor: "pointer",
                              zIndex: 1,
                            }}
                            onClick={handleDeletePost}
                          >
                            Delete
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {/* Post content */}
                  <div
                    style={{
                      color: "#fff",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      marginBottom: "10px",
                      marginLeft: "50px",
                      wordWrap: "break-word",
                      marginTop: "-10px",
                    }}
                    onClick={() => navigate(`/posts/${id}`)}
                  >
                    {renderPostContent(content)}
                  </div>
                  {/* Post image if available */}
                  {imageUrl && (
                    <img
                      src={imageUrl}
                      alt="Post"
                      style={{
                        maxWidth: "80%",
                        maxHeight: "500px",
                        display: "block",
                        marginLeft: "50px",
                        marginRight: "auto",
                        borderRadius: "10px",
                        marginBottom: "20px",
                        objectFit: "contain",
                      }}
                      onClick={() => navigate(`/posts/${id}`)}
                    />
                  )}
                  {/* Like section */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "50px",
                    }}
                  >
                    <img
                      src={isLiked ? likeIcon : unlikeIcon}
                      alt={isLiked ? "Unlike" : "Like"}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLikeClick(id, isLiked).catch(console.error);
                      }}
                      style={{
                        cursor: "pointer",
                        width: "27px",
                        height: "26px",
                        marginRight: "7px",
                      }}
                    />
                    <span
                      style={{
                        color: "#fff",
                        fontFamily: "Manrope, sans-serif",
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "12px",
                        letterSpacing: "-0.24px",
                      }}
                    >
                      {likeCount !== undefined && likeCount !== null
                        ? likeCount
                        : 0}
                    </span>
                  </div>
                </div>
              )
            )}
          </div>
        </div>

        {isWideDesktop && <TournamentSidebar />}

        {showProfileSettings && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <div
              style={{
                backgroundColor: "#121516",
                borderRadius: "12px",
                padding: "24px",
                width: "90%",
                maxWidth: "400px",
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "24px",
                }}
              >
                <h2 style={{ color: "#FFF", fontSize: "24px", margin: 0 }}>
                  Profile Settings
                </h2>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={closePostIcon}
                    alt="Close"
                    onClick={toggleProfileSettings}
                    style={{
                      cursor: "pointer",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </div>
              </div>

              {/* Profile picture section */}
              <div style={{ marginBottom: "24px" }}>
                <div className="profile-picture-container">
                  <img
                    src={profile.profileImage}
                    alt="Profile"
                    className="profile-picture-settings"
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                  <label
                    htmlFor="profile-picture-input"
                    className="profile-picture-edit"
                    style={{
                      position: "absolute",
                      bottom: "5px",
                      right: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={ProfileEditIcon}
                      alt="Edit Profile"
                      style={{ width: "24px", height: "24px" }}
                    />
                  </label>
                  <input
                    type="file"
                    id="profile-picture-input"
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={handleFileChange}
                  />
                  <div
                    className="profile-picture-remove"
                    onClick={handleRemoveProfileImage}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: "71.5px",
                      right: "70px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      fill="red"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="10"
                        fill="none"
                        stroke="red"
                        strokeWidth="2"
                      />
                      <line
                        x1="8"
                        y1="8"
                        x2="16"
                        y2="16"
                        stroke="red"
                        strokeWidth="2"
                      />
                      <line
                        x1="8"
                        y1="16"
                        x2="16"
                        y2="8"
                        stroke="red"
                        strokeWidth="2"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              {/* Profile fields */}
              {["fullName", "username", "email", "bio"].map((field) => (
                <div key={field} style={{ marginBottom: "16px" }}>
                  <label
                    htmlFor={field}
                    style={{
                      color: "#FFF",
                      display: "block",
                      marginBottom: "8px",
                      fontSize: "14px",
                    }}
                  >
                    {field.charAt(0).toUpperCase() + field.slice(1)}
                  </label>
                  {field === "bio" ? (
                    <textarea
                      id="bio"
                      name="bio"
                      value={bio}
                      onChange={handleInputChange}
                      style={{
                        width: "100%",
                        padding: "8px",
                        backgroundColor: "#2c2c2c",
                        border: "none",
                        borderRadius: "4px",
                        color: "#FFF",
                        fontSize: "16px",
                        minHeight: "100px",
                        resize: "vertical",
                      }}
                    />
                  ) : (
                    <input
                      type="text"
                      id={field}
                      name={field}
                      value={profile[field] || ""}
                      onChange={handleInputChange}
                      style={{
                        width: "100%",
                        padding: "8px",
                        backgroundColor: "#2c2c2c",
                        border: "none",
                        borderRadius: "4px",
                        color: "#FFF",
                        fontSize: "16px",
                      }}
                    />
                  )}
                </div>
              ))}

              {/* Dropdown fields */}
              {["gender", "country", "region"].map((field) => (
                <div key={field} style={{ marginBottom: "16px" }}>
                  <label
                    htmlFor={field}
                    style={{
                      color: "#FFF",
                      display: "block",
                      marginBottom: "8px",
                      fontSize: "14px",
                    }}
                  >
                    {field.charAt(0).toUpperCase() + field.slice(1)}
                  </label>
                  <select
                    id={field}
                    name={field}
                    value={
                      field === "gender"
                        ? gender
                        : field === "country"
                        ? country
                        : region
                    }
                    onChange={(e) => {
                      if (field === "gender") setGender(e.target.value);
                      else if (field === "country") setCountry(e.target.value);
                      else setRegion(e.target.value);
                    }}
                    style={{
                      width: "100%",
                      padding: "8px",
                      backgroundColor: "#2c2c2c",
                      border: "none",
                      borderRadius: "4px",
                      color: "#FFF",
                      fontSize: "16px",
                    }}
                  >
                    <option value="">Select {field}</option>
                    {(field === "gender"
                      ? genderOptions
                      : field === "country"
                      ? countryOptions
                      : regionOptions
                    ).map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              ))}

              {/* Action buttons */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "24px",
                }}
              >
                <button
                  onClick={toggleProfileSettings}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#2c2c2c",
                    color: "#FFF",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={handleSaveProfileChanges}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#3498db",
                    color: "#FFF",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                >
                  Save Changes
                </button>
              </div>

              {/* Additional options */}
              <div
                style={{
                  marginTop: "24px",
                  borderTop: "1px solid #2c2c2c",
                  paddingTop: "16px",
                }}
              >
                <div
                  onClick={handleBlockedUsersClick}
                  style={{
                    color: "#FFF",
                    cursor: "pointer",
                    marginBottom: "16px",
                    fontSize: "14px",
                  }}
                >
                  Blocked Users
                </div>
                <div
                  onClick={handleLogout}
                  style={{
                    color: "#e74c3c",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                >
                  Logout
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Following Overlay */}
        {showFollowingOverlay && (
          <div className="overlay" onClick={handleOverlayClick}>
            <div
              className="overlay-content"
              style={{ background: "#121516", maxWidth: "400px", width: "90%" }}
            >
              <h2
                style={{
                  color: "#FFF",
                  fontSize: "24px",
                  marginBottom: "20px",
                }}
              >
                Following
              </h2>
              {followingList.length > 0 ? (
                followingList.map((user) => (
                  <div
                    key={user.userId}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "15px",
                    }}
                  >
                    <div
                      style={{ display: "flex", alignItems: "center", flex: 1 }}
                    >
                      <img
                        src={user.profileImage || defaultImage}
                        alt={user.username}
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          marginRight: "15px",
                        }}
                      />
                      <div>
                        <div style={{ color: "#FFF", fontWeight: "bold" }}>
                          {user.username}
                        </div>
                        <div style={{ color: "#B7B7B7" }}>{user.fullName}</div>
                      </div>
                    </div>
                    <button
                      style={{
                        padding: "8px 16px",
                        backgroundColor: "#3498db",
                        color: "white",
                        border: "none",
                        borderRadius: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleProfileNavigation(user.username)}
                    >
                      View Profile
                    </button>
                  </div>
                ))
              ) : (
                <p style={{ color: "#FFF" }}>No following users found.</p>
              )}
            </div>
          </div>
        )}

        {/* Followers Overlay */}
        {showFollowersOverlay && (
          <div className="overlay" onClick={handleOverlayClick}>
            <div
              className="overlay-content"
              style={{ background: "#121516", maxWidth: "400px", width: "90%" }}
            >
              <h2
                style={{
                  color: "#FFF",
                  fontSize: "24px",
                  marginBottom: "20px",
                }}
              >
                Followers
              </h2>
              {followersList.length > 0 ? (
                followersList.map((user) => (
                  <div
                    key={user.userId}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "15px",
                    }}
                  >
                    <div
                      style={{ display: "flex", alignItems: "center", flex: 1 }}
                    >
                      <img
                        src={user.profileImage || defaultImage}
                        alt={user.username}
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          marginRight: "15px",
                        }}
                      />
                      <div>
                        <div style={{ color: "#FFF", fontWeight: "bold" }}>
                          {user.username}
                        </div>
                        <div style={{ color: "#B7B7B7" }}>{user.fullName}</div>
                      </div>
                    </div>
                    <button
                      style={{
                        padding: "8px 16px",
                        backgroundColor: "#3498db",
                        color: "white",
                        border: "none",
                        borderRadius: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleProfileNavigation(user.username)}
                    >
                      View Profile
                    </button>
                  </div>
                ))
              ) : (
                <p style={{ color: "#FFF" }}>No followers found.</p>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="profile-page">
      {currentUser && profile && (
        <div
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          {/* Options menu - only visible on other users' profiles */}
          {currentUser.uid !== profile.userId && (
            <div style={{ position: "relative" }}>
              <img
                src={threeDotIcon}
                alt="Options"
                onClick={handleDropdownToggle}
                style={{
                  cursor: "pointer",
                  width: "24px",
                  height: "24px",
                  opacity: 0.7,
                }}
              />
              {isDropdownOpen && (
                <div
                  style={{
                    position: "absolute",
                    top: "calc(100% + 10px)",
                    right: 0,
                    backgroundColor: "rgba(52, 57, 60, 0.8)",
                    backdropFilter: "blur(10px)",
                    padding: "12px",
                    borderRadius: "12px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    zIndex: 1,
                  }}
                >
                  <button
                    style={{
                      display: "block",
                      width: "100%",
                      padding: "10px",
                      marginBottom: "8px",
                      backgroundColor: "#ff4d4d",
                      color: "#fff",
                      border: "none",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "14px",
                      fontWeight: "500",
                      transition: "background-color 0.3s",
                    }}
                    onClick={() => handleBlockUser(profile.userId)}
                  >
                    Block
                  </button>
                  <button
                    style={{
                      display: "block",
                      width: "100%",
                      padding: "10px",
                      backgroundColor: "#4d5050",
                      color: "#fff",
                      border: "none",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "14px",
                      fontWeight: "500",
                      transition: "background-color 0.3s",
                    }}
                    onClick={handleMessageUser}
                  >
                    Message
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {/* Profile header section */}
      <div className="profile-header">
        <img
          src={profile ? profile.profileImage : defaultImage}
          alt="Profile"
          className="profile-picture"
          style={{
            width: "104px",
            height: "104px",
            borderRadius: "52px",
            objectFit: "cover",
            cursor: "pointer",
          }}
          onClick={() => setIsProfileImageOpen(true)}
        />
        {/* Profile image overlay */}
        {isProfileImageOpen && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            }}
            onClick={() => setIsProfileImageOpen(false)} // Close the overlay on click
          >
            <img
              src={profile ? profile.profileImage : defaultImage}
              alt="Profile"
              style={{
                maxWidth: "90%",
                maxHeight: "90%",
                objectFit: "contain",
                borderRadius: "10px",
              }}
            />
          </div>
        )}
        {/* Username */}
        <h2
          className="username"
          style={{ marginTop: "-10px", marginBottom: "5px" }}
        >
          {isTeamProfile ? profile?.team_name : profile?.username}
          {currentUser && profile && currentUser.uid !== profile.userId && (
            <button
              className="follow-button"
              onClick={handleFollowClick}
              style={{
                display: "flex",
                width: "75px",
                height: "30px",
                padding: "4px",
                alignItems: "center",
                justifyContent: "center",
                flexShrink: 0,
                borderRadius: "100px",
                background: "#4d5050",
                color: "#FFF",
                fontSize: "12px",
                cursor: "pointer",
                border: "none",
                marginLeft: "auto", // This pushes the button to the right
              }}
            >
              <span
                style={{
                  fontFamily: "Roboto",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "44px",
                  letterSpacing: "0.13px",
                }}
              >
                {isFollowing ? "Unfollow" : "Follow"}
              </span>
            </button>
          )}
        </h2>
        {profile?.team && (
          <div
            style={{
              backgroundColor: "#4d5050",
              color: "#FFF",
              padding: "4px 8px",
              borderRadius: "20px",
              fontSize: "12px",
              display: "inline-block",
              marginBottom: "15px",
              marginTop: "15px",
              cursor: "pointer",
              position: "absolute",
              top: "80px",
              left: "145px",
            }}
            onClick={() => navigate(`/teams/${profile.team}`)}
          >
            {profile.team}
          </div>
        )}

        <p
          style={{
            position: "absolute",
            top: "110px", // Adjusted to make room for the team pill
            left: "145px",
            fontSize: "14px",
            color: "#c6c6c6",
            maxWidth: "calc(100% - 170px)",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            lineHeight: "1.2em",
            maxHeight: "2.4em", // 2 lines of text
          }}
        >
          {profile?.bio
            ? profile.bio.length > 100
              ? `${profile.bio.slice(0, 100)}...`
              : profile.bio
            : "No bio available"}
        </p>
      </div>
      {/* Check if the current user is the profile owner */}
      {currentUser && currentUser.username === username && (
        <div className="profile-settings-icon-container">
          {/* Profile settings icon */}
          <img
            src={ProfileSettingIcon}
            className="profile-settings-icon"
            alt="Profile Settings"
            onClick={toggleProfileSettings}
          />
          {/* Profile settings overlay */}
          {showProfileSettings && (
            <div
              className="profile-settings-overlay"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <div
                style={{
                  backgroundColor: "#121516",
                  borderRadius: "12px",
                  padding: "24px",
                  width: "90%",
                  maxWidth: "400px",
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "24px",
                  }}
                >
                  <h2 style={{ color: "#FFF", fontSize: "24px", margin: 0 }}>
                    Profile Settings
                  </h2>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={closePostIcon}
                      alt="Close"
                      onClick={toggleProfileSettings}
                      style={{
                        cursor: "pointer",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                  </div>
                </div>
                <div style={{ marginBottom: "24px" }}>
                  <div className="profile-picture-container">
                    <img
                      src={profile.profileImage}
                      alt="Profile"
                      className="profile-picture-settings"
                    />
                    <label
                      htmlFor="profile-picture-input"
                      className="profile-picture-edit"
                    >
                      <img src={ProfileEditIcon} alt="Edit Profile" />
                    </label>
                    <input
                      type="file"
                      id="profile-picture-input"
                      style={{ display: "none" }}
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={handleFileChange}
                    />
                    {/* Remove profile picture */}
                    <div
                      className="profile-picture-remove"
                      onClick={handleRemoveProfileImage}
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        marginBottom: "0px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        width="32"
                        height="32"
                        fill="red"
                        style={{ marginTop: "-40px" }}
                      >
                        <circle
                          cx="16"
                          cy="16"
                          r="15"
                          stroke="red"
                          strokeWidth="2"
                          fill="none"
                        />
                        <line
                          x1="11"
                          y1="11"
                          x2="21"
                          y2="21"
                          stroke="red"
                          strokeWidth="2"
                        />
                        <line
                          x1="11"
                          y1="21"
                          x2="21"
                          y2="11"
                          stroke="red"
                          strokeWidth="2"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                {["fullName", "username", "email", "bio"].map((field) => (
                  <div key={field} style={{ marginBottom: "16px" }}>
                    <label
                      htmlFor={field}
                      style={{
                        color: "#FFF",
                        display: "block",
                        marginBottom: "8px",
                        fontSize: "14px",
                      }}
                    >
                      {field.charAt(0).toUpperCase() + field.slice(1)}
                    </label>
                    {field === "bio" ? (
                      <textarea
                        id="bio"
                        name="bio"
                        value={bio}
                        onChange={handleInputChange}
                        style={{
                          width: "100%",
                          padding: "8px",
                          backgroundColor: "#2c2c2c",
                          border: "none",
                          borderRadius: "4px",
                          color: "#FFF",
                          fontSize: "16px",
                          minHeight: "100px",
                          resize: "vertical",
                        }}
                      />
                    ) : (
                      <input
                        type="text"
                        id={field}
                        name={field}
                        value={profile[field] || ""}
                        onChange={handleInputChange}
                        style={{
                          width: "100%",
                          padding: "8px",
                          backgroundColor: "#2c2c2c",
                          border: "none",
                          borderRadius: "4px",
                          color: "#FFF",
                          fontSize: "16px",
                        }}
                      />
                    )}
                  </div>
                ))}

                {["gender", "country", "region"].map((field) => (
                  <div key={field} style={{ marginBottom: "16px" }}>
                    <label
                      htmlFor={field}
                      style={{
                        color: "#FFF",
                        display: "block",
                        marginBottom: "8px",
                        fontSize: "14px",
                      }}
                    >
                      {field.charAt(0).toUpperCase() + field.slice(1)}
                    </label>
                    <select
                      id={field}
                      name={field}
                      value={
                        field === "gender"
                          ? gender
                          : field === "country"
                          ? country
                          : region
                      }
                      onChange={(e) => {
                        if (field === "gender") setGender(e.target.value);
                        else if (field === "country")
                          setCountry(e.target.value);
                        else setRegion(e.target.value);
                      }}
                      style={{
                        width: "100%",
                        padding: "8px",
                        backgroundColor: "#2c2c2c",
                        border: "none",
                        borderRadius: "4px",
                        color: "#FFF",
                        fontSize: "16px",
                      }}
                    >
                      <option value="">Select {field}</option>
                      {(field === "gender"
                        ? genderOptions
                        : field === "country"
                        ? countryOptions
                        : regionOptions
                      ).map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "24px",
                  }}
                >
                  <button
                    onClick={toggleProfileSettings}
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "#2c2c2c",
                      color: "#FFF",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      fontSize: "14px",
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSaveProfileChanges}
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "#3498db",
                      color: "#FFF",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      fontSize: "14px",
                    }}
                  >
                    Save Changes
                  </button>
                </div>

                <div
                  style={{
                    marginTop: "24px",
                    borderTop: "1px solid #2c2c2c",
                    paddingTop: "16px",
                  }}
                >
                  <div
                    onClick={handleBlockedUsersClick}
                    style={{
                      color: "#FFF",
                      cursor: "pointer",
                      marginBottom: "16px",
                      fontSize: "14px",
                    }}
                  >
                    Blocked Users
                  </div>
                  <div
                    onClick={handleLogout}
                    style={{
                      color: "#e74c3c",
                      cursor: "pointer",
                      fontSize: "14px",
                    }}
                  >
                    Logout
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Display the post counter with the number of posts */}
      <div className="post-counter">
        <span className="post-count">{postCount}</span>
        <span className="post-label">Posts</span>
      </div>
      {/* Display the follower counter with the number of followers
          and handle click event to show followers list */}
      <div
        className="follower-counter"
        onClick={handleFollowersClick}
        style={{ cursor: "pointer" }}
      >
        <span className="follower-count">{followersCount}</span>
        <span className="follower-label">Followers</span>
      </div>
      {/* Display the following counter with the number of users the profile is following
          and handle click event to show following list */}
      <div
        className="following-counter"
        onClick={handleFollowingClick}
        style={{ cursor: "pointer" }}
      >
        <span className="following-count">{followingCount}</span>
        <span className="following-label">Following</span>
      </div>
      {/* Display the tab selector for switching between different tabs */}
      <div className="tab-selector">
        <div
          className={`tab ${activeTab === "posts" ? "active" : ""}`}
          onClick={() => handleTabChange("posts")}
        >
          Posts
        </div>
      </div>
      {/* Divider for the tab section with a negative margin to adjust spacing */}
      <div className="tab-divider" style={{ marginBottom: "-100px" }}></div>
      {/* Container for displaying posts */}
      <div
        className="postsDisplay"
        style={{
          marginTop: postsDisplayMarginTop,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        {posts.map(({ id, userId, content, imageUrl, isLiked, likeCount }) => (
          <div
            key={id}
            style={{
              background: "rgba(37, 37, 37, 0.7)",
              backdropFilter: "blur(10px)",
              WebkitBackdropFilter: "blur(10px)",
              borderRadius: "16px",
              border: "1px solid rgba(255, 255, 255, 0.1)",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              padding: "20px",
              marginBottom: "20px",
              transition: "all 0.3s ease",
              width: "90%",
              maxWidth: "500px",
            }}
          >
            {/* Post header containing user information */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <img
                src={usernames[userId]?.profileImage || defaultImage}
                alt="User"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              />
              <span
                style={{
                  color: "#fff",
                  fontFamily: "Roboto, sans-serif",
                  fontSize: "14px",
                  fontWeight: "700",
                  lineHeight: "25px",
                  letterSpacing: "0.14px",
                  marginTop: "-15px",
                }}
              >
                {usernames[userId]?.username}
              </span>
              {currentUser && currentUser.uid === userId && (
                <div style={{ position: "relative", marginLeft: "auto" }}>
                  <img
                    src={threeDotIcon}
                    alt="Options"
                    style={{ cursor: "pointer", width: "24px", height: "24px" }}
                    onClick={() => handleThreeDotClick(id)}
                  />
                  {isDropdownOpen && selectedPostId === id && (
                    <div
                      className="dropdown-menu"
                      style={{
                        position: "absolute",
                        top: "100%",
                        right: 0,
                        backgroundColor: "#34393c",
                        padding: "8px",
                        borderRadius: "12px",
                        cursor: "pointer",
                        zIndex: 1,
                      }}
                      onClick={handleDeletePost}
                    >
                      Delete
                    </div>
                  )}
                </div>
              )}
            </div>
            {/* Post content */}
            <div
              style={{
                color: "#fff",
                fontFamily: "Roboto, sans-serif",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                marginBottom: "10px",
                marginLeft: "50px",
                wordWrap: "break-word",
                marginTop: "-20px",
              }}
              onClick={() => navigate(`/posts/${id}`)}
            >
              <p>{truncateText(content)}</p>
            </div>
            {/* Post image if available */}
            {imageUrl && (
              <img
                src={imageUrl}
                alt="Post"
                style={{
                  maxWidth: "80%",
                  maxHeight: "500px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  borderRadius: "10px",
                  marginBottom: "20px",
                  objectFit: "contain",
                  marginLeft: "50px",
                }}
                onClick={() => navigate(`/posts/${id}`)}
              />
            )}
            {/* Like section */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "50px",
              }}
            >
              <img
                src={isLiked ? likeIcon : unlikeIcon}
                alt={isLiked ? "Unlike" : "Like"}
                onClick={(e) => {
                  e.stopPropagation();
                  handleLikeClick(id, isLiked).catch(console.error);
                }}
                style={{
                  cursor: "pointer",
                  width: "27px",
                  height: "26px",
                  marginRight: "7px",
                }}
              />
              <span
                style={{
                  color: "#fff",
                  fontFamily: "Manrope, sans-serif",
                  fontSize: "14px",
                  fontWeight: "700",
                  lineHeight: "12px",
                  letterSpacing: "-0.24px",
                }}
              >
                {likeCount !== undefined && likeCount !== null ? likeCount : 0}
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Overlay for displaying following users */}
      {showFollowingOverlay && (
        <div className="overlay" onClick={handleOverlayClick}>
          <div
            className="overlay-content"
            style={{
              background: "#1E2122",
              borderRadius: "12px",
              padding: "24px",
              width: "90%",
              maxWidth: "400px",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <h2
                style={{
                  color: "#FFF",
                  fontSize: "24px",
                  fontWeight: "bold",
                  margin: 0,
                }}
              >
                Following
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              {followingList.length > 0 ? (
                followingList.map((user) => (
                  <div
                    key={user.userId}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "15px",
                    }}
                  >
                    <div
                      style={{ display: "flex", alignItems: "center", flex: 1 }}
                    >
                      <img
                        src={user.profileImage || defaultImage}
                        alt={user.username}
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          marginRight: "15px",
                        }}
                      />
                      <div>
                        <div style={{ color: "#FFF", fontWeight: "bold" }}>
                          {user.username}
                        </div>
                        <div style={{ color: "#B7B7B7" }}>{user.fullName}</div>
                      </div>
                    </div>
                    <button
                      style={{
                        padding: "8px 16px",
                        backgroundColor: "#3498db",
                        color: "white",
                        border: "none",
                        borderRadius: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleProfileNavigation(user.username)}
                    >
                      View Profile
                    </button>
                  </div>
                ))
              ) : (
                <p style={{ color: "#FFF" }}>Not following anyone.</p>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Overlay for displaying followers */}
      {showFollowersOverlay && (
        <div className="overlay" onClick={handleOverlayClick}>
          <div
            className="overlay-content"
            style={{
              background: "#1E2122",
              borderRadius: "12px",
              padding: "24px",
              width: "90%",
              maxWidth: "400px",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              {followersList.length > 0 ? (
                followersList
                  .filter((user) => user.username) // Filter out users with no username
                  .map((user) => (
                    <div
                      key={user.userId}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                        }}
                      >
                        <img
                          src={user.profileImage || defaultImage}
                          alt={user.username}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            marginRight: "15px",
                          }}
                        />
                        <div>
                          <div style={{ color: "#FFF", fontWeight: "bold" }}>
                            {user.username}
                          </div>
                          <div style={{ color: "#B7B7B7" }}>
                            {user.fullName}
                          </div>
                        </div>
                      </div>
                      <button
                        style={{
                          padding: "8px 16px",
                          backgroundColor: "#3498db",
                          color: "white",
                          border: "none",
                          borderRadius: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleProfileNavigation(user.username)}
                      >
                        View Profile
                      </button>
                    </div>
                  ))
              ) : (
                <p style={{ color: "#FFF" }}>No followers found.</p>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Overlay for displaying blocked users */}
      {showBlockedUsersOverlay && (
        <div className="overlay">
          <div
            className="overlay-content"
            style={{
              background: "#1E2122",
              borderRadius: "12px",
              padding: "24px",
              width: "90%",
              maxWidth: "400px",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
            ref={overlayRef}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <h2
                style={{
                  color: "#FFF",
                  fontSize: "24px",
                  fontWeight: "bold",
                  margin: 0,
                }}
              >
                Blocked Users
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              {blockedUsersList.length > 0 ? (
                blockedUsersList.map((user) => (
                  <div
                    key={user.userId}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "15px",
                    }}
                  >
                    <div
                      style={{ display: "flex", alignItems: "center", flex: 1 }}
                    >
                      <img
                        src={user.profileImage || defaultImage}
                        alt={user.username}
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          marginRight: "15px",
                        }}
                      />
                      <div style={{ color: "#FFF", fontWeight: "bold" }}>
                        {user.username}
                      </div>
                    </div>
                    <button
                      style={{
                        padding: "8px 16px",
                        backgroundColor: "#e74c3c",
                        color: "white",
                        border: "none",
                        borderRadius: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleUnblockUser(user.userId)}
                    >
                      Unblock
                    </button>
                  </div>
                ))
              ) : (
                <p style={{ color: "#FFF" }}>No blocked users found.</p>
              )}
            </div>
          </div>
        </div>
      )}
      {/* Dock component */}
      <Dock username={profile?.username} />
    </div>
  );
};

export default ProfilePage;
