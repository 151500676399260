// src/pages/OnboardingPage.jsx

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/styles/onboardingPage.css";
import { auth, firestore } from "../firebase";
import { doc, getDoc, updateDoc, writeBatch } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import defaultImage from "../assets/images/CircleLogo.svg";

const OnboardingPage = () => {
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [region, setRegion] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/signup");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const checkContentWithOpenAIModeration = async (content) => {
    if (!content) {
      console.error("No content provided for moderation.");
      return false;
    }

    try {
      const response = await fetch(
        "https://us-central1-vexvibe-frontend.cloudfunctions.net/checkContentWithOpenAIModeration",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: { content } }),
        }
      );

      if (!response.ok) {
        throw new Error(`OpenAI API error: ${response.statusText}`);
      }

      const result = await response.json();
      return result.result.isApproved;
    } catch (error) {
      console.error("Error with OpenAI Moderation API:", error);
      return false;
    }
  };
  const checkTeamExistsInFirestore = async (username) => {
    const teamsRef = collection(firestore, "teams");
    const q = query(teamsRef, where("team_name", "==", username.toUpperCase()));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const checkTeamExistsInRobotEvents = async (username) => {
    const apiKey =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiOTlmNmYyMzYwNTY0ZTY1NmJiODlkNTUwNmYzZjRiMmMxNDA0M2E2YjZlMWVkNWRmODVkNThjYzkzOWFiYzk0YjIyYTYwYjA4MzcyNTZmNjYiLCJpYXQiOjE3MTY2NzM2MTguMTM0NTE0MSwibmJmIjoxNzE2NjczNjE4LjEzNDUxNywiZXhwIjoyNjYzMzYyMDE4LjEyODY2NSwic3ViIjoiMTI3Nzg2Iiwic2NvcGVzIjpbXX0.jhOKVtQQI1acAwIRrAR78xw7l__XHewZzJDYBf3OMzHZ4zsIh_6jWG1ulswGCN6128v_dwPji6s8JmVQj_tmcn-KyDLQACkY8ZJHQXZLIuTSrNGXFKE-DLqFKO9an04kmZoMKtZay86bCuSMiHoJGkd9WctvUdAkjzNdsWBz8lwD48hLY5h2F5U55YhX2areEoPyioOsN1QHe9ExoXOk8fW88vpgvw7_aZnmhTp5BV6WICnIlP1PCLWxUI_EwI8X32QJ1PJhOUFJ6eEb7iM2KPT3jlJmJBy675wDCix84H11jSUCJs8F-LIXRERVERniWElTWp9tIJYRk1HRQN4bhCEnuzL8U01oO49Ghc-k9vYQnY3WlHwAJEGuPQtQt8NttI2DcBDcBM3acQXHHHfvKvk6pc1__bwM32W41Ealqku1dL5Jw8MNUCFqAthDd--mjtkyevuGJsLwu1NobdI94wNDHna8iuRg27XwRGXJKGy32scGsmeBnplhEdOBbVu44Ns1MGJuxmLLffICQWVdEl4Gwth63r4AzBiX__iMYwJw39MfWpiyYrDZRX7Z6n12m2WeeZqrdmRAuSyzVSeZIqFs6TgqbgAuFOMo68aN_6InrbMD62PvXhdH8CL6wR05_v_Gz10OJtAWdwboXB780tdd7X5mdP2KcBZHbqvSbl4";

    try {
      const response = await fetch(
        `https://www.robotevents.com/api/v2/teams?number[]=${encodeURIComponent(
          username
        )}&myTeams=false`,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
            accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`RobotEvents API error: ${response.statusText}`);
      }

      const data = await response.json();
      return data.data.length > 0;
    } catch (error) {
      console.error("Error checking RobotEvents API:", error);
      return false;
    }
  };

  const nextStep = async () => {
    if (step === 1) {
      if (username.length > 16) {
        alert("Username cannot be more than 16 characters.");
        return;
      }

      const isUsernameUnique = await checkUsernameUnique(username);
      if (!isUsernameUnique) {
        alert("Username is already taken. Please choose a different one.");
        return;
      }

      const existsInFirestore = await checkTeamExistsInFirestore(username);
      if (existsInFirestore) {
        alert(
          "This username matches an existing team. Please choose a different one."
        );
        return;
      }

      const existsInRobotEvents = await checkTeamExistsInRobotEvents(username);
      if (existsInRobotEvents) {
        alert(
          "This username matches a VEX team number. Please choose a different one."
        );
        return;
      }

      await updateUsername(username);
    } else if (step === 2) {
      if (!gender) {
        alert("Please select a gender.");
        return;
      }
      await updateUserProfile({ gender });
    } else if (step === 3) {
      if (!country || country === "Select a country") {
        alert("Please select a valid country.");
        return;
      }
      if (!region || region === "Select a region") {
        alert("Please select a valid region.");
        return;
      }
      await updateUserProfile({ country, region });
    }
    setStep(step < 4 ? step + 1 : 4);
  };

  const selectCountry = (selectedCountry) => {
    setCountry(selectedCountry);
  };

  const checkUsernameUnique = async (username) => {
    const usernameRef = doc(firestore, "usernames", username);
    const docSnap = await getDoc(usernameRef);
    return !docSnap.exists();
  };

  const updateUsername = async (username) => {
    const user = auth.currentUser;
    const usernameRef = doc(firestore, "usernames", username);
    const userRef = doc(firestore, "users", user.uid);

    const batch = writeBatch(firestore);
    batch.set(usernameRef, { userId: user.uid });
    batch.update(userRef, { username });

    await batch.commit();
  };

  // const updateUserProfile = async (data) => {
  //   const userRef = doc(firestore, "users", auth.currentUser.uid);
  //   await updateDoc(userRef, data);
  // };

  const selectGender = (selectedGender) => {
    setGender(selectedGender);
  };

  const goBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const [profileImage, setProfileImage] = useState(null);
  const [profileImageURL, setProfileImageURL] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      const storage = getStorage();
      const storageRef = ref(storage, `profile_images/${auth.currentUser.uid}`);
      try {
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        setProfileImage(file);
        setProfileImageURL(downloadURL);
      } catch (error) {
        console.error("Error uploading image:", error);
        // Optionally, show an error message to the user
      } finally {
        setIsUploading(false);
      }
    }
  };

  const updateUserProfile = async (data) => {
    const userRef = doc(firestore, "users", auth.currentUser.uid);
    await updateDoc(userRef, {
      ...data,
      profileImage: profileImageURL,
    });
  };

  const backButtonStyle = {
    backgroundColor: "transparent",
    border: "none",
    color: "#666873",
    fontSize: "16px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  };

  return (
    <div className="onboardingPage">
      <div className="stepIndicators">
        {[1, 2, 3, 4].map((indicatorStep) => (
          <div
            key={indicatorStep}
            className={`stepIndicator ${
              step === indicatorStep ? "active" : ""
            }`}
          />
        ))}
      </div>
      {step === 1 && (
        <>
          <div className="stepInstruction">
            Choose a username and profile picture
          </div>
          <div className="profileImageUpload">
            <img
              src={profileImageURL || defaultImage}
              alt="Profile"
              className="profilePreview"
            />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: "none" }}
              id="profileImageInput"
              disabled={isUploading}
            />
            <label
              htmlFor="profileImageInput"
              className="uploadButton"
              style={{ opacity: isUploading ? 0.5 : 1 }}
            >
              {isUploading ? (
                <>
                  Uploading... <div className="loadingSpinner"></div>
                </>
              ) : (
                "Upload Profile Picture"
              )}
            </label>
          </div>
          <div className="usernameField">
            <svg
              className="userIcon"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
            >
              <path
                d="M8 9C10.2091 9 12 7.20914 12 5C12 2.79086 10.2091 1 8 1C5.79086 1 4 2.79086 4 5C4 7.20914 5.79086 9 8 9Z"
                stroke="#666873"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15 17C15 13.134 11.866 10 8 10C4.13401 10 1 13.134 1 17"
                stroke="#666873"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input
              type="text"
              placeholder="Username"
              className="usernameInput"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <button className="nextButton" onClick={nextStep}>
            Next
          </button>
        </>
      )}

      {step === 2 && (
        <>
          <div className="genderSelectionText">Please select your gender</div>
          <div className="genderSelectionSubtext">
            Choose how you identify to help us build better content for you
          </div>
          <div className="genderOptions">
            <div
              onClick={() => selectGender("Male")}
              className={`genderOption ${gender === "Male" ? "selected" : ""}`}
            >
              <span className="emoji">👨</span>
              <span className="text">Male</span>
            </div>
            <div
              onClick={() => selectGender("Female")}
              className={`genderOption ${
                gender === "Female" ? "selected" : ""
              }`}
            >
              <span className="emoji">👩</span>
              <span className="text">Female</span>
            </div>
            <div
              onClick={() => selectGender("Other")}
              className={`genderOption ${gender === "Other" ? "selected" : ""}`}
            >
              <span className="emoji">🌈</span>
              <span className="text">Other</span>
            </div>
          </div>
          <button className="nextButton" onClick={nextStep}>
            Next
          </button>
        </>
      )}

      {step === 3 && (
        <>
          <div className="onboardingCountrySelectionPrompt">
            Please choose a country
          </div>
          <div className="onboardingCountrySelector">
            <div className="onboardingCountryArrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
              >
                <path
                  d="M25.5 22.5L18 30L10.5 22.5"
                  stroke="#666873"
                  stroke-width="2"
                />
                <path
                  d="M25.5 13.5L18 6L10.5 13.5"
                  stroke="#666873"
                  stroke-width="2"
                />
              </svg>
            </div>
            <select
              className="onboardingCountrySelect"
              value={country}
              onChange={(e) => selectCountry(e.target.value)}
            >
              <option value="Select a country">Select a country</option>
              <option value="USA">United States of America</option>
              <option value="CAN">Canada</option>
            </select>
          </div>
          <div
            className="onboardingCountrySelectionPrompt"
            style={{ marginTop: "20px" }}
          >
            Please select a region
          </div>
          <div className="onboardingCountrySelector">
            <div className="onboardingCountryArrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
              >
                <path
                  d="M25.5 22.5L18 30L10.5 22.5"
                  stroke="#666873"
                  stroke-width="2"
                />
                <path
                  d="M25.5 13.5L18 6L10.5 13.5"
                  stroke="#666873"
                  stroke-width="2"
                />
              </svg>
            </div>
            <select
              className="onboardingCountrySelect"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
            >
              <option value="Select a region">Select a region</option>
              <option value="Alabama">Alabama</option>
              <option value="Alaska">Alaska</option>
              <option value="Alberta/Saskatchewan">Alberta/Saskatchewan</option>
              <option value="American Samoa">American Samoa</option>
              <option value="Andorra">Andorra</option>
              <option value="Arizona">Arizona</option>
              <option value="Arkansas">Arkansas</option>
              <option value="Australia">Australia</option>
              <option value="Azerbaijan">Azerbaijan</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="Belgium">Belgium</option>
              <option value="Bermuda">Bermuda</option>
              <option value="Brazil">Brazil</option>
              <option value="British Columbia (BC)">
                British Columbia (BC)
              </option>
              <option value="Burundi">Burundi</option>
              <option value="California - Region 1">
                California - Region 1
              </option>
              <option value="California - Region 2">
                California - Region 2
              </option>
              <option value="California - Region 3">
                California - Region 3
              </option>
              <option value="California - Region 4">
                California - Region 4
              </option>
              <option value="Chile">Chile</option>
              <option value="China">China</option>
              <option value="Chinese Taipei">Chinese Taipei</option>
              <option value="Colombia">Colombia</option>
              <option value="Colorado">Colorado</option>
              <option value="Cook Islands">Cook Islands</option>
              <option value="Czech Republic">Czech Republic</option>
              <option value="Côte d’Ivoire">Côte d’Ivoire</option>
              <option value="Delaware">Delaware</option>
              <option value="Delmarva">Delmarva</option>
              <option value="District of Columbia">District of Columbia</option>
              <option value="Egypt">Egypt</option>
              <option value="Estonia">Estonia</option>
              <option value="Ethiopia">Ethiopia</option>
              <option value="Finland">Finland</option>
              <option value="Florida - North/Central">
                Florida - North/Central
              </option>
              <option value="Florida - South">Florida - South</option>
              <option value="France">France</option>
              <option value="Gambia">Gambia</option>
              <option value="Georgia">Georgia</option>
              <option value="Germany">Germany</option>
              <option value="Ghana">Ghana</option>
              <option value="Guam">Guam</option>
              <option value="Guatemala">Guatemala</option>
              <option value="Haiti">Haiti</option>
              <option value="Hawaii">Hawaii</option>
              <option value="Hong Kong">Hong Kong</option>
              <option value="Idaho">Idaho</option>
              <option value="Illinois">Illinois</option>
              <option value="India">India</option>
              <option value="Indiana">Indiana</option>
              <option value="Indonesia">Indonesia</option>
              <option value="Iowa">Iowa</option>
              <option value="Ireland">Ireland</option>
              <option value="Japan">Japan</option>
              <option value="Jordan">Jordan</option>
              <option value="Kansas">Kansas</option>
              <option value="Kazakhstan">Kazakhstan</option>
              <option value="Kentucky">Kentucky</option>
              <option value="Kenya">Kenya</option>
              <option value="Kuwait">Kuwait</option>
              <option value="Lebanon">Lebanon</option>
              <option value="Lesotho">Lesotho</option>
              <option value="Liberia">Liberia</option>
              <option value="Libya">Libya</option>
              <option value="Lithuania">Lithuania</option>
              <option value="Louisiana">Louisiana</option>
              <option value="Luxembourg">Luxembourg</option>
              <option value="Macau">Macau</option>
              <option value="Maine">Maine</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Manitoba">Manitoba</option>
              <option value="Maryland">Maryland</option>
              <option value="Mexico">Mexico</option>
              <option value="Michigan">Michigan</option>
              <option value="Minnesota">Minnesota</option>
              <option value="Mississippi">Mississippi</option>
              <option value="Missouri">Missouri</option>
              <option value="Montana">Montana</option>
              <option value="Morocco">Morocco</option>
              <option value="Myanmar">Myanmar</option>
              <option value="Nebraska">Nebraska</option>
              <option value="Netherlands">Netherlands</option>
              <option value="Nevada">Nevada</option>
              <option value="New Brunswick/Nova Scotia/Prince Edward Island">
                New Brunswick/Nova Scotia/Prince Edward Island
              </option>
              <option value="New Hampshire/Vermont">
                New Hampshire/Vermont
              </option>
              <option value="New Jersey">New Jersey</option>
              <option value="New Mexico">New Mexico</option>
              <option value="New York-North">New York-North</option>
              <option value="New York-South">New York-South</option>
              <option value="New Zealand">New Zealand</option>
              <option value="Newfoundland and Labrador">
                Newfoundland and Labrador
              </option>
              <option value="Nigeria">Nigeria</option>
              <option value="North Carolina">North Carolina</option>
              <option value="North Dakota">North Dakota</option>
              <option value="Northern Mariana Islands">
                Northern Mariana Islands
              </option>
              <option value="Ohio">Ohio</option>
              <option value="Oklahoma">Oklahoma</option>
              <option value="Oman">Oman</option>
              <option value="Ontario">Ontario</option>
              <option value="Oregon">Oregon</option>
              <option value="Panama">Panama</option>
              <option value="Paraguay">Paraguay</option>
              <option value="Pennsylvania - East">Pennsylvania - East</option>
              <option value="Pennsylvania - West">Pennsylvania - West</option>
              <option value="Philippines">Philippines</option>
              <option value="Poland">Poland</option>
              <option value="Puerto Rico">Puerto Rico</option>
              <option value="Qatar">Qatar</option>
              <option value="Quebec">Quebec</option>
              <option value="Romania">Romania</option>
              <option value="Russia">Russia</option>
              <option value="Saudi Arabia">Saudi Arabia</option>
              <option value="Singapore">Singapore</option>
              <option value="Slovakia">Slovakia</option>
              <option value="Slovenia">Slovenia</option>
              <option value="South Africa">South Africa</option>
              <option value="South Carolina">South Carolina</option>
              <option value="South Dakota">South Dakota</option>
              <option value="South Korea">South Korea</option>
              <option value="Southern New England">Southern New England</option>
              <option value="Spain">Spain</option>
              <option value="Suriname">Suriname</option>
              <option value="Switzerland">Switzerland</option>
              <option value="Tennessee">Tennessee</option>
              <option value="Texas - Region 1">Texas - Region 1</option>
              <option value="Texas - Region 2">Texas - Region 2</option>
              <option value="Texas - Region 3">Texas - Region 3</option>
              <option value="Texas - Region 4">Texas - Region 4</option>
              <option value="Texas - Region 5">Texas - Region 5</option>
              <option value="Texas - Region 6">Texas - Region 6</option>
              <option value="Thailand">Thailand</option>
              <option value="Tunisia">Tunisia</option>
              <option value="Turkmenistan">Turkmenistan</option>
              <option value="Türkiye">Türkiye</option>
              <option value="Uganda">Uganda</option>
              <option value="Unassigned">Unassigned</option>
              <option value="United Arab Emirates">United Arab Emirates</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="Utah">Utah</option>
              <option value="Vancouver Island (BC)">
                Vancouver Island (BC)
              </option>
              <option value="Vietnam">Vietnam</option>
              <option value="Virginia">Virginia</option>
              <option value="Washington">Washington</option>
              <option value="West Virginia">West Virginia</option>
              <option value="Wisconsin">Wisconsin</option>
              <option value="Wyoming">Wyoming</option>
              <option value="Zimbabwe">Zimbabwe</option>
            </select>
          </div>
          <button className="nextButton" onClick={nextStep}>
            Next
          </button>
        </>
      )}

      {step === 4 && (
        <>
          <div className="tosText">
            By continuing, you agree to our Terms of Service.
            <div className="privacyText">
              We value your privacy and data security, so rest assured your
              information is safe with us.
            </div>
          </div>
          <div className="checkboxContainer">
            <label className="checkboxLabel">
              <div
                className="checkboxIcon"
                onClick={() => setAgreeToTerms(!agreeToTerms)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M3 11C3 7.22876 3 5.34315 4.17157 4.17157C5.34315 3 7.22876 3 11 3H13C16.7712 3 18.6569 3 19.8284 4.17157C21 5.34315 21 7.22876 21 11V13C21 16.7712 21 18.6569 19.8284 19.8284C18.6569 21 16.7712 21 13 21H11C7.22876 21 5.34315 21 4.17157 19.8284C3 18.6569 3 16.7712 3 13V11Z"
                    stroke="white"
                    stroke-width="2"
                  />
                  {agreeToTerms && (
                    <path
                      d="M8 12L11 15L16 9"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  )}
                </svg>
              </div>
              <span className="checkboxText">
                By checking this box I agree that I am 13 years of age or older
                and agree to the{" "}
                <a
                  href="https://VexVibe.com/legal/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  VexVibe Terms and Conditions
                </a>{" "}
                and{" "}
                <a
                  href="https://VexVibe.com/legal/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  VexVibe Privacy Policy
                </a>
                .
              </span>
            </label>
          </div>
          <button
            className="nextButton"
            onClick={async () => {
              await updateUserProfile({ agreeToTerms });
              // Navigate to the desired page after onboarding
              navigate("/home");
            }}
            disabled={!agreeToTerms}
          >
            Agree and Continue
          </button>
        </>
      )}
    </div>
  );
};

export default OnboardingPage;
