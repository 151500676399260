import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { doc, getDoc } from "firebase/firestore";
import { auth, firestore } from "../firebase";

const TournamentSidebar = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userRegion, setUserRegion] = useState(null);
  const [tournamentActiveTab, setTournamentActiveTab] = useState("myRegion");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const apiKey =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiOTlmNmYyMzYwNTY0ZTY1NmJiODlkNTUwNmYzZjRiMmMxNDA0M2E2YjZlMWVkNWRmODVkNThjYzkzOWFiYzk0YjIyYTYwYjA4MzcyNTZmNjYiLCJpYXQiOjE3MTY2NzM2MTguMTM0NTE0MSwibmJmIjoxNzE2NjczNjE4LjEzNDUxNywiZXhwIjoyNjYzMzYyMDE4LjEyODY2NSwic3ViIjoiMTI3Nzg2Iiwic2NvcGVzIjpbXX0.jhOKVtQQI1acAwIRrAR78xw7l__XHewZzJDYBf3OMzHZ4zsIh_6jWG1ulswGCN6128v_dwPji6s8JmVQj_tmcn-KyDLQACkY8ZJHQXZLIuTSrNGXFKE-DLqFKO9an04kmZoMKtZay86bCuSMiHoJGkd9WctvUdAkjzNdsWBz8lwD48hLY5h2F5U55YhX2areEoPyioOsN1QHe9ExoXOk8fW88vpgvw7_aZnmhTp5BV6WICnIlP1PCLWxUI_EwI8X32QJ1PJhOUFJ6eEb7iM2KPT3jlJmJBy675wDCix84H11jSUCJs8F-LIXRERVERniWElTWp9tIJYRk1HRQN4bhCEnuzL8U01oO49Ghc-k9vYQnY3WlHwAJEGuPQtQt8NttI2DcBDcBM3acQXHHHfvKvk6pc1__bwM32W41Ealqku1dL5Jw8MNUCFqAthDd--mjtkyevuGJsLwu1NobdI94wNDHna8iuRg27XwRGXJKGy32scGsmeBnplhEdOBbVu44Ns1MGJuxmLLffICQWVdEl4Gwth63r4AzBiX__iMYwJw39MfWpiyYrDZRX7Z6n12m2WeeZqrdmRAuSyzVSeZIqFs6TgqbgAuFOMo68aN_6InrbMD62PvXhdH8CL6wR05_v_Gz10OJtAWdwboXB780tdd7X5mdP2KcBZHbqvSbl4";
  const seasonId = "190";

  useEffect(() => {
    loadUserRegionTournaments();
  }, []);

  const loadUserRegionTournaments = async () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userRegion = userDoc.data().region;
          setUserRegion(userRegion);
          await fetchUserRegionEvents(userRegion);
        } else {
          console.error("User document does not exist");
        }
      } else {
        console.error("No user is logged in");
      }
    } catch (error) {
      console.error("Error fetching user region:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserRegionEvents = async (region, page = 1) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://www.robotevents.com/api/v2/events`,
        {
          params: {
            season: seasonId,
            region: region,
            eventType: "tournament",
            page: page,
          },
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      const sortedEvents = response.data.data.sort(
        (a, b) => new Date(a.start) - new Date(b.start)
      );
      if (sortedEvents.length === 0) {
        setHasMore(false);
      } else {
        setEvents((prevEvents) => {
          const newEvents = sortedEvents.filter(
            (event) =>
              !prevEvents.some((prevEvent) => prevEvent.id === event.id)
          );
          return [...prevEvents, ...newEvents];
        });
      }
    } catch (error) {
      console.error("Failed to fetch events:", error);
      setEvents([]);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllWorldwideEvents = async (page = 1) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://www.robotevents.com/api/v2/events`,
        {
          params: {
            season: seasonId,
            eventTypes: ["tournament"],
            page: page,
          },
          headers: {
            Authorization: `Bearer ${apiKey}`,
            accept: "application/json",
          },
        }
      );
      const sortedAllEvents = response.data.data.sort(
        (a, b) => new Date(a.start) - new Date(b.start)
      );
      if (sortedAllEvents.length === 0) {
        setHasMore(false);
      } else {
        setEvents((prevEvents) => {
          const newEvents = sortedAllEvents.filter(
            (event) =>
              !prevEvents.some((prevEvent) => prevEvent.id === event.id)
          );
          return [...prevEvents, ...newEvents];
        });
      }
    } catch (error) {
      console.error("Failed to fetch worldwide events:", error);
      setEvents([]);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  const handleTournamentTabClick = async (tab) => {
    setTournamentActiveTab(tab);
    setPage(1);
    setHasMore(true);
    setEvents([]);

    if (tab === "myRegion") {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userRegion = userDoc.data().region;
            fetchUserRegionEvents(userRegion, 1);
          } else {
            console.error("User document does not exist");
          }
        } else {
          console.error("No user is logged in");
        }
      } catch (error) {
        console.error("Error fetching user region:", error);
      }
    } else if (tab === "all") {
      fetchAllWorldwideEvents(1);
    }
  };

  const handleCardClick = (eventSku) => {
    navigate(`/tournament/${eventSku}`);
  };

  const cardStyle = {
    background: "#1d2021",
    borderRadius: "12.84px",
    margin: "10px auto",
    padding: "16px",
    width: "90%",
    maxWidth: "350px",
    height: "auto",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    opacity: 0,
    transform: "translateY(20px)",
    animation: "fadeInUp 0.5s forwards",
    transition: "box-shadow 0.3s ease",
  };

  const fadeInUpKeyframes = `
    @keyframes fadeInUp {
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `;

  return (
    <div
      className="tournamentsSidebar"
      style={{
        width: "400px",
        height: "100vh",
        position: "fixed",
        right: 0,
        top: 0,
        background: "#121516",
        borderLeft: "1px solid var(--black, #2E313C)",
        overflowY: "auto",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      <style>
        {`
          .tournamentsSidebar::-webkit-scrollbar {
            display: none;
          }
          ${fadeInUpKeyframes}
        `}
      </style>
      <div
        style={{
          color: "var(--White, #FFF)",
          textAlign: "center",
          fontFamily: "Manrope",
          fontSize: "26px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "160%",
          letterSpacing: "0.18px",
          background: "#121516",
          zIndex: 1,
          margin: "10px",
        }}
      >
        Upcoming Tournaments
      </div>
      <div
        style={{
          display: "flex",
          width: "calc(100% - 48px)",
          padding: "4px",
          alignItems: "flex-start",
          borderRadius: "100px",
          background: "#000",
          zIndex: 1,
          marginLeft: "20px",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "8px 20px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            flex: "1 0 0",
            borderRadius: "100px",
            cursor: "pointer",
            color: "#fff",
            fontFamily: "Manrope",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "160%",
            letterSpacing: "0.12px",
            transition: "background-color 0.3s ease",
            background:
              tournamentActiveTab === "myRegion" ? "#101828" : "transparent",
          }}
          onClick={() => handleTournamentTabClick("myRegion")}
        >
          My Region
        </div>
        <div
          style={{
            display: "flex",
            padding: "8px 20px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            flex: "1 0 0",
            borderRadius: "100px",
            cursor: "pointer",
            color: "#fff",
            fontFamily: "Manrope",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "160%",
            letterSpacing: "0.12px",
            transition: "background-color 0.3s ease",
            background:
              tournamentActiveTab === "all" ? "#101828" : "transparent",
          }}
          onClick={() => handleTournamentTabClick("all")}
        >
          All Regions
        </div>
      </div>
      <div style={{ marginTop: "30px" }}>
        {events.map((event, index) => (
          <div
            key={event.id}
            className="card"
            style={{ ...cardStyle, animationDelay: `${index * 0.1}s` }}
            onClick={() => handleCardClick(event.sku)} // Updated to use event.sku
          >
            <div
              style={{
                color: "#fff",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "25px",
                letterSpacing: "0.16px",
                wordWrap: "break-word",
                borderBottom: "1px solid #gray-700",
                paddingBottom: "12px",
              }}
            >
              {event.name}
            </div>
            <div
              style={{
                color: "#B7B7B7",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "25px",
                letterSpacing: "0.12px",
                wordWrap: "break-word",
              }}
            >
              Date: {new Date(event.start).toLocaleDateString()}
            </div>
            <div
              style={{
                color: "#B7B7B7",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "25px",
                letterSpacing: "0.12px",
                wordWrap: "break-word",
              }}
            >
              Event Region: {event.location.region}
            </div>
          </div>
        ))}
      </div>
      {loading && <div></div>}
    </div>
  );
};

export default TournamentSidebar;
