import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import axios from "axios"; // Add this import

const SuccessPage = () => {
  const [status, setStatus] = useState("loading");
  const [subscriptionStatus, setSubscriptionStatus] = useState("loading");
  const [cancellingSubscription, setCancellingSubscription] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const verifyPayment = async () => {
      const sessionId = new URLSearchParams(location.search).get("session_id");
      if (sessionId) {
        try {
          const response = await axios.post(
            "https://us-central1-vexvibe-frontend.cloudfunctions.net/verifyCheckoutSession",
            { sessionId },
            { headers: { "Content-Type": "application/json" } }
          );
          if (response.data.success) {
            setStatus("success");
          } else {
            setStatus("failed");
          }
        } catch (error) {
          console.error("Error verifying payment:", error);
          setStatus("error");
        }
      } else {
        setStatus("invalid");
      }
    };

    verifyPayment();
  }, [location]);

  useEffect(() => {
    if (auth.currentUser) {
      const unsubscribe = onSnapshot(
        doc(db, "users", auth.currentUser.uid),
        (doc) => {
          if (doc.exists()) {
            setSubscriptionStatus(doc.data().subscriptionStatus || "none");
          } else {
            setSubscriptionStatus("none");
          }
        },
        (error) => {
          console.error("Error fetching subscription status:", error);
          setSubscriptionStatus("error");
        }
      );

      return () => unsubscribe();
    }
  }, [auth.currentUser, db]);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: "#121516",
    color: "#ffffff",
    fontFamily: "Arial, sans-serif",
  };

  const messageStyle = {
    fontSize: "24px",
    marginBottom: "20px",
    textAlign: "center",
  };

  const buttonStyle = {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#3498db",
    color: "#ffffff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    marginTop: "10px",
  };

  const cancelSubscription = async () => {
    if (cancellingSubscription) return;

    setCancellingSubscription(true);
    try {
      const functions = getFunctions();
      const cancelSubscriptionFunction = httpsCallable(
        functions,
        "cancelSubscription"
      );
      await cancelSubscriptionFunction();
      alert("Subscription cancelled successfully");
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      alert("Failed to cancel subscription. Please try again.");
    } finally {
      setCancellingSubscription(false);
    }
  };

  const renderContent = () => {
    switch (status) {
      case "loading":
        return <div style={messageStyle}>Verifying your payment...</div>;
      case "success":
        return (
          <>
            <div style={messageStyle}>Thank you for your subscription!</div>
            {subscriptionStatus === "loading" ? (
              <div style={messageStyle}>Loading subscription status...</div>
            ) : null}
            <button
              style={buttonStyle}
              onClick={() => navigate("/profile")}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#2980b9")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#3498db")}
            >
              Go to Profile
            </button>
          </>
        );
      case "failed":
        return (
          <div style={messageStyle}>
            Payment verification failed. Please contact support.
          </div>
        );
      case "error":
        return (
          <div style={messageStyle}>
            An error occurred. Please try again or contact support.
          </div>
        );
      default:
        return (
          <div style={messageStyle}>
            Invalid session. Please try subscribing again.
          </div>
        );
    }
  };

  return <div style={containerStyle}>{renderContent()}</div>;
};

export default SuccessPage;
